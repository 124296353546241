<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import leilaoWindow from '../../windows/leilao'
import * as Status from '../../../../domain/leiloes/helpers/LeilaoStatus'
import {gerarEdital} from '@/domain/leiloes/services'
import {donwloadFile} from '@/utils/downloadFile'

export default {
  name: 'BtnAcoesLeilao',
  inject: ['leilaoPage'],
  props: ['leilao'],
  components: {MenuOptionsItem, UPopover},
  computed: {
    Status () {
      return Status
    },
    STATUS_INTERNO_EM_PREPARACAO () {
      return Status.STATUS_INTERNO_EM_PREPARACAO
    }
  },
  methods: {
    leilaoWindow: leilaoWindow,
    windowLeilaoEvents (wid) {
      this.$uloc.window.listen(wid, {
        update: (wid, leilao) => {
          console.log('Updated leilão event')
          // this.load()
          this.$emit('updateLeilao')
        }
      })
    },
    prepararLeilao () {
      this.$uloc.dialog({
        title: 'Preparar para Leilão',
        message: 'Ao preparar para leilão, lotes não poderão mais ser removidos ou ter a numeração alterada. Tem certeza ?',
        ok: 'Sim, preparar para leilão',
        cancel: 'Não'
      })
        .then(() => {
          this.$emit('prepararLeilao')
        })
        .catch(() => {
        })
    },
    atualizarStatusInterno () {
      this.$uloc.actionSheet({
        title: 'Alterar status interno do leilão',
        grid: false,
        actions: [
          {
            label: 'Voltar para em Preparação',
            icon: 'backward',
            iconType: 'fa',
            color: 'black',
            handler: () => {
              this.$uloc.notify({color: 'grey', message: 'Voltando status para Em Preparação'})
              this.$emit('alteraStatusInterno', Status.STATUS_INTERNO_EM_PREPARACAO)
            }
          },
          {
            label: 'Iniciar Recebimento',
            icon: 'cash-register',
            iconType: 'fa',
            color: 'positive',
            handler: () => {
              this.$uloc.notify({color: 'grey', message: 'Alterando status para Em Reebimento'})
              this.$emit('alteraStatusInterno', Status.STATUS_INTERNO_EM_RECEBIMENTO)
            }
          },
          {
            label: 'Fechar Leilão',
            icon: 'stop-circle',
            iconType: 'fa',
            color: 'blue',
            handler: () => {
              this.$uloc.notify({color: 'grey', message: 'Fechando leilão...'})
              this.$emit('alteraStatusInterno', Status.STATUS_INTERNO_ENCERRADO)
            }
          },
          {}, // separator
          {
            label: 'Cancelar',
            icon: 'times',
            iconType: 'fa',
            color: 'negative',
            handler: () => {
              // this.$uloc.notify('Operação cancelada')
            }
          }
        ]
      })
        .catch(() => {
          // dimiss...
        })
    },
    gerarEdital () {
      gerarEdital(this.leilao.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>

<template>
  <e-btn align="left" label="Ações">
    <span class="m-l-md"></span>
    <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7"
            style="font-size: 10px !important;" />
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item v-if="cracl(null, ['sl/leilao/update'])" icon="edit" icon-type="fa" label="Editar leilão" @click="leilaoWindow(leilao.id)" close />
          <menu-options-item v-if="cracl(null, ['sl/leilao/update', 'sl/leilao/cadastrar-documento'])" icon="upload" icon-type="fa" label="Anexar arquivos" @click="leilaoPage && leilaoPage.$refs.docs.uploadDocumento(leilao)" close />
          <menu-options-item icon="file-pdf" icon-type="fa" label="Gerar Edital" @click="gerarEdital" close />
          <menu-options-item v-if="leilao.statusInterno === STATUS_INTERNO_EM_PREPARACAO && cracl(null, ['sl/leilao/alterar-status-interno'])" @click="prepararLeilao" class="bg-green-2"
                             icon="clipboard-check"
                             icon-type="fa" label="Preparar para leilão" close />
          <menu-options-item @click="atualizarStatusInterno" class="bg-purple-2"
                             v-if="leilao.statusInterno !== STATUS_INTERNO_EM_PREPARACAO && cracl(null, ['sl/leilao/alterar-status-interno'])" icon="clipboard-check"
                             icon-type="fa" label="Atualizar status interno" close />
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
