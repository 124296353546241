<template>
  <a class="text-grey-9" @click="$emit('click')">
    <u-icon name="list-alt" type="fa" />
    <span class="m-l-xs text-grey-7 font-10"
          v-if="!value">clique para mostrar</span>
    <span class="m-l-xs text-grey-7 font-10" v-else>clique para ocultar</span>
  </a>
</template>

<script>
export default {
  name: 'BtnMostrar',
  props: {
    value: {required: true}
  },
  data () {
    return {
    }
  }
}
</script>
