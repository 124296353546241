<template>
  <div class="pda-nav-label no-select">
    <div class="inline-block m-r-xs" v-for="(item, i) in target" :class="bindClass(item)">{{item.label}} <span v-if="i !== levels-1">&raquo;</span> </div>
  </div>
</template>

<script>
export default {
  name: 'PdaNavLabel',
  props: {
    target: Array
  },
  computed: {
    levels () {
      if (!Array.isArray(this.target)){
        return 0
      }
      return this.target.length
    }
  },
  methods: {
    bindClass (item) {
      let css = []
      if (item.bold) {
        css.push('font-bold')
      }
      return css
    }
  }
}
</script>

<style scoped>

</style>
