export default {
    name: 'TarefasMixin',
    data() {
        return {
            isSuporte: false
        }
    },
    inject: {
        erplayout: {
            default() {
                console.error('Main needs to be child of ErpLayout')
            }
        }
    },
    beforeMount() {
        this.testRoute()
    },
    mounted() {
        window.setTimeout(() => {
            this.erplayout.erpheader.setMenuModuleToggle(true)
        }, 10)
        document.body.classList.add('task-module-body')
        this.erplayout.erpheader.menu.setActiveMenu('configuracoes')
    },
    destroyed() {
        document.body.classList.remove('task-module-body')
        // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
    },
    beforeDestroy() {
        this.erplayout.erpheader.setMenuModuleToggle(false)
    },
    methods: {
        testRoute() {
            if (this.$route.query.suporte && this.$route.query.suporte == '1') {
                this.isSuporte = true
            } else {
                this.isSuporte = false
            }
        }
    }
}
