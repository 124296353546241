<script>
import LayoutPweb from '../../layout/components/LayoutPweb'
import Mixin from '../mixin'
import ColunasExportArrematantesSelect from "components/arrematante/components/include/export-base/ColunasExportArrematantesSelect";
import {ErpSField, ErpInput, ErpCheckbox} from 'uloc-vue-plugin-erp'
import {getColunasSelectValues} from "components/arrematante/components/include/export-base/colunas-relatorio-arrematantes";
import {exportar} from "@/domain/arrematante/services/arrematante-export";
import createDownloadLink from "@/utils/create-download-link";

export default {
  name: 'ExportarBaseArrematantes',
  mixins: [Mixin],
  components: {
    ColunasExportArrematantesSelect,
    LayoutPweb,
    ErpSField,
    ErpCheckbox
  },
  data () {
    return {
      lista: [],
      loading: false,
      marcarTudo: true
    }
  },
  computed: {
    isTodosMarcados: {
      get () {
        return this.lista.length === this.colunasSelectValues.length
      },
      set (value) {
        this.lista = value ? this.colunasSelectValues : []
      }
    },
    colunasSelectValues () {
      return getColunasSelectValues()
    }
  },
  created () {
    this.lista = this.colunasSelectValues
  },
  methods: {
    exportarBase () {
      this.loading = true
      if (!this.lista.length) return
      const colunas = this.lista.join(',')
      exportar(colunas)
      .then(response => {
        createDownloadLink(response.data, 'base-arrematantes')
      })
      .catch(error => {
        this.alertApiError(error)
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  meta: {
    title: 'Arrematantes - Exportar',
    name: 'Arrematantes - Exportar'
  }
}
</script>

<template>
  <layout-pweb>
    <h2>Exportação de arrematantes</h2>
    <p>Exporte a base de dados de arrematantes para uso em mailing ou outros sistemas</p>

    <div class="m-t-lg">
      <colunas-export-arrematantes-select multiple v-model="lista"></colunas-export-arrematantes-select>

      <erp-s-field label="Marcar todas as colunas" class="m-t" view="lr">
        <erp-checkbox v-model="isTodosMarcados" />
      </erp-s-field>

      <u-btn @click="exportarBase" class="bg-dark m-t-md" color="white" label="Exportar" :loading="loading" :disable="!lista.length" />
    </div>

  </layout-pweb>
</template>

<style lang="stylus">

</style>

