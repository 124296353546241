<template>
  <div class="stats servicos">
    <div class="mtab">
      <div class="title">Serviços</div>

      <div class="monitor-servicos">
        <div>
          <div class="stitle">API</div>
          <div class="legend">Estável. Última atualização: 3 segundos atrás</div>
        </div>

        <div>
          <div class="stitle">Certificados</div>
          <div class="legend">
            <div class="service-status">
              <div class="check">
                API <i class="check-icon check-ok"></i>
              </div>
              <div class="check">
                WEBSOCKET <i class="check-icon check-ok"></i>
              </div>
              <div class="check">
                SITE <i class="check-icon check-ko"></i>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="stitle">Versões</div>
          <div class="legend">API: 1.0.27 | WEBSOCKET: 0.0.2 | SITE: 0.1.21</div>
        </div>

        <div>
          <div class="stitle">Software Comitente</div>
          <div class="legend service-status">
            <div class="check">
              <i class="check-icon check-ok"></i>
            </div>
          </div>
        </div>

        <div>
          <div class="stitle">Site</div>
          <div class="legend service-status">
            <div class="check">
              <i class="check-icon check-ok"></i>
            </div>
          </div>
        </div>

        <div>
          <div class="stitle">Aplicativo Arrematante</div>
          <div class="legend service-status">
            <div class="check">
              <i class="check-icon check-ok"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Servicos',
  inject: ['controlador'],
  computed: {}
}
</script>
