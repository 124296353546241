export default [
  {
    desc: true,
    name: 'Telas'
  },
  {
    id: 'hom',
    name: 'Visão Geral',
    icon: 'dashboard',
    iconType: 'material',
    iconStyle: 'regular',
    class: '',
    route: 'leiloes.show'
  },
  {
    id: 'acc',
    name: 'Acomp. Comitente', // comitente
    icon: 'history',
    iconType: 'fa',
    iconStyle: 'regular',
    class: '',
    route: 'leiloes.monitor-comitente',
    roles: ['!ROLE_COMITENTE']
  },
  {
    id: 'aco',
    name: 'Aprovação de Condicionais', // comitente (retirar do ERP)
    icon: 'history',
    iconType: 'fa',
    iconStyle: 'regular',
    class: '',
    route: 'leiloes.acompanhamento-comitente',
    roles: ['ROLE_COMITENTE']
  },
  {
    id: 'aco2',
    name: 'Aprovação de Cond. (v2)', // comitente (retirar do ERP)
    icon: 'history',
    iconType: 'fa',
    iconStyle: 'regular',
    class: '',
    route: 'leiloes.acompanhamento-comitente2',
    roles: ['ROLE_COMITENTE']
  },
  {
    id: 'control',
    name: 'Controle do Leilão', // menu - ok
    icon: 'gamepad',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    route: 'leiloes.controlador',
    roles: ['!ROLE_COMITENTE']
  },
  {
    id: 'leiloeiro',
    name: 'Tela Leiloeiro', // principal - ok
    icon: 'id-card',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    route: 'leiloes.tela-leiloeiro',
    roles: ['!ROLE_COMITENTE']
  },
  {
    id: 'telao',
    name: 'Telão Leilão (TV/Telão)', // transmissão - ok
    icon: 'desktop-alt',
    iconType: 'fa',
    iconStyle: 'regular',
    class: '',
    route: 'leiloes.apresentacao-telao-seletor'
  },
  {
    id: 'acc',
    name: 'Monitor Comitente', // comitente
    icon: 'dvr',
    iconType: 'material',
    iconStyle: 'regular',
    class: '',
    route: 'leiloes.monitor-comitente',
    roles: ['!ROLE_COMITENTE']
  },
  /*{
    id: 'apresentacao-lotes',
    name: 'Apresentação Lotes (Pré-Leilão)',
    icon: 'sliders-h',
    iconType: 'fa',
    iconStyle: 'regular',
    class: '',
    route: 'leiloes.apresentacao-lotes-seletor'
  },*/
  /*{
    id: '',
    name: 'Telas para TV',
    icon: 'desktop-alt',
    iconType: 'fa',
    iconStyle: 'regular',
    class: ''
  },*/
  {
    desc: true,
    name: 'Supervisão' // principal
  },
  /*{
    id: 'pds',
    name: 'PDS',
    icon: 'terminal',
    iconType: 'erp',
    iconStyle: '',
    class: ''
  },*/
  {
    id: 'hbl',
    name: 'Habilitações', // menu - ok
    icon: 'check',
    iconType: 'fa',
    iconStyle: '',
    class: '',
    route: 'leiloes.habilitacoes'
  },
  {
    id: 'autolan',
    name: 'Lances Automáticos', // principal - ok
    icon: 'robot',
    iconType: 'fa',
    iconStyle: '',
    class: '',
    route: 'leiloes.lances.automaticos'
  },
  {
    id: 'cbl',
    name: 'Cobrança', // fechamento - ok
    icon: 'list',
    iconType: 'erp',
    iconStyle: '',
    class: '',
    route: 'leiloes.cobranca',
    roles: ['!ROLE_COMITENTE']
  },
  {
    id: 'pgl',
    name: 'Pagamento de Lotes', // fechamento - ok
    icon: 'thermal-print',
    iconType: 'erp',
    iconStyle: '',
    class: '',
    prerouter: function (router, leilao) {
      router.push({name: 'pda.main', query: {
          app: 'app-iniciar-atendimento-leilao',
          leilao: leilao.id
        }
      })
    },
    route: 'pda.main',
    roles: ['!ROLE_COMITENTE']
  },
  {
    id: 'pda',
    name: 'Ponto de Atendimento', // fechamento
    icon: 'contact',
    iconType: 'erp',
    iconStyle: '',
    class: '',
    route: 'pda.main',
    roles: ['!ROLE_COMITENTE']
  },
  {
    id: 'monitor',
    name: 'Monitoramento', // menu - ok
    icon: 'monitor',
    iconType: 'erp',
    iconStyle: '',
    class: '',
    route: 'leiloes.monitor',
    routeQueryParams: (l) => { return {leilao: l.id}}
  },
  {
    id: 'live',
    name: 'Transmissão', // transmissao - ok
    icon: 'video',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    route: 'leiloes.live',
    roles: ['!ROLE_COMITENTE']
  },
  {
    desc: true,
    name: 'Relatórios'
  },
  {
    id: 'rro', // ok
    name: 'Resumo',
    icon: 'chart-pie',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    route: 'leiloes.relatorios.resumo'
  },
  {
    id: 'rro', // ok
    name: 'Saída/Vendas',
    icon: 'chart-line',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    route: 'leiloes.relatorios.saida'
  },
  {
    id: 'rro', // ok
    name: 'Lances',
    icon: 'chart-bar',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    route: 'leiloes.relatorios.lances'
  },
  {
    id: 'relatorios', // ok
    name: 'Mais Relatórios',
    icon: 'layer-plus',
    iconType: 'fa',
    iconStyle: 'duotone',
    class: '',
    route: 'leiloes.relatorios'
  },
  {
    desc: true,
    name: 'Marketing',
    roles: ['!ROLE_COMITENTE']
  },
  {
    id: 'rro',
    name: 'Campanha do leilão',
    icon: 'bullseye-arrow',
    iconType: 'fa',
    iconStyle: 'solid',
    class: '',
    roles: ['!ROLE_COMITENTE']
  },
]
