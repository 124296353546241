<template>
  <div>
    <h2 class="diviser">
      Participação em leilões
    </h2>

    <div class="m-t">
      <historico-leiloes :leiloes="bem.participacaoLeiloes"/>
    </div>

<!--    <h2 class="diviser m-t-lg">
      Registro de sugestões de datas
    </h2>

    <div class="m-t">
      Nenhuma data cadastrada
    </div>-->

    <div class="m-t-lg">
      <historico-propostas :bem="bem" :router="$router" />
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import HistoricoLeiloes from "@/components/bem/components/include/HistoricoLeiloes"
import HistoricoPropostas from "@/components/bem/components/include/HistoricoPropostas"

export default {
  name: "BemLeiloes",
  components: {
    HistoricoPropostas,
    HistoricoLeiloes
  },
  mounted() {
    console.log('ROUTEERRRRRRR', this.router)
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {}
}
</script>
