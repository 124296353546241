<template>
  <erp-s-field
      :view="fieldView || 'tl'"
      :label="label || 'Data'"
  >
    <erp-input
        v-if="showed"
        @input="__set"
        :placeholder="placeholder || 'dd/mm/yyyy'"
        v-model="model"
        :disable="disable"
        v-mask="'##/##/####'"
    ></erp-input>
    <erp-input
        v-else
        @input="__set"
        :placeholder="placeholder || 'dd/mm/yyyy'"
        v-model="model"
        :disable="disable"
    ></erp-input>
  </erp-s-field>
</template>

<script>
import InputMixin from './mixin'
export default {
  name: 'DateInput',
  mixins: [InputMixin],
  data () {
    return {
      showed: true // To prevent v-mask bind bug
    }
  },
  watch: {
    value (val) {
      if (val && val.length !== 10 && val.length > 1) {
        console.log('Ok')
        return
      }
      this.showed = false
      setTimeout(() => {
        this.showed = true
      }, 1)
    }
  }
}
</script>
