<template>
  <erp-box style="min-height: 216px" :label="label || 'Imagem'">
    <div class="bem-img-thumb">
      <a target="_blank" :href="image ? image.full.url : '#'"
         v-if="tmp || (image && image.thumb)">
        <img id="frame" :src="tmp || (image.thumb.url || image.thumb)">
      </a>
      <u-icon v-else name="image" />
    </div>
    <p class="small text-center font-thin"><i>{{legend}}</i></p>
    <div v-if="!disable" class="text-center m-b-xs">
      <label for="input">
        <e-btn @click="$refs.input.click()" icon="upload" icon-type="fa" class="m-r-sm" />
        <input @change="inputListener($event)" type="file" accept="image/*" id="input" ref="input"
               v-show="false">
      </label>
      <e-btn @click.prevent="deleteImage" :disable="!image && !tmp" icon="times" icon-type="fa" icon-color="negative" />
    </div>
  </erp-box>
</template>

<script>
import {ErpBox} from 'uloc-vue-plugin-erp'
import {fileToBase64} from '../../utils/fileToBase64'

export default {
  name: 'Imagem',
  props: ['image', 'label', 'legend', 'disable'],
  data () {
    return {
      changed: false,
      tmp: null,
      type: null,
      delete: false
    }
  },
  components: {
    ErpBox
  },
  mounted () {
    this.$inputImage = document.getElementById('input')
  },
  beforeDestroy () {
  },
  methods: {
    inputListener (e) {
      let frame = document.getElementById('frame')
      this.$file = e.target.files[0];
      // Do something with the image file.
      this.tmp = URL.createObjectURL(this.$file)
      this.changed = true
      this.delete = false
      this.type = 'upload'
      window.setTimeout(() => {
        this.updateImage()
        e.target.value = ''
      }, 50)
      // frame.src = URL.createObjectURL(file);
    },
    deleteImage () {
      this.$uloc.dialog({
        title: 'Você está prestes a remover uma imagem',
        message: 'Você tem certeza que deseja remover esta imagem?',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        this.changed = true
        this.tmp = null
        this.delete = true
        this.$file && delete this.$file
        this.$emit('toDelete', null)
        this.$emit('update', null)
      }).catch(() => {
      })
    },
    getUploadedFileContent () {
      const file = this.$file
      return fileToBase64(file)
    },
    hasChanged () {
      return this.changed
    },
    toDelete () {
      return this.delete
    },
    getImage () {
      return this.getUploadedFileContent()
    },
    updateImage () {
      this.getImage()
        .then(file => {
          this.$emit('update', file)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>
