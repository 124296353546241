<script>
import LayoutPweb from '../../layout/components/LayoutPweb'
import ProfileMixin from '../mixin'
export default {
  name: 'Dashboard',
  mixins: [ProfileMixin],
  components: {
    LayoutPweb,
    // Layout,
  },
  data () {
    return {}
  },
  computed: {},
  methods: {},
  meta: {
    title: 'Minhas Mensagens',
    name: 'Minhas Mensagens'
  }
}
</script>

<template>
  <layout-pweb>
    <h2>Minhas mensagens e notificações</h2>
    <p>Defina suas preferências gerais do seu acesso ao software</p>

    <div class="m-t-lg">
      Nenhuma mensagem ou notificação disponível.
    </div>

  </layout-pweb>
</template>

<style lang="stylus">

</style>

