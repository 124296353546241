<template>
  <a class="pda-menu-item btn-pda">
    <div class="inline-block m-r-sm" style="width: 24px" v-if="icon">
      <u-icon class="fa-fw" v-if="!iconType || iconType === 'fa' || iconType === 'material'" style="font-size: 16px" :name="icon" :type="iconType || 'fa'" :icon-style="iconStyle || 'light'" />
      <i v-else class="fa-fw" :class="icon"></i>
    </div>
    <span class="menu-label">{{label}}</span> <span v-if="shortcut" class="menu-shortcut">{{shortcut}}</span>
  </a>
</template>

<script>
export default {
  name: 'PdaMenuItem',
  props: ['label', 'shortcut', 'icon', 'iconType', 'iconStyle']
}
</script>
