export default [
    {
        path: '/financeiro', component: () => import('./components/Page'), name: 'financeiro.page', children: [
            {path: '', component: () => import('./components/Dashboard'), name: 'financeiro.dashboard'},
            {
                path: 'contas', component: () => import('./components/Contas'), name: 'financeiro.contas',
                /*children: [
                    {
                        path: 'registro',
                        name: 'contas.registro',
                        component: () => import('./components/window/ContaWindow')
                    }
                ]*/
            },
            {path: 'cc', component: () => import('./components/CentroCusto'), name: 'financeiro.centro-custos'},
            {path: 'categorias', component: () => import('./components/Categorias'), name: 'financeiro.categorias'},
        ]
    }
]
