<template>
  <u-input :autofocus="autofocus" @keydown.native.enter="$emit('enter')" @input="$emit('input', null)" ref="definput" v-model="terms" :placeholder="placeholder" :hide-underline="hideUnderline"
  :class="[{hasIcon: icon}, css]">
    <template v-if="icon" v-slot:before>
      <u-icon :color="iconColor" :name="icon" :type="iconType" class="m-l-sm" />
    </template>
    <autocomplete-wrapper @search="search" @selected="selected" :max-results="50" :debounce="200" :min-characters="minCharacters" />
    <sl-loading v-if="isLoading || ($refs.definput && $refs.definput.isLoading)" content="" class="size-18" />
    <div v-if="isNew" class="new"><span class="lbl">Novo</span></div>
    <u-icon v-if="!isLoading && caret && !isNew" name="chevron-down" class="pos-abt absolute-right m-r-sm text-blue-grey-5" type="fa" />
    <slot />
  </u-input>
</template>

<script>
import {UInput} from "uloc-vue"
import AutocompleteWrapper from "@/components/layout/components/AutocompleteWrapper"
import SlLoading from "@/components/layout/components/Loading"

export default {
  name: "Autocomplete",
  components: {SlLoading, AutocompleteWrapper, UInput},
  props: {
    value: {
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    iconType: {
      type: String,
      default: 'fa'
    },
    iconColor: {
      type: String,
      default: 'grey-7'
    },
    search: {
      type: Function,
      required: true
    },
    minCharacters: {
      type: Number,
      default: 3
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    caret: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    hideUnderline: {
      type: Boolean,
      default: true
    },
    placeholder: {
      required: false
    },
    parse: {
      type: Function,
      required: false
    },
    layout: {
      type: String,
      default: 'default'
    },
    handleNew: {
      type: Function,
      required: false
    },
    transformModel: {
      type: Boolean,
      default: false
    },
  },
  data () {
    let data = {
      loading: false,
      terms: this.parse ? this.parse(this.value) : (this.value && this.value.value ? this.value.value : this.value),
      selectedValue: null
    }
    if (this.transformModel) {
      data.terms = this.value
    }
    return data
  },
  watch: {
    terms (v) {
      if (this.transformModel) {
        this.$emit('input', v)
      }
    }
  },
  computed: {
    isNew () {
      return this.selectedValue && this.selectedValue.isNew && this.terms === this.selectedValue.value
    },
    css () {
      const css = []
      if (this.layout === 'default') {
        css.push('erp-input erp-autocomplete float-icon input-hide-loading')
      } else {
        css.push(this.layout)
      }
      return css
    }
  },
  methods: {
    /*search (terms, done) {
      setTimeout(() => {
        const extra = {
          label: `Adicionar "${this.terms}" como nova organização`,
          value: this.terms,
          isNew: true
        }
        let result = filter(terms, {field: 'value', list: parseCountries()})
        result.push(extra)
        done(result)
      }, 1000)
    },*/
    selected (item) {
      this.handleNew && this.handleNew()
      if (item && item.link && item.click) {
        item.click()
        return
      }
      if (this.transformModel) {
        if (item && item.value) {
          item = item.value
        }
      }
      this.selectedValue = item
      this.$emit('input', item)
    },
    set (val) {
      this.terms = val
    },
    focus () {
      this.$refs.definput.focus()
    }
  }
}
</script>
