<script>
import Layout from '../../layout/Layout'
import Menu from '../menu'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultMixin from '../mixin'

export default {
  name: 'Bens',
  mixins: [DefaultMixin],
  components: {
    Layout
  },
  data () {
    return {
    }
  },
  computed: {
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Bens',
    name: 'Bens'
  }
}
</script>

<template>
  <layout class="erp-list" :menu="menu" menu-style="compact" menu-active="bens">
    <router-view />
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
