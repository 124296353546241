<script>
import {UIcon} from 'uloc-vue'

export default {
  name: 'DashOptions',
  props: {
    menu: Array
  },
  data () {
    return {
    }
  },
  methods: {
    url (m) {
      this.$router.push(m.url)
    },
    bindClick (m) {
      m.clickEvent && typeof m.clickEvent === 'function' ? m.clickEvent.call(this, m) : this.url(m)
    }
  },
  components: {UIcon}
}
</script>
<template>
  <div class="dash-options infos-dashboard">
    <a @click="bindClick(m)" v-for="m in menu" :key="m.name" class="item">
      <div class="cont-icon">
        <u-icon :name="m.icon" :type="m.iconType || 'fa'" :icon-style="m.iconStyle || 'regular'" />
      </div>
      <h5>{{ m.title }}</h5>
      <p>{{ m.description }}</p>
    </a>
  </div>
</template>

<style lang="stylus">
  .dash-options {
    display: flex;
    justify-content: center;
    margin-top: 44px;

    .item {
      max-width: 168px;
      text-align: center;
      margin: 0 20px;
      color: #333333;
      transition 0.3s all

      &:hover {
        color #000000
        .cont-icon i{
          transform translateY(-5px)
        }
      }

      .cont-icon {
        display: flex;
        justify-content: center;

        i {
          font-size: 40px;
          color: #5C55C4;
          transform translateY(0px)
          transition 0.3s all
        }
      }

      h5 {
        font-weight: 600;
        font-size: 16px;
        margin: 20px 0 5px 0;
        line-height: initial;
        letter-spacing: 0.02em;
      }

      p {
        font-size: 12px;
      }
    }
  }
</style>
