export default [
  {
    path: '/validade-documentos', component: () => import('./Main'), name: 'validadeDocumentos.main',
    children: [
      {
        path: '', component: () => import('./Documentos'), name: 'validadeDocumentos.lista', children: [
          // { path: '/validadeDocs/:id', name: 'task.show', component: () => import('./components/OpenValDocs')}
        ]
      }
    ]
  }
]
