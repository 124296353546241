import {listDocumentoRequeridoArrematante, deleteDocumentoRequeridoArrematante} from '@/domain/cadastro/services'
import window from './window/window.js'

export default {
  pageTitle: 'Documentos Requeridos de Arrematante',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'tipoPessoa',
      required: true,
      label: 'Tipo de Pessoa',
      align: 'left',
      field: 'tipoPessoa',
      sortable: true,
      parser: function (props) {
        switch (props.tipoPessoa) {
          case 0:
          case null:
            return 'Todos'
          case 1:
            return 'Pessoa Física'
          case 2:
            return 'Pessoa Jurídica'
        }
      }
    },
    {
      name: 'obrigatorio',
      required: true,
      label: 'Obrigatório',
      align: 'left',
      field: 'obrigatorio',
      sortable: true,
      parser: function (props) {
        return props.obrigatorio ? 'Sim' : 'Não'
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'ordem',
      required: true,
      label: 'Ordem',
      align: 'left',
      field: 'ordem',
      sortable: true,
      parser: function (props) {
        return props.order
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listDocumentoRequeridoArrematante,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    },
    excluir: deleteDocumentoRequeridoArrematante
  }
}
