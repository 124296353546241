<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Fipe Ano Fabricação'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'

export default {
  name: 'FipeAnoFabricacaoSelect',
  mixins: [SelectMixin],
  props: ['anoModelo'],
  data () {
    return {
      asyncdata: []
    }
  },
  watch: {
  },
  computed: {},
  mounted () {
  },
  components: {},
  methods: {
    define (d) {
      let n = Number(String(d).replace(/\D/gi, ''))
      if (n === 0) {
        let today = new Date()
        let year = today.getFullYear()
        this.asyncdata = [
          {
            label: year,
            value: String(year)
          }
        ]
        return
      }
      this.asyncdata = [
        {
          label: n,
          value: String(n)
        },
        {
          label: n-1,
          value: String(n-1)
        },
      ]
    }
  }
}
</script>
