<script>
import LayoutPweb from '../layout/components/LayoutPweb'
import {LocalStorage, UIcon} from 'uloc-vue'
import ErpSField from 'uloc-vue-plugin-erp/components/form/SimpleField'
import ErpInput from 'uloc-vue-plugin-erp/components/form/input'
import alterarSenhaWindow from './window/alterarSenhaWindow'
import {fileToBase64} from '@/utils/fileToBase64'
import {updateUserProfilePicture, updateSimple, findSimple} from '@/domain/usuario/services'

export default {
  name: 'Dashboard',
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  components: {
    ErpInput,
    ErpSField,
    LayoutPweb,
    UIcon
    // Layout,
  },
  data () {
    return {
      data: {
        email: '',
        celular: '-'
      },
      edit: false,
      profilePicture: '',
      loadingPicture: false,
      mockProfilePicture: 'http://www.gravatar.com/avatar/xxx?s=256',
      loading: false
    }
  },
  computed: {},
  mounted () {
    this.erplayout.erpheader.menu.setActiveMenu('principal')
    const {email, photo} = this.$uloc.auth.session.user
    console.log(this.$uloc.auth.session.user)
    this.data.email = email
    this.profilePicture = photo
    this.load()
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    enableEdit () {
      this.edit = true
    },
    setFile (evt) {
      this.loadingPicture = true
      const file = evt.target.files[0]
      setTimeout(() => {
        fileToBase64(file)
            .then(response => {
              this.updateProfilePicture(response)
            })
            .catch(error => {
              alert('Ocorreu um erro na leitura do arquivo')
              this.loadingPicture = false
            })
      }, 50)
    },
    updateProfilePicture (data) {
      updateUserProfilePicture(this.$uloc.auth.session.user.id, {data, filename: 'foto'})
          .then(response => {
            this.profilePicture = response.data.image
            let user = LocalStorage.get.item('auth.user')
            user.photo = response.data.image
            LocalStorage.set('auth.user', user)
            this.$uloc.auth.session.user = user
            this.$uloc.auth.loginByStorage()
            console.log(response.data)
            this.loadingPicture = false
            // @TODO: session não está reativo, por isto não atualiza a foto. Verificar
          })
          .catch(error => {
            alert('Ocorreu um erro ao alterar a foto de usuário')
            console.log(error)
            this.loadingPicture = false
          })
    },
    editUser () {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.data))
      const original = data
      data.phone = data.celular?.replace(/\D/g, '')
      updateSimple(this.$uloc.auth.session.user.id, data)
          .then(response => {
            console.log(response.data)
            this.dg()
            this.edit = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.data = original
          })
          .finally(() => {
            this.loading = false
          })
    },
    openEditPassword () {
      this.alterarSenhaWindow()
    },
    load () {
      findSimple(this.$uloc.auth.session.user.id)
          .then(response => {
            this.data.celular = response.data?.person?.phoneNumbers[0]?.phoneNumber
            this.data.email = response.data?.person?.emails[0]?.email
          })
    },
    alterarSenhaWindow
  },
  meta: {
    title: 'Meu Perfil',
    name: 'Meu Perfil'
  }
}
</script>

<template>
  <layout-pweb>
    <div class="user-profile flex">
      <div>
        <div class="u-img-rounded hoverable pos-rlt profile-image">
          <!--<img :src="$uloc.auth.session.user.image" />-->
          <img :src="profilePicture || mockProfilePicture" />
          <div class="camera-hover hidden-nohover" @click="$refs.profileInput.click()" v-if="!loadingPicture">
            <u-icon name="camera" type="fa" />
          </div>
          <div class="camera-hover" v-else>
            <strong>Carregando...</strong>
          </div>
          <input type="file" ref="profileInput" accept="image/*" @change="setFile($event)" v-show="false">
        </div>
      </div>
      <div class="m-l-lg m-t col-grow">
        <h2>Olá {{String($uloc.auth.session.user.name)}}!</h2>
        <p>{{$uloc.auth.session.user.username}}</p>
        <div class="m-t">
          <u-btn label="Alterar minha senha" rounded push icon="key" icon-type="fa" no-caps @click="openEditPassword"/>
          <u-btn label="Desconectar" rounded push icon="sign-out" icon-type="fa" no-caps flat class="m-l text-red-5" @click="$router.push('/logout')"  />
        </div>
      </div>
    </div>

    <div class="form-dados">
      <h4 class="subdiv">Dados para comunicação
        <u-btn label="Editar" rounded push icon="edit" icon-type="fa" no-caps class="m-l text-blue" @click="enableEdit" />
      </h4>
      <p class="igroup">
        <label>E-mail</label>
        <erp-s-field no-label v-if="edit">
          <erp-input size="2" v-model="data.email" />
        </erp-s-field>
        <span class="field" v-else>{{data.email}}</span>
      </p>
      <p class="igroup">
        <label>Celular</label>
        <erp-s-field no-label v-if="edit">
          <erp-input size="2" v-model="data.celular" v-mask="'(##) # ####-####'"/>
        </erp-s-field>
        <span class="field" v-else>{{data.celular|formataTelefone}}</span>
      </p>
      <u-btn label="Enviar" :loading="loading" rounded push icon-type="fa" icon="check" no-caps class="pull-right text-blue-6" v-if="edit" @click="editUser"/>
    </div>

  </layout-pweb>
</template>

<style src="./assets/default.styl" lang="stylus"></style>

