<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Modelo'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <!--<erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :disable="disable"
        :simpleBorder="simpleBorder"
    ></erp-select>-->
    <modelo-input
        @input="__set"
        :size="1"
        :tipo="tipo"
        :disable="disable"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
        :disable-create="disableCreate || false"
        :marca="marca"
        :finded-data-obj="findedDataObj"
        @updateSelected="(v) => $emit('updateSelected', v)"
    />
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import ModeloInput from '../../../cadastro/components/modelos/window/ModeloInput'

export default {
  name: 'ModeloSelect',
  mixins: [SelectMixin],
  props: {
    marca: {
      required: true
    },
    tipo: {
      required: false
    },
    disableCreate: {
      required: false,
      type: Boolean
    }
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {},
  mounted () {
  },
  components: {ModeloInput},
  watch: {
    marca (v) {
    }
  },
  methods: {
  }
}
</script>
