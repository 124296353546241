import http from '../../../utils/services/http'

export const list = (bem, limit = 100, page = 1, filtros = '') => {
    let url = `/api/bem/${bem}/historicoProprietarios?page=` + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (bem, id) => {
    let url = `/api/bem/${bem}/historicoProprietarios/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newProprietario = (bem, data) => {
    let url = `/api/bem/${bem}/historicoProprietarios`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateProprietario = (bem, proprietario, method = 'PUT') => {
    let url = `/api/bem/${bem}/historicoProprietarios/${proprietario.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, proprietario)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteProprietario = (bem, id) => {
    let url = `/api/bem/${bem}/historicoProprietarios/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
