<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Conservação'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
        v-if="!setting"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {listConservacao as list} from '../../../../domain/bem/services'

export default {
  name: 'ConservacaoSelect',
  mixins: [SelectMixin],
  props: {
  },
  data () {
    return {
      asyncdata: [],
      setting: false
    }
  },
  computed: {},
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      this.loading = true
      list()
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.result)) {
            return
          }
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: obj.nome,
              value: obj.id
            }
          })

          !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
          this.asyncdata = list
        })
        .catch((error) => {
          this.loading = false

        })
    },
    autoSet (label, value = null) {
      console.log('Autoset Conservação ', label)
      const f = this.asyncdata.find(f => f.label === label)
      console.log(f)
      if (f) {
        this.setting = true
        this.$emit('input', f.id)
        this.$nextTick(() => {
          this.setting = false
        })
      }
    }
  }
}
</script>
