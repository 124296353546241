<template>
  <div class="pda">
    <div class="pda-container">
      <pda-menu :app="app" :menu-view="menuView" />
      <div class="pda-content">
        <pda-nav-label :target="labelTarget" />
        <component v-bind:is="currentTabComponent" :parse-props="parseProps" class="pda-content-app"></component>
      </div>
    </div>
    <pda-stats />
  </div>
</template>

<script>
import DefaultMixin from './mixin'
import PdaStats from './components/Stats'
import PdaMenu from './components/PdaMenu'
import PdaNavLabel from './components/NavLabel'
import Vue from 'vue'
import Home from './components/apps/Home'
import IniciarAtendimento from './components/apps/IniciarAtendimento'
import PesquisaArrematante from './components/apps/PesquisaArrematante'
import Arrematante from './components/apps/Arrematante'
import PagamentoLote from './components/apps/PagamentoLote'
import PesquisaLote from './components/apps/PesquisaLote'
import AtendimentoLeilao from './components/apps/AtendimentoLeilao'
import appList from './components/apps/app'

Vue.component(appList.home, Home)
Vue.component(appList.atendimentoArrematante, IniciarAtendimento)
Vue.component(appList.pesquisaArrematante, PesquisaArrematante)
Vue.component(appList.arrematante, Arrematante)
Vue.component(appList.pagamentoLote, PagamentoLote)
Vue.component(appList.pesquisaLote, PesquisaLote)
Vue.component(appList.atendimentoLeilao, AtendimentoLeilao)

export default {
  name: 'PdaMain',
  components: {PdaNavLabel, PdaMenu, PdaStats},
  mixins: [DefaultMixin],
  provide: function () {
    return {
      pda: this
    }
  },
  data () {
    return {
      labelTarget: null,
      app: appList.home,
      menuView: ['ata', 'pqa', 'pql', 'atl'],
      return: null,
      parseProps: null
    }
  },
  computed: {
    currentTabComponent: function () {
      return this.app
    }
  },
  meta: {
    title: 'PDA',
    name: 'PDA'
  },
  mounted () {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
      if (this.$route.query.app) {
        this.app = this.$route.query.app
      }
      // this.$uloc.fullscreen.toggle()
    }, 400)
  },
  beforeDestroy () {
    this.erplayout.erpheader.setMenuModuleToggle(false)
    this.$uloc.fullscreen.exit()
  },
  methods: {
    changeApp(app, props) {
      this.app = app
      this.parseProps = props
    },
    atendimentoArrematante () {
      this.app = appList.atendimentoArrematante
    },
    pesquisaArrematante () {
      this.app = appList.pesquisaArrematante
    },
    pesquisaLote () {
      this.app = appList.pesquisaLote
    },
    atendimentoLeilao () {
      this.app = appList.atendimentoLeilao
    },
    iniciarPagamentoLote () {
      this.changeApp(appList.pagamentoLote, this.parseProps)
    },
    returnFcn () {
      console.log(this.return)
      typeof this.return === 'function' && this.return()
    }
  }
}
</script>

<style lang="stylus" src="./assets/default.styl" />
