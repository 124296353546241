export default {
  data () {
    return {
      findedData: this.findedDataObj,
      description: null
    }
  },
  watch: {
    findedData (v) {
      this.$emit('updateSelected', v)
    }
  },
  mounted () {
    if (this.findedDataObj) {
      this.description = this.findedDataObj.id + ' - ' + this.findedDataObj.nome
    }
  },
}
