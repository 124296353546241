<template>
  <div class="pda-stats">
    <pda-stats-item label="Atendimentos realizados hoje" :value="0" />
    <pda-stats-item label="Previstos para hoje" :value="0" />
    <pda-stats-item label="Retiradas previstas para hoje" :value="0" />
    <pda-stats-item label="Lotes pendentes de pagamento" :value="0" />
  </div>
</template>

<script>
import PdaStatsItem from './StatsItem'
export default {
  name: 'PdaStats',
  components: {PdaStatsItem}
}
</script>

<style scoped>

</style>
