<template>
  <transition name="slide" appear>
    <div class="transition-generic">
      <div class="app-usuarios">
        <e-row v-if="!loading && !error" class="erp-sl-header">
          <e-col class="side">
            <h2>{{pagination.rowsNumber-1}} membros cadastrados</h2>
            <p>Gerencie pessoas que podem acessar o sistema e suas respectivas permissões. Convide novos membros para
              sua empresa.</p>
          </e-col>
          <e-col class="flex items-center justify-end">
            <u-btn @click="novo" label="Convidar novo membro" color="primary" no-caps class="b-radius-3px" />
          </e-col>
        </e-row>
        <e-row v-else>
          <e-col v-if="!error" class="side">
            Aguarde enquanto carregamos as informações...
          </e-col>
          <e-col v-else class="side">
            <span class="text-2x">Ops! Não conseguimos carregar a lista de usuários, motivo:</span>
            <p class="m-t negative">{{errorMessage}}</p> <!-- TODO: Tratar -->
          </e-col>
        </e-row>

        <ul v-if="!loading" class="list-user">
          <list-user @click.native="windowUsuarioShow(user.id, user.username)" v-for="user in usersFiltered" :key="user.id" :user="user" :me="$uloc.auth.session.user.id === user.id" />
        </ul>
        <ul v-else class="list-user">
          <list-user loading :user="{}" />
          <list-user loading :user="{}" />
          <list-user loading :user="{}" />
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import DefaultMixin from './mixin'
import ERow from '../layout/components/Row'
import ECol from '../layout/components/Col'
import ListUser from './components/ListUser'
import {list} from '../../domain/usuario/services'
import windowUsuario from './window/windowUsuario'
import windowUsuarioShow from './window/windowUsuarioShow'

export default {
  name: 'UserMain',
  components: {ListUser, ECol, ERow},
  mixins: [DefaultMixin],
  data () {
    return {
      loading: false,
      error: false,
      errorMessage: null,
      filter: '',
      pagination: {
        page: 1,
        rowsPerPage: 500,
        rowsNumber: 0,
        sortBy: 'id',
        descending: false
      },
      users: null
      /* users: [
        {
          id: 1,
          username: 'tiago',
          email: 'tiago@tiagofelipe.com',
          image: 'https://www.gravatar.com/avatar/2b0c7e0e6b7c4f36fc7617d745098ff1',
          admin: true,
          roles: [],
          pessoa: {
            id: 1,
            nome: 'Tiago Felipe'
          }
        },
        {
          id: 2,
          username: 'dayane',
          email: 'dayane@uloc.cloud',
          admin: false,
          roles: [],
          pessoa: {
            id: 2,
            nome: 'Dayane Soares'
          }
        }
      ] */
    }
  },
  computed: {
    usersFiltered () {
      let me = this.$uloc.auth.session.user
      return this.users?.filter(user => user.username !== 'slroot' || 'root'=== me.name)
    }
  },
  meta: {
    title: 'Usuários',
    name: 'Usuários'
  },
  mounted () {
    this.load()
    document.querySelector('.u-erp-layout-container').classList.add('bg-white')
  },
  beforeDestroy () {
    document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load () {
      this.loading = true
      this.error = false
      this.errorMessage = null
      let pagination = this.pagination
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${this.filter}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
        .then(({data}) => {
          this.pagination.rowsNumber = data.total
          this.users = data.result

          this.loading = false
        })
        .catch(error => {
          this.error = true
          this.errorMessage = error.data
          this.loading = false
        })
    },
    windowUsuario: windowUsuario,
    windowUsuarioShow: windowUsuarioShow,
    novo () {
      this.windowUsuario()
    }
  }
}
</script>

<style lang="stylus" src="./assets/default.styl" />
