<template>
  <div class="flex justify-between content-between no-wrap input-with-btn">
    <u-input v-on:input.native="$emit('input', $event.target.value)" :required="required" :autofocus="autofocus" ref="definput" class="erp-input float-icon input-hide-loading" color="amber" v-model="model"
             placeholder="" hide-underline
             @keyup="pesquisarCep">
      <sl-loading v-if="loading" content="" class="absolute-center-right size-18 m-r-sm" />
    </u-input>
    <u-btn @click="pesquisarManual" no-caps flat class="btn-modern b-radius-right no-border-left btn-group text-nowrap" label="Buscar dados" />
  </div>
</template>

<script>
import {debounce, UInput} from "uloc-vue"
import SlLoading from "@/components/layout/components/Loading"
import {buscaCep} from "@/domain/utils/cep"

export default {
  name: "CepInput",
  components: {SlLoading, UInput},
  props: {
    person: {
      required: false
    },
    address: {
      required: false
    },
    value: {
      required: true
    },
    debounce: {
      type: Number,
      default: 500
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    noUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      model: this.value,
      cepOk: null
    }
  },
  watch: {
    value (v) {
      this.$nextTick(() => {
        const bkp = String(this.model).replace(/\D/g, '')
        if (v !== this.model) {
          this.model = v
          this.$nextTick(() => {
            if (this.noUpdate && bkp.length <= 8) {
              return
            }
            this.pesquisarManual()
          })
        }
      })
    }
  },
  computed: {
  },
  methods: {
    pesquisarCep: debounce(function () {
      const cep = String(this.model).replace(/\D/g, '')
      console.log(cep, cep.length)
      if (cep.length !== 8) {
        console.log('PAROU')
        return
      }
      this.pesquisar(cep)
    }, 500),
    pesquisarManual () {
      const cep = String(this.model).replace(/\D/g, '')
      if (cep.length !== 8) {
        console.log('PAROU 2')
        return
      }
      this.pesquisar(this.model)
    },
    pesquisar (cep) {
      this.cepOk = null
      this.loading = true
      buscaCep(cep)
          .then(({data}) => {
            this.loading = false
            console.log(data)
            if (data.erro) {
              this.cepOk = false
              this.$uloc.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'CEP não encontrado'
              })
              return
            }
            console.log(data)
            this.$emit('cep', data)
            if (this.address) {
              this.address.address = data.logradouro
              this.address.complement = data.complemento
              this.address.district = data.bairro
              this.address.city = null
              this.address.cityId = null
              this.address.state = null
              this.$nextTick(() => {
                setTimeout(() => {
                  this.address.city = data.localidade
                  this.address.cityId = data.ibge
                }, 100)
                this.address.state = data.uf
              })
            }
            /*this.form.dadosEndereco = data
            this.form.pessoa.addresses[0].address = data.logradouro
            this.form.pessoa.addresses[0].complement = data.complemento
            this.form.pessoa.addresses[0].district = data.bairro
            this.form.pessoa.addresses[0].city = data.localidade
            this.form.pessoa.addresses[0].cityId = data.ibge
            this.form.pessoa.addresses[0].state = data.uf*/
            this.cepOk = true
            // this.$refs.endNumero.focus()
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
            this.$uloc.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Falha ao tentar comunicar com o servidor de CEPs'
            })
          })
    }
  }
}
</script>
