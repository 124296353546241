import http from '../../../utils/services/http'

export const list = (limit = 1000, page = 1, filtros = '') => {
    let url = '/api/bem/config-tarefas?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id) => {
    let url = `/api/bem/config-tarefas/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newConfig = (data) => {
    let url = `/api/bem/config-tarefas`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateConfig = (config, method = 'PUT') => {
    let url = `/api/bem/config-tarefas/${config.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteConfig = (id) => {
    let url = `/api/bem/config-tarefas/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}



export const gerar = (bemId) => {
    let url = `/api/bem/${bemId}/generate-tarefas`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
