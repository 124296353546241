<template>
  <div class="app-caller" v-if="isCalling"> <!-- @TODO: Adicionar algum efeito -->
    <div class="caller-history">
      <s-l-editor
          ref="editor"
          v-model="html"
          placeholder="Registre esta chamada, faça uma anotação, marque alguém com @nome..."
          :show-toolbar-on-focus="true"
          :close-click-outside="false"
          @focus="() => { editing = true }"
      />
      <div v-if="editing" class="log-info">
        <div class="flex items-center">
          <u-radio :val="true" v-model="log" class="m-r-xs" /> Registrar esta ligação
          <u-radio :val="false" v-model="log" class="m-r-xs m-l-md" /> Não registrar esta ligação
        </div>
        <div class="m-t-sm log-input">
          <u-input tabindex="1" :before="[{icon: 'call', handler () {}}]" v-model="logData.title" class="app-input" hide-underline style="max-width: 50%" placeholder="Ligação para Tiago" />
        </div>
      </div>
    </div>
    <div class="caller-content">
      <div class="connection">
        <div class="number">{{logData.phone}} <u-icon name="phone" class="phone-animation font-12" type="fa" /></div>
        <div class="person cursor-pointer" @click="gotoPerson(logData.person)">
          <u-icon name="user" type="fa" /> {{logData.person.name}} <span v-if="logData.organization"><u-icon name="building" type="fa" class="m-l" /> {{ logData.organization && logData.organization.name ? logData.organization.name : logData.organization }}</span>
        </div>
      </div>
      <div class="options">
<!--        <u-btn @click="redial" class="redial" label="Ligar novamente" no-caps />-->
        <u-btn @click="onlyClose" class="redial" label="Cancelar" no-caps />
        <u-btn @click="close" class="save m-l-sm" label="Salvar e fechar" no-caps />
      </div>
    </div>
  </div>
</template>

<script>
import SLEditor from "@/components/layout/components/Editor"
import {URadio, UInput} from "uloc-vue"
import {call} from "@/domain/pessoa/services"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"

const logData = {
  phone: null,
  person: null,
  organization: null,
  title: null
}

export default {
  name: "CallerComponent",
  mixins: [GlobalPersonMixin],
  components: {SLEditor, URadio, UInput},
  data () {
    return {
      isCalling: false,
      html: '',
      editing: false,
      log: true,
      logData: JSON.parse(JSON.stringify(logData)),
      redialCallback: null,
      closeCallback: null,
      config: {},
      router: null
    }
  },
  methods: {
    call (phone, person, organization, config, redialCallback, closeCallback, router) {
      this.router = router
      this.logData = {}
      this.html = ''
      this.log = true
      this.editing = false
      this.isCalling = false
      this.redialCallback = null
      this.closeCallback = null
      this.config = config
      if (redialCallback) {
        this.redialCallback = redialCallback
      }
      if (closeCallback) {
        this.closeCallback = closeCallback
      }
      console.log('Call', phone, person, organization, config, redialCallback, closeCallback)
      this.logData.phone = phone
      this.logData.person = person
      this.logData.organization = organization
      this.isCalling = true
    },
    redial () {
      this.redialCallback && this.redialCallback(this.getData())
    },
    onlyClose () {
      this.isCalling = false
    },
    close () {
      this.isCalling = false
      this.closeCallback && this.closeCallback(this.getData())
    },
    getData () {
      let data = JSON.parse(JSON.stringify(this.logData))
      data.html = this.$refs.editor.htmlContent()
      data.log = this.log
      if (this.config.id) {
        data.id = this.config.id
      }
      return data
    }
  }
}
</script>
