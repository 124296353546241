export default function (props) {
  let tipos = []
  Array.isArray(props.tiposBem) && props.tiposBem.map(v => {
    tipos.push(v.nome)
  })
  tipos = tipos.join(', ')
  if (props.tiposExcecao && Array.isArray(props.tiposExcecao) && props.tiposExcecao.length > 0) {
    let excecao = []
    props.tiposExcecao.map(v => {
      excecao.push(v.nome)
    })
    tipos += ' exceto ' + excecao.join(', ')
  }
  return tipos
}
