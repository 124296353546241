import http from '../../../utils/services/http'

export const list = (limit, page, filtros, basic) => {
  let url = '/api/globalconfigs?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/globalconfigs/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newGlobalconfig = (data) => {
  let url = `/api/globalconfigs`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const setup = () => {
  let url = `/api/setup`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateGlobalconfig = (data) => {
  let url = `/api/globalconfigs`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteGlobalconfig = (id) => {
  let url = `/api/globalconfigs/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getPublicConfig = (configs, isPublic = true) => {
  let url = `/api/public/globalconfigs`
  if (!isPublic) {
    let url = `/api/globalconfigs`
  }
  let method = http.get
  if (typeof configs !== 'undefined') {
    method = (url) => http.post(url, configs)
  }
  return method(url, configs)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getUserConfig = () => {
  let url = `/api/userConfig`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
