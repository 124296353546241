<script>
/** TODO: Move to Uloc ERP Plugin */
export default {
  name: 'widgets',
  props: {
    maxSlots: {
      type: Number,
      default: 6
    },
    adjusts: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      slots: []
    }
  },
  computed: {},
  mounted () {
    this.adjustPosition()
    window.setTimeout(() => {
      this.adjustPosition()
    }, 200)
  },
  created () {
    window.addEventListener('resize', this.adjustPosition)
  },
  updated () {
    this.adjustPosition()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.adjustPosition)
  },
  destroyed () {
  },
  methods: {
    adjustPosition () {
      this.slots = []
      let maxSlots = this.maxSlots
      let defaultMaxSlots = Number(this.maxSlots)
      this.$width = this.$el.clientWidth
      if (Array.isArray(this.adjusts)) {
        this.adjusts.map((a) => {
          if (typeof a['screen'] !== 'undefined') {
            if (this.$width <= Number(a['screen'])) {
              maxSlots = a['slots']
            }
          }
        })
      }
      this.$sizePerSlot = this.$width / maxSlots
      if (maxSlots !== defaultMaxSlots) {
        this.$sizePerSlot = this.$sizePerSlot * (maxSlots / defaultMaxSlots)
      }
      let iL = 0
      let iT = 0
      // let maxTopToHeight = 0
      this.$children.forEach((c, i) => {
        if (c.wslot > maxSlots) {
          c.wslot = maxSlots
        }
        c._item = (i + 1)
        c.$el.style.width = this.$sizePerSlot * c.wslot + 'px'
        c.$el.style.height = this.$sizePerSlot * c.hslot + 'px'

        /*if( (this.$sizePerSlot * c.hslot) >= maxTopToHeight) {
          maxTopToHeight = this.$sizePerSlot * c.hslot
        }*/

        let computeLeft =
          (iL % maxSlots) + c.wslot <= maxSlots
            ?
            (iL * maxSlots / maxSlots) % maxSlots
            :
            0

        let left = this.$sizePerSlot * computeLeft + 'px'
        let computeTop = Math.floor(iL / maxSlots)
        let top = (computeTop * this.$sizePerSlot) + 'px'

        iL = iL + c.wslot
        iT = iT + c.hslot
        let maxTop = 0
        for (let _s = (iL - c.wslot); _s < iL; _s++) {
          if (typeof this.slots[_s + 1] === 'undefined') {
            this.slots[_s + 1] = c
            if (c.hslot > 1) {
              this.slots[(_s + maxSlots) + 1] = c
            }
          } else {
            top = this.getMaxTop(maxTop, this.slots[_s + 1])
          }
        }
        c.$el.style.left = left
        c.$el.style.top = top

        /*let topInt = parseFloat(top.replace('px', ''))
        if (topInt >= maxTopToHeight) {
          maxTopToHeight = topInt + (this.$sizePerSlot * c.hslot)
        }*/
      })

      let container = this.$refs.container
      let firstNode = container.firstChild
      let lastNode = container.lastChild

      // this.$el.style.height = maxTopToHeight + 'px'
      this.$el.style.height = (lastNode.offsetTop + lastNode.offsetHeight) + 'px'
    },
    getMaxTop (a, b) {
      let _b = Number(b.$el.style.top.replace('px', ''))
      let result = _b > Number(a) ? _b : Number(a)
      return (result + b.$el.clientHeight) + 'px'
    }
  },
  components: {}
}
</script>

<template>
  <ul ref="container" class="widget-items">
    <slot />
  </ul>
</template>
