import {listModelos, deleteModelo} from '@/domain/cadastro/services'
import window from './window/windowModelo'
import parse from '../tiposbem/parse'

export default {
  pageTitle: 'Modelos de Bem',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'marca',
      required: true,
      label: 'Marca',
      align: 'left',
      field: 'marca',
      class: 'td-limit l-130',
      sortable: true,
      parser: function (props) {
        return props.marca ? props.marca.nome : '-'
      }
    },
    {
      name: 'tipo',
      required: true,
      label: 'Tipo(s) de Bem',
      align: 'left',
      field: 'tipo',
      class: 'td-limit l-130',
      sortable: true,
      parser: parse
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listModelos,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    },
    excluir: deleteModelo
  }
}
