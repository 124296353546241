import listOptions from "@/reuse/list/listOptions"
import {LocalStorage, UTable, UTd, UTh, UTr, UCheckbox} from "uloc-vue"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {
    EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import ListMenu from "@/reuse/list/ListMenu"

export default {
    provide: function () {
        return {
            container: this
        }
    },
    components: {
        UTable,
        UTh,
        UTr,
        UTd,
        UCheckbox,
        ECol,
        ERow,
        EBtnTableOptions,
        MenuOptions,
        ListMenu
    },
    props: {
        parseProps: {required: false}
    },
    data() {
        return {
            listStorage: this.listStorage,
            table: {
                busca: '',
                serverData: [],
                serverPagination: {
                    page: 1,
                    rowsNumber: 0, // specifying this determines pagination is server-side
                    rowsPerPage: 20
                },
                columns: this.listStorage.map(o => {
                    return {
                        ...o,
                        field: o.name,
                        align: 'left'
                    }
                }).concat([
                    {
                        name: 'options',
                        required: true,
                        label: '',
                        field: 'options',
                        sortable: false
                    }
                ]),
                filter: '',
                selected: [],
                loading: false
            },
        }
    },
    computed: {
        visibledColumns() {
            return this.listStorage.filter(item => item.active).map(item => item.name)
        }
    },
    methods: {
        listOptions: listOptions,
        updateListDefinition(newConfig) {
            console.log('Ok', newConfig)
            LocalStorage.set(this.listName, newConfig)
            this.listStorage = newConfig
        },
        load() {
            this.request({
                pagination: this.table.serverPagination,
                filter: this.table.busca
            })
        },
        clearFilters() {
            this.table.filters = JSON.parse(JSON.stringify(filters))
        },
        changeListType(v) {
            this.listType = v
            this.parseStatusFilter && this.parseStatusFilter(v)
            this.load()
        },
        parseHash() {
            if (this.$route.hash) {
                const hash = this.$route.hash.replace('#', '')
                this.parseStatusFilter()
                return true
            }
        },
        parseStatusFilter(hash) {
            // if need
        }
    },
    beforeMount() {
        if (this.$route && this.$route.hash) {
            this.parseHash()
        }
    },
    watch: {
        '$route'() {
            this.$nextTick(() => {
                if (this.parseHash()) {
                    this.load()
                }
            })
        }
    }
}
