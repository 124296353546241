<template>
  <div class="erp-profile-menu">
    <PosMenuIcon name="bell" icon-type="fa" icon-style="light" notify="" />
    <u-popover class="window-context-menu menu-profile notify-menu" anchor="bottom left" self="top left" :offset="[0, 5]">
      <div>
        <h2>Notificações</h2>
        <ul>
          <div class="notify-content m-b">
            <div style="text-align: justify; cursor: default">
              Nenhuma notificação até o momento.
            </div>
          </div>
<!--          <div class="notify-content m-b">
            <div style="text-align: justify; cursor: default">
              <u-icon class="m-r-xs fa-fw" name="newspaper" type="fa" icon-style="regular"/>
              <br>
              <strong>Aviso importante</strong>
              <br>
              Estamos configurando a versão 2.0.0 do sofware. Não se preocupe, você será avisado assim que concluírmos.
            </div>
          </div>-->
        </ul>
        <div class="diviser"></div>
        <ul>
          <menu-options-item @click="$router.push({name: 'perfil.mensagens'})" icon="bell" icon-type="fa" label="Configurar notificações" close  />
        </ul>
      </div>
    </u-popover>
  </div>
</template>

<script>
import {PosMenuIcon} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
export default {
  name: 'NotifyMenu',
  components: {PosMenuIcon, MenuOptionsItem, UPopover},
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
