import http from '@/utils/services/http'

export const checaDisponibilidade = () => {
  let url = '/api/integra/grupo-porto/checa-disponibilidade'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const consultaLeilao = (servico = 'azul') => {
  let url = '/api/integra/grupo-porto/consultaLeilao?servico=' + servico
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relacionaItensLeilao = (leilao, itens) => {
  let url = `/api/integra/${leilao}/relacionaBensLeilao`
  return http.post(url, {
    itens: itens
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const atualizaLotes = (leilao) => {
  let url = `/api/integra/grupo-porto/leilao/${leilao}/atualizaLotes`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const commandLogBuffer = (filter) => {
  let url = `/app/log/find`
  return http.get(url + `?` + filter)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

