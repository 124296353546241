<script>
import DefaultMixin from '../mixin'
import MenuConfig from './menu/MenuConfig'

export default {
  name: 'ConfigLayout',
  provide: function () {
    return {
      page: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    MenuConfig
  },
  data () {
    return {
      active: 'hom'
    }
  },
  computed: {},
  methods: {
    setActive (v) {
      this.active = v
    }
  },
  meta: {
    title: 'Grupo Porto',
    name: 'Grupo Porto'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap">
    <menu-config :active="active" />
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic">
          <router-view />
        </div>
      </transition>
    </div>
  </div>
</template>
