import Vue from 'vue'

export const CRITERIO_COMITENTE = 1
export const CRITERIO_TIPO_BEM = 2
export const CRITERIO_VALOR_VENDA = 3
export const CRITERIO_CONSERVACAO = 4

export const CRITERIO_COMITENTE_COMPONENT = 'criterio-comitente'
Vue.component(CRITERIO_COMITENTE_COMPONENT, () => import('../../../components/taxas/components/include/Comitente.vue'))

export const CRITERIO_TIPO_BEM_COMPONENT = 'criterio-tipo-bem'
Vue.component(CRITERIO_TIPO_BEM_COMPONENT, () => import('../../../components/taxas/components/include/TipoBem.vue'))

export const CRITERIO_VALOR_VENDA_COMPONENT = 'criterio-valor-venda'
Vue.component(CRITERIO_VALOR_VENDA_COMPONENT, () => import('../../../components/taxas/components/include/Valor.vue'))

export const CRITERIO_CONSERVACAO_COMPONENT = 'criterio-conservacao-bem'
Vue.component(CRITERIO_CONSERVACAO_COMPONENT, () => import('../../../components/taxas/components/include/Conservacao.vue'))


export const camposCriterio = [
  {
    label: 'Comitente',
    value: CRITERIO_COMITENTE,
    component: CRITERIO_COMITENTE_COMPONENT
  },
  {
    label: 'Tipo de Bem',
    value: CRITERIO_TIPO_BEM,
    component: CRITERIO_TIPO_BEM_COMPONENT
  },
  {
    label: 'Valor Venda',
    value: CRITERIO_VALOR_VENDA,
    component: CRITERIO_VALOR_VENDA_COMPONENT
  },
  {
    label: 'Conservação',
    value: CRITERIO_CONSERVACAO,
    component: CRITERIO_CONSERVACAO_COMPONENT
  },
]
