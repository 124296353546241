<template>
  <div class="leilao-page-resumo">

    <div class="resumo-inline">
      <div class="datas">
        <div class="sl-alert m-b-xs" v-for="numeroLeilao in leilao.instancia" :class="{'bg-green text-white': leilao.praca === numeroLeilao, 'bg-ok': leilao.praca > numeroLeilao}" :key="'leilao-nro-' + numeroLeilao">
          <div class="flex justify-between">
            <div class="data-lbl">
              <div class="font-avenir text-uppercase">
                {{ numeroLeilao }}° leilão: <strong>{{leilao['data' + numeroLeilao]|formatDate}}</strong>
              </div>
            </div>
            <div class="abertura" v-if="leilao['dataAbertura' + numeroLeilao]" >
              <span>Abertura: </span>
              <div class="inline-block">
                <div class="text-uppercase-first inline-block">{{leilao['dataAbertura' + numeroLeilao]|formatDate('dd/MM/yy HH:mm')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="label">ID:</div>
        <strong>{{leilao.id}}</strong>
      </div>
      <div>
        <div class="label">Código:</div>
        <strong>{{leilao.numero}}/{{leilao.ano}}</strong>
      </div>
      <div>
        <div class="label">Robô:</div> <strong v-if="leilao.controleAutomatico" class="text-positive">Sim</strong>
        <strong v-else class="text-negative">Não</strong>
      </div>
      <div>
        <div class="label">Cronômetro:</div>
        <strong v-if="leilao.cronometro">{{leilao.timerPregao}} segundos</strong>
        <strong v-else>desativado</strong>
      </div>
      <div v-if="leilao.enderecoEletronico">
        <div class="label">Endereço eletrônico:</div>
        <strong><span class="font-bold">{{leilao.enderecoEletronico}}</span></strong>
      </div>
      <div>
        <div class="label">Lance automático:</div> <strong v-if="leilao.permitirLanceAutomatico" class="text-positive">Sim</strong><strong
          v-else class="text-negative">Não</strong>
      </div>
      <div>
        <div class="label">Parcelamento:</div>
        <strong v-if="leilao.permitirParcelamento" class="text-positive">Sim</strong>
        <strong v-else class="text-negative">Não</strong>
      </div>
      <div>
        <div class="label">Habilitados:</div>
        <strong>{{leilao.habilitados}}</strong>
      </div>
      <div>
        <div class="label">Preparação:</div>
        <status-interno-leilao class="font-bold" :leilao="leilao" />
      </div>
    </div>

<!--    <div>
      Publicação no site:
      <div class="inline-block" v-if="leilao.publicarSiteQuando">
        <div class="text-uppercase-first inline-block font-bold" v-if="leilao.dataPublicacao">{{leilao.dataPublicacao.date|formatDate}}</div>
      </div>
      <strong v-else>Não publicado</strong>
    </div>-->
  </div>
</template>

<script>
// import {format} from 'date-fns'
// import {ptBR} from 'date-fns/locale'

import StatusInternoLeilao from '../StatusInternoLeilao'

export default {
  name: 'LeilaoInfo',
  inject: ['page'],
  components: {StatusInternoLeilao},
  computed: {
    leilao () {
      return this.page.leilao
    }
  },
  methods: {}
}
</script>
