<template>
  <u-dialog
      v-model="dialog"
      @cancel="cancel"
      @ok="confirm"
  >
    <span slot="title">Alterar Status de Lotes em Massa</span>

    <!--<span slot="message">What is your superhero of choice?</span>-->

    <div slot="body">
      <p>Alterar os lotes cujo status atual é:</p>
      <div>
        <status-lote-select simple-border :label="' '"
                            field-view="lr"
                            v-model="alterar.de" />
      </div>
      <p class="m-t">Para o novo status:</p>
      <div>
        <status-lote-select simple-border :label="' '"
                            field-view="lr"
                            v-model="alterar.para" />
      </div>
    </div>

    <template slot="buttons" slot-scope="props">
      <u-btn flat label="Cancelar" @click="props.cancel" />
      <u-btn color="primary" label="Confirmar alteração" @click="props.ok" />
    </template>
  </u-dialog>
</template>

<script>
import {UDialog} from "uloc-vue"
import StatusLoteSelect from "components/leiloes/components/include/StatusLoteSelect"
import LoteStatus from "comunicator/src/helpers/LoteStatus"
import {atualizaStatusLotesEmMassa} from "@/domain/leiloes/services"
import LeilaoMixin from "components/leiloes/components/LeilaoMixin"

export default {
  name: "AlterarStatusLoteMassaDialog",
  mixins: [LeilaoMixin],
  components: {StatusLoteSelect, UDialog},
  data () {
    return {
      dialog: false,
      alterar: {
        de: LoteStatus.STATUS_RASCUNHO,
        para: LoteStatus.STATUS_ABERTO_PARA_LANCES
      }
    }
  },
  methods: {
    open () {
      this.dialog = true
    },
    cancel () {
      this.dialog = false
    },
    confirm () {
      this.$uloc.loading.show({message: 'Efetuando operação...'})
      atualizaStatusLotesEmMassa(this.leilao.id, this.alterar)
          .then(response => {
            this.$uloc.loading.hide()
            this.$uloc.dialog({
              title: 'Emissão agendada',
              message: `Em alguns minutos um e-mail será enviado para o e-mail ${data} com o link para download das notas`
            })
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    }
  }
}
</script>
