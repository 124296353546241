export default function (id) {
  let window, title
  if (typeof id !== 'undefined' && id) {
    window = id ? `processo.${id}` : 'processo.new'
    title = 'Visualizar Processo'
  } else {
    window = 'processo.new'
    title = 'Novo Processo'
  }

  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: title,
    width: '1100',
    height: '90%',
    minWidth: '1000px',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id,
      router: this.$router
    }
  }, () => import('./Processo'))
      .then((wid) => {
        console.log(wid)
        this.$uloc.window.listen(wid, {
          loaded: (wid, processo) => {
            console.log('Loaded processo event')
          },
          update: () => {
            this.load?.()
          }
        })
      }) // return wid
}
