export default {
    props: {
        field: {required: true},
        fieldValue: {required: true},
        value: {required: true}
    },
    data () {
        return {
            model: this.value,
            extra: this.fieldValue.valorExtra
        }
    }
}
