<template>
  <div>
    <h2 class="diviser two-lines">
      Localização
      <span>Informações sobre a localização do bem</span>
    </h2>

    <div class="m-t-lg">
      <e-row mr>
<!--        <e-col style="max-width: 300px">
          <erp-s-field view="lr" label-width="100px" label="País">
            <erp-select v-model="bem.pais" :options="[
                    {label: 'Brasil', value: '1'},
                ]"/>
          </erp-s-field>
        </e-col>-->
        <e-col class="cep-input" style="max-width: 380px">
          <erp-s-field label-width="100px" view="lr" label="CEP">
            <cep-input @cep="cep" v-model="bem.cep" no-update />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field view="lr" label-width="100px" label="Endereço">
            <erp-input v-model="bem.endereco"/>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field view="lr" label="Número">
            <erp-input ref="addressNumber" v-model="bem.numero"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field view="lr" label-width="100px" label="Complemento">
            <erp-input v-model="bem.endComplemento"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field view="lr" label-width="100px" label="Bairro">
            <erp-input v-model="bem.bairro"/>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 200px">
          <erp-s-field view="lr" label="UF">
            <uf-select :city="bem.cidade" v-model="bem.uf" @preventCitySelected="(city) => bem.cidade = city" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field view="lr" label="Cidade">
            <cidade-select :uf="bem.uf" v-model="bem.cidade" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <local-bem ref="localBem" />
        </e-col>
      </e-row>

      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Latitude:"
              label-width="110px"
          >
            <erp-input v-model="bem.localizacaoLatitude"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Longitude:"
          >
            <erp-input v-model="bem.localizacaoLongitude"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row>
        <erp-s-field class="font-thin font-12" view="lr" label-width="110px">
          Use dados de geolocalização para oferecer uma experiência melhor para os interessados. Esta informação permite
          ao software apresentar ofertas próximas da localização identificada ou informada pelo interessado. Dados do
          endereço também são relevantes para esta funcionalidade.
        </erp-s-field>
      </e-row>

      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              label="URL Street View:"
          >
            <erp-input v-model="bem.localizacaoUrlStreetView"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Embutir mapa no site:"
              label-width="110px"
          >
            <erp-input v-model="bem.localizacaoMapEmbed"/>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="max-width: 260px">
          <erp-s-field
              view="lr"
              label=" "
              label-width="110px"
          >
            <e-btn @click="tratarLocalizacaoMapEmbed" label="Tratar URL do mapa"/>
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label=" "
          >
            <e-btn @click="embutirAuto" label="Embutir mapa automaticamente pela latitude e longitude"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <bem-campo-secao css="erp-row-view simple col-limit-4 v3 view-wrap" secao="localizacao"/>
      </e-row>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import SlCheckbox from "@/reuse/input/Checkbox"
import BemCampoSecao from "@/components/bem/components/include/CamposSecao"
import CidadeSelect from "components/pessoa/components/include/CidadeSelect"
import CepInput from "components/pessoa/components/include/CepInput"
import UfSelect from "components/pessoa/components/include/UfSelect"
import {ErpSelect} from "@/plugins/uloc-erp"
import LocalBem from "components/bem/components/include/LocalBem"

export default {
  name: "BemLocalizacao",
  components: {
    LocalBem,
    BemCampoSecao,
    CidadeSelect,
    CepInput,
    UfSelect,
    // ErpSelect
    // SlCheckbox
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  computed: {},
  methods: {
    extrairUrlDoMapa(codigo) {
      const regex = /src="([^"]+)"/;
      const resultado = regex.exec(codigo);
      if (resultado && resultado.length > 1) {
        return resultado[1];
      } else {
        return null;
      }
    },
    tratarLocalizacaoMapEmbed() {
      const urlTratada = this.extrairUrlDoMapa(this.bem.localizacaoMapEmbed)
      if (urlTratada) {
        this.bem.localizacaoMapEmbed = urlTratada
      }
    },
    async embutirAuto () {
      this.bem.localizacaoMapEmbed = this.$refs.localBem.getEmbedUrl(this.bem.localizacaoLatitude, this.bem.localizacaoLongitude)
      this.bem.localizacaoUrlStreetView = await this.$refs.localBem.getStreetViewEmbedUrl(this.bem.localizacaoLatitude, this.bem.localizacaoLongitude)
    },
    cep (data) {
      this.$refs.addressNumber.focus()
      this.bem.endereco = data.logradouro
      this.bem.complemento = data.complemento
      this.bem.bairro = data.bairro
      this.bindCidade = true
      setTimeout(() => {
        this.bem.cidade = data.localidade
        this.$nextTick(() => {
          this.bindCidade = false
        })
        // this.bem.cityId = data.ibge
      }, 1200)
      this.bem.uf = data.uf
    }
  }
}
</script>
