<template>
  <div class="m-t-lg">
    <e-row>
      <e-col style="max-width: 100px"><p class="font-bold m-b-xs font-13">Lotes</p></e-col>
      <e-col v-if="ativarFiltros">
        <e-row>
          <e-col class="font-10 text-right text-nowrap">
            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.pago">
              <label class="no-select font-bold">
                <span class="m-l-xs">Pago</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.naoPago">
              <label class="no-select font-bold">
                <span class="m-l-xs">Não pago</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.vendido">
              <label class="no-select font-bold">
                <span class="m-l-xs">Vendido</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.cancelado">
              <label class="no-select font-bold">
                <span class="m-l-xs">Cancelado</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.semLicitante">
              <label class="no-select font-bold">
                <span class="m-l-xs">Sem licitante</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.condicional">
              <label class="no-select font-bold">
                <span class="m-l-xs">Condicional</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.aberto">
              <label class="no-select font-bold">
                <span class="m-l-xs">Aberto</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.baixaOferta">
              <label class="no-select font-bold">
                <span class="m-l-xs">Baixa Oferta</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.retirado">
              <label class="no-select font-bold">
                <span class="m-l-xs">Retirado</span>
              </label>
            </erp-checkbox>

            <erp-checkbox class="m-l-sm flex items-center" v-model="filtros.homologando">
              <label class="no-select font-bold">
                <span class="m-l-xs">Homologando</span>
              </label>
            </erp-checkbox>
          </e-col>
        </e-row>
      </e-col>
    </e-row>
    <div class="erp-list pda-list">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th :style="col.name === 'lote' ? 'max-width: 25px !important' : ''" v-for="col in props.cols"
                :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrirPda(props.row.arremate ? (props.row.arremate.comprador || props.row.arremate.arrematante) : null)" class="cursor-pointer" slot="body"
              slot-scope="props"
              :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td key="lote" style="max-width: 25px !important" :props="props">
            {{ props.row.numero }}
          </u-td>
          <u-td :title="props.row.descricao" class="td-limit l-130 uppercase" key="descricao"
                :props="props">
            {{ props.row.descricao }}
          </u-td>
          <u-td key="arrematante" :props="props" class="td-limit l-130 uppercase">
            <div v-if="props.row.arremate">
              <div v-if="props.row.arremate.comprador">
                {{ props.row.arremate.comprador ? props.row.arremate.comprador.apelido : props.row.arremate.apelido }}
                <span v-if="props.row.arremate.comprador"> - {{ props.row.arremate.comprador.pessoa.name }}</span>
              </div>
              <div v-else>
                {{ props.row.arremate.arrematante ? props.row.arremate.arrematante.apelido : props.row.arremate.apelido }}
                <span v-if="props.row.arremate.arrematante"> - {{ props.row.arremate.arrematante.pessoa.name }}</span>
              </div>
            </div>
            <div v-else>-</div>
          </u-td>
          <u-td key="valor" :props="props">
            <div v-if="props.row.arremate">
              R$
              <span v-if="props.row.arremate.lance">{{ props.row.arremate.lance.valor|moeda }}</span>
              <span v-else>{{ props.row.arremate.valor|moeda }}</span>
            </div>
            <div v-else>-</div>
          </u-td>
          <u-td class="text-uppercase" key="status" :props="props">
            <status-lote :lote="props.row"/>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Ver lote" @click="abrir(props.row)" icon="folder-open"/>
                  <menu-options-item close label="PDA" label-class="text-blue"
                                     @click="abrirPda(props.row.arremate.arrematante)" icon="terminal"/>
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <!--<context-menu-pendencias-item @abrir="abrir(props.row)" :leilao="props.row" />-->
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import MenuOptions from '../../../../layout/context-menu/context-window-options'
import {UTable, UTd, UTh, UTr, UCheckbox, debounce} from 'uloc-vue'
import {EBtnTableOptions, ErpCheckbox} from 'uloc-vue-plugin-erp'
import {listLotes as list} from '@/domain/leiloes/services'
import {find} from '@/domain/arrematante/services'
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
import appList from '../app'
import StatusLote from '../../../../leiloes/components/include/StatusLote'
import * as StatusLoteConst from '@/domain/leiloes/helpers/LoteStatus'
import loteWindow from '@/components/leiloes/windows/lote'

export default {
  name: 'PdaBuscaLote',
  inject: {
    pda: {
      default() {
        console.error('BuscaLote for PDA requires PDA parent instance')
      }
    }
  },
  props: {
    busca: {required: true},
    leiloes: {required: true},
    ativarFiltros: {
      type: Boolean,
      default: false
    },
    disableBuscaWatch: {
      required: false,
      type: Boolean,
      default: false
    },
    buscaMultipla: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filtros: {
        pago: true,
        naoPago: true,
        vendido: true,
        cancelado: true,
        semLicitante: true,
        condicional: true,
        aberto: true,
        baixaOferta: true,
        retirado: true,
        homologando: true
      },
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10,
          rowsPerPage: 300
        },
        columns: [
          {name: 'lote', label: 'LT', field: 'lote', align: 'left', sortable: true},
          {
            name: 'descricao',
            required: true,
            label: 'Descrição',
            align: 'left',
            field: 'descricao',
            sortable: true
          },
          {
            name: 'arrematante',
            required: true,
            label: 'Comprador',
            align: 'left',
            field: 'arrematante',
            sortable: true
          },
          {
            name: 'valor',
            required: true,
            label: 'Valor s/ imposto',
            align: 'left',
            field: 'valor',
            sortable: true
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: false
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ],
        filter: '',
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
  },
  components: {
    StatusLote,
    MenuOptionsItem,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    EBtnTableOptions,
    UCheckbox,
    ErpCheckbox
  },
  watch: {
    filtros: {
      handler: function (val, oldVal) {
        console.log('Filtros mudou')
        this.$nextTick(() => {
          this.search()
        })
      },
      deep: true
    },
    busca() {
      if (this.disableBuscaWatch) return
      this.$nextTick(() => {
        this.search()
      })
    }
  },
  methods: {
    search() {
      this.$nextTick(() => {
        this.request({
          pagination: this.table.serverPagination,
          filter: this.busca
        })
      })

    },
    request: debounce(function ({pagination, filter}) {
      if (!this.leiloes) return
      this.table.loading = true

      let filtros = []
      let filtrosStatus = []
      if (this.busca) filtros.push('search=' + this.busca)
      if (!this.filtros.pago) filtros.push('pago=false')
      if (!this.filtros.naoPago) filtros.push('naoPago=false')

      if (this.filtros.vendido) {
        filtrosStatus.push(StatusLoteConst.STATUS_VENDIDO)
      }

      if (this.filtros.cancelado) {
        filtrosStatus.push(StatusLoteConst.STATUS_CANCELADO)
      }

      if (this.filtros.semLicitante) {
        filtrosStatus.push(StatusLoteConst.STATUS_SEM_LICITANTES)
      }

      if (this.filtros.condicional) {
        filtrosStatus.push(StatusLoteConst.STATUS_CONDICIONAL)
      }

      if (this.filtros.aberto) {
        filtrosStatus.push(StatusLoteConst.STATUS_ABERTO_PARA_LANCES)
      }

      if (this.filtros.baixaOferta) {
        filtrosStatus.push(StatusLoteConst.STATUS_BAIXA_OFERTA)
      }

      if (this.filtros.retirado) {
        filtrosStatus.push(StatusLoteConst.STATUS_RETIRADO)
      }

      if (this.filtros.homologando) {
        filtrosStatus.push(StatusLoteConst.STATUS_HOMOLOGANDO)
      }

      if (filtrosStatus.length) {
        filtros.push(`status=${filtrosStatus.join(',')}`)
      }

      if (this.buscaMultipla) {
        filtros.push('buscaMultipla=true')
      }

      filtros = filtros.join('&')

      list(this.leiloes, this.table.serverPagination.rowsPerPage, this.table.serverPagination.page, '&' + filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            // this.table.serverData = data

            this.table.loading = false
          })
          .catch(error => {
            this.loading = false
          })
    }, 500),
    loteWindow: loteWindow,
    abrir(lote) {
      this.loteWindow(lote)
    },
    abrirPda(arrematante) {
      if (arrematante) {
        find(arrematante.id)
            .then(response => {
              this.pda.changeApp(appList.arrematante, {arrematante: response.data})
            })
            .catch((error) => {
              console.error(error, error.data)
            })
      }
    }
    // arrematanteWindow: arrematanteWindow
  }
}
</script>
