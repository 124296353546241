<template>
  <div class="comitente-leilao-stats-box">
    <div v-if="comitente.image"><img :src="comitente.image.thumb" width="80" /></div>
    <div class="m-l flex-zero comitente-nome">
      <div class="font-12 font-bold">{{nomeResumido}}</div>
      <div class="font-10 m-t-xxs">
        <label-count label="lote" :number="comitente.estatisticas ? comitente.estatisticas.lotes : 0" />
      </div>
      <div class="font-10 m-t-xxs text-negative" v-if="comitente.estatisticas && comitente.estatisticas.retirados">
        <label-count label="retirado" :number="comitente.estatisticas.retirados" />
      </div>
    </div>
    <div class="m-l-lg flex row font-10 no-wrap">
      <div class="m-r-sm">
        <div class="font-bold">Vendidos</div>
        <div class="m-t-xxs">
          <label-count label="lote" :number="comitente.estatisticas ? comitente.estatisticas.vendidos : 0" />
        </div>
      </div>
      <div class="m-r-sm">
        <div class="font-bold">Condicional</div>
        <div class="m-t-xxs">
          <label-count label="lote" :number="comitente.estatisticas ? comitente.estatisticas.condicional : 0" />
        </div>
      </div>
      <div>
        <div class="font-bold">Não vendido</div>
        <div class="m-t-xxs">
          <label-count label="lote" :number="comitente.estatisticas ? comitente.estatisticas.naoVendidos : 0 " />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabelCount from '../../helpers/LabelCount'

export default {
  name: 'ComitenteLeilaoStats',
  components: {LabelCount},
  computed: {
    nomeResumido () {
      let name = String(this.comitente.pessoa.name)
      return name
      /* if (!name) {
        return 'n/d'
      }
      if (name.length > 20) {
        return name.slice(0, 20) + '...'
      }
      return name */
    }
  },
  props: ['comitente']
}
</script>

<style lang="stylus">
  .comitente-leilao-stats-box {
    width 410px
    max-width 445px
    min-height 76px
    display flex
    align-items center
    padding 10px
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    background-color #FFFFFF
    color #333333 !important
    margin-right 25px
    margin-top 12px

    .comitente-nome {
      min-width 20%
    }
  }
</style>
