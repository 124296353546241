<script>
import Layout from '../layout/Layout'
import Widgets from '../layout/widget/Widgets'
import WidgetItem from '../layout/widget/WidgetItem'
import DashOptions from '../dashboard/components/DashOptions';
import WidgetRemocoesStats from './components/WidgetRemocoesStats';
import Menu from './menu'
import MenuOptions from './menuOptions'
import {dashboard} from '@/domain/remocao/services'
import RemocaoMixin from './mixin';
import {statusStrings} from "@/domain/reboquista/helpers/status-reboquista";

let schema = {
  'remocao': {
    'abertas': 0,
    'recebidasHoje': {
      'entregue': 0,
      'paraVistoria': 0
    },
    'solicitadas': {
      'hoje': 0,
      'mes': 0
    },
    'retirada': {
      'hoje': 0,
      'previsto': 0
    },
    'solicitacoes': 0,
    'reboquistas': {
      'ativos': 0,
      'pendentes': 0,
      'bloqueados': 0
    },
    'vistoria': {
      'hoje': 0,
      'inconsistencias': 0
    }
  }
}

export default {
  name: 'Remocao',
  mixins: [RemocaoMixin],
  components: {
    WidgetRemocoesStats,
    DashOptions,
    Layout,
    Widgets,
    WidgetItem
  },
  data () {
    return {
      remocao: {
        loading: false,
        data: JSON.parse(JSON.stringify(schema.remocao))
      },
      showDashOptions: false
    }
  },
  computed: {
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
    // document.querySelector('.u-erp-layout-container').classList.add('bg-white')
    window.setTimeout(() => {
      this.showDashOptions = true
    }, 400)
    this.load()
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load () {
      this.remocao.loading = true
      dashboard()
        .then(response => {
          console.log(response.data)
          this.parseReboquistaStats(response.data)
          this.remocao.data.solicitacoes = response.data.solicitacoes
          this.remocao.data.solicitadas = response.data.solicitadas
          this.remocao.data.vistoria = response.data.vistoria
          this.remocao.data.retirada = response.data.retirada
          // this.remocao.data = response.data
        })
        .catch((error) => {
          this.alertApiError(error)
        })
      .finally(() => {
        this.remocao.loading = false
      })
    },
    parseReboquistaStats (data) {
      for (const item of data.reboquistas) {
        console.log(item)
        const key = statusStrings[+item.status].keyStats
        this.remocao.data.reboquistas[key] = +item.c
      }
    }
  },
  meta: {
    title: 'Remoção',
    name: 'Remoção'
  }
}
</script>

<template>
  <layout class="remocao" @resize="$refs.widgets.adjustPosition()" title="Dashboard" :menu="menu"
          menu-active="dashboard" pad>
    <div>
      <widgets :maxSlots="10" :adjusts="[{'screen': 0, 'slots': 12}, {'screen': 0, 'slots': 4}]" ref="widgets">
        <widget-item :wslot="2" :hslot="2">
          <widget-remocoes-stats
              title="Em andamento"
              subtitle="Remoções em aberto"
              :count="[{title: null, value: remocao.data.abertas}]"
              :loading="remocao.loading"
          >
            <slot slot="link"><router-link :to="{name: 'remocao.lista'}">Gerenciar remoções</router-link></slot>
          </widget-remocoes-stats>
        </widget-item>

        <widget-item :wslot="2" :hslot="2">
          <widget-remocoes-stats
              title="Recebidas hoje"
              :count="[
                {title: 'Entregue', value: remocao.data.recebidasHoje.entregue},
                {title: 'P/ Vistoria', value: remocao.data.recebidasHoje.paraVistoria, class: 'indicator-color-blue'}
                ]"
              :loading="remocao.loading"
          >
            <slot slot="link"><a>Monitorar</a></slot>
          </widget-remocoes-stats>
        </widget-item>

        <widget-item :wslot="2" :hslot="2">
          <widget-remocoes-stats
              title="Solicitadas"
              :count="[
                {title: 'Hoje', value: remocao.data.solicitadas.hoje},
                {title: 'No mês', value: remocao.data.solicitadas.mes}
                ]"
              :loading="remocao.loading"
          >
            <slot slot="link"><a>Incluir nova remoção</a></slot>
          </widget-remocoes-stats>
        </widget-item>

        <widget-item :wslot="4" :hslot="2">
          <widget-remocoes-stats
              title="Estatística retirada"
              :count="[
                {title: 'Retirados hoje', value: remocao.data.retirada.hoje},
                {title: 'Previsto', value: remocao.data.retirada.previsto}
                ]"
              :loading="remocao.loading"
          >
            <slot slot="link"><a>Monitorar retiradas</a></slot>
          </widget-remocoes-stats>
        </widget-item>

        <!-- LINE -->
        <widget-item :wslot="2" :hslot="2">
          <widget-remocoes-stats
              title="Solicitações de remoção"
              :count="[{title: null, value: remocao.data.solicitacoes}]"
              :loading="remocao.loading"
          >
            <slot slot="link"><a>Gerenciar solicitações</a></slot>
          </widget-remocoes-stats>
        </widget-item>

        <widget-item :wslot="4" :hslot="2">
          <widget-remocoes-stats
              title="Estatística de Reboquistas"
              :count="[
                {title: 'Ativos', value: remocao.data.reboquistas.ativos},
                {title: 'Pendentes', value: remocao.data.reboquistas.pendentes},
                {title: 'Bloqueados', value: remocao.data.reboquistas.bloqueados}
                ]"
              :loading="remocao.loading"
          >
            <slot slot="link"><router-link :to="{name: 'reboquistas.lista'}">Gerenciar reboquistas</router-link></slot>
          </widget-remocoes-stats>
        </widget-item>

        <widget-item :wslot="4" :hslot="2">
          <widget-remocoes-stats
              title="Estatística de vistoria"
              :count="[
                {title: 'Vistoriados hoje', value: remocao.data.vistoria.hoje},
                {title: 'Inconsistências', value: remocao.data.vistoria.inconsistencias, class: 'indicator-color-red'}
                ]"
              :loading="remocao.loading"
          >
            <slot slot="link"><a>Monitorar vistorias</a></slot>
          </widget-remocoes-stats>
        </widget-item>
      </widgets>
    </div>
    <dash-options v-if="showDashOptions" class="dash-remocao" :menu="menuDashOptions" />
  </layout>
</template>

<style src="./assets/default.styl" lang="stylus"></style>

