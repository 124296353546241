<script>
/**
 * TODO: Move do ERP Plugin
 */
import MenuLateral from './MenuLateral'

export default {
  name: 'Layout',
  props: {
    title: String,
    menuName: String,
    menu: [Array, Object],
    menuStyle: String,
    menuActive: String,
    noMenu: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    pad: {
      type: Boolean,
      default: false
    },
    hfull: {
      type: Boolean,
      default: false
    },
    folded: {
      type: Boolean,
      default: true
    },
    autoFolded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {
    resize () {
      this.$emit('resize')
    }
  },
  components: {MenuLateral}
}
</script>

<template>
  <div class="flex col-grow no-wrap">
    <menu-lateral @resize="resize" :menu="menu" :menu-style="menuStyle" :active="menuActive" v-if="!noMenu" :auto-folded="autoFolded" :folded="folded" full-height :menu-name="menuName" />
    <div class="app-layout-cmenu" :class="{defpad: pad}">
      <transition name="slide" appear>
        <div class="transition-generic" :class="{'h-full': hfull}">
          <h2 v-if="!noTitle" class="l-title">{{ title }}</h2>
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>

<style src="./layout.styl" lang="stylus"></style>

