export default function (leilao, bem, lote) {
  let window = `leiloes.criacaoRapidaBens`
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Criação de Bens, Lotes e Sublotes',
    width: '90%',
    height: '90%',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window-modern',
    contentClass: '__overflow-hidden',
    props: {
      leilao: leilao,
      bem: bem,
      lote: lote
    }
  }, () => import('../../bem/components/CriacaoRapida'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        updateLotes: (wid, val) => {
          this.$emit('updateLotes')
        }
      })
    }) // return wid
}
