import http from '../../../utils/services/http'

export const callRobo = (id) => {
  let url = `/api/processos/${id}/callRobo`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}