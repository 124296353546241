import http from '../../../utils/services/http'

export const exportar = (colunas) => {
    let url = `/api/arrematantes/export` // ?oolunas=${colunas}`
    const config = {}
    config.responseType = 'blob'
    return http.post(url, {colunas}, config)
        .then(response => Promise.resolve(response))
        .catch(({response}) => Promise.reject(response))
}
