<template>
  <div class="pda-stats-item">
    <label>{{label}}</label>
    <div class="pda-stats-value">{{value}}</div>
  </div>
</template>

<script>
export default {
  name: 'PdaStatsItem',
  props: ['label', 'value']
}
</script>

<style scoped>

</style>
