<template>
  <div class="m-t-lg">
    <div v-if="loading">
      <i class="fa fa-spin fa-spinner"></i> Aguarde enquanto verificamos a disponibilidade do ambiente.
    </div>
    <div v-if="disponibilidade">
      <div v-if="disponibilidade.statusAzul || disponibilidade.statusPorto">
        <div class="text-white erp-terminal">
          Ambientes disponíveis do Grupo Porto: <br><br>
          Azul Seguros: <span v-if="disponibilidade.statusAzul">Sim</span><span v-else>Não</span><br>
          Porto Seguro: <span v-if="disponibilidade.statusPorto">Sim</span><span v-else>Não</span><br>

          <div v-if="startCommand || finishCommand" class="m-t">
            <vue-typed-js v-if="!finishCommand" :typeSpeed="5" :strings="['Iniciando comunicação com o comitente...']">
              <div class="typing"></div>
            </vue-typed-js>
            <div v-for="message in buffer" :key="message.id" class="m-t-xs">
              [{{message.date.date|formatDate}}] {{message.message}}
            </div>
          </div>
        </div>


        <div class="m-t-sm">
          <div class="m-t-sm">
            <u-btn label="Atualiza Lotes" @click="atualizarLotes" :loading="startCommand" icon="exchange-alt" icon-type="fa" color="info"
                   class="m-r-xs"/>
            <u-btn label="Atualiza Arrematantes" icon="user-friends" icon-type="fa" color="primary" class="m-r-xs"/>
            <u-btn label="Atualiza Vendas" icon="money-check" icon-type="fa" color="positive" class="m-r"/>
            <u-btn @click="getBuffer" label="Visualizar log" flat />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checaDisponibilidade, atualizaLotes, commandLogBuffer} from "@/domain/integra/services/grupo-porto"

export default {
  name: "GrupoPortoAcoesLeilao",
  props: ['leilao'],
  data() {
    return {
      loading: true,
      disponibilidade: null,
      startCommand: false,
      finishCommand: false,
      buffer: null
    }
  },
  mounted() {
    this.checaDisponibilidade()
  },
  methods: {
    checaDisponibilidade() {
      checaDisponibilidade()
          .then(({data}) => {
            this.loading = false
            this.disponibilidade = data
            console.log(data)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    atualizarLotes() {
      this.startCommand = true
      this.finishCommand = false
      this.$commandInterval = setInterval(() => {
        this.getBuffer()
      })
      setTimeout(() => {
        this.$commandInterval && clearInterval(this.$commandInterval)
      }, 30000)
      atualizaLotes(this.leilao.id)
          .then(({data}) => {
            console.log(data)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    getBuffer (force = false) {
      if (force) {
        this.startCommand = true
        this.finishCommand = false
      }
      commandLogBuffer(`entity=leilao&entityId=${this.leilao.id}&action=grupo-porto-atualiza-lotes&order=ASC`)
          .then(({data}) => {
            this.buffer = data
            if(!force) {
              const find = this.buffer.find(b => {
                return String(b.message).includes('[OK] Comando finalizado.')
              })
              if (find) {
                this.$commandInterval && clearInterval(this.$commandInterval)
                this.startCommand = false
                this.finishCommand = true
              }
            }
          })
          .catch(error => {
            this.startCommand = false
            this.finishCommand = true
            console.log(error)
          })
    }
  },
  beforeDestroy() {
    this.$commandInterval && clearInterval(this.$commandInterval)
  },
}
</script>

<style scoped>

</style>
