<template>
  <div class="layout-pw">
    <div class="header azul"></div>
    <div class="layout-pw-menu" v-if="menu">
      <slot name="menu">
        <div v-for="(m, index) in menu" :key="index">
          <router-link :to="m.href">{{m.title}}</router-link>
        </div>
      </slot>
    </div>
    <!--<transition name="slide" appear>
      <div class="transition-generic">-->
    <div class="section page">
      <slot />
    </div>
    <!--</div>
  </transition>-->
  </div>
</template>

<script>
export default {
  name: 'LayoutPweb',
  props: {
    menu: {
      required: false
    }
  }
}
</script>

<style lang="stylus">
  .layout-pw {
    position: relative;
    color #666666

    .layout-pw-menu{
      z-index 1
      position absolute
      left 60px
      top 100px
      a{
        color #ffffff
        font-size 14px

        margin-bottom 8px
        display block
        &.router-link-exact-active{
          font-weight bold
        }
      }
    }

    .header {
      height: 350px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: 0;

      &.azul {
        background-color: #4285f4;
      }
    }

    .section.page {
      max-width: 832px;
      border-radius: 0;
      margin: 40px auto;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
      -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
      min-height: 600px;
      padding: 40px 64px;
      z-index 1
      position relative
    }

    .section {
      background-color: #fff;
      -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
      box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
      border-radius: 5px;
      margin-bottom: 20px;
      min-height: 285px;
      padding: 7px 15px 15px;
      overflow: auto;
    }

    h2 {
      font-size 30px;
      color #333
    }

    .subdiv {
      margin-top: 30px;
      margin-bottom: 30px;
      display: block;
      padding-bottom: 10px;
      width: 100%;
      float: left;
      clear: left;
      border-bottom: #e0e0e0 1px solid;
      font-size 18px
      line-height 1.1
    }

    .form-dados .igroup {
      display: block;
      float: left;
      clear: left;
      width: 100%;
    }

    .form-dados label {
      display: block;
      width: 25%;
      float: left;
      clear: left;
      text-align: right;
      padding-right: 20px;
      font-weight: normal;
      margin-bottom: 8px;
    }

    label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
    }

    .form-dados .field {
      display: block;
      width: 74%;
      float: left;
    }
  }
</style>
