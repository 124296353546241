<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import selecaoBensLeilao from '../../windows/selecaoBensLeilao'
import criacaoRapidaBens from '../../windows/criarBensLeilao'
import * as Status from '../../../../domain/leiloes/helpers/LeilaoStatus'

export default {
  name: 'BtnLoteamentoLeilao',
  props: ['leilao'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  methods: {
    selecaoBensLeilao: selecaoBensLeilao,
    criacaoRapidaBens: criacaoRapidaBens,
    loteamentoAutomatico () {
      this.$uloc.actionSheet({
        title: 'Loteamento Automático',
        grid: false,
        actions: [
          {
            label: 'Organizar por Comitente',
            icon: 'user-tie',
            iconType: 'fa',
            handler: () => {
              // this.$uloc.notify({color: 'grey', message: ''})
              this.$emit('lotearAutomatico', 'comitente')
            }
          },
          {
            label: 'Pelo Tipo de Bem',
            icon: 'clipboard-list-check',
            iconType: 'fa',
            handler: () => {
              // this.$uloc.notify({color: 'grey', message: ''})
              this.$emit('lotearAutomatico', 'tipo')
            }
          },
          {
            label: 'Pelo Valor de Avaliação Crescente',
            icon: 'sort-numeric-down',
            iconType: 'fa',
            handler: () => {
              // this.$uloc.notify({color: 'grey', message: ''})
              this.$emit('lotearAutomatico', 'valorCrescente')
            }
          },
          {
            label: 'Pelo Valor de Avaliação Decrescente',
            icon: 'sort-numeric-up',
            iconType: 'fa',
            handler: () => {
              // this.$uloc.notify({color: 'grey', message: ''})
              this.$emit('lotearAutomatico', 'valorDecrescente')
            }
          },
          {}, // separator
          {
            label: 'Cancelar',
            icon: 'times',
            iconType: 'fa',
            color: 'negative',
            handler: () => {
              // this.$uloc.notify('Operação cancelada')
            }
          }
        ]
      })
        .catch(() => {
          // dimiss...
        })
    },
    loteamentoManual () {
      this.$emit('loteamentoManual')
    }
  }
}
</script>

<template>
  <e-btn align="left" label="Loteamento">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item icon="plus-circle" icon-type="fa" @click="selecaoBensLeilao(leilao)" label="Adicionar lotes" close />
          <menu-options-item icon="plus-circle" icon-type="fa" @click="criacaoRapidaBens(leilao)" label="Criação Rápida de Bens" close />
          <menu-options-item @click="loteamentoAutomatico" icon="sort-numeric-down" icon-type="fa" label="Lotear automaticamente" close />
          <menu-options-item @click="loteamentoManual" icon="keyboard" icon-type="fa" label="Lotear manualmente" close />
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
