<template>
  <div class="leilao-datas">
    <leilao-data-item @click.native="mudar(numeroLeilao)" v-for="numeroLeilao in leilao.instancia" :key="numeroLeilao" :active="leilao.praca === numeroLeilao">
      <span v-if="leilao.instancia === 1">Leilão Único</span>
      <span v-else>{{ numeroLeilao }}° Leilão</span>
    </leilao-data-item>
  </div>
</template>

<script>
import LeilaoDataItem from '@/components/leiloes/components/layout-components/LeilaoDataItem'
import LeilaoMixin from "@/components/leiloes/components/LeilaoMixin"
import {updateLeilao} from "@/domain/leiloes/services"

export default {
  name: "LeilaoData",
  mixins: [LeilaoMixin],
  components: {LeilaoDataItem},
  computed: {
  },
  methods: {
    mudar (praca) {
      if (praca === this.leilao.praca) return
      this.$uloc.dialog({
        title: 'Alterar praça do leilão',
        message: `Você tem certeza que deseja mudar do ${this.leilao.praca}° Leilão para o ${praca}° Leilão ?`,
        ok: 'Sim',
        cancel: 'Cancelar'
      }).then(() => {
        this.$uloc.loading.show({message: 'Alterando praça do leilão'})
        updateLeilao(this.leilao.id, {
          praca: praca
        }, 'PATCH')
            .then(response => {
              this.$uloc.loading.hide()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {})
    }
  }
}
</script>
