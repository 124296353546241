<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import importacaoWindow from '../../windows/importacaoArquivo'
import importacaoWebservice from '../../windows/importacaoWebservice'

export default {
  name: 'BtnAcoesLotesSelecionados',
  props: ['leilao'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  methods: {
  }
}
</script>

<template>
  <e-btn icon="check-square" icon-type="fa" icon-style="light" align="left" label="Ações em massa">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item @click="$emit('removerSelecionados')" size="sm" label="Remover do leilão" class="bg-red-1" close  />
          <menu-options-item @click="$emit('removerLoteamento')" label="Remover loteamento" close  />
          <menu-options-item @click="$emit('alterarStatus')" label="Alterar status" close  />
          <menu-options-item @click="$emit('copiarLotesSelecionados')" label="Copiar para outro leilão" close  />
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
