import removerAcentos from "@/utils/remover-acentos";

const colunasRelatorioArrematantes = [
    'ID',
    'DATA CADASTRO',
    'NOME',
    'APELIDO',
    'DOCUMENTO',
    'DATA DE NASCIMENTO',
    'SEXO',
    'TIPO',
    'STATUS',
    'PODE COMPRAR SUCATA',
    'REGIME CASAMENTO',
    'ESTADO CIVIL',
    'CPF CONJUGE',
    'NOME CONJUGE',
    'RG CONJUGE',
    'REGIME CONJUGE',
    'E-MAILS',
    'TELEFONES',
    'ENDEREÇO',
    'BAIRRO',
    'CIDADE',
    'NÚMERO',
    'COMPLEMENTO',
    'ESTADO',
    'PAÍS',
    'CEP'
];

export function getColunasSelect () {
    return colunasRelatorioArrematantes.map(item => ({
            label: item,
            value: removerAcentos(item.replace(/\s/g, '_').toLowerCase())
        })
    );
}

export function getColunasSelectValues () {
    return getColunasSelect().map(({value}) => value)
}
