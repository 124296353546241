<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        :simple-border="simpleBorder || false"
        v-model="description"
        @keydown.enter="searchMarca"
        :size="size || 2"
        readonly
    >
      <u-popover v-if="this.findedData" hover :hover-delay="500" class="popover-user-profile" anchor="bottom middle" self="top middle" :offset="[10, 10]">
        <div class="wrapper bg-white font-12">
          <div class="row">
            <div class="col m-r">ID: <strong>{{findedData.id}}</strong></div>
          </div>
          <div class="row m-t">
            <div class="col m-r">Nome: <strong>{{findedData.nome}}</strong></div>
          </div>
          <a @click="reset" class="m-t-xs block font-10">Limpar</a>
          <!--<div class="row m-t">
            <div class="col m-r">Tipo: <strong>{{findedData.tipo ? findedData.tipo.nome : '-'}}</strong></div>
          </div>-->
          <!--<div class="m-t">
            <e-btn label="Acessar marca" />
          </div>-->
        </div>
      </u-popover>
    </erp-input>
    <a @click="searchMarca"><i class="erp-icon search min"></i> </a>
    <u-icon v-if="!disableCreate" @click.native="windowMarca" name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" />
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import searchMarca from './SearchMarca'
import windowMarca from './windowMarca'
import SearchInputFindedData from '../../../../../mixins/SearchInputFindedData'
export default {
  name: 'MarcaInput',
  props: ['size', 'simpleBorder', 'disableCreate', 'tipo'],
  mixins: [InputMixin, SearchInputFindedData],
  components: {HelperInputBtn,UPopover},
  methods: {
    __updateInternal() {
      this.updateInternal((v) => v.id + ' - ' + v.nome)
    },
    searchMarca: searchMarca,
    windowMarca: windowMarca
  }
}
</script>
