<template>
  <div>
    <u-btn :disable="loading.isLoading" :loading="loading.fechar" @click="$emit('adicionar', true)" no-caps push color="primary" size="sm" icon="plus-circle" icon-type="fa" label="Adicionar ao leilão e fechar" />
    <u-btn :disable="loading.isLoading" :loading="loading.continuar" @click="$emit('adicionar', false)" class="m-l-xs" no-caps push color="grey" icon="plus-circle" icon-type="fa" size="sm" label="Adicionar ao leilão e continuar" />
  </div>
</template>

<script>
export default {
  name: 'ImportacaoBemBtn',
  props: ['hasSelected', 'loading']
}
</script>
