<template>
  <div class="widget-content">
    <widget-header>
      {{title}}
    </widget-header>
    <widget-body :class="{'multiple': Array.isArray(count)}">
      <slot v-if="Array.isArray(count)">
        <div v-for="c in count" class="indicator-col" :class="c.class">
          <div class="i-c-title" :class="{'title-null': !c.title}">{{ c.title || '|' }}</div>
          <widget-indicator :value="c.value" />
        </div>
      </slot>
      <slot v-else>
        <widget-indicator :value="count" />
      </slot>
    </widget-body>
    <widget-footer>
      <slot name="footer">
        <div v-if="!loading">
          <div v-if="subtitle">{{ subtitle }}</div>
          <slot name="link" v-if="!loading"><a>Gerenciar</a></slot>
        </div>
        <u-spinner-uloc class="text-primary" v-else />
      </slot>
    </widget-footer>
  </div>
</template>
<script>
import WidgetHeader from '../../layout/widget/WidgetHeader'
import WidgetBody from '../../layout/widget/WidgetBody'
import WidgetIndicator from '../../layout/widget/WidgetIndicator'
import WidgetFooter from '../../layout/widget/WidgetFooter'
import {USpinnerUloc} from 'uloc-vue'

export default {
  name: 'WidgetRemocoesStats',
  props: ['title', 'subtitle', 'count', 'loading'],
  components: {WidgetFooter, WidgetIndicator, WidgetBody, WidgetHeader, USpinnerUloc}
}
</script>
