export default function (data, template, type = 'gerencial', title = 'Relatório', orientation = 'portrait') {
    let window = 'printView-' + type
    let exports = {}
    if (data.xlsx) {
        exports.excel = () => {
            this.$uloc.printView.emit(window, 'print', 'xlsx')
        }
    }
    !this.$uloc.printView.get(window) && this.$uloc.printView.new({
        wid: window,
        title: title,
        windowClass: 'erp-print',
        contentClass: '',
        props: {
            data: data,
            orientation: orientation,
            router: this.router
        },
        ...exports
    }, template)
        .then((wid) => {
            this.$uloc.printView.listen(wid, {
                print: (wid, action) => {
                    // console.log('Created event')
                    this.actionPrint && this.actionPrint(action)
                }
            })
        }) // return wid
}