export const treeCheck = function (tipos, _level = -1) {
  let items = []
  let level = _level + 1
  tipos.slice().map(tipo => {
    let childsIds = []
    tipo.label = tipo.nome
    tipo.value = tipo.id
    tipo.level = level
    tipo.check = () => {
      console.log('Check processor')
    }
    items.push(tipo)
    if (Array.isArray(tipo.childs) && tipo.childs.length > 0) {
      items = items.concat(this.treeCheck(tipo.childs, level))
    }
  })
  return items
}
