<template>
  <div class="flex items-center">
    <!--<status-leilao-icon class="fa-fw" :status="status" />-->
    <span :class="'scolor leilao-tipo leilao-tipo-' + status">{{getTipo(status).title}}</span>
  </div>
</template>

<script>
import {Tipo, getStatus} from '../../../../domain/leiloes/helpers/LeilaoStatus'
// import TipoLeilaoIcon from './TipoLeilaoIcon'

export default {
  name: 'TipoLeilaoSelector',
  components: {/* TipoLeilaoIcon */},
  props: ['status'],
  computed: {
  },
  methods: {
    getTipo (s) {
      return getStatus(s, Tipo)
    }
  }
}
</script>

<style scoped>

</style>
