<template>
  <div class="search-filter no-select" :class="computedClass">
    <div class="flbl">
      <a @click="toggle" class="btn-toggle">
        <span>Filtros de pesquisa</span>
        <u-icon class="m-l" :name="isFolded ? 'chevron-down' : 'chevron-up'" type="fa"></u-icon>
      </a>
    </div>
    <div class="filter-body m-t-xs" v-show="!isFolded">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchFilter',
  props: {
    folded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFolded: this.folded
    }
  },
  watch: {
    folded (v) {
      this.toggle(v)
    }
  },
  computed: {
    computedClass () {
      let classes = []
      if (this.isFolded) {
        classes.push('folded')
      }
      if (this.menuStyle) {
        classes.push(this.menuStyle)
      }
      return classes
    }
  },
  methods: {
    toggle (v) {
      v = typeof v === 'boolean' ? v : !this.isFolded
      this.isFolded = v
      this.$disableAdjustPosition = true
      this.$nextTick(() => {
        window.setTimeout(() => {
          this.$disableAdjustPosition = false
        }, 500)
        this.$emit('resize')
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .search-filter {
    margin-bottom 14px
    .btn-toggle {
      color #999999
    }
  }
</style>
