import {work} from "@/domain/stats/service"
import {format, differenceInSeconds} from "date-fns"

export default {
    data() {
        return {
            working: {
                paused: false,
                lastActivity: new Date()
            }
        }
    },
    watch: {
        '$uloc.appVisible' (v) {
            this.eventVisibility (v)
        }
    },
    mounted() {
        removeEventListener('mousemove', this.eventMousemove)
        removeEventListener('keydown', this.eventKeydown)
        addEventListener('mousemove', this.eventMousemove)
        addEventListener('keydown', this.eventKeydown)
    },
    methods: {
        eventVisibility (v) {
            if (!v) {
                this.work()
                this.working.paused = true
            } else {
                this.resetTimeout()
            }
        },
        eventMousemove (event) {
            this.resetTimeout()
        },
        eventKeydown (event) {
            this.resetTimeout()
        },
        timeout () {
            // Ausência de operação do usuário
            console.log('Ausência de operação do usuário, enviando sinal de trabalho e pausando.')
            this.work()
            this.working.paused = true
        },
        resetTimeout () {
            if (this.working.paused) {
                this.working.lastActivity = new Date()
            }
            this.working.paused = false
            this.$timeout && clearTimeout(this.$timeout)
            this.$timeout = setTimeout(this.timeout, 30000)
        },
        work() {
            if (this.working.paused || !this.$uloc.auth.logged || !this.$uloc.auth.session) return
            const seconds = differenceInSeconds(new Date(), this.working.lastActivity)
            console.log('Seconds worked: ', seconds)
            work(this.$uloc.auth.session.user.id, format(new Date(), 'yyyyMMdd'), seconds)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.error(error)
                })
        }
    }
}
