<script>
import {UPopover} from "uloc-vue";
import TaskMenuOptionsItem from "./context-window-options-item";

export default {
  name: 'context-reuniao-window',
  components: {TaskMenuOptionsItem, UPopover},
  methods: {
    deleteTask (evt) {
      console.log('Delete task')
    }
  }
}
</script>

<template>
    <u-popover class="window-context-menu" anchor="bottom right" self="top right">
        <div class="menu">
            <ul>
                <task-menu-options-item close label="Visualizar dados"/> <!-- TODO: Mover para um componente Uloc -->
                <task-menu-options-item close label="Exportar para XLSX"/>
                <task-menu-options-item close label="Exportar para CSV"/>
            </ul>
        </div>
    </u-popover>
</template>
