export default {
  title: 'Leilões',
  menu: [
    {
      id: 'leiloes',
      title: 'Leilões',
      icon: 'clipboard-list-check',
      style: 'solid',
      url: '/#/leiloes'
    },
    {
      id: 'bens',
      title: 'Bens',
      icon: 'clipboard-list-check',
      style: 'solid',
      url: '/#/bens/lista'
    },
    {
      id: 'relatorios',
      title: 'Relatórios',
      icon: 'user-chart',
      style: 'regular',
      url: '#'
    },
    {
      id: 'cadastros',
      title: 'Cadastros básicos',
      icon: 'cog',
      style: 'solid',
      url: '#'
    }
  ]
}
