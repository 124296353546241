<template>
  <li @mouseover="onmouseover" @mouseleave="onmouseleave" @mousedown="onmousedown" class="mitem" :class="{'active': m.id === menuContainer.active || active, 'has-submenu': opened, 'disabled': disabled}">
    <helper v-if="showHelper" :title="m.title" />
    <div class="mitem-wrapper">
      <span v-if="m.desc">{{ m.title }}</span>
      <a class="mitem-action flex" :data-title="m.title" @click="menuClick(m)"> <!--:href="m.url"-->
        <div v-if="!submenu" class="micon">
          <u-icon :name="m.icon" :type="m.type || 'fa'" :icon-style="m.style || 'solid'" class="fa-fw"/>
        </div>
        <span v-if="!isSubmenuOpened || submenu" :class="{'has-submenu-opacity': hasSubmenuEffect && !submenu}"
              class="menu-lbl menu-item-col hide-folded">{{ m.title }}</span>
        <div v-if="!submenu && m.submenu && !isSubmenuOpened" :class="{'has-submenu-opacity': hasSubmenuEffect}"
             class="menu-nav menu-item-col hide-folded">
          <span><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                     class="svg-inline--fa fa-chevron-right fa-w-10 fa-fw fa-sm"><path data-v-d57394a6=""
                                                                                       fill="currentColor"
                                                                                       d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                                                                       class=""></path></svg></span>
        </div>
        <div v-if="submenu && m.submenu" class="menu-nav menu-item-col">
          <span><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                     class="svg-inline&#45;&#45;fa fa-chevron-down fa-w-14 fa-fw fa-sm"><path data-v-d57394a6=""
                                                                                              fill="currentColor"
                                                                                              d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                                                                                              class=""></path></svg></span>
        </div>
      </a>
    </div>
    <submenu @active="eventActive" :level="menuLevel" @close="closeSubmenu" :opened="opened" :title="m.title" v-show="m.submenu && opened"
             :menu="m.submenu"/>
  </li>
</template>

<script>
import Submenu from "@/components/layout/menu-components/Submenu"
import Helper from "@/components/layout/menu-components/Helper"

export default {
  // name: "MenuItem",
  components: {Helper, Submenu},
  inject: ['menuContainer', 'menuContent'],
  props: {
    m: {
      required: true
    },
    submenu: {
      type: Boolean,
      default: false
    },
    menuLevel: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    if (this.menuLevel === 1) {
      this.menuContainer.$on('open', (id) => {
        id !== this.m.id && this.close(null, false)
      })
    }
    this.testActive(this.$route)
  },
  watch: {
    '$route' (r) {
      this.testActive(r)
    },
    active (v) {
      if (!v && this.opened) {
        this.menuContainer.isFolded && this.close(null, true)
        //this.menuContainer.$emit('close', null)
      }
    }
  },
  data() {
    return {
      opened: false,
      active: false,
      whenSubmenuOpened: false,
      showHelper: false,
      disabled: this.m.disabled || false
    }
  },
  computed: {
    isSubmenuOpened() {
      return this.menuContainer.submenuOpen
    },
    hasSubmenuEffect() {
      return this.menuContainer.hasSubmenuEffect
    }
  },
  methods: {
    eventActive () {
      console.log('Event active')
      if (this.menuLevel !== 1) return
      this.active = true
    },
    testActive (r) {
      this.active = false
      if (this.m.route) {
        if (this.m.route === r.name) {
          this.active = true
          setTimeout(() => {
            this.$emit('active')
          }, 300)
          return
        }
      }
      if (this.m.url) {
        if (this.m.url.replace(/^\/#/, '') === (r.path + r.hash)) {
          this.active = true
          setTimeout(() => {
            this.$emit('active')
          }, 300)
          return
        }
      }
    },
    menuClick(m) {
      if (this.disabled) return
      if (!m.submenu) {
        if (m.route) {
          this.$router.push({name: m.route})
        }
        if (!m.url) return
        this.$router.push(m.url.replace(/^\/#/, ''))
        if (!m.href) return
        this.$router.push(m.href)
      } else {
        // has submenu
        this.opened && this.menuLevel > 1 ? this.close(m) : this.open(m)
      }
    },
    open(m) {
      if (this.opened) return
      if (this.menuLevel > 1) {
        this.opened = true
        return
      }
      this.menuContainer.hasSubmenuEffect = true
      setTimeout(() => {
        this.menuContainer.$emit('open', m ? m.id : null)
        this.opened = true
      }, 200)
    },
    close(m, emitEvent = true) {
      this.opened = false
      if (this.menuLevel > 1) return
      this.menuContainer.hasSubmenuEffect = false
      setTimeout(() => {
        emitEvent && this.menuContainer.$emit('close', m ? m.id : null)
      }, 200)
    },
    closeSubmenu () {
      this.opened = false
      this.menuContainer.$emit('close', null)
    },
    onmouseover () {
      if (this.menuLevel === 1 && !this.opened && (this.menuContainer.isFolded || this.menuContainer.submenuOpen)) {
        this.showHelper = true
      }
    },
    onmouseleave () {
      this.showHelper = false
    },
    onmousedown () {
      this.showHelper = false
    }
  }
}
</script>
