<template>
  <div class="flex items-center">
    <!--<status-leilao-icon class="fa-fw" :status="status" />-->
    <span :class="'scolor leilao-status leilao-status-interno-' + status">{{getStatus(status).title}}</span>
  </div>
</template>

<script>
import {StatusInterno as Status, getStatus} from '../../../../domain/leiloes/helpers/LeilaoStatus'
// import StatusInternoLeilaoIcon from './StatusInternoLeilaoIcon'

export default {
  name: 'StatusInternoLeilaoSelector',
  components: {/* StatusInternoLeilaoIcon */},
  props: ['status'],
  computed: {
  },
  methods: {
    getStatus (s) {
      return getStatus(s, Status)
    }
  }
}
</script>

<style scoped>

</style>
