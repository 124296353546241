export {getStatus} from '../../../utils/getStatus'

export const STATUS_PENDENTE_APROVACAO = 0;
export const STATUS_PENDENTE = 1;
export const STATUS_PAGO = 2;
export const STATUS_ESTORNADO  = 3;
export const STATUS_CANCELADO  = 4;
export const STATUS_REPROVADO  = 99;

export const STATUS_PAGAMENTO_ARREMATE_PENDENTE_CONFIRMACAO = 0;
export const STATUS_PAGAMENTO_ARREMATE_CONFIRMADO = 1;
export const STATUS_PAGAMENTO_ARREMATE_CANCELADO = 2;
export const STATUS_PAGAMENTO_ARREMATE_ESTORNADO = 3;

export const Status = {
  [STATUS_PENDENTE_APROVACAO]: {title: 'Pagamento', class: 'lote-pag-status-' + STATUS_PENDENTE_APROVACAO},
  [STATUS_PENDENTE]: {title: 'Pagamento', class: 'lote-pag-status-' + STATUS_PENDENTE},
  [STATUS_PAGO]: {title: 'Vendido', class: 'lote-pag-status-' + STATUS_PAGO},
  [STATUS_ESTORNADO]: {title: 'Desistência', class: 'lote-pag-status-' + STATUS_ESTORNADO},
  [STATUS_CANCELADO]: {title: 'Cancelado', class: 'lote-pag-status-' + STATUS_CANCELADO},
  [STATUS_REPROVADO]: {title: 'Baixa Oferta', class: 'lote-pag-status-' + STATUS_REPROVADO},
}
