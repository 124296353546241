<template>
  <div class="row">
    <div class="col m-r pos-rlt" style="width: 50%">
      <div class="font-11 m-b-xs">Lista de acessórios disponíveis para escolha:</div>
      <select v-model="left" class="w-full wrapper-xs" style="height: 200px" multiple>
        <option v-for="acessorio in leftValue" :value="acessorio.value">{{acessorio.label}}</option>
      </select>
      <div class="set-options">
        <div>
          <div>
            <e-btn @click="add">
              <u-icon name="chevron-right" type="fa" />
            </e-btn>
          </div>
          <div class="m-t-sm">
            <e-btn @click="remove">
              <u-icon name="chevron-left" type="fa" />
            </e-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="col m-l" style="width: 50%">
      <div class="font-11 m-b-xs">Lista de acessórios do bem:</div>
      <select v-model="right" class="w-full wrapper-xs" style="height: 200px" multiple>
        <option v-for="acessorio in rightValue" :value="acessorio.value">{{acessorio.label}}</option>
      </select>
    </div>
  </div>
</template>

<script>
import {listAcessorios} from '@/domain/bem/services'

export default {
  name: 'AcessoriosSelect',
  props: {
    value: {required: true},
    tipoBem: {required: false}
  },
  data () {
    return {
      loading: false,
      acessorios: [],
      selecteds: this.value,
      left: [],
      right: []
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    tipoBem (v) {
      console.log(v)
      this.$nextTick(() => {
        this.load()
      })
    },
    selecteds () {
      this.$emit('change', this.selecteds)
    }
  },
  computed: {
    leftValue () {
      return this.acessorios.filter((a) => {
        return !this.selecteds.find((v) => {
          return v.id === a.id
        })
      })
    },
    rightValue () {
      return this.selecteds.map((obj) => {
        return {
          ...obj,
          label: obj.nome,
          value: obj.id
        }
      })
    }
  },
  methods: {
    load () {
      listAcessorios(this.tipoBem)
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.result)) {
            return
          }
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: obj.nome,
              value: obj.id
            }
          })

          this.acessorios = list
        })
        .catch((error) => {
          this.loading = false

        })
    },
    add () {
      console.log(this.left, this.selecteds)
      this.left.map((v) => {
        let find = this.acessorios.find((a) => {
          return a.id === v
        })
        if (find) {
          !this.selecteds.find(f => f === find) && this.selecteds.push(find)
        }
      })
    },
    remove () {
      console.log(this.right)
      this.right.map((v) => {
        let find = this.selecteds.find((a) => {
          return a.id === v
        })
        if (find) {
          this.selecteds.splice(this.selecteds.indexOf(find), 1)
        }
      })
    }
  }
}
</script>
