<template>
  <div>
    <u-input placeholder="Pesquisar" autofocus class="input-search m-t" hide-underline
             v-model="search" />
    <ul class="templates-list">
      <li v-for="(v, index) in filtered" :key="index" @click="$emit('select', v)">
        <a>
          <!--<u-icon class="t-icon" :name="v.icon || 'envelope'" type="fa" />-->
          <p>
            <strong>{{v.name}}</strong>
            {{v.description}}
          </p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {UInput} from 'uloc-vue'
export default {
  name: 'CategoryVariablesList',
  props: ['list'],
  components: {UInput},
  data () {
    return {
      search: null
    }
  },
  computed: {
    filtered () {
      if (!this.search) {
        return this.list
      }
      return this.list.filter(item => String(item.name).toLowerCase().indexOf(this.search) > -1 || String(item.description).toLowerCase().indexOf(this.search) > -1)
    }
  }
}
</script>

<style scoped>

</style>
