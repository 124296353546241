<template>
  <menu-lateral default-class="menu-lateral-leilao" posmenu-full>
    <div class="">
      <h3>Leilão</h3>
      <h1 class="overflow-hidden">{{leilao.descricaoInterna}}</h1>
      <div class="labels m-t-xs">
        <classificacao-leilao-label v-if="leilao.classificacao" :classificacao="leilao.classificacao.nome" />
        <tipo-leilao-label :tipo="leilao.tipo" class="m-l-xs" />
      </div>

      <component v-if="erplayout.$dynamicMenuLeilaoComponent" v-bind:is="erplayout.$dynamicMenuLeilaoComponent.name" :parse-props="erplayout.$dynamicMenuLeilaoComponent.props" class="menu-leilao-dynamic-component"></component>

      <div class="m-t">
        <ul class="menu-leilao-items">
          <li v-for="m in menu" :class="[m.class || null, m.desc && 'desc', {'active': m.id === active}]">
            <span v-if="m.desc">{{m.name}}</span>
            <a @click="link(m)" class="flex" v-else>
              <div class="erp-icon-size" v-if="m.iconType === 'erp'"><i class="erp-icon" :class="m.icon"></i></div>
              <u-icon :name="m.icon" :type="m.iconType" :style="m.iconStyle" v-else />
              <span class="menu-lbl">{{m.name}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </menu-lateral>
</template>

<script>
import TipoLeilaoLabel from '../label/TipoLeilao';
import ClassificacaoLeilaoLabel from '../label/ClassificacaoLeilao'
import MenuJS from './menu-leilao'
import MenuLateral from '../../../layout/MenuLateral';

export default {
  name: 'MenuLeilao',
  components: {MenuLateral, ClassificacaoLeilaoLabel, TipoLeilaoLabel},
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  props: ['leilao', 'active'],
  mounted () {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 400)
  },
  beforeDestroy () {
    this.erplayout.erpheader.setMenuModuleToggle(false)
  },
  methods: {
    link (m) {
      console.log('Link', m)
      if (m.prerouter) {
        m.prerouter(this.$router, this.leilao)
        return
      }
      if (m.route) {
        let route = {name: m.route, params: {id: this.leilao.id}}
        if(m.routeQueryParams){
          route.query = m.routeQueryParams(this.leilao)
        }
        this.$router.push(route)
      }
    }
  },
  computed: {
    menu () {
      return MenuJS.filter(m => !m.roles || this.cracl(m.roles))
    }
  }
}
</script>
