<template>
  <div class="pda-a-status" :class="css">
    {{getStatus}}!
  </div>
</template>

<script>
import * as Status from '../../../../../domain/arrematante/helpers/Status'

export default {
  name: 'PdaArrematanteStatus',
  props: ['arrematante'],
  computed: {
    status () {
      return Number(this.arrematante.status)
    },
    getStatus () {
      return Status.Status[this.arrematante.status].title
    },
    css () {
      let css = []
      if (this.status === Status.STATUS_PENDENTE) {
        css.push('bg-warning text-black')
      }
      if (this.status === Status.STATUS_APROVADO) {
        css.push('bg-positive text-white')
      }
      if (this.status === Status.STATUS_REPROVADO) {
        css.push('bg-purple text-white')
      }
      if (this.status === Status.STATUS_BLOQUEADO) {
        css.push('bg-negative text-white')
      }
      return css
    }
  },
  methods: {}
}
</script>

<style lang="stylus">
  .pda-a-status {
    box-shadow 0px 3px 6px rgba(0, 0, 0, 0.16)
    width 140px
    height 62px
    display flex
    align-items center
    justify-content center
    font-weight bold
    user-select none
  }
</style>
