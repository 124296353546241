export default function createDownloadLink (data, filename, enableDownload = true) {
    const url = window.URL.createObjectURL(new Blob([data], {type: data.type}))
    const link = document.createElement('a')
    link.href = url
    if (enableDownload) {
        link.setAttribute('download', filename)
    } else {
        link.target = '_blank'
    }
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
