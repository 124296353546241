// import Comunicator from 'comunicator/src/index'
import Comunicator from '../../comunicator_wrapper/src/index'
// import RealtimeInterface from 'comunicator/src/realtime-service/interface.js'
import RealtimeInterface from '../../comunicator_wrapper/src/realtime-service/interface.js'
import http from '../utils/services/http'
import Vue from 'vue'

export const createComunicator = function () {
  Vue.prototype.comunicatorClass = new Comunicator(process.env.VUE_APP_GATEWAY, RealtimeInterface, http)
  Vue.prototype.comunicator = this.comunicatorClass.comunicator.connect(process.env.VUE_APP_REALTIME, {})
  //this.comunicator.subscribe('all')
  //this.$globalConfig && this.$globalConfig.clientId && this.comunicator.subscribe(this.$globalConfig.clientId);

  // bind events
}
