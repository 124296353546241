<template>
  <div>
    <h2 class="diviser">
      Movimentações do processo ativo
    </h2>

    <div class="m-t">
      <processo-movimentacao v-if="bem.processo && bem.processo.id" :filtrar-por-processo="bem.processo.id" />
      <div v-else>Este bem não está relacionado à um processo.</div>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import ProcessoMovimentacao from "@/components/processos/components/processos/include/apps/Movimentacao"

export default {
  name: "MovimentacoesProcesso",
  components: {
    ProcessoMovimentacao
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  watch: {
  },
  computed: {},
  methods: {
  }
}
</script>
