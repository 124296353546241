<template>
  <div class="inline-component">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "Inline"
}
</script>
