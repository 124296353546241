export default [
  {
    id: 'basic',
    name: 'Principal',
    icon: 'home',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'integra.resale.main'
  },
  {
    id: 'consultaImoveis',
    name: 'Consulta Bens',
    icon: 'search',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'integra.resale.consultaImoveis'
  }
]
