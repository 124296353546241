<template>
  <div v-show="opened" class="bem-local-map relative-position">
    <div class="rl-endereco-input">
      <form v-on:submit.prevent.stop="search" class="flex justify-between w-full">
        <u-input ref="input" required autofocus placeholder="Digite o endereço para pesquisar no mapa" class="col-grow"
                 v-model="endereco" />
        <u-btn type="submit" size="sm" class="m-l" color="primary" label="pesquisar" />
        <u-btn v-if="this.latLng" size="sm" class="m-l-xs" color="positive" label="Capturar Lat/Lng"
               @click="confirm" />
      </form>
    </div>
    <div class="google-map"
         style="z-index:14000;width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0"
         ref="googleMap"></div>
  </div>
</template>

<script>
import GoogleMap from '../../../../reuse/map/google-maps'
import {UInput} from 'uloc-vue'
import CadastroBemMixin from "components/bem/components/include/CadastroBemMixin"

export default {
  name: 'LocalBem',
  mixins: [GoogleMap, CadastroBemMixin],
  components: {UInput},
  data () {
    return {
      opened: true,
      endereco: null,
      latLng: null
    }
  },
  async mounted () {
    // document.body.appendChild(this.$el)
    this.$nextTick(() => {
      setTimeout(() => {
        let enderecoBem = null
        if (this.bem.endereco && this.bem.numero) {
          enderecoBem = `${this.bem.endereco} ${this.bem.numero}, ${this.bem.bairro}, ${this.bem.cidade} - ${this.bem.uf}, ${this.bem.cep}`
        } else if (this.bem.cidade && this.bem.uf) {
          enderecoBem = `${this.bem.cidade} - ${this.bem.uf}`
        }
        if (this.bem.localizacaoLatitude && this.bem.localizacaoLongitude) {
          this.endereco = `${this.bem.localizacaoLatitude}, ${this.bem.localizacaoLongitude}`
        } else {
          if (enderecoBem) {
            this.endereco = enderecoBem
          }
        }
        this.$nextTick(() => {
          enderecoBem && this.search()
        })
        this.$refs.input.focus()
      }, 200)
    })
  },
  destroyed () {
    // document.body.removeChild(this.$el)
  },
  methods: {
    search () {
      this.searchAddressInMap(this.endereco)
    },
    changeAddress (address) {
      this.endereco = address
    },
    changeLatLng (location) {
      this.latLng = location.toJSON()
    },
    getLocation () {
      return {
        address: this.endereco,
        latLng: this.latLng
      }
    },
    confirm () {
      const location = this.getLocation()
      this.$emit('confirm', location)
      if (typeof this.$callbackConfirm === 'function') {
        this.$callbackConfirm(location)
      }
      if (this.bem) {
        this.bem.localizacaoLatitude = location.latLng.lat
        this.bem.localizacaoLongitude = location.latLng.lng
      }
      // this.hide()
    },
    toggle () {
      this.opened = !this.opened
    },
    simpleShow () {
      this.opened = true
    },
    simpleHide () {
      this.opened = false
    },
    show (location, callbackConfirm) {
      this.$callbackConfirm && delete this.$callbackConfirm
      if (typeof callbackConfirm === 'function') {
        this.$callbackConfirm = callbackConfirm
      }
      this.opened = true
      if (location.lat && location.lng) {
        setTimeout(() => {
          let latlng = new this.google.maps.LatLng(location.lat, location.lng)
          if (!this.$geocodeAddressMarker) {
            let mapComponent = this
            this.$geocodeAddressMarker && this.$geocodeAddressMarker.setMap(null)
            this.$geocodeAddressMarker && delete this.$geocodeAddressMarker
            this.$geocodeAddressMarker = new mapComponent.google.maps.Marker({
              map: mapComponent.map,
              position: location,
              title: this.geocodeAddressTitle
            });

            mapComponent.maxZoomService.getMaxZoomAtLatLng(location, (response) => {
              if (response.status !== 'OK') {
                mapComponent.map.setZoom(11)
              } else {
                mapComponent.map.setZoom(response.zoom)
              }
            });
          }
          console.log('Set actual position', latlng, location)
          this.$geocodeAddressMarker.setPosition(latlng)
          this.map.panTo(this.$geocodeAddressMarker.position)
        }, 1000)
        // this.geocodeLatLng({lat: location.lat, lng: location.lng})
      }
    },
    hide () {
      this.opened = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.bem-local-map {
  position: relative
  height 45vh
  z-index 14000


  .rl-endereco-input {
    position: absolute
    bottom: 20px;
    width 80%;
    left 0
    right 0
    margin auto
    z-index 14001
    display flex
    background-color #FFFFFF
    padding 10px
    border-radius 5px
  }
}
</style>
