<template>
  <i v-if="s === helper.STATUS_PENDENTE_APROVACAO" title="Pendente de Aprovação" class="fa fa-dollar-sign text-warning sm m-r-xs" />
  <i v-else-if="s === helper.STATUS_PENDENTE" class="fa fa-dollar-sign text-blue sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_PAGO" status="positive" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_CANCELADO" status="negative" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_ESTORNADO" status="negative" class="sm m-r-xs" />
  <i v-else></i>
</template>

<script>
import {EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../domain/leiloes/helpers/PagamentoStatus'
export default {
  name: 'StatusPagamentoLoteIcon',
  props: ['status'],
  components: {EBtnCircleStatus},
  computed: {
    helper () {
      return Status
    },
    s () {
      return parseFloat(this.status)
    }
  }
}
</script>
