import {UIcon} from 'uloc-vue'

export default {
  name: 'EBtnTableOptions',
  props: {
    status: String,
    iconName: {
      default: 'chevron-down'
    },
    iconType: {
      default: 'fa'
    },
    iconStyle: {
      default: 'regular'
    }
  },
  components: {},
  computed: {
    computedClass () {
      return {}
    }
  },
  render (h) {
    let props = this.$props
    props['noCaps'] = true
    return h('a', {staticClass: 'erp-btn-table-options', class: this.computedClass}, [
      h(UIcon, {
        props: {
          name: this.iconName,
          type: this.iconType,
          iconStyle: this.iconStyle
        }
      }),
      this.$slots.default
    ])
  }
}
