export default function (data) {
    let window = `relatorio.leiloes.leiloes`
    !this.$uloc.printView.get(window) && this.$uloc.printView.new({
        wid: window,
        title: 'Relatórios de Leilões',
        windowClass: 'erp-print',
        contentClass: '',
        props: {
            data: data,
            orientation: 'landscape'
        }
    }, () => import('./Leiloes.vue'))
        .then((wid) => {
            this.$uloc.printView.listen(wid, {
                excel: (wid, val) => {
                    this.load && this.load('excel')
                }
            })
        }) // return wid
}