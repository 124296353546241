<template>
  <div class="acao-comitente" :class="bindClass">
    <h2 class="lote">Lote {{acao.lote}}</h2>
    <div class="comitente-nome m-b-sm">BV Financeira</div>
    <div class="flex justify-between items-center">
      <u-btn no-caps :label="acaoLabel" class="font-bold" />
      <u-btn @click="acao.show = false" no-caps icon="eye" icon-type="fa" label="Visualizei" size="xs" color="purple-9"
             class="m-l-xs" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AcaoComitente',
  props: {
    acao: {
      required: true
    }
  },
  computed: {
    bindClass () {
      let css = []
      let status = this.acao.status
      if (status === 1) {
        css.push('aprovado')
      }
      if (status === 2) {
        css.push('condicional')
      }
      if (status === 3) {
        css.push('naoAprovado')
      }
      return css
    },
    acaoLabel () {
      let label
      switch (this.acao.status) {
        case 1:
          return 'Aprovado'
        case 2:
          return 'Condicional'
        case 3:
          return 'Não Aprovado'
        default:
          return 'Status inválido'
      }
    }
  }
}
</script>

<style lang="stylus">
  .acao-comitente {
    min-width 260px
    max-width 260px
    min-height 124px
    flex 1
    background-color #FFFFFF
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding 12px
    margin 10px
    animation-name: showAnim;
    animation-duration: 1s
    animation-iteration-count 10
    transition all .5s

    &.aprovado {
      background-color #44B437
      color #FFFFFF

      .u-btn {
        background-color #14570c
      }
    }

    &.condicional {
      background-color #A89817
      color #FFFFFF

      .u-btn {
        background-color #61580d
      }
    }

    &.naoAprovado {
      background-color #D16565
      color #FFFFFF

      .u-btn {
        background-color #5a0b0b
      }
    }

    h2 {
      font-size 38px
      font-weight bold
    }

    .comitente-none {
      font-size 13px
      color #666666
    }
  }

  @keyframes showAnim {
    0% {
      filter: contrast(150%)
      transform scale(1.04)
    }
    25% {
      filter: contrast(200%)
      transform scale(1.02)
    }
    50% {
      filter: contrast(120%)
      transform scale(1.05)
    }
    100% {
      filter: contrast(100%)
      transform scale(1)
    }
  }
</style>
