<template>
  <div>
    <!-- nothing here -->
  </div>
</template>

<script>
export default {
  name: 'AppHome',
  mounted () {
    this.$parent.menuView = ['ata', 'pqa', 'pql', 'atl']
  }
}
</script>

<style scoped>

</style>
