<template>
  <div class="stats hardware">
    <div class="mtab">
      <div class="title">Participação Online / Presencial</div>

      <div class="default-monitor-stats">
        <div class="dados">
          <div class="coluna">
            <div class="previsao lances">
              <div class="titulo">Lances Online</div>
              <div class="value">
                {{ controlador.lancesOnline }}
              </div>
              <div class="legend">{{ controlador.pctLancesOnline }}%</div>
            </div>
          </div>

          <div class="coluna">
            <div class="previsao lances">
              <div class="titulo">Lances Plateia</div>
              <div class="value">
                {{ controlador.lancesPlateia }}
              </div>
              <div class="legend">{{ controlador.pctLancesPlateia }}%</div>
            </div>
          </div>

          <div class="coluna">
            <div class="previsao lances">
              <div class="titulo">Participantes Online</div>
              <div class="value">
                {{ controlador.participantesOnline }}
              </div>
              <div class="legend">{{ controlador.pctParticipantesOnline }}%</div>
            </div>
          </div>
          <div class="coluna">
            <div class="previsao lances">
              <div class="titulo">Participantes Plateia</div>
              <div class="value">
                {{ controlador.participantesPlateia }}
              </div>
              <div class="legend">{{ controlador.pctParticipantesPlateia }}%</div>
            </div>
          </div>
        </div>

      </div>

      <div class="title">Hardware</div>
      <div class="default-monitor-stats">
        <div class="dados">
          <div class="coluna">
            <div class="previsao lances">
              <div class="titulo">Limite de Conexões</div>
              <div class="value">
                <velocimetro />
              </div>
            </div>
          </div>

          <div class="coluna">
            <div class="previsao lances">
              <div class="titulo">Memória RAM</div>
              <div class="value bar">
                <u-knob v-model="memoriaRAM" readonly
                        color="green"
                        line-width="6px"
                        track-color="black">{{ Math.floor(memoriaRAM) }}%
                </u-knob>
              </div>
            </div>
          </div>

          <div class="coluna">
            <div class="previsao lances">
              <div class="titulo">Websocket</div>
              <div class="value">
                159.23MB
              </div>
              <div class="legend service-status justify-center">
                <div class="check m-l-none m-t-sm">
                   <i class="check-icon check-ok m-l-none"></i> <span class="text-white m-l-sm">Serviço ativo</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {UKnob} from 'uloc-vue'
import Velocimetro from "@/components/leiloes/components/monitoramento/components/Velocimetro";
export default {
  name: 'Hardware',
  inject: ['controlador'],
  computed: {
    memoriaRAM () {
      return 9
    }
  },
  components: {
    Velocimetro,
    UKnob
  }
}
</script>
