<template>
  <div :class="statusClass">{{(comitente.active || comitente.value) ? 'Ativo' : 'Inativo'}}</div>
</template>

<script>

export default {
  name: 'StatusComitente',
  props: ['comitente'],
  computed: {
    statusClass () {
      let css = ['comitente-status']
      if (this.comitente.active || this.comitente.value) {
        css.push('text-positive')
      } else {
        css.push('text-negative')
      }
      return css
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus">
  .comitente-status{
    &.text-positive{
      color #009505 !important
    }
    &.text-warning{
      color #956300 !important
    }
    &.text-negative{
      color #EF0505 !important
    }
  }
</style>
