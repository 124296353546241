<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Tipo'"
      :helper="helper"
      :helper-position="helperPosition || 'lb'"
      :error="error"
      :label-width="labelWidth"
      :style="fieldStyle"
  >
    <erp-select
        @input="__set"
        :multiple="multiple"
        :placeholder="placeholder || 'Qualquer'"
        :options="status"
        v-model="model"
        propoverClass="select-status-def1"
        :disable="disable"
        :style="inputStyle"
        :simple-border="simpleBorder"
    >
      <template v-slot:itemLabel="props">
        <tipo-leilao :leilao="mapperProps(props.opt)" />
      </template>
    </erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {Tipo as Status} from '../../../../domain/leiloes/helpers/LeilaoStatus'
import TipoLeilao from './TipoLeilao'

export default {
  name: 'TipoLeilaoSelect',
  mixins: [SelectMixin],
  props: {},
  data () {
    return {}
  },
  computed: {
    status () {
      return Object.keys(Status).map((k) => {
        return {
          label: Status[k].title,
          value: Number(k),
          class: typeof Status[k].title !== 'undefined' ? Status[k].class : null
        }
      })
    }
  },
  components: {TipoLeilao},
  methods: {
    __updateInternal () {
      this.$nextTick(() => {
        if (this.value !== 'undefined' && this.value !== null && this.model === null) {
          let _cp = JSON.stringify(this.value) // force to update datepicker with actual value
          this.model = ''
          setTimeout(() => {
            this.model = JSON.parse(_cp)
          }, 1)
        }
      })
    }
  }
}
</script>

<style lang="stylus">
  .select-status-def1 {
    td {
      display flex
      justify-items center
    }
  }
</style>
