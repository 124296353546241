<template>
  <div class="l-header">
    <div class="nav-left">
      <nav class="header_menu_nav" v-if="$route.name !== 'leiloes.show'">
        <button @click="$router.push({name: 'leiloes.show'})" type="button" class="nav-btn" title="Menu">
          <!--          <div class="erp-icon-size"><i class="erp-icon monitor"></i></div>-->
          <i class="fas fa-arrow-left" />
          <span class="header-button-label">Voltar para o leilão</span>
        </button>
      </nav>
      <nav-btn />
      <nav class="header_menu_nav">
<!--        <button @click="$router.push({name: 'leiloes.controlador'})" type="button" class="nav-btn" title="Menu">
          <div class="erp-icon-size"><i class="erp-icon auction"></i></div>
          <span class="header-button-label">Controlador</span>
        </button>
        <button @click="$router.push({name: 'leiloes.habilitacoes'})" type="button" class="nav-btn" title="Menu">
          <div class="erp-icon-size"><i class="erp-icon approved"></i></div>
          <span class="header-button-label">Habilitações</span>
        </button>
        <button @click="$router.push({name: 'leiloes.monitor', query: {leilao: page.leilao.id}})" type="button" class="nav-btn" title="Menu">
          <div class="erp-icon-size"><i class="erp-icon monitor"></i></div>
          <span class="header-button-label">Monitor</span>
        </button>
        <button @click="$router.push({name: 'leiloes.relatorios'})" type="button" class="nav-btn" title="Menu">
          &lt;!&ndash;          <div class="erp-icon-size"><i class="erp-icon monitor"></i></div>&ndash;&gt;
          <div class="erp-icon-size"> <i class="erp-icon reports" /></div>
          <span class="header-button-label">Relatórios</span>
        </button>-->
      </nav>
      <nav class="header_menu_nav btns-actions">
      </nav>
    </div>
    <div class="header-menu-options">
      <leilao-data />
    </div>
  </div>
</template>

<script>
import NavBtn from '@/components/leiloes/components/layout-components/NavBtn'
import LeilaoData from '@/components/leiloes/components/layout-components/LeilaoData'
import LeilaoMixin from '@/components/leiloes/components/LeilaoMixin'
export default {
  name: 'MenuSuperior',
  mixins: [LeilaoMixin],
  components: {
    LeilaoData,
    NavBtn
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
