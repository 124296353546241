import {uid} from "uloc-vue";

export default function (
    id,
    tipo = 'r',
    relacaoEntidade = null,
    relacaoEntidadeId = null,
    pessoa = null,
    extraRelations = null,
    tags = null,
    processo = null
) {
  let window = typeof id !== 'undefined' ? `conta.${id}.show` : 'conta.new-' + uid()
  let css = window.replace(/\./g, '-')
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Nova Conta',
    width: '1000',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    maximize: false,
    move: false,
    fullscreen: true,
    disableFixedSize: true,
    windowClass: 'bem-window ' + css,
    contentClass: 'overflow-hidden',
    props: {
      id: id,
      $router: this.$router,
      router: this.$router,
      tipo: tipo,
      entidade: relacaoEntidade,
      entidadeId: relacaoEntidadeId,
      extraRelations: extraRelations,
      pessoa: pessoa,
      processo: processo,
      tags: tags
    }
  }, () => import('../components/window/ContaWindow'))
      .then((wid) => {
        console.log(wid)
        setTimeout(() => {
          document.querySelector('.bem-window.' + css).classList.add('opened')
        }, 20)
        this.$uloc.window.listen(wid, {
          updated: (wid, val) => {
            console.log('Update payment event')
            this.load && this.load()
            this.updateConta && this.updateConta(val)
          },
          created: (wid, val) => {
            console.log('Create payment event')
            this.load && this.load()
            this.updateConta && this.updateConta(val)
          }
        })
      }) // return wid
}
