export default function (id, bem) {
  let window = id ? `remocao.${id}` : 'remocao.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Solicitação de Remoção' + (id ? ` #${id}` : ''),
    width: '900',
    height: '900',
    minHeight: '600px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id,
      bemProp: bem
    }
  }, () => import('../components/window/Remocao.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          console.log('Atualizado: ', val)
          this.updateList && this.updateList(val)
        }
      })
    }) // return wid
}
