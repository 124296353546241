export default function () {
  let window = 'tasks.listConfig'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Configurar lista de tarefas',
    width: '500',
    height: '80%',
    minHeight: '500px',
    maxHeight: '500px',
    backdrop: true,
    move: false,
    clickOutside: false,
    windowClass: 'erp-window-modern',
    contentClass: '',
    props: {
      options: this.listStorage
    }
  }, () => import('../../layout/window/ConfigurarLista.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        updated: (wid, storage) => {
          // console.log('Created event')
          this.updateListDefinition(storage)
        }
      })
    }) // return wid
}
