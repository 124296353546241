<template>
  <status-pagamento-lote-selector class="no-select" :status="pagamento.status" />
</template>

<script>
import StatusPagamentoLoteSelector from './StatusPagamentoLoteSelector'
export default {
  name: 'StatusPagamentoLote',
  components: {StatusPagamentoLoteSelector},
  props: ['pagamento']
}
</script>
