export default function (lotes, tipo, salvar = false, enviarArrematante = false) {
  let window = 'nota-arrematacao'
  !this.$uloc.printView.get(window) && this.$uloc.printView.new({
    wid: window,
    title: 'Nota de Arrematação',
    windowClass: 'erp-print',
    contentClass: '',
    props: {
      lotes: lotes,
      tipo: tipo,
      salvar: salvar,
      enviarArrematante: enviarArrematante
    }
  }, () => import('@/components/pda/print/views/NotaArrematacao'))
    .then((wid) => {
    }) // return wid
}
