
export default [
    {
        path: '/relatorios', component: () => import('@/components/relatorios/layout/Layout'), name: 'relatorios.page', children: [
            {path: '', component: () => import('./components/Index'), name: 'relatorios.main'},
            {path: 'leiloes/visualizacao', component: () => import('./apps/leiloes/Visualizacao'), name: 'relatorio.leiloes-visualizacoes'},
            {path: 'produtividade', component: () => import('./apps/produtividade/ProdutividadePeriodo'), name: 'relatorio.produtividade.periodo'},
            {path: 'colaboradores/tempoTrabalho', component: () => import('./apps/colaboradores/TempoTrabalho'), name: 'relatorio.colab.tempoTrabalho'},
            {path: 'bens/tempoEstoque', component: () => import('./apps/bens/TempoEstoque'), name: 'relatorio.bens.tempoEstoque'},
            {path: 'arrematantes/exportar', component: () => import('./apps/arrematantes/ExportarArrematantes'), name: 'relatorio.arrematantes.exportar'},
            {path: 'operacional/logs', component: () => import('./apps/operacional/Logs'), name: 'relatorio.operacional.logs'},
        ]
    }
]
