<template>
  <div class="flex items-center text-nowrap no-wrap">
    <status-solicitacao-icon class="fa-fw" :status="status" />
    <span class="text-black">{{getStatus(status).title}}</span>
  </div>
</template>

<script>
import {StatusSolicitacao, getStatus} from '@/domain/remocao/helpers/Status'
import StatusSolicitacaoIcon from './StatusSolicitacaoIcon'

export default {
  name: 'StatusSolicitacaoSelector',
  components: {StatusSolicitacaoIcon},
  props: ['status'],
  computed: {
  },
  methods: {
    getStatus (s) {
      return getStatus(s, StatusSolicitacao)
    }
  }
}
</script>

<style scoped>

</style>
