<template>
  <div class="pda-menu">
    <pda-menu-item
        v-for="m in getMenu"
        :key="m.name"
        v-shortkey="Array.isArray(m.shortkey) ? m.shortkey.map(s => { return s.toLowerCase()}) : [m.shortkey.toLowerCase()]"
        @shortkey.native="m.action"
        @click.native="m.action"
        :icon="m.icon"
        :icon-type="m.iconType"
        :icon-style="m.iconStyle"
        :label="m.label"
        :shortcut="Array.isArray(m.shortkey) ? m.shortkey.join(' + ') : m.shortkey"
        :class="{'align-bottom': m.bottom, 'active': m.active ? m.active() : false}"
    />
    <!--<pda-menu-item v-if="hasMenu('ata')" v-shortkey="['f3']" @shortkey.native="atendimentoArrematante" @click.native="atendimentoArrematante" label="Atendimento ao Arrematante" shortcut="F3" />
    <pda-menu-item v-if="hasMenu('pqa')" label="Pesquisar Arrematante" shortcut="F4" />
    <pda-menu-item v-if="hasMenu('pql')" label="Pesquisa de Lote" shortcut="F6" />
    <pda-menu-item v-if="hasMenu('atl')" label="Atendimento em Leilão" shortcut="F7" />
    <pda-menu-item v-if="hasMenu('rtn')" class="align-bottom" label="Retornar" shortcut="SHIFT + X" />-->
  </div>
</template>

<script>
import PdaMenuItem from './MenuItem'
import appList from './apps/app'

export default {
  name: 'PdaMenu',
  components: {PdaMenuItem},
  props: {
    menuView: Array,
    app: String
  },
  data () {
    return {
      menu: [
        {
          name: 'ata',
          label: 'Atendimento ao Arrematante',
          shortkey: 'F3',
          action: this.atendimentoArrematante,
          active: () => {
            return this.app === appList.atendimentoArrematante
          }
        },
        {
          name: 'pqa',
          label: 'Pesquisar Arrematante',
          shortkey: 'F4',
          action: this.pesquisaArrematante,
          active: () => {
            return this.app === appList.pesquisaArrematante
          }
        },
        {
          name: 'pql',
          label: 'Pesquisa de Lote',
          shortkey: 'F6',
          action: this.pesquisaLote,
          active: () => {
            return this.app === appList.pesquisaLote
          }
        },
        {
          name: 'atl',
          label: 'Atendimento em Leilão',
          shortkey: 'F7',
          action: this.atendimentoLeilao,
          active: () => {
            return this.app === appList.atendimentoLeilao
          }
        },
        {
          name: 'ipl',
          label: 'Iniciar Pagamento de Lote',
          shortkey: 'F3',
          action: this.iniciarPagamentoLote
        },
        {
          name: 'vca',
          label: 'Visualizar Cadastro',
          shortkey: 'F4',
          action: () => {
            this.$parent.$emit('visualizarCadastroArrematante')
          }
        },
        {
          name: 'ira',
          label: 'Incluir Representante',
          shortkey: 'F6',
          action: () => {
            this.$parent.$emit('incluirRepresentante')
          }
        },
        {
          name: 'fco',
          label: 'Financeiro',
          shortkey: 'F8',
          action: () => {
            this.$parent.$emit('abrirFinanceiro')
          }
        },

        {
          name: 'rgp',
          icon: 'file-invoice-dollar',
          iconStyle: 'light',
          label: 'Registrar Pagamento',
          shortkey: 'F3',
          action: () => {
            console.log(this.$parent)
            this.$parent.$emit('registrarPagamento')
          }
        },
        {
          name: 'irb',
          label: 'Imprimir Recibo',
          icon: 'erp-icon thermal-print zoom-56',
          iconType: 'erp',
          shortkey: 'F4',
          action: () => {
            this.$parent.$emit('imprimirRecibo')
          }
        },
        {
          name: 'nta',
          label: 'Nota de Arrematacao',
          icon: 'erp-icon template zoom-56',
          iconType: 'erp',
          shortkey: 'F6',
          action: () => {
            this.$parent.$emit('imprimirNTA')
          }
        },
        {
          name: 'tre',
          label: 'Termo de Retirada',
          icon: 'erp-icon template zoom-56',
          iconType: 'erp',
          shortkey: ['SHIFT', 'F6'],
          action: () => {
            this.$parent.$emit('imprimirTRE')
          }
        },
        {
          name: 'talienacao',
          label: 'Termo de Alienação',
          icon: 'erp-icon template zoom-56',
          iconType: 'erp',
          shortkey: ['SHIFT', 'F7'],
          action: () => {
            this.$parent.$emit('imprimirTAL')
          }
        },
        {
          name: 'autopositivo',
          label: 'Auto Positivo',
          icon: 'erp-icon template zoom-56',
          iconType: 'erp',
          shortkey: ['SHIFT', 'F8'],
          action: () => {
            this.$parent.$emit('imprimirAUTOP')
          }
        },

        {
          name: 'rtn',
          label: 'Retornar',
          shortkey: ['SHIFT', 'X'],
          action: this.retornar,
          bottom: true
        }
      ]
    }
  },
  computed: {
    getMenu () {
      return this.menu.filter((m) => {
        return this.menuView.indexOf(m.name) > -1
      })
    }
  },
  methods: {
    hasMenu (name) {
      return this.menuView.indexOf(name) > -1
    },
    atendimentoArrematante () {
      console.log('Iniciando atendimento ao arrematante...')
      this.$parent.atendimentoArrematante()
    },
    pesquisaArrematante () {
      console.log('Iniciando pesquisa ao arrematante...')
      this.$parent.pesquisaArrematante()
    },
    pesquisaLote () {
      console.log('Iniciando pesquisa à lote...')
      this.$parent.pesquisaLote()
    },
    atendimentoLeilao () {
      console.log('Iniciando atendimento ao leilão...')
      this.$parent.atendimentoLeilao()
    },
    iniciarPagamentoLote () {
      this.$parent.iniciarPagamentoLote()
    },
    financeiro () {
      this.$parent.financeiro()
    },
    retornar () {
      console.log('Retornando...')
      this.$parent.returnFcn()
    }
  }
}
</script>

<style scoped>

</style>
