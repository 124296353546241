<template>
  <erp-select multiple v-model="model" v-on:input="(v) => $emit('input', v)" placeholder="Todos" :options="sitesFiltered"
              :loading="loading" size="2" />
</template>

<script>
import {ErpSelect} from "uloc-vue-plugin-erp"
import {treeCheck} from "@/components/cadastro/components/tiposbem/helpers/treeCheck"
import {list} from "@/domain/sites/services"

export default {
  name: "Multisite",
  props: ['value'],
  data () {
    return {
      model: this.value,
      loading: false,
      sites: []
    }
  },
  mounted() {
    this.loading = true
    list(200, 1, '', true)
        .then(({data}) => {
          this.sites = data.result
          this.loading = false
        })
        .catch(error => {
          console.error(error)
        })
  },
  computed: {
    sitesFiltered() {
      if (!this.sites) {
        return []
      }
      return this.sites.map(s => {
        return {
          ...s,
          label: s.nome,
          value: s.id
        }
      })
    }
  },
  components: {
    ErpSelect
  },
  methods: {
    treeCheck
  }
}
</script>
