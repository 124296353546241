<template>
  <e-row mr>
    <e-col>
      <erp-s-field class="m-t" label="Etiquetas (Tags)" icon-help="Use Tags para classificar o bem com etiquetas personalizadas para facilitar a pesquisa, identificação e até mesmo estratégias de marketing e relatórios">
        <autocomplete-multiple load-on-focus :search="searchTags" v-model="bem.tags" placeholder="Nenhum"/>
      </erp-s-field>
    </e-col>
  </e-row>
</template>

<script>
import searchTags from "@/components/bem/helpers/searchTags"
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import AutocompleteMultiple from "@/components/layout/components/AutocompleteMultiple"

export default {
  name: "Tags",
  components: {AutocompleteMultiple},
  mixins: [CadastroBemMixin],
  methods: {
    searchTags(terms, done) {
      searchTags(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova tag`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
  }
}
</script>
