export default function (arrematante, lotes, saldoDevedor = null) {
  let window = arrematante ? `pda.registrar-pagamento.${arrematante.id || arrematante}` : 'pda.registrar-pagamento'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Registrar Pagamento de Lote',
    width: '900',
    height: '650',
    minWidth: '900px',
    minHeight: '700px',
    maximize: true,
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window erp-window-macos',
    contentClass: '__overflow-hidden',
    props: {
      lotes: lotes,
      arrematante: arrematante,
      saldoDevedor: saldoDevedor
      // saldo: saldo
    }
  }, () => import('./RegistrarPagamento.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          console.log('Update pagamento lote event')
          this.$emit('update')
          this.load && this.load()
        }
      })
    }) // return wid
}
