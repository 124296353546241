<template>
  <div>
    <h2 class="diviser two-lines">
      Marketplace
      <span>Informações para divulgação nos canais de venda</span>
    </h2>

    <div class="m-t-lg">
      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Título Principal:"
              :helper="titleCountLabel"
              helper-position="lb"
          >
            <erp-input v-model="bem.siteTitulo" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t-sm font-thin font-12">
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              label=""
          >
            O título é muito importante para um bom anúncio do bem. Monte-o de forma resumida mas que apresente o bem da melhor forma possível. Se este bem for para venda, este título estará em destaque nos resultados das pesquisas do Google, Redes Sociais e demais marketplaces integrados.
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Subtítulo:"
              :helper="subtitleCountLabel"
              helper-position="lb"
          >
            <textarea v-model="bem.siteSubtitulo" class="sl-textarea" rows="3" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t-sm font-thin font-12">
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              label=""
          >
            O subtítulo é  importante para complementar o anúncio do bem dentro dos marketplaces. Use-o para complementar informações que não foram possíveis inserir no título.
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Sites"
              label-width="110px"
              icon-help="Para quais sites nossa API deve transmitir este bem (em caso de venda direta)"
          >
            <multisite v-model="bem.sites" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row style="max-width: 700px" class="m-t">
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Venda Direta"
          >
            <sl-checkbox v-model="bem.vendaDireta" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Bem em Destaque"
          >
            <sl-checkbox v-model="bem.destaque" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              class="m-t-n-xs"
              view="lr"
              label="Posição"
              icon-help="Utilizado para ordenar este bem na lista de destaque, de acordo às configurações do website"
          >
            <erp-input type="number" v-model="bem.order" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-t-lg">
        <div class="dtitle subtitle two-lines pos-rlt">
          Descrição completa sobre o bem
          <u-icon @click.native="isDescMin = !isDescMin" :name="!isDescMin ? 'minus-square' : 'expand'"
                  class="cursor-pointer absolute-center-right" type="fa" color="blue-grey-3">
            <u-tooltip :offset="[5,5]">{{ !isDescMin ? 'Ocultar' : 'Restaurar' }}</u-tooltip>
          </u-icon>
        </div>
        <sl-editor
            v-show="!isDescMin"
            class="bem-mplace-editor"
            style="display: block; margin-top: 10px"
            disable-mark
            :default-type="$appConfig.isConfigTrue('bem.descricaoEditorHtml') ? 'source' : 'html'"
            ref="editorDescricao"
            v-model="bem.siteDescricao"
            placeholder="Descreva tudo sobre o bem aqui..."
            :show-toolbar-on-focus="true"
            :close-click-outside="false"
            :toolbar-menu="{mark: false}"
            @focus="() => { siteDescricaoEditing = true }"
        />
      </e-row>

      <e-row class="m-t-lg">
        <div class="dtitle subtitle two-lines pos-rlt">
          Observações adicionais sobre o bem
          <u-icon @click.native="isObsMin = !isObsMin" :name="!isObsMin ? 'minus-square' : 'expand'"
                  class="cursor-pointer absolute-center-right" type="fa" color="blue-grey-3">
            <u-tooltip :offset="[5,5]">{{ !isObsMin ? 'Ocultar' : 'Restaurar' }}</u-tooltip>
          </u-icon>
        </div>
        <sl-editor
            v-show="!isObsMin"
            class="bem-mplace-editor"
            style="display: block; margin-top: 10px"
            disable-mark
            :default-type="$appConfig.isConfigTrue('bem.descricaoEditorHtml') ? 'source' : 'html'"
            ref="editorObservacao"
            v-model="bem.siteObservacao"
            placeholder="Observações sobre o bem..."
            :show-toolbar-on-focus="true"
            :close-click-outside="false"
            :toolbar-menu="{mark: false}"
            @focus="() => { siteObsEditing = true }"
        />
      </e-row>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import SlEditor from "@/components/layout/components/Editor"
import SlCheckbox from "@/reuse/input/Checkbox"
import {UIcon} from "uloc-vue"
import Multisite from "@/components/website/components/Multisite"

export default {
  name: "Marketplace",
  components: {Multisite, SlCheckbox, SlEditor, UIcon},
  mixins: [CadastroBemMixin],
  data () {
    return {
      siteDescricaoEditing: false,
      siteObsEditing: false,
      isDescMin: false,
      isObsMin: true
    }
  },
  computed: {
    bem () {
      return this.bemContainer.bem
    },
    titleCount () {
      return this.bem.siteTitulo ? Number(String(this.bem.siteTitulo).length) : 0
    },
    subtitleCount () {
      return this.bem.siteSubtitulo ? Number(String(this.bem.siteSubtitulo).length) : 0
    },
    titleCountLabel () {
      let count = this.titleCount
      return `${count} de 200 caracteres permitidos`
    },
    subtitleCountLabel () {
      let count = this.subtitleCount
      return `${count} de 255 caracteres permitidos`
    }
  },
}
</script>
