<script>
import Layout from '../../layout/Layout'
import Menu from '../../bem/menu'
import {list, deletePatio} from '@/domain/patio/services'
import {UTable, UTh, UTr, UTd, UCheckbox, LocalStorage} from 'uloc-vue'
import {
  ErpInput, ErpSelect, ErpSField,
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import ContextMenuPatioItem from './context/list-context'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu'
import SearchFilter from '../../layout/helpers/SearchFilter'
import DefaultMixin from '../mixin'
import patioWindow from '../windows/patio'
import listOptions from '../windows/listOptions'
import ERow from '../../layout/components/Row'
import ECol from '../../layout/components/Col'

let listStorage = LocalStorage.get.item('patios.list')
if (!listStorage) {
  listStorage = [
    {label: 'Código', name: 'codigo', active: true, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 1},
    {label: 'Tipo', name: 'tipo', active: true, sortable: true, ordering: 1},
    {label: 'UF', name: 'uf', active: true, sortable: true, ordering: 1},
    {label: 'Cidade', name: 'cidade', active: true, sortable: true, ordering: 1},
    {label: 'Endereço', name: 'endereco', active: true, sortable: true, ordering: 1},
    {label: 'Vagas', name: 'vagas', active: true, sortable: true, ordering: 1},
    {label: 'GPS', name: 'localizacao', active: true, sortable: true, ordering: 1},
    {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1}
  ];
  LocalStorage.set('patios.list', listStorage)
}

let filters = {
  busca: '',
  active: [true, false],
  uf: null, // @TODO
  cidade: null // @TODO
}

export default {
  name: 'Patios',
  mixins: [DefaultMixin],
  components: {
    ECol,
    ERow,
    SearchFilter,
    DefaultListMenu,
    MenuOptions,
    ContextMenuPatioItem,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSelect,
    ErpSField
  },
  data () {
    return {
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    },
    visibledColumns () {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    request ({pagination, filter}) {

      let data1, data2
      let extraFilters = []

      this.table.filters.uf && extraFilters.push(`&id=${this.table.filters.uf}`)
      this.table.filters.cidade && extraFilters.push(`&tipo=${this.table.filters.cidade}`)

      let active = this.table.filters.active.join(',')

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&active=${active}&search=${filter}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
        .then(({data}) => {
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = data.total
          this.table.serverData = data.result

          this.table.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    load () {
      this.table.loading = true
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      this.patioWindow()
    },
    deletar (id) {
      this.$uloc.dialog({
        title: 'Confirmar remoção',
        message: 'Você tem certeza que deseja remover este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        deletePatio(id)
            .then(() => {
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {})
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    },
    patioWindow: patioWindow,
    listOptions: listOptions,
    updateListDefinition (newConfig) {
      LocalStorage.set('patios.list', newConfig)
      this.listStorage = newConfig
    }
  },
  meta: {
    title: 'Patios',
    name: 'Patios'
  }
}
</script>

<template>
  <layout class="erp-list" :menu="menu" menu-style="compact" menu-active="patios">
    <div>
      <search-filter>
        <form id="filter" class="m-t-sm">
          <e-row class="def-m-r">

            <e-col style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Pesquisa inteligente"
              >
                <erp-input @keydown.enter="pesquisar" shortkey="F2" v-model="table.filters.busca" />
              </erp-s-field>
            </e-col>

            <e-col style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Status"
              >
                <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]" multiple
                            v-model="table.filters.active" />
              </erp-s-field>
            </e-col>
          </e-row>
        </form>
      </search-filter>
      <div class="erp-list">
        <div class="col flex justify-end m-t-n-lg m-b def-list-buttons" style="min-width: 300px">
          <div class="flex flex-center column m-r-xs">
            <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                   @click="pesquisar" />
            <span class="shortcut">Shift + Enter</span>
          </div>
          <div class="flex flex-center column m-r-xs">
            <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                   @shortkey.native="clearFilters" />
            <span class="shortcut">Ctrl + Shift + R</span>
          </div>
          <div class="flex flex-center column">
            <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                   @shortkey.native="novo" />
            <span class="shortcut">Shift + N</span>
          </div>
        </div>
      </div>
      <div class="erp-list">
        <div class="text-right">
          <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8" />
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" @dblclick.native="patioWindow(props.row.id)">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td key="codigo" :props="props" auto-width>
              {{props.row.id}} <!-- CODIGO ? -->
            </u-td>
            <u-td class="td-limit" key="nome" :props="props">
              {{props.row.nome}}
            </u-td>
            <u-td :title="props.row.tipo ? props.row.tipo.nome : ''" class="td-limit l-130"
                  key="tipo"
                  :props="props">
              {{props.row.tipo && props.row.tipo.nome ? props.row.tipo.nome :
              props.row.tipo}}
            </u-td>
            <u-td key="uf" :props="props">
              {{props.row.uf}}
            </u-td>
            <u-td key="cidade" :props="props">
              {{props.row.cidade}}
            </u-td>
            <u-td key="endereco" :props="props">
              {{props.row.endereco}}
            </u-td>
            <u-td key="vagas" :props="props">
              {{(props.row.stats || {}).total || 0}}
            </u-td>
            <u-td key="localizacao" :props="props">
              {{props.row.localizacao}}
            </u-td>
            <u-td key="status" :props="props">
              <span class="text-positive" v-if="props.row.active">Ativo</span>
              <span v-else class="text-negative">Inativo</span>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="patioWindow(props.row.id)" @deletar="deletar(props.row.id)" :patio="props.row" />
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-patio-item @abrir="patioWindow(props.row.id)" :patio="props.row" />
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
