export default {
    methods: {
        firstLettersName(name) {
            name = name.split(' ')
            if (Array.isArray(name) && name.length > 1) {
                return name[0].charAt(0) + name[1].charAt(0)
            }
            return String(name).substr(0, 2)
        },
        firstLastName(name) {
            name = name.split(' ')
            if (Array.isArray(name) && name.length > 1) {
                return name[0].charAt(0).toUpperCase() + name[0].slice(1) + ' ' + name[1].charAt(0).toUpperCase() + name[1].slice(1)
            }
            return String(name[0]).charAt(0).toUpperCase() + name[0].slice(1)
        },
        gotoPerson (p, target = '_blank') {
            let router = this.$router || this.router
            let routeData = router.resolve({name: 'pessoa.show', params: {id: p.id}})
            window.open(routeData.href, '_blank')
        }
    }
}
