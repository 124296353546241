import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'bem.propostas.list'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: true, sortable: false, ordering: 1},
    {label: 'Data', name: 'data', active: true, sortable: false, ordering: 2},
    {label: 'Proponente', name: 'proponente', active: true, sortable: false, ordering: 3},
    {label: 'Valor', name: 'valor', active: true, sortable: false, ordering: 4},
    {label: 'Status', name: 'status', active: true, sortable: false, ordering: 4},
], listVersion)
