export default function (id) {
  let window = id ? `reboquista.${id}` : 'reboquista.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Reboquista' + (id ? ` #${id}` : ''),
    width: '1000',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id,
      listClassificacao: this.listClassificacao,
      listLeiloeiros: this.listLeiloeiros
    }
  }, () => import('../components/window/Reboquista.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        update: (wid, storage) => {
          // console.log('Created event')
          this.load?.()
        }
      })
    }) // return wid
}
