<template>
  <div>
    <div class="pda-a-header flex row justify-between pos-rlt">
      <arrematante-card class="card-pos-abs" :class="{'float-card': floatCard}" :arrematante="arrematante"/>
    </div>
    <transition name="slide" appear>
      <div class="transition-generic">
        <div>
          <pendencias-tela-pagamento ref="pendencias" @data="proccessServerData" tela-pagamento
                                     :arrematante="arrematante"/>
          <e-row class="pag-lote-totais">
            <e-col class="m-r-lg">
              <tp-historico-pagamento ref="pagamentos" v-if="serverData" :server-data="serverData"/>
              <div v-if="serverData" class="font-10 m-t-n-lg">
                <div class="m-t-xs"><strong>Pressione F3</strong> para registrar um novo pagamento</div>
              </div>
            </e-col>
            <e-col style="max-width: 185px">
              <e-row>
                <e-col class="total-label">TOTAL LOTES</e-col>
              </e-row>
              <e-row>
                <e-col class="total-input">
                  <erp-input v-model="stats.totalLotes" readonly class="btn-pda btn-pda-rounded md"/>
                </e-col>
              </e-row>

              <e-row>
                <e-col class="total-label">TAXAS</e-col>
              </e-row>
              <e-row>
                <e-col class="total-input">
                  <erp-input v-model="stats.totalTaxas" readonly class="btn-pda btn-pda-rounded md"/>
                </e-col>
              </e-row>

              <e-row>
                <e-col class="total-label">SUBTOTAL</e-col>
              </e-row>
              <e-row>
                <e-col class="total-input">
                  <erp-input v-model="stats.subtotal" readonly class="btn-pda btn-pda-rounded md"/>
                </e-col>
              </e-row>

              <e-row>
                <e-col class="total-label">TOTAL PAGO</e-col>
              </e-row>
              <e-row>
                <e-col class="total-input pago">
                  <erp-input v-model="stats.totalPago" readonly class="btn-pda btn-pda-rounded md"/>
                </e-col>
              </e-row>

              <e-row>
                <e-col class="total-label">SALDO DEVEDOR</e-col>
              </e-row>
              <e-row>
                <e-col class="total-input">
                  <erp-input v-model="stats.saldo" readonly class="btn-pda btn-pda-rounded md" :class="cssSaldo"/>
                </e-col>
              </e-row>
            </e-col>
          </e-row>
          <!--<div style="height: 1000px"></div>--> <!-- TODO: Resolve overflow bug -->
        </div>
        <u-dialog
            v-model="printDialog"
            prevent-close
        >
          <span slot="title">{{ printType }}</span>

          <span slot="message">Como você deseja gerar?</span>

          <!--          <div slot="body">
                      ...
                    </div>-->

          <template class="flex" slot="buttons" slot-scope="props">
            <u-btn no-caps flat label="Cancelar" color="red" @click="props.cancel"/>
            <u-btn no-caps icon="copy" icon-type="fa" flat label="Nota Separada"
                   @click="confirmarImpressaoNota(props.ok, 'separado')"/>
            <u-btn no-caps icon="file-alt" icon-type="fa" flat label="Nota Unificada"
                   @click="confirmarImpressaoNota(props.ok, 'unificado')"/>
          </template>
        </u-dialog>
      </div>
    </transition>
  </div>
</template>

<script>
import appList from './app'
import ArrematanteCard from './arrematante/Card'
import PendenciasTelaPagamento from './arrematante/PendenciasTelaPagamento'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import {ErpInput} from 'uloc-vue-plugin-erp'
import TpHistoricoPagamento from './arrematante/TpHistoricoPagamentos'
import registrarPagamentoWindow from './arrematante/registrarPagamento'
import printRecibo from '../../print/recibo'
import printNota from '../../print/nota'
import termoRetirada from '../../print/termoRetirada'
import termoAlienacao from '../../print/termoAlienacao'
import autoArrematacao from '../../print/autoArrematacao'
import {UDialog} from 'uloc-vue'

export default {
  name: 'AppPdaPagamentoLote',
  inject: {
    pda: {
      default() {
        console.error('AppPdaPagamentoLote for PDA requires PDA parent instance')
      }
    }
  },
  components: {TpHistoricoPagamento, ECol, ERow, PendenciasTelaPagamento, ArrematanteCard, ErpInput, UDialog},
  props: {
    parseProps: {required: false}
  },
  data() {
    return {
      floatCard: false,
      serverData: null,
      stats: {
        totalLotes: 0,
        totalTaxas: 0,
        subtotal: 0,
        totalPago: 0,
        saldo: 0
      },
      cssSaldo: '',
      printDialog: false,
      printType: ''
    }
  },
  computed: {
    arrematante() {
      return this.parseProps.arrematante
    }
  },
  created() {
    this.pda.$on('registrarPagamento', this.registrarPagamento)
    this.pda.$on('imprimirRecibo', this.imprimirRecibo)
    this.pda.$on('imprimirNTA', this.imprimirNTA)
    // this.pda.$on('imprimirTRE', this.imprimirTRE)
    this.pda.$on('imprimirTAL', this.imprimirTAL)
    this.pda.$on('imprimirAUTOP', this.imprimirAUTOP)
  },
  beforeDestroy() {
    this.pda.$off('registrarPagamento', this.registrarPagamento)
    this.pda.$off('imprimirRecibo', this.imprimirRecibo)
    this.pda.$off('imprimirNTA', this.imprimirNTA)
    // this.pda.$off('imprimirTRE', this.imprimirTRE)
    this.pda.$off('imprimirTAL', this.imprimirTAL)
    this.pda.$off('imprimirAUTOP', this.imprimirAUTOP)
  },
  mounted() {
    window.setTimeout(() => {
      this.floatCard = true
    }, 1)
    this.$parent.labelTarget = [
      {label: 'Atendimento'},
      {label: 'Arrematante'},
      {label: this.parseProps.arrematante.pessoa.name.split(' ').shift(), bold: true},
      {label: 'Pagamento de Lotes'}
    ]
    this.$parent.return = () => {
      this.$parent.app = appList.arrematante
    }
    this.$parent.menuView = ['rgp', 'irb', 'nta',/* 'tre',*/'talienacao', 'autopositivo', 'rtn']
  },
  methods: {
    proccessServerData(data) {
      console.log('Process', data)
      if (!data) {
        return
      }
      this.serverData = data
      this.stats.totalLotes = 'R$ ' + this.$options.filters.moeda(data.resumo.totalLotes)
      this.stats.totalTaxas = 'R$ ' + this.$options.filters.moeda(data.resumo.taxas)
      this.stats.subtotal = 'R$ ' + this.$options.filters.moeda(data.resumo.totalLotes + data.resumo.taxas)
      this.stats.totalPago = 'R$ ' + this.$options.filters.moeda(data.resumo.totalPago)
      data.saldo = data.resumo.totalPago - data.resumo.total
      let prefix = ''
      if (data.saldo > 0) {
        prefix = '+'
      } else if (data.saldo < 0) {
        prefix = '-'
      }
      this.stats.saldo = (prefix + 'R$ ') + this.$options.filters.moeda(Math.abs(data.saldo))
      this.cssSaldo = this.classSaldo(data.resumo.total, data.resumo.totalPago)
    },
    classSaldo(total, totalPago) {
      let saldo = this.$refs.pendencias.saldo(total, totalPago)
      if (saldo > 0) return 'saldoDevedorPositivo'
      if (saldo < 0) return 'saldoDevedorNegativo'
      return 'saldoDevedorCompleto'
    },
    registrarPagamento() {
      const lotes = this.$refs.pendencias.table.selected
      const saldoDevedor = this.stats.saldo
      /*let lote = null
      if (lotes && lotes.length > 1) {
        this.$uloc.dialog({
          title: 'Registrar pagamento',
          message: 'Você selecionou mais de um lote para registrar pagamento. Selecione apenas um lote para registrar um pagamento indivual ou não selecione nenhum para registrar um pagamento geral e abater automaticamente nos lotes.',
          color: 'negative'
        })
        return
      }
      if (lotes.length) {
        lote = lotes[0]
      }*/
      this.registrarPagamentoWindow(this.arrematante, lotes, saldoDevedor)
    },
    registrarPagamentoWindow: registrarPagamentoWindow,
    load() {
      this.$refs.pendencias.load()
    },
    printRecibo: printRecibo,
    printNota: printNota,
    termoRetirada: termoRetirada,
    termoAlienacao: termoAlienacao,
    autoArrematacao: autoArrematacao,
    imprimirRecibo() {
      console.log('Imprimindo Recibo')
      const lotes = this.$refs.pendencias.table.selected
      const pagamentos = this.$refs.pagamentos.table.selected
      if (!lotes || !lotes.length) {
        this.$uloc.dialog({
          title: 'Selecionar lote(s)',
          message: `Selecione ao menos um lote para gerar o recibo de pagamento(s)`,
          noCaps: true,
          ok: `Ok`
        })
        return
      }
      this.printRecibo(lotes, pagamentos)
    },
    imprimirNTA() {
      const lotes = this.$refs.pendencias.table.selected
      if (!lotes || !lotes.length) {
        this.$uloc.dialog({
          title: 'Selecionar lote(s)',
          message: `Selecione ao menos um lote para gerar a nota`,
          noCaps: true,
          ok: `Ok`
        })
        return
      }
      this.printType = 'Gerar Nota de Arrematação'
      this.printDialog = true
      console.log('Imprimindo Nota de Arrematação')
    },
    imprimirTRE() {
      const lotes = this.$refs.pendencias.table.selected
      if (!lotes || !lotes.length) {
        this.$uloc.dialog({
          title: 'Selecionar lote(s)',
          message: `Selecione ao menos um lote para gerar o documento`,
          noCaps: true,
          ok: `Ok`
        })
        return
      }
      console.log('Imprimindo Termo de Retirada')
      this.termoRetirada(lotes)
    },
    imprimirTAL() {
      const lotes = this.$refs.pendencias.table.selected
      if (!lotes || !lotes.length) {
        this.$uloc.dialog({
          title: 'Selecionar lote(s)',
          message: `Selecione ao menos um lote para gerar o documento`,
          noCaps: true,
          ok: `Ok`
        })
        return
      }
      console.log('Imprimindo Termo de Alienação')
      this.termoAlienacao(lotes)
    },
    imprimirAUTOP() {
      const lotes = this.$refs.pendencias.table.selected
      if (!lotes || !lotes.length) {
        this.$uloc.dialog({
          title: 'Selecionar lote(s)',
          message: `Selecione ao menos um lote para gerar o documento`,
          noCaps: true,
          ok: `Ok`
        })
        return
      }
      console.log('Imprimindo Auto de Arrematação')
      this.autoArrematacao(lotes)
    },
    async confirmarImpressaoNota(okFn, tipo) {
      await okFn()
      const lotes = this.$refs.pendencias.table.selected
      console.log(lotes)
      this.printNota(lotes, tipo)
    }
  }
}
</script>

<style lang="stylus">
.pag-lote-totais {
  .erp-row > .col {
    margin-bottom 6px
  }

  .total-label {
    text-align right
    font-weight bold
    text-transform uppercase
    padding-right 24px
    color #666666
  }

  .pago {
    input {
      color #78D46F
    }
  }

  .saldoDevedorNegativo {
    color #C53A3A !important
  }

  .saldoDevedorCompleto {
    color #185fc5 !important
  }

  .saldoDevedorPositivo {
    color #78D46F !important
  }

  .total-input {
    .btn-pda {
      height 30px
      margin-bottom 6px
    }

    input {
      text-align right
      font-size 16px
      font-weight bold
      height 30px !important
    }
  }
}
</style>
