<template>
  <div class="monitor">
    <div class="overflow">
      <ul>
        <li v-for="m in log">[{{m.typeDesc}}] {{ m.message }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {logOperacaoLeilao} from "@/domain/leiloes/services"

export default {
  name: "Monitor",
  inject: ['controlador'],
  props: {
    lote: {required: false},
    leilao: {required: false}
  },
  data() {
    return {
      log: null
    }
  },
  mounted() {
    this.$interval = setInterval(() => {
      this.getLog()
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.$interval)
  },
  methods: {
    getLog() {
      logOperacaoLeilao(this.leilao.id)
          .then(({data}) => {
            console.log(data)
            this.log = data
          })
          .catch(error => {
            console.log('Falha ao carregar o log de operação do leilão')
            console.log(error)
          })
    }
  }
}
</script>
