export default function (id) {
    let window = typeof id !== 'undefined' ? `tasks.${id}.show` : 'tasks.new'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Movimentação',
        move: true,
        backdrop: true,
        clickOutside: false,
        closeOnEsc: false,
        windowClass: 'window-modern',
        width: '80%',
        height: '80%',
        props: {
            id: id,
            $router: this.$router,
        }
    }, () => import('./MovimentacaoProcesso'))
        .then((wid) => {
            this.$uloc.window.listen(wid, {
                update: (wid, val) => {
                    this.load && this.load()
                },
                created: (wid, val) => {
                    this.load && this.load()
                }
            })
        }) // return wid
}
