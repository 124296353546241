import http from "@/utils/services/http"

export const list = (limit, page, filtros, exportar = false) => {
    let url = '/api/vistorias?page=' + page + '&limit=' + limit + filtros
    let config = {}
    if (exportar){
        config.responseType = 'blob'
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id) => {
    let url = `/api/vistorias/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const registrarVistoria = (data) => {
    let url = `/api/vistorias`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const update = (vistoria, method = 'PUT', acao = 'atualizar') => {
    let url = `/api/vistorias/${vistoria.id}`
    if (acao) {
        url = url + '?acao=' + acao
    }
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, vistoria)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteVistoria = (id) => {
    let url = `/api/vistorias/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadLaudo = (idVistoria) => {
    let url = `/api/vistorias/${idVistoria}/laudo/download`
    let config = {}
    config.responseType = 'blob'
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const uploadArquivo = (id, data) => {
    let url = `/api/vistorias/${id}/arquivos/upload`
    return http.post(url, data, {
        'timeout': 300000
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteArquivo = (vistoria, arquivo) => {
    let url = `/api/vistorias/${vistoria}/arquivos/${arquivo}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const atualizarBin = (vistoria) => {
    let url = `/api/vistorias/${vistoria}/atualizar-bin`
    return http.patch(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
