<script>
import {ErpInput, ErpSelect} from 'uloc-vue-plugin-erp'
import * as Tipos from '../../../domain/taxas/helpers/Status'
import CriterioGroup from './CriterioGroup'
import {deleteTaxa, list, newTaxa, updateTaxa} from '../../../domain/taxas/services'
import SistemaTaxacaoContext from './context/sistema-context'
import CategoriaHirerarquiaSelect from "components/financeiro/components/input/CategoriaHirerarquiaSelect";

const mockTaxa = {
  nome: null,
  tipo: null,
  valor: null,
  criterios: []
}

export let mockCriterio = {
  campo: null,
  comparativo: null,
  value: null
}

export default {
  name: 'Taxas',
  inject: ['page'],
  mixins: [],
  components: {CategoriaHirerarquiaSelect, SistemaTaxacaoContext, CriterioGroup, ErpInput, ErpSelect},
  data () {
    return {
      sistemas: null,
      sistemaSelected: null,
      taxas: [
        {
          nome: 'ICMS',
          tipo: 1,
          valor: 5,
          criterios: []
        }
      ],
      tipoOptions: Object.keys(Tipos.Tipos).map((k) => {
        return {
          label: Tipos.Tipos[k].title,
          value: Number(k),
          class: typeof Tipos.Tipos[k].title !== 'undefined' ? Tipos.Tipos[k].class : null
        }
      })
    }
  },
  computed: {},
  mounted () {
    this.page.setActive('taxas')
    this.load()
  },
  destroyed () {
  },
  methods: {
    getSuffix (taxa) {
      switch (taxa.tipo) {
        case Tipos.TIPO_PORCENTAGEM:
          return '%'
        case Tipos.TIPO_VALOR_FIXO:
          return 'R$'
        default:
          return ''
      }
    },
    adicionarTaxa () {
      if (!this.sistemaSelected) {
        return
      }
      if (!this.sistemaSelected.taxas || !Array.isArray(this.sistemaSelected.taxas)) {
        this.sistemaSelected.taxas = []
      }
      this.sistemaSelected.taxas.push(JSON.parse(JSON.stringify(mockTaxa)))
    },
    load () {
      list(100, 1, '')
        .then(response => {
          console.log(response.data)
          this.sistemas = response.data.result
          if (Array.isArray(this.sistemas)) {
            this.sistemas.map(s => {
              if (s.padrao) {
                this.sistemaSelected = s
              }
            })
          }
        })
        .catch(error => {
          this.alertApiError(error)
        })
    },
    addCriterio (arr) {
      arr.push([JSON.parse(JSON.stringify(mockCriterio))])
    },
    criarSistema () {
      this.$uloc.dialog({
        title: 'Registrar novo Sistema de Taxação',
        message: 'Digite um nome para o novo sistema de taxas:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'secondary'
      }).then(data => {
        this.$uloc.notify({
          color: 'primary',
          message: `Registrando novo sistema de taxas: "${data}"...`
        })
        newTaxa({
          active: true,
          nome: data,
          padrao: false
        })
          .then(response => {
            this.$uloc.notify({
              color: 'positive',
              message: `Sistema registrado com sucesso! Agora, monte os taxas e seus critérios.`
            })
            this.load()
          })
          .catch(error => {
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    selectSistema (sistema) {
      this.sistemaSelected = sistema
    },
    salvar () {
      this.$uloc.dialog({
        title: 'Tem certeza que deseja alterar sistema de taxas atual?',
        message: 'Digite SIM e clique para confirmar:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'secondary'
      }).then(data => {
        if(data.toUpperCase() !== 'SIM') {
          this.$uloc.notify({
            color: 'negative',
            message: `Você não confirmou a operação. Atualização não efetuada.`
          })
          return
        }
        this.$uloc.notify({
          color: 'primary',
          message: `Alterando sistema de taxas...`
        })
        let _data = JSON.parse(JSON.stringify(this.sistemaSelected))
        if(Array.isArray(_data.taxas) && _data.taxas.length){
          _data.taxas = _data.taxas.map(taxa => {
            taxa.criterios = JSON.stringify(taxa.criterios)
            taxa.finCategoria = taxa.finCategoria && taxa.finCategoria.id ? taxa.finCategoria.id : taxa.finCategoria
            return taxa
          })
        }
        updateTaxa(_data.id, _data)
          .then(response => {
            this.$uloc.notify({
              color: 'positive',
              message: `Sistema atualizado com sucesso! As mudanças impactará nas novas vendas.`
            })
            this.load()
          })
          .catch(error => {
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    excluir (sistema) {
      this.$uloc.dialog({
        title: `Tem certeza que deseja excluir sistema de taxas ${sistema.nome} ?`,
        message: 'Digite EXCLUIR e clique para confirmar:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'negative'
      }).then(data => {
        if(data.toUpperCase() !== 'EXCLUIR') {
          this.$uloc.notify({
            color: 'negative',
            message: `Você não confirmou a operação. Exclusão não efetuada.`
          })
          return
        }
        this.$uloc.notify({
          color: 'negative',
          message: `Excluindo sistema de taxas...`
        })
        deleteTaxa(sistema.id)
          .then(response => {
            this.$uloc.notify({
              color: 'positive',
              message: `Sistema deletado com sucesso!`
            })
            this.load()
          })
          .catch(error => {
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    excluirTaxa (taxa) {
      this.$uloc.dialog({
        title: `Tem certeza que deseja excluir esta taxa ?`,
        message: 'Digite EXCLUIR e clique para confirmar:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'negative'
      }).then(data => {
        if(data.toUpperCase() !== 'EXCLUIR') {
          this.$uloc.notify({
            color: 'negative',
            message: `Você não confirmou a operação. Exclusão não efetuada.`
          })
          return
        }
        this.sistemaSelected.taxas.splice(this.sistemaSelected.taxas.indexOf(taxa), 1)

      }).catch(() => {
      })
    }
  },
  meta: {
    title: 'Taxas',
    name: 'Taxas'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap taxas-container">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <div class="taxas-page">
          <div class="taxas-list">
            <div class="font-14 font-bold m-b">Sistema de taxas registrados</div>
            <ul>
              <li class="non-selectable" v-for="sistema in sistemas" :key="sistema.id">
                <a @click="selectSistema(sistema)"
                   :class="{'font-bold': sistemaSelected && sistemaSelected.id === sistema.id }">
                  &raquo;
                  {{sistema.nome || 'Sem nome'}}
                </a>
                <i v-if="sistema.padrao" class="font-10 m-l-xs text-grey">principal</i>
                <sistema-taxacao-context @excluir="excluir(sistema)" v-if="!sistema.padrao" />
              </li>
            </ul>
            <div class="m-t">
              <u-btn @click="criarSistema" no-caps flat size="sm" icon="file-invoice-dollar" icon-type="fa"
                     label="Criar novo sistema de taxação" />
            </div>
          </div>
          <div class="taxas-content">
            <div v-if="sistemaSelected" class="taxas-body">
              <h2 class="font-16 font-bold m-b-lg">Configuração de Taxas</h2>
              <div class="taxa-row trheader">
                <div class="taxa-col col1">Taxa</div>
                <div class="taxa-col col2">Tipo</div>
                <div class="taxa-col col3">Valor</div>
                <div class="taxa-col col1">Financeiro</div>
                <div class="taxa-col col4">Critérios</div>
              </div>
              <div v-for="(taxa, index) in sistemaSelected.taxas" :key="index">
                <div class="taxa-row">
                  <div class="taxa-col col1">
                    <erp-input size="2" v-model="taxa.nome" />
                  </div>
                  <div class="taxa-col col2">
                    <erp-select placeholder="Selecione o tipo" :options="tipoOptions" size="2" v-model="taxa.tipo" />
                  </div>
                  <div class="taxa-col col3">
                    <erp-input size="2" v-model="taxa.valor" :suffix="getSuffix(taxa)" />
                  </div>
                  <div class="taxa-col col1">
                    <categoria-hirerarquia-select v-model="taxa.finCategoria" :tipo="1" :search-only-select="false" />
                  </div>
                  <div class="taxa-col col4 flex item-center ">
                    <u-btn v-if="!taxa.criterios || !taxa.criterios.length" @click="addCriterio(taxa.criterios)" flat
                           round icon="plus-circle" icon-type="fa" class="font-10" />
                    <div v-else class="flex items-center text-blue">Com critérios</div>
                    <u-btn @click="excluirTaxa(taxa)" size="sm" label="excluir" no-caps flat color="negative" class="m-l" />
                  </div>
                </div>
                <criterio-group v-for="(criterioGroup, cindex) in taxa.criterios" :key="cindex" :cindex="cindex"
                                :criterioGroup="criterioGroup" :reference="taxa.criterios" />
                <u-btn @click="addCriterio(taxa.criterios)" v-if="taxa.criterios && taxa.criterios.length"
                       color="blue-grey-2" class="text-black m-t" size="sm" icon="plus" icon-type="fa"><strong
                    class="m-l-xs">OU</strong></u-btn>
              </div>
              <div class="m-t">
                <e-btn @click="adicionarTaxa" label="Adicionar nova taxa" />
                <e-btn @click="salvar" label="Salvar alterações" class="m-l" icon="save" />
              </div>
            </div>
            <div v-else class="taxas-body">Nenhuma taxa selecionada</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
