<template>
  <div>
    <!--<div class=" flex justify-between" style="min-height: 160px !important;">-->
      <div class="col-grow flex justify-between h-full">
        <!-- TODO: BUG: Window table need an max-height prop and ajsut scroll and thead -->
        <e-window-table class="col-grow h-full"
                        :columns="['ID Leilão', 'Data', 'Lances recebidos', 'Valor mínimo', 'Lote', 'Venda', 'Status', 'Opções']"
        >
          <e-tr v-for="(data, index) in leiloes" :key="index">
            <e-td><span>{{ data.leilao.id }}</span></e-td>
            <e-td>{{ data.leilao.data|formatDate }}</e-td>
            <e-td>{{ data.lances }}</e-td>
            <e-td class="text-nowrap">R$ {{ data.valorMinimo | moeda }}</e-td>
            <e-td class="text-nowrap">{{ data.numero }}</e-td>
            <e-td>
              <span class="font-bold" v-if="data.valorArremate">R$ {{data.valorArremate | moeda}}</span>
              <span v-else>-</span>
            </e-td>
            <e-td>
              <div v-if="status(data.status)" class="flex items-center">
                <status-lote :lote="data" />
              </div>
            </e-td>
            <e-td>
              <a v-if="!lote || lote.id !== data.id" @click="open(data)"><i class="erp-icon search min"></i> </a>
              <span v-else>Lote atual</span>
            </e-td>
          </e-tr>
          <e-tr v-if="!leiloes || !leiloes.length">
            <e-td style="text-align: left !important;" colspan="100%">
              Nenhum registro
            </e-td>
          </e-tr>
        </e-window-table>
      </div>
    <!--</div>-->
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable} from 'uloc-vue-plugin-erp'
// import * as Status from '../../../../domain/leiloes/helpers/LoteStatus'
import StatusLote from '../../../leiloes/components/include/StatusLote'
import windowLote from '../../../leiloes/windows/lote'

export default {
  name: 'HistoricoLeiloes',
  props: {
    leiloes: {
      required: true
    },
    lote: {
      required: false
    }
  },
  components: {
    StatusLote,
    EWindowTable,
    ETr,
    ETd
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    status (s) {
      if (typeof s !== 'undefined') {
        return String(s)
      }
      return false
    },
    windowLote: windowLote,
    open (lote) {
      console.log('Open lote...')
      this.windowLote(lote)
    }
  }
}
</script>
