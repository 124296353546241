import http from '../../utils/services/http'

export const buscaCep = (cep) => {
  const authorization = http.defaults.headers.common.Authorization
  let cache = false
  if (typeof http.defaults.headers['Cache-Control'] !== 'undefined') {
    cache = http.defaults.headers['Cache-Control']
  }
  if (typeof authorization !== 'undefined') {
    delete http.defaults.headers.common.Authorization
    if (cache) {
      delete http.defaults.headers['Cache-Control']
    }
  }
  return http.get('//viacep.com.br/ws/' + cep + '/json')
    .then(response => {
      if (typeof authorization !== 'undefined') {
        http.defaults.headers.common.Authorization = authorization
      }
      if (cache) {
        http.defaults.headers['Cache-Control'] = cache
      }
      return Promise.resolve(response)
    })
    .catch((error) => {
      if (typeof authorization !== 'undefined') {
        http.defaults.headers.common.Authorization = authorization
      }
      if (cache) {
        http.defaults.headers['Cache-Control'] = cache
      }
      return Promise.reject(error)
    })
}
