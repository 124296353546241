<script>
import {debounce, UTable, UTh, UTr, UTd/*, UCheckbox*/} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '../../layout/context-menu/context-window-options'
import SearchFilter from '../../layout/helpers/SearchFilter'
import {ErpInput, ErpSelect, ErpSField} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import MenuOptionsItem from '../../layout/context-menu/context-window-options-item'
import ERow from '../../layout/components/Row'
import ECol from '../../layout/components/Col'

let filters = {
  busca: '',
  active: [true, false]
}

export default {
  name: 'ListDefault',
  mixins: [DefaultMixin],
  props: {
    resolver: {
      required: true,
      type: Object
    },
    parseFilters: {
      required: false
    }
  },
  components: {
    ECol,
    ERow,
    MenuOptionsItem,
    SearchFilter,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    // UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSelect,
    ErpSField
  },
  data () {
    return {
      table: {
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: JSON.parse(JSON.stringify(this.resolver.columns)),
        filters: this.resolver.filters ? this.resolver.filters() : JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {},
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    },
    request ({pagination, filter}) {

      this.table.loading = true
      let search = this.table.filters.busca
      let active = this.table.filters.active.join(',')
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${search}&active=${active}`)

      console.log('call', pagination.rowsPerPage, pagination.page, filtros)

      if (this.parseFilters) {
        filtros = this.parseFilters(pagination)
      }

      if (this.resolver.parseResolve) {
        this.resolver.parseResolve.call(this)
      } else {
        this.resolver.resolve
        (pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result

            this.table.loading = false
          })
          .catch(error => {
            this.loading = false
          })
      }

    },
    clearFilters () {
      this.$emit('clearFilters')
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      if (this.resolver.methods.novo && typeof this.resolver.methods.novo === 'function') {
        this.resolver.methods.novo.call(this)
      }
    },
    pesquisar () {
      this.load()
    },
    abrir (props) {
      console.log('Open...')
      if (this.resolver.methods.abrir && typeof this.resolver.methods.abrir === 'function') {
        this.resolver.methods.abrir.call(this, props.id)
      }
    },
    excluir (props) {
      console.log('Delete...')
      if (this.resolver.methods.excluir && typeof this.resolver.methods.excluir === 'function') {
        this.$uloc.dialog({
          title: 'Excluir',
          message: `Tem certeza que deseja excluir este registro ?`,
          noCaps: true,
          ok: `Confirmar exclusão`,
          cancel: 'Não'
        })
            .then(() => {
              this.resolver.methods.excluir(props.id).then(() => {
                this.load()
              })
              .catch(error => {
                this.alertApiError(error)
              })
            })
            .catch(() => {})
      } else {
        console.log('method does not exists')
      }
    },
    updateList (data) {
      this.$emit('updateList', data)
    }
  },
  meta: {
    title: 'Cadastro',
    name: 'Cadastro'
  }
}
</script>

<template>
  <div class="default-list">
    <div class="wrapper-md" style="padding-bottom: 0">
      <p v-if="resolver.pageTitle" class="font-16 font-bold">{{resolver.pageTitle}}</p>
    </div>
    <search-filter>
      <slot name="filters">
        <form id="filter" class="m-t-sm">
          <e-row class="def-m-r">

            <e-col style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Pesquisa inteligente"
              >
                <erp-input @keydown.enter="pesquisar" shortkey="F2" v-model="table.filters.busca" />
              </erp-s-field>
            </e-col>

            <e-col style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Status"
              >
                <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]" multiple
                            v-model="table.filters.active" />
              </erp-s-field>
            </e-col>
          </e-row>
        </form>
      </slot>
    </search-filter>
    <e-row>
      <div class="col flex justify-end m-t-n def-list-buttons wrapper-md" style="min-width: 300px; padding-top: 0; padding-bottom: 0">
        <div class="flex flex-center column m-r-xs">
          <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                 @click="pesquisar" />
          <span class="shortcut">Shift + Enter</span>
        </div>
        <div class="flex flex-center column m-r-xs">
          <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                 @shortkey.native="clearFilters" />
          <span class="shortcut">Ctrl + Shift + R</span>
        </div>
        <div class="flex flex-center column">
          <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                 @shortkey.native="novo" />
          <span class="shortcut">Shift + N</span>
        </div>
      </div>
    </e-row>
    <div class="erp-list"> <!-- :filter="table.filters.busca" -->
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[20, 50, 100]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <!--<u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row)" class="cursor-pointer" slot="body" slot-scope="props"
              :props="props">
          <!--<u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs" />
          </u-td>-->
          <u-td v-for="col in resolver.columns.slice().filter(o => o.name !== 'options')" :key="col.name"
                :class="col.class"
                :props="props">
            {{col.parser ? col.parser(props.row) : '-'}}
          </u-td>
          <!--<u-td class="td-limit l-130" key="resumo"
                :props="props">
            {{props.row.resumo}}
          </u-td>-->
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Abrir" @click="abrir(props.row)" icon="folder-open" />
                  <menu-options-item close label="Excluir" @click="excluir(props.row)" icon="times"
                                     label-class="text-negative" />
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
