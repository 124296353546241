<template>
  <div class="stats progesso-leilao">
    <div class="mtab">
      <div class="title">Progresso do Leilão</div>

      <leilao-tempo-stats full v-if="controlador.leilao" :leilao="controlador.leilao" :lote="controlador.lote" />
    </div>
  </div>
</template>

<script>
import LeilaoTempoStats from '@/components/leiloes/components/tela-leiloeiro/include/LeilaoTempoStats'
export default {
  name: 'ProgressoLeilao',
  components: {LeilaoTempoStats},
  inject: ['controlador'],
  computed: {}
}
</script>

<style src="../../../assets/leilao-progresso.styl" lang="stylus"></style>
