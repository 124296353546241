<template>
  <div class="erp-profile-menu">
    <PosMenuIcon name="tasks" icon-type="fa" icon-style="light" />
    <u-popover class="window-context-menu menu-profile notify-menu" anchor="bottom left" self="top left" :offset="[0, 5]">
      <div>
        <h2>Notificações de Tarefas</h2>
        <ul>
          <div class="notify-content m-b-lg">
            Nenhuma aviso de tarefa até o momento.
          </div>
        </ul>
      </div>
    </u-popover>
  </div>
</template>

<script>
import {PosMenuIcon} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
// import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
export default {
  name: 'NotifyTarefas',
  components: {PosMenuIcon, UPopover},
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
