<template>
  <erp-input class="pos-rtl"
             @focus="showDatepicker"
             @blur="hideDatepicker"
             @keydown.enter="$emit('enter')"
             :autofocus="autofocus"
             :simple-border="simpleBorder"
             v-model="model"
             v-mask="dateFormat"
             ref="input"
             :after="icon ? iconAfter() : null"
             :disable="disable"
             :class="inputClass"
             @input="__set">
    <u-popover v-if="!disable" @click.native="popoverClick" noFocus :offset="offset" ref="popDataRemocao">
      <u-datetime-picker v-model="timestamp" :type="dateType" />
    </u-popover>
  </erp-input>
</template>

<script>
import InputMixin from './mixin'
import {UDatetimePicker, UPopover, date} from 'uloc-vue'

export default {
  name: 'DatetimeInput',
  mixins: [InputMixin],
  props: {
    dateFormat: {
      type: String,
      default: '##/##/#### ##:##'
    },
    dateType: {
      type: String,
      default: 'datetime'
    },
    icon: {
      type: Boolean,
      default: true
    },
    inputClass: {
      required: false
    },
    offset: {
      required: false,
      default: [0, 10]
    }
  },
  data () {
    return {
      timestamp: null,
      noUpdateModel: false
    }
  },
  methods: {
    showDatepicker () {
      setTimeout(() => {
        this.$refs.popDataRemocao && !this.$refs.popDataRemocao.showing && this.$refs.popDataRemocao.show() && this.$refs.input.focus()
      }, 200)
    },
    hideDatepicker () {
      // TODO: CLICK dentro do popover está fechando o mesmo, devido so hover do input após o clique. Verificar anchor
      // return
      this.$timerCloseDatepicker = window.setTimeout(() => {
        this.$refs.popDataRemocao && !this.$refs.popDataRemocao.hide()
      }, 200)
    },
    popoverClick (e) {
      clearTimeout(this.$timerCloseDatepicker)
    },
    iconAfter () {
      return [{icon: 'date_range', handler: this.iconClick}]
    },
    iconClick () {
      console.log(this)
      this.$refs.input.focus()
    },
    __updateInternal () {
      this.$nextTick(() => {
        if (this.timestamp === null && typeof this.value !== 'undefined' && this.value !== null) {
          let _cp = JSON.stringify(this.value) // force to update datepicker with actual value
          this.model = ''
          setTimeout(() => {
            this.model = JSON.parse(_cp)
          }, 1)
        }
      })
    }
  },
  watch: {
    timestamp (v) {
      if (date.isValid(v)) {
        console.log('Valid date', this.dateType, v)
        let formatPtBr
        if (this.dateType === 'date') {
          formatPtBr =  date.formatDate(v, 'DD/MM/YYYY')
        } else if (this.dateType === 'time') {
          formatPtBr =  date.formatDate(v, 'HH:mm')
        } else {
          formatPtBr = date.formatDate(v, 'DD/MM/YYYY HH:mm')
        }
        if (!this.noUpdateModel) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.model = formatPtBr
              this.$emit('input', this.model)
            }, 50)
          })
          //this.model = formatPtBr
          //this.$emit('input', this.model)
        }
        this.$nextTick(() => {
          this.noUpdateModel = false
        })
      }
      // console.log(v)
    },
    model (v) {
      let datetime = v.split(' ')
      console.log('XXX', datetime)
      // date
      let _date = datetime[0] || null

      // time
      let _time = datetime[1] || null

      if (_date === null) {
        return
      }

      let now = new Date()

      _date = _date.split('/')
      if (_date.length === 3 && _date[2] !== '') {
        _date = `${_date[2]}-${_date[1]}-${_date[0]}`
      } else if (_date.length === 2 && _date[1] !== '') {
        _date = `${now.getFullYear()}-${_date[1]}-${_date[0]}`
      } else {
        _date = `${now.getFullYear()}-${now.getMonth()}-${_date[0]}`
      }

      if (_time !== null) {
        _date = _date + ' ' + _time
      } else if(this.dateType !== 'time') {
        _date = _date + ' 00:00:00'
      }

      console.log(_date, _time)
      if (date.isValid(_date)) {
        this.noUpdateModel = true
        this.timestamp = _date
      }
    }
  },
  components: {
    UDatetimePicker,
    UPopover
  }
}
</script>

<style scoped>

</style>
