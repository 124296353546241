<template>
  <div class="flex flex-zero column text-center">
    <div class="flex flex-zero column text-center" style="margin: auto; min-height: 200px">
      <transition name="slide" appear>
        <div class="transition-generic">
          <div class="m-b"><h2>Digite o <strong>CPF ou CNPJ</strong> do arrematante para iniciar o atendimento</h2>
          </div>
          <div style="width: 356px; margin: 0 auto">
            <erp-input @keydown.enter="buscar" autofocus v-model="busca" class="btn-pda btn-pda-rounded big text-center"
                       shortkey="F2" />
          </div>
          <div v-if="!loading" class="text-center">
            <pda-menu-item label="Iniciar atendimento" shortcut="ENTER" class="btn-pda-def" @click.native="buscar" />
          </div>
          <div class="text-center" v-if="loading || finded">
            <u-progress style="max-width: 300px; margin: auto" :percentage="loadingProgress" color="primary"
                        :animate="true"
                        v-if="loading" />
            <div class="font-10 m-t-xxs">{{loadingProgress.toFixed(0)}}%</div>
          </div>
          <div v-if="notFound" class="text-negative">Arrematante não encontrado</div>
          <div v-if="arrematante" class="text-success m-t-xs">
            Arrematante encontrado! Abrindo...
          </div>
          <div class="m-t-md text-grey-7 font-11">Ou pressione <strong>F4</strong> para pesquisar</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {date, UProgress} from 'uloc-vue'
import {ErpInput} from 'uloc-vue-plugin-erp'
import PdaMenuItem from '../MenuItem'
import appList from './app'
import {findByDoc} from '@/domain/arrematante/services'
import loadingMixin from '../../../../reuse/loading/loadingMixin'

export default {
  name: 'IniciarAtendimento',
  mixins: [loadingMixin],
  components: {PdaMenuItem, ErpInput, UProgress},
  mounted () {
    this.$parent.labelTarget = null
    this.$parent.return = () => {
      this.$parent.app = appList.home
    }
    this.$parent.menuView = ['ata', 'pqa', 'pql', 'atl', 'rtn']
  },
  data () {
    return {
      busca: null,
      loading: false,
      finded: false,
      notFound: false,
      arrematante: null
    }
  },
  methods: {
    buscar () {
      if (!this.busca || this.busca.length === 0) {
        return
      }
      console.log('Pesquisar por arrematante: ', this.busca)
      this.loading = true
      this.startLoading()
      this.notFound = false
      this.arrematante = null
      findByDoc(this.busca)
        .then(response => {
          let data = response.data
          this.arrematante = data
          this.$nextTick(() => {
            this.stopLoading()
            this.$parent.changeApp(appList.arrematante, {arrematante: this.arrematante})
          })
        })
        .catch((error) => {
          this.stopLoading()
          this.notFound = true
          console.error(error, error.data)
        })
    }
  }
}
</script>

<style lang="stylus">

</style>
