import {list} from '@/domain/leiloes/services'

const search = function () {
    console.log('click window search leilão...')
    let window = 'windowSearch'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Selecione o leilão para o qual serão copiados os lotes',
        width: '800',
        height: '600',
        minHeight: '500',
        backdrop: true,
        clickOutside: false,
        windowClass: 'erp-window',
        contentClass: 'overflow-hidden bg-white',
        props: {
            columns: [
                {label: 'ID', value: 'id'},
                {label: 'Código', value: 'codigo'},
                {label: 'Título', value: 'titulo'}
            ],
            fetchData: (terms, filters, done) => {
                console.log('Buscando dados: ', terms, filters, done)
                let rowsPerPage = 100
                list(rowsPerPage, filters.page, `&sortBy=id&descending=desc&search=${terms}&searchCopiaLotes=true`)
                    .then(({data}) => {
                        console.log(data)
                        /*let filter = function (terms, {field, list}) {
                            const token = terms.toLowerCase()
                            return list.filter(item => ('' + item[field]).toLowerCase().includes(token))
                        }

                        let filteredData = filter(terms, {field: 'titulo', list: data.result})

                        done(filteredData, {
                            page: filters.page,
                            rowsNumber: data.total, // response.totalRows,
                            rowsPerPage: rowsPerPage
                        })*/
                        done(data.result, {
                            page: filters.page,
                            rowsNumber: data.total, // response.totalRows,
                            rowsPerPage: rowsPerPage
                        })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
    }, () => import('../../../../layout/window/WindowSearch.vue'))
        .then((wid) => {
            this.$uloc.window.listen(wid, {
                selected: (wid, val) => {
                    console.log('Selecionado com sucesso: ', val)
                    this.leilaoSelecionado = val
                    this.__emit && this.__emit(val.id)
                    this.confirmaCopiarLotes && this.confirmaCopiarLotes() // @TODO: Aqui está certo ?
                }
            })
            console.log(wid)
        }) // return wid
}

export default search
