export default {
  data () {
    return {
      loadingProgress: 0
    }
  },
  methods: {
    startLoading () {
      clearInterval(this.$loading)
      this.loadingProgress = 0
      this.$loading = setInterval(() => {
        if (this.loadingProgress >= 100) {
          clearInterval(this.$loading)
          return
        }
        this.loadingProgress = this.loadingProgress + Math.round(Math.random() * 2)
      }, 10)
    },
    stopLoading () {
      clearInterval(this.$loading)
      this.loadingProgress = 100
      window.setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  }
}
