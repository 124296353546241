export const testRoles = (roles, permission) => {
  if (Array.isArray(roles) && roles.includes('ROLE_GERENCIA') && (!permission || !permission.includes('ROLE_GERENCIA'))) {
    return false
  }
  if (!Array.isArray(permission) || !permission.length) {
    return false
  }
  if (permission.includes('ROLE_ADMIN')) return true
  if (typeof roles !== 'undefined' && Array.isArray(roles)) {
    for (let i = 0; i < roles.length; i++) {
      if (testRole(roles[i], permission)) {
        return true
      }
    }
  }
  return false
}

export const testRole = (role, permission) => {
  if (String(role).startsWith('!')) {
    // NEG
    return !permission.includes(role.replace('!', ''))
  }
  return permission.includes(role)
}

export const testAcls = (acls, permission) => {
  console.log(permission, acls)
  if (!Array.isArray(permission) || !permission.length) {
    return false
  }
  if (permission.includes('ROLE_ADMIN')) return true
  for (let i = 0; i < acls.length; i++) {
    if (testAcl(acls[i], permission)) {
      return true
    }
  }
  return false
}

export const testAcl = (acl, permission) => {
  if (String(acl).startsWith('!')) {
    // NEG
    return !permission.includes(acl.replace('!', ''))
  }
  return permission.includes(acl)
}

/**
 * Check for Role or ACL pass
 * @param role
 * @param acl
 * @param permissionRoles
 * @param permissionAcls
 * @returns {boolean}
 */
export const checkRoleAcl = (role, acl, permissionRoles, permissionAcls) => {
  if (!Array.isArray(permissionRoles) || !permissionRoles.length) {
    permissionRoles = []
  }
  if (permissionRoles.includes('ROLE_ADMIN')) return true
  let success = false
  if (role) {
    if (Array.isArray(role)) {
      success = testRoles(role, permissionRoles)
    } else if (testRole(role, permissionRoles)) {
      success = true
    }
  }
  if (success) return true

  if (!Array.isArray(permissionAcls) || !permissionAcls.length) {
    permissionAcls = []
  }
  if (acl) {
    if (Array.isArray(acl)) {
      return testAcls(acl, permissionAcls)
    } else if (testAcl(acl, permissionAcls)) {
      return true
    }
  }
  return false
}
