<template>
  <div class="menu_submenu">
    <div class="submenu-column submenu-column_1">
      <ul class="submenu_inner submenu_inner_level-1">
        <li class="submenu_item submenu_item_level-2">
          <span class="submenu_link submenu_link_nolink"><span class="submenu_link-text">Funções de Transmissão</span></span>
          <ul class="submenu_inner_level-2">
            <nav-sub-link @click="$router.push({name: 'leiloes.apresentacao-telao-seletor'})" icon="desktop-alt" icon-type="fa" icon-style="regular" label="Tela para TV/Telão" />
            <nav-sub-link @click="$router.push({name: 'leiloes.monitor', query: {leilao: page.leilao.id}})" icon="monitor" icon-type="erp" label="Monitoramento" />
            <nav-sub-link @click="$router.push({name: 'leiloes.live'})" icon="video" icon-type="fa" icon-style="regular" label="Transmissão por Vídeo" />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavSubLink from './NavSubLink'
import LeilaoMixin from "@/components/leiloes/components/LeilaoMixin"
export default {
  name: 'TransmissaoSubmenu',
  mixins: [LeilaoMixin],
  components: {NavSubLink},
  inject: ['nav'],
  methods: {
    click () {
      this.nav.clickEvent()
      return true
    }
  }
}
</script>
