<script>
import LayoutPweb from '../layout/components/LayoutPweb'
import Mixin from './mixin'
export default {
  name: 'Dashboard',
  mixins: [Mixin],
  components: {
    LayoutPweb,
    // Layout,
  },
  data () {
    return {}
  },
  computed: {
    menu () {
      return [
        {
          title: 'Gerenciar Banners',
          href: '/website/banners'
        },
        {
          title: 'Gerenciar Popups',
          href: '/website/banners?type=popups'
        },
        {
          title: 'Páginas e Conteúdo',
          href: '/website/paginas'
        },
        {
          title: 'Menus',
          href: '/website/menus'
        }
      ]
    }
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {},
  meta: {
    title: 'Website',
    name: 'Website'
  }
}
</script>

<template>
  <layout-pweb :menu="menu">
    <router-view />
  </layout-pweb>
</template>

<style src="./assets/default.styl" lang="stylus"></style>

