<script>
import LayoutPweb from '../../layout/components/LayoutPweb'
import ProfileMixin from '../mixin'
export default {
  name: 'Dashboard',
  mixins: [ProfileMixin],
  components: {
    LayoutPweb,
    // Layout,
  },
  data () {
    return {}
  },
  computed: {},
  methods: {},
  meta: {
    title: 'Minhas Configurações',
    name: 'Minhas Configurações'
  }
}
</script>

<template>
  <layout-pweb>
    <h2>Minhas Configurações e Preferências</h2>
    <p>Defina suas preferências gerais do seu acesso ao software</p>

    <div class="form-dados">
      <h4 class="subdiv">Configurações globais<!--Dados para comunicação--></h4>
      <p class="igroup">
        <label>Habilitar notificações por SMS</label>
        <span class="field">Não</span>
      </p>
      <p class="igroup">
        <label>Notificações por E-mail</label>
        <span class="field">Sim</span>
      </p>
      <p class="igroup">
        <label>Tema</label>
        <span class="field">Padrão</span>
      </p>
      <p class="igroup">
        <label>Linguagem</label>
        <span class="field">Oficial - Português/Brasil</span>
      </p>
    </div>

  </layout-pweb>
</template>

<style lang="stylus">

</style>

