<script>
import WidgetHeader from '../WidgetHeader'
import WidgetBody from '../WidgetBody'
import WidgetIndicator from '../WidgetIndicator'
import WidgetFooter from '../WidgetFooter'

export default {
  name: 'widget-default',
  props: ['label', 'count'],
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  destroyed () {
  },
  methods: {},
  components: {WidgetFooter, WidgetIndicator, WidgetBody, WidgetHeader}
}
</script>

<template>
  <div class="widget-content">
    <widget-header>
      {{label}}
    </widget-header>
    <widget-body>
      <slot>
        <widget-indicator :value="count" />
      </slot>
    </widget-body>
    <widget-footer>
      <slot name="footer" />
    </widget-footer>
  </div>
</template>
