<template>
  <div v-show="opened" class="popup-select-input" :class="classes">
    <div class="p-header">
      <u-input ref="input" @keyup="setVal" clearable inverted-light :before="[{icon: 'search', handler () {}}]" v-model="search" class="full-width bg-grey-1 b-radius-3px" />
    </div>
    <div class="p-body">
      <div class="pop-body-content">
        <component v-bind:is="currentTabComponent" class="popup-popover" ref="popover" :anchor-click="false">
          <u-list ref="list" :no-border="true">
            <u-item v-if="loading" class="text-center flex flex-center">
              <sl-loading class="size-18" content=""/>
            </u-item>
            <u-item
                v-else-if="computedResults.length"
                v-for="(result, index) in computedResults" :key="index"
                v-show="!expand || result.level === 1 || result.parent && expandeds.includes(result.parent.id)"
                :class="[{
          'u-select-highlight': keyboardIndex === index,
          'choice-selected': (active && active === result) || (selectedValue && selectedValue === result) || defaultValue === result || (defaultValue && defaultValue.id && defaultValue.id === result.id),
          'cursor-pointer': !result.disable,
          'text-faded': result.disable || result.disabled
        }, result.level ? 'level-' + result.level : null]"
                @dblclick.native="(e) => dblClick(result, index, e)"
                @mouseenter.native="itemMouseEnter(result, index)"
                @mousedown.native="(e) => itemMouseClick(result, index, e)"
            >
              <div v-if="expand" class="flex items-center">
                <i class="fal m-r font-10" :class="expandeds.includes(result.id) ? 'fa-minus' : 'fa-plus'" v-if="result.childrens && result.childrens.length"></i> {{ result.label }}
              </div>
              <div v-else-if="enablePhoto" class="flex items-center popup-with-photo-item">
                <div class="popup-photo">
                  <img v-if="result.photo" width="25" class="" :src="result.photo"/>
                  <div v-else class="img-fake">{{ firstLettersName(result.name) }}</div>
                </div>
                <div class="m-l-sm">{{ result.label }}</div>
              </div>
              <div v-else class="flex items-center">
                <div v-if="enablePhoto"></div> {{ result.label }}
              </div>
            </u-item>
            <u-item v-else class="text-center flex flex-center">
              Nenhum registro encontrado.
            </u-item>
          </u-list>
        </component>
      </div>
      <div class="p-footer">
        <u-btn label="Cancelar" @click="$emit('close')" no-caps  />
      </div>
    </div>
  </div>
</template>

<script>
import {UInput, UBtn} from "uloc-vue"
import UMark from "@/components/layout/components/EditorComponents/Mark"
import {EscapeKey} from "uloc-vue"
import {UPopover, UList, UItem} from "uloc-vue"
import KeyboardSelectionMixin from "uloc-vue/src/mixins/keyboard-selection"
import SlLoading from "@/components/layout/components/Loading"
import {getEventKey, stopAndPrevent} from "uloc-vue/src/utils/event"
import {normalizeToInterval} from "uloc-vue/src/utils/format"
import Inline from "@/components/layout/components/EditorComponents/helpers/Inline"
import Vue from "vue"

Vue.component('popover', UPopover)
Vue.component('inline', Inline)

export default {
  name: "ListSelectPopup",
  mixins: [KeyboardSelectionMixin],
  props: {
    defaultValue: {
      required: false
    },
    active: {
      required: false
    },
    searchData: {
      required: true
    },
    inline: {
      type: Boolean,
      default: true
    },
    autoSearch: {
      type: Boolean,
      default: true
    },
    async: {
      type: Boolean,
      default: true
    },
    scroll: {
      type: Boolean,
      default: true
    },
    emitOnlyValue: {
      type: Boolean,
      default: true
    },
    searchOnlySelect: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false
    },
    enablePhoto: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      opened: false,
      search: null,
      selectedValue: null,
      loading: false,
      input: null,
      result: [],
      enterKey: false,
      timer: null,
      expandeds: []
    }
  },
  mounted() {
    document.body.addEventListener('click', this.clickOutside, true)
    this.$nextTick(() => {
      this.autoSearch && !this.async && this.searchTrigger(this.input, (result) => {
        this.result = result
        if (this.defaultValue) {
          const d = this.defaultValue
          let selected = result.find(item => {
            if(!item) return false
            if(!d) return false
            return item === d || (item.value === d || (d.value && d.value === item.value))
          })
          if (selected) {
            this.keyboardIndex = result.indexOf(selected)
          }
        }
      })
      // document.addEventListener('blur', this.blurHide)
      //document.addEventListener('focus', this.__focusShowTrigger)
    })
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.clickOutside)
    //document.removeEventListener('focus', this.__focusShowTrigger)
  },
  computed: {
    currentTabComponent() {
      return this.inline ? 'inline' : 'popover'
    },
    computedResults() {
      let input = String(this.input).replace('@', '')
      if (this.autoSearch && !this.input) {
        return this.result
      }
      if (this.searchOnlySelect) {
        let f = this.result.filter(d => {
          return !input || String(d.label).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(input.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1  //d.label.toLowerCase().includes(input.toLowerCase())
        })
        this.focusFinded(f)
        return this.result
      }
      return this.result.filter(d => {
        return !input || String(d.label).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(input.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1  //d.label.toLowerCase().includes(input.toLowerCase())
      })
    },
    keyboardMaxIndex() {
      return this.computedResults.length - 1
    },
    classes () {
      const css = []
      if (this.active && this.active.photo && this.enablePhoto) {
        css.push('popup-select-with-photo')
      }
      return css
    }
  },
  watch: {
    computedResults() {
      this.$nextTick(() => {
        // this.__focusShowTrigger()
      })
    },
    keyboardIndex(val) {
      if (this.scroll) {
        setTimeout(() => {
          const selected = this.$el.querySelector('.u-select-highlight')
          if (selected && selected.scrollIntoView) {
            if (selected.scrollIntoViewIfNeeded) {
              return selected.scrollIntoViewIfNeeded(false)
            }
            selected.scrollIntoView(this.keyboardMoveDirection < 0)
          }
        }, 100)
      }
    },
  },
  methods: {
    focusFinded (f) {
      if (f && f[0]) {
        this.keyboardIndex = this.result.indexOf(f[0])
      }
    },
    close () {
      document.removeEventListener('keydown', this.__keyboardHandleKey)
      EscapeKey.findAndPop(this.closeOnEscapeKey)
      EscapeKey.enable()
      this.opened = false
    },
    closeOnEscapeKey () {
      this.$emit('close')
      this.close()
    },
    focus () {
      document.addEventListener('keydown', this.__keyboardHandleKey)
      console.log('Focus')
      EscapeKey.findAndPop(this.closeOnEscapeKey)
      EscapeKey.register(this.closeOnEscapeKey)
      // EscapeKey.disable()
      this.opened = true
      this.$nextTick(() => {
        this.$refs.input.focus()
        if (this.async) {
          this.searchTrigger(this.search, (result) => {
            this.result = result
          })
        }
      })
    },
    selected (item) {
      this.selectedValue = item
      this.$emit('input', item)
      this.search = null
      this.setVal()
    },
    show(e) {
      this.$refs.popover.show(e)
    },
    setVal () {
      this.$nextTick(() => {
        if (this.input !== this.search) {
          if (this.async) {
            this.searchTrigger(this.search, (result) => {
              this.result = result
            })
          }
        }
        this.input = this.search
      })
    },
    async searchTrigger(terms, done) {
      return new Promise((resolve, reject) => {
        console.log('Search Trigger')
        this.loading = true
        this.searchData(terms)
            .then(data => {
              this.loading = false
              console.log(data)
              this.result = data
              done && done(data)
              resolve(data)
            })
            .catch(error => {
              this.loading = false
              reject(error)
              this.alertApiError(error)
            })
      })
    },
    autoSelectValue (v, done) {
      this.searchTrigger(v, (result) => {
        if(!result || !result.length) return
        const finded = result.filter(d => {
          return String(d.label).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(v.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1 || String(d.value).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(v.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1
        })
        console.log('Auto select finded', finded)
        if (finded && finded.length === 1) {
          this.selectValue(finded[0])
          if (typeof done !== 'undefined') {
            done(finded[0])
          }
        }
      })
    },
    checkExpanded (result, force, onlyClose = false) {
      if (this.expand && !force) {
        if (!this.expandeds.includes(result.id)) {
          !onlyClose && this.expandeds.push(result.id)
        } else {
          this.expandeds.splice(this.expandeds.indexOf(result.id), 1)
        }
        if (result.childrens && result.childrens.length) {
          result.childrens.map(c => {
            this.checkExpanded(c, force, true)
          })
        }
      }
    },
    itemMouseEnter(result, index) {
      !result.disable && (this.keyboardIndex = index)
    },
    itemMouseClick(result, index, e, force = false) {
      this.checkExpanded(result, force)
      if (this.expand && result.childrens && result.childrens.length) {
        return
      }
      console.log('Click', e)
      stopAndPrevent(e)
      !result.disable && this.selectValue(result)
    },
    dblClick (result, index, e) {
      this.itemMouseClick(result, index, e, true)
    },
    selectValue(val) {
      this.$emit('selected', val)
    },
    __keyboardCustomKeyHandle(key) {
      switch (key) {
        case 27: // ESCAPE
          // this.__clearSearch()
          break
        case 38: // UP key
        case 40: // DOWN key
        case 9: // TAB key
          this.__keyboardSetCurrentSelection(true)
          break
      }
    },
    __keyboardHandleKey(e) {
      const key = getEventKey(e)

      switch (key) {
        case 38: // UP key
          this.__keyboardMoveCursor(-1, e)
          break
        case 40: // DOWN key
          console.log('Down key')
          this.__keyboardMoveCursor(1, e)
          break
        case 13: // ENTER key
          stopAndPrevent(e)
          this.__keyboardSetCurrentSelectionFromEnter()
          return
          break
        case 9: // TAB key
          this.close()
          break
      }

      this.__keyboardCustomKeyHandle(key, e)
    },
    __keyboardShowTrigger() {
      this.trigger()
    },
    __focusShowTrigger() {
      clearTimeout(this.timer)
      setTimeout(() => this.trigger(true), 100)
    },
    __keyboardIsSelectableIndex(index) {
      return index > -1 && index < this.computedResults.length && !this.computedResults[index].disable
    },
    __keyboardSetSelection(index, navigation) {
      // this.setValue(this.results[index], navigation)
    },
    __delayTrigger() {
      this.__clearSearch()
      if (!this.__input.hasFocus()) {
        return
      }
      if (this.staticData) {
        this.trigger()
        return
      }
      this.timer = setTimeout(this.trigger, this.debounce)
    },
    blurHide() {
      this.timer = setTimeout(() => this.$refs.popover.hide(), 300)
    },
    trigger(focus) {
      //this.__keyboardShow(-1)
      if (this.computedResults.length) {
        this.__keyboardShow(-1)
      }
    },
    __keyboardSetCurrentSelectionFromEnter(navigation) {
      if (this.keyboardIndex >= 0 && this.keyboardIndex <= this.keyboardMaxIndex) {
        this.selectValue(this.computedResults[this.keyboardIndex], navigation)
      }
    },
    __keyboardMoveCursor(offset, e) {
      stopAndPrevent(e)

      if (!this.computedResults || !this.computedResults.length) {
        return
      }

      clearTimeout(this.keyboardMoveTimer)
      let
          index = this.keyboardIndex,
          valid = this.__keyboardIsSelectableIndex || (() => true)

      do {
        index = normalizeToInterval(
            index + offset,
            -1,
            this.keyboardMaxIndex
        )
      }
      while (index !== this.keyboardIndex && !valid(index))

      this.keyboardMoveDirection = index > this.keyboardIndex ? 1 : -1
      this.keyboardMoveTimer = setTimeout(() => {
        this.keyboardMoveDirection = false
      }, 500)
      this.keyboardIndex = index
      if (this.scroll) {
        setTimeout(() => {
          this.$refs.list.$el.scrollIntoView()
        }, 50)
      }
      return
    },
    clickOutside(evt) {
      if (
          evt && evt.target &&
          (this.$el.contains(evt.target) || (this.$el.parentNode && this.$el.parentNode.contains(evt.target)))
      ) {
        return
      }
      this.close()
      this.$emit('close')
      // this.hide(evt)
    },
    firstLettersName(name) {
      name = name.split(' ')
      if (Array.isArray(name) && name.length > 1) {
        return name[0].charAt(0) + name[1].charAt(0)
      }
      return String(name).substr(0, 2)
    }
  },
  components: {
    UMark,
    UInput,
    UBtn,
    SlLoading,
    UPopover,
    UList,
    UItem
  }
}
</script>
