<template>
  <div>
    <h2 class="diviser">
      Remoção
    </h2>

    <div class="m-t-lg" v-if="bemContainer.isImovel">Bem imóvel não possui remoção.</div>
    <div v-else>
      <p class="font-11 font-bold m-t-lg">Localização do bem</p>
      <e-row class="full-width" v-if="editar">
        <e-col>
          <e-row mr>
            <e-col>
              <patio-input v-model="bem.patio" />
            </e-col>
          </e-row>
          <e-row mr>
            <e-col class="flex justify-between" style="max-width: 300px">
              <div class="" style="flex: 1; padding-right: 5px">
                <u-btn @click="changeTab('interno')" label="Interno" no-caps class="btn-default2 full-width" :class="{'bg-black text-white': tab === 'interno'}" />
              </div>
              <div style="flex: 1; padding-left: 5px">
                <u-btn @click="changeTab('externo')" label="Externo" no-caps class="btn-default2 full-width" :class="{'bg-black text-white': tab === 'externo'}" />
              </div>
            </e-col>
          </e-row>

          <e-row>
            <e-col class="box-select">
              <e-row mr>
                <e-col>
                <select class="erp-input" v-model="filtros.tipoVaga">
                  <option :value="null" disabled>Tipo de vaga</option>
                  <option v-for="(val, key) in dados.tiposVaga" :key="key" :value="val.id">{{ val.nome }}</option>
                </select>
                </e-col>
                <e-col>
                <select class="erp-input" v-model="filtros.local" :disabled="!dados.locais || !dados.locais.length">
                  <option :value="null" disabled>Local / Prateleira</option>
                  <option v-for="(val, key) in dados.locais" :key="key" :value="val.id">{{ val.nome }}</option>
                </select>
                </e-col>
                <e-col>
                <select class="erp-input" v-model="filtros.andar" :disabled="!dados.andares || !dados.andares.length">
                  <option :value="null" disabled>Andar</option>
                  <option v-for="(val, key) in dados.andares" :key="key" :value="val">{{ val }}</option>
                </select>
                </e-col>
                <e-col>
                <select class="erp-input" v-model="filtros.vaga" :disabled="!dados.vagas || !dados.vagas.length">
                  <option :value="null" disabled>Vaga</option>
                  <option v-for="(val, key) in dados.vagas" :key="key" :value="val.id">{{ val.nome }}</option>
                </select>
                </e-col>
              </e-row>
            </e-col>
          </e-row>

          <e-row>
            <e-col>
              <u-btn :disabled="!filtros.vaga" color="positive" @click="salvar">
                <strong v-if="bem.vaga">TRANSFERIR</strong>
                <strong v-else>OK</strong>
              </u-btn>
              <u-btn color="white" class="text-blue-grey m-l-sm" @click="editar = false">
                Cancelar
              </u-btn>
            </e-col>
          </e-row>
        </e-col>
<!--        <e-row mr class="m-t-sm full-width">
          <e-col style="min-width: 30%; width: 30%; max-width: 30%">
            <patio-select label="Pátio:" field-view="lr" v-model="bem.patio" />
          </e-col>
          <e-col style="min-width: 30%; width: 30%; max-width: 30%">
            <erp-s-field
                view="lr"
                label="Local:"
            >
              <patio-local-select v-model="local" />
            </erp-s-field>
          </e-col>
          <e-col style="min-width: 30%; width: 30%; max-width: 30%">
            <erp-s-field
                view="lr"
                label-width="50px"
                label="Vaga:"
            >
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row>
          <e-btn @click="bemContainer.save()" label="Salvar alterações" class="m-r" />
        </e-row>-->
      </e-row>
      <e-row class="m-t-sm full-width" v-else-if="bem.patio">
        <e-col>
          <e-row v-if="bem.vaga" mr>
            <e-col style="min-width: 30%; width: 30%; max-width: 30%">
              <erp-s-field
                  view="lr"
                  label="Pátio:"
              >
                <strong>{{ bem.vaga.local.patio.nome }}</strong>
              </erp-s-field>
            </e-col>
            <e-col style="min-width: 30%; width: 30%; max-width: 30%">
              <erp-s-field
                  view="lr"
                  label="Local:"
              >
                <strong>{{ bem.vaga.local.nome }}</strong>
              </erp-s-field>
            </e-col>
            <e-col style="min-width: 30%; width: 30%; max-width: 30%">
              <erp-s-field
                  view="lr"
                  label-width="50px"
                  label="Vaga:"
              >
                <strong>{{ bem.vaga.nome }}</strong>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr v-else>
            <e-col>
              Este bem está armazenado no pátio <strong>{{bem.patio.nome}}</strong> mas ainda não possui local e vaga reservada.
            </e-col>
          </e-row>
        </e-col>
      </e-row>
      <e-row class="m-t-sm" v-else>
        Bem não está armazenado no estoque
      </e-row>

      <e-row v-if="!editar" class="m-t">
        <e-btn @click="editarArmazenamento" label="Editar localização do bem" />
      </e-row>

      <div class="m-t-lg">
        <solicitacoes-remocao :solicitacoes="solicitacoes" :bem="bem"/>
      </div>

      <div class="m-t">
        <bem-campo-secao :full-width="false" css="erp-row-view simple col-limit-4 v3 view-wrap" secao="remocao" />
      </div>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import SolicitacoesRemocao from "@/components/remocao/components/window/RemocaoComponents/Solicitacoes"
import {listSolicitacoesRemocaoBem} from "@/domain/bem/services"
// import PatioSelect from "@/components/patio/components/include/PatioSelect"
import {armazenar, locais, tipoVaga, vagas} from "@/domain/patio/services"
import PatioInput from "components/patio/helpers/input/PatioInput"
import BemCampoSecao from "components/bem2/components/include/CamposSecao";

const filtros = {
  tipoVaga: null,
  local: null,
  andar: null,
  vaga: null
}
const dados = {
  tiposVaga: null,
  locais: null,
  andares: null,
  vagas: null
}

export default {
  name: "BemRemocao",
  props: {},
  data() {
    return {
      remocoes: null,
      solicitacoesLoading: false,
      solicitacoes: [],
      editar: false,
      local: null,
      tab: 'interno',
      filtros: JSON.parse(JSON.stringify(filtros)),
      dados: JSON.parse(JSON.stringify(dados))
    }
  },
  components: {
    BemCampoSecao,
    PatioInput,
    SolicitacoesRemocao,
    // PatioSelect
  },
  mounted() {
    if (!this.bemContainer.isImovel) {
      this.loadTiposVaga()
      this.filtros = JSON.parse(JSON.stringify(filtros))
      this.dados = JSON.parse(JSON.stringify(dados))
      this.loadSolicitacoes()
    }
  },
  watch: {
    bem (bem) {
      this.local = bem.vaga ? this.bem.vaga.local : null
    },
    'filtros.tipoVaga' (v) {
      this.dados.locais = null
      this.dados.andares = null
      this.dados.vagas = null
      this.filtros.local = null
      this.filtros.vaga = null
      this.filtros.andar = null
      this.$nextTick(() => {
        this.loadLocais(v, this.tab === 'externo')
      })
    },
    'filtros.local' (v) {
      if (!v || !this.dados) return
      console.log(v)
      const local = this.dados.locais.find(l => l.id === v)
      this.dados.andares = []
      if (local.andares && Number(local.andares) > 0) {
        for (let i = 1; i <= Number(local.andares); i++) {
          this.dados.andares.push(i)
        }
        return
      }
      this.loadVagas(v)
    },
    'filtros.andar' (v) {
      this.dados.vagas = null
      this.filtros.vaga = null
      this.$nextTick(() => {
        const local = this.dados.locais.find(l => l.id === this.filtros.local)
        if (!local) return
        this.loadVagas(local.id, v)
      })
    }
  },
  mixins: [CadastroBemMixin],
  computed: {},
  methods: {
    editarArmazenamento () {
      this.editar = true
    },
    loadSolicitacoes () {
      this.solicitacoesLoading = true
      this.bem && this.bem.id && listSolicitacoesRemocaoBem(this.bem.id)
        .then(response => {
          this.solicitacoesLoading = false
          this.solicitacoes = response.data
        })
        .catch(error => {
          this.solicitacoesLoading = false
          this.alertApiError(error)
        })
    },
    salvar () {
      if (!this.filtros.vaga) {
        return
      }
      this.loading = true
      this.$uloc.loading.show({message: 'Armazenando bem'})
      armazenar(this.bem.id, this.filtros.vaga)
          .then(response => {
            this.loading = false
            this.$uloc.notify({
              color: 'positive',
              message: 'Bem armazenado sucesso!'
            })
            this.$emit('armazenado', response.data)
            this.bemContainer.load()
            this.$uloc.loading.hide()
          })
          .catch(error => {
            this.loading = false
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    changeTab (tab) {
      this.tab = tab
      this.$nextTick(() => {
        this.loadLocais(this.filtros.tipoVaga, this.tab === 'externo')
      })
    },
    loadTiposVaga () {
      tipoVaga()
          .then(response => {
            this.dados.tiposVaga = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    loadLocais (tipoVaga, externo) {
      this.dados.andares = null
      this.dados.vagas = null
      this.filtros.andar = null
      this.filtros.vaga = null
      locais(tipoVaga, externo)
          .then(response => {
            this.dados.locais = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    loadVagas (local, andar = null) {
      this.dados.vagas = null
      this.filtros.vaga = null
      vagas(local, andar)
          .then(response => {
            this.dados.vagas = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>
