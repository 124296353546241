<template>
  <div>
    <u-input placeholder="Pesquisar" autofocus class="input-search m-t" hide-underline
             v-model="search" />
    <ul class="templates-list">
      <li v-for="(cat, index) in filtered" :key="index" @click="$emit('select', cat)">
        <a>
          <u-icon class="t-icon" :name="cat.type ? iconByType(cat.type) : 'envelope'" type="fa" />
          <p>
            <strong>{{cat.name}}</strong>
            {{cat.description}}
          </p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {UInput} from 'uloc-vue'
export default {
  name: 'CategoryList',
  props: ['list'],
  components: {UInput},
  data () {
    return {
      search: null
    }
  },
  computed: {
    filtered () {
      if (!this.search) {
        return this.list
      }
      return this.list.filter(item => String(item.name).toLowerCase().indexOf(this.search) > -1 || String(item.description).toLowerCase().indexOf(this.search) > -1)
    }
  },
  methods: {
    iconByType (type) {
      switch (type){
        case 'comunicacao':
          return 'envelope'
        case 'docs':
          return 'file-alt'
      }
    }
  }
}
</script>

<style scoped>

</style>
