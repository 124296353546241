<template>
  <div>
    <h2 class="diviser">
      Vídeos
    </h2>

    <div class="m-t" v-if="bem.id">


      <div v-if="loading">
        <u-fake-loader />
        <u-fake-loader width="60%" />
        <u-fake-loader width="20%" />
      </div>
      <div class="form-column-align-center" v-else-if="listData && listData.length">
        <e-row v-for="(video, index) in listData" :key="index" mr>
          <e-col>
            <erp-s-field
                class="full-width"
                label="URL do Vídeo:"
            >
              <erp-input autofocus placeholder="https://youtube.com/embed/..." v-model="video.url" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                class="full-width"
                label="Descrição:"
            >
              <erp-input placeholder="Detalhes sobre o vídeo" v-model="video.descricao" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 50px">
            <e-btn-table-options class="m-t">
              <menu-options>
                <ul>
                  <menu-options-item close label="Excluir" @click="excluir(video, index)" />
                </ul>
              </menu-options>
            </e-btn-table-options>
          </e-col>
        </e-row>
      </div>
      <div v-else class="m-t m-b-lg">Nenhum vídeo cadastrado.</div>
      <div v-if="bem.id && !loading" class="m-t">
        <u-btn @click="novo" class="sl-btn" color="positive" no-caps :label="listData && listData.length ? 'Adicionar mais vídeos' : 'Adicionar o primeiro vídeo'"/>
        <u-btn @click="salvar" :disable="!listData || !listData.length" class="sl-btn m-l" color="primary" no-caps label="Salvar alterações"/>
      </div>
    </div>
    <div v-else class="m-t">
      Salve o bem antes de editar os vídeos
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {list, deleteVideo, newVideo, updateVideo} from "@/domain/bem/services/videos"
import {UFakeLoader} from "uloc-vue"
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'

const mockVideo = {
  id: null,
  url: null,
  descricao: null,
  bem: null
}

export default {
  name: "BemVideos",
  data() {
    return {
      loading: false,
      listData: null
    }
  },
  components: {
    UFakeLoader,
    EBtnTableOptions,
    MenuOptions,
    MenuOptionsItem
  },
  mounted() {
    this.bem.id && this.load()
  },
  mixins: [CadastroBemMixin],
  watch: {},
  beforeCreate() {
  },
  computed: {},
  methods: {
    load() {
      this.loading = true
      list(this.bem.id)
          .then(response => {
            this.loading = false
            this.listData = response.data.result.map(video => {
              return {
                ...video,
                bem: video.bem && video.bem.id ? video.bem.id : video.bem
              }
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    novo() {
      if (!Array.isArray(this.listData)) {
        this.listData = []
      }
      const video = JSON.parse(JSON.stringify(mockVideo))
      video.bem = this.bem.id
      this.listData.push(video)
    },
    salvar() {
      this.listData.map(video => {
        if (video.id) {
          updateVideo(this.bem.id, video).then(response => {

          }).catch(error => {
            this.alertApiError(error)
          })
        } else {
          newVideo(this.bem.id, video).then(response => {
            video.id = response.data.id
          }).catch(error => {
            this.alertApiError(error)
          })
        }
      })
    },
    excluir(video, index = null) {
      this.$uloc.dialog({
        title: 'Excluir registro',
        message: 'Você tem certeza que deseja excluir este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        if (video.id) {
          this.$uloc.loading.show({message: 'Deletando...'})
          deleteVideo(this.bem.id, video.id)
              .then(() => {
                this.$uloc.loading.hide()
              })
              .catch(error => {
                this.alertApiError(error)
              })
        }
        this.listData.splice(index, 1)
      }).catch(() => {
      })
    }
  }
}
</script>
