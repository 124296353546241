<template>
  <div class="no-select" v-if="Array.isArray(bem.status)">
    <div class="flex items-center m-b-xs" v-for="(s, i) in bem.status">
      <span class="m-r-xs font-bold">#{{i+1}}</span> <status-bem-selector :status="s.status" />
    </div>
  </div>
  <status-bem-selector class="no-select" v-else :status="bem.status" />
</template>

<script>
import StatusBemSelector from './StatusBemSelector';
export default {
  name: 'StatusBem',
  components: {StatusBemSelector},
  props: ['bem']
}
</script>

<style scoped>

</style>
