<script>
import DefaultMixin from '../../mixin'
import Vue from 'vue'
import AcaoComitente from './AcaoComitente';
import GrupoPortoAcoesLeilao from "@/components/leiloes/components/monitor-comitente/grupo-porto/AcoesLeilao";
// import MenuMonitorComitente from './MenuMonitorComitente'

Vue.component('menu-monitor-comitente', () => import('./MenuMonitorComitente'))

export default {
  name: 'MonitorComitente',
  inject: ['page', 'erplayout'],
  mixins: [DefaultMixin],
  components: {GrupoPortoAcoesLeilao, AcaoComitente},
  data () {
    return {
      leilao: this.page.leilao,
      acoes: []
    }
  },
  computed: {
    filteredAcoes () {
      return this.acoes.filter(acao => acao.show)
    },
    desativadas () {
      return this.acoes.filter(acao => !acao.show)
    }
  },
  mounted () {
    this.page.setActive('acc')
    this.erplayout.$dynamicMenuLeilaoComponent = {
      name: 'menu-monitor-comitente',
      props: {leilao: this.leilao}
    }
    /* window.setTimeout(() => {
      this.acoes.push({
        id: 1,
        lote: 29,
        data: {
          date: '2020-03-01 14:22:21'
        },
        status: 1,
        show: true
      })
    }, 3000)
    window.setTimeout(() => {
      this.acoes.push({
        id: 2,
        lote: 71,
        data: {
          date: '2020-03-01 14:22:21'
        },
        status: 2,
        show: true
      })
    }, 5000)
    window.setTimeout(() => {
      this.acoes.push({
        id: 3,
        lote: 192,
        data: {
          date: '2020-03-01 14:22:21'
        },
        status: 3,
        show: true
      })
    }, 10000) */
  },
  destroyed () {
    this.erplayout.$dynamicMenuLeilaoComponent = null
    delete this.erplayout.$dynamicMenuLeilaoComponent
  },
  methods: {
    mostrarTudo () {
      this.acoes.map(acao => {
        acao.show = true
        return acao
      })
    }
  },
  meta: {
    title: 'Monitor Comitentes',
    name: 'Monitor Comitentes'
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="acoes-comitente" style="margin: -10px">
      <acao-comitente v-for="acao in filteredAcoes" :key="acao.id" :acao="acao" />
    </div>
    <div v-if="desativadas.length" class="m-t font-11 text-grey-8">
      Você ocultou <strong>{{desativadas.length}}</strong> pendências por tê-las visualizado.
      <div class="m-t-sm">
        <u-btn @click="mostrarTudo" size="sm" label="Reativar pendências ocultadas" color="purple-9" />
      </div>
    </div>
    <div v-if="!acoes || !acoes.length" class="m-t-lg">Nenhuma interação com comitente até o momento.</div>

    <grupo-porto-acoes-leilao :leilao="leilao" />
  </div>
</template>

<style lang="stylus">
  .acoes-comitente {
    display flex
    flex-wrap wrap
  }
</style>
