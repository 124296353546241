<script>
import DefaultMixin from '../../mixin'
import {find} from '@/domain/leiloes/services'
import {UInput} from 'uloc-vue'
import EstatisticaAcesso from "@/components/leiloes/components/monitoramento/include/EstatisticaAcesso";
import ProgressoLeilao from "@/components/leiloes/components/monitoramento/include/ProgressoLeilao";
import Hardware from "@/components/leiloes/components/monitoramento/include/Hardware"
import Servicos from "@/components/leiloes/components/monitoramento/include/Servicos"
import LeilaoMixin from "comunicator/src/integrations/vue/mixins/leilao.mixin"

export default {
  name: 'MonitorLeilao',
  provide: function () {
    return {
      controlador: this
    }
  },
  mixins: [DefaultMixin, LeilaoMixin],
  components: {
    Servicos,
    Hardware,
    ProgressoLeilao,
    EstatisticaAcesso,
    UInput
  },
  data () {
    return {
      loading: true,
      idLeilao: null,
      leilao: null,
      lote: null
    }
  },
  computed: {
    leilaoAtivo () {
      return this.leilao
    },
    lancesOnline () {
      if (!this.leilaoAtivo || !this.lances) {
        return 0
      }
      const lances = this.lances.filter(l => l.autor.apelido.toLowerCase() !== 'plateia')
      return lances && lances.length ? lances.length : 0
    },
    lancesPlateia () {
      if (!this.leilaoAtivo || !this.lances) {
        return 0
      }
      const lances = this.lances.filter(l => l.autor.apelido.toLowerCase() === 'plateia')
      return lances && lances.length ? lances.length : 0
    },
    pctLancesOnline () {
      if (!this.leilaoAtivo || !this.lancesOnline) {
        return 0
      }
      return (this.lancesOnline * 100) / (this.lancesOnline + this.lancesPlateia)
    },
    pctLancesPlateia () {
      if (!this.leilaoAtivo || !this.lancesPlateia) {
        return 0
      }

      return (this.lancesPlateia * 100) / (this.lancesOnline + this.lancesPlateia)
    },
    participantesOnline () {
      return 0
    },
    pctParticipantesOnline () {
      return 0
    },
    participantesPlateia () {
      return 0
    },
    pctParticipantesPlateia () {
      return 0
    }
  },
  mounted () {
    this.idLeilao = this.$route.query.leilao
    this.load()
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 400)
  },
  beforeDestroy () {
    this.erplayout.erpheader.setMenuModuleToggle(false)
  },
  methods: {
    load () {
      this.loading = true
      find(this.idLeilao)
        .then(({data}) => {
          this.loading = false
          this.leilao = Object.assign({}, this, data)
          if (!data.pregaoAtivo || !data.pregaoAtivo.lote) {
            console.log('Pregão ainda não está ativo')
            return
          }
          this.lote = data.pregaoAtivo.lote
          console.log(data)
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    }
  },
  meta: {
    title: 'Monitor Leilão',
    name: 'Monitor Leilão'
  }
}
</script>

<template>
  <div class="monitor-leilao">

    <div class="mtab header">
      <h1>Monitoramento de Leilão</h1>
      <div class="seletor">
        <div class="lbl">Selecione o leilão:</div>
        <div class="input">
          <u-input hide-underline v-model="idLeilao" />
        </div>
      </div>
      <div class="leilao-classificacao">BANCOS E FINANCEIRAS</div>
    </div>

    <div class="rest-tabs">
      <estatistica-acesso :class="{isLoading: !leilaoAtivo}" />
      <progresso-leilao :class="{isLoading: !leilaoAtivo}" />
      <hardware :class="{isLoading: !leilaoAtivo}" />
      <servicos :class="{isLoading: !leilaoAtivo}" />
    </div>

  </div>
</template>

<style src="./assets/monitor.styl" lang="stylus" />
