<template>
  <div class="font-12 font-avenir text-grey-7" style="width: 250px">
    <div class="at-title m-b">CONCLUSÃO DAS ATIVIDADES</div>
    <e-row class="font-11">
      <e-col>PROGRESSO DA SEMANA</e-col>
      <e-col class="text-right" style="max-width: 30px">{{ semana }}%</e-col>
    </e-row>
    <u-progress class="progress-positive" :percentage="semana" color="positive" height="6px"/>
    <e-row class="font-11 m-t-sm">
      <e-col>Progresso da semana</e-col>
      <e-col class="text-right font-bold" style="max-width: 90px">{{ Number(stats.fechadasSemana)|number }} de {{ Number(stats.totalSemana)|number }}</e-col>
    </e-row>
    <e-row class="font-11">
      <e-col>Tarefas ativas</e-col>
      <e-col class="text-right font-bold" style="max-width: 90px">{{ Number(stats.ativas)|number }}</e-col>
    </e-row>
    <e-row class="font-11">
      <e-col>Em andamento</e-col>
      <e-col class="text-right font-bold" style="max-width: 90px">{{ Number(stats.emAndamento)|number }}</e-col>
    </e-row>
    <e-row class="font-11">
      <e-col>Vencidas</e-col>
      <e-col class="text-right font-bold" :class="{'text-negative': Number(stats.vencidas) > 0}" style="max-width: 90px">{{ Number(stats.vencidas)|number }}</e-col>
    </e-row>
  </div>
</template>

<script>
import {UProgress} from 'uloc-vue'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {userStats} from "@/domain/tarefas/services"

export default {
  name: "Resumo",
  props: ['participants'],
  data() {
    return {
      stats: {
        ativas: 0,
        emAndamento: 0,
        vencidas: 0,
        totalSemana: 0,
        vencidasSemana: 0,
        fechadasSemana: 0
      }
    }
  },
  components: {
    ECol,
    ERow,
    UProgress
  },
  mounted() {
    this.$nextTick(() => {
      this.load()
    })
  },
  computed: {
    semana () {
      const total = Number(this.stats.totalSemana)
      const fechadas = Number(this.stats.fechadasSemana)
      if(!total || !fechadas) return 0
      return Math.floor((fechadas * 100) / total)
    }
  },
  watch: {
    participants () {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  methods: {
    load() {
      userStats(this.participants)
          .then(response => {
            this.stats = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>
