<script>
export default {
  name: 'Dashboard',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.erplayout.erpheader.menu.setActiveMenu('bens')
    document.querySelector('.u-erp-layout-container').classList.add('dashboard')
  },
  destroyed() {
    document.querySelector('.u-erp-layout-container').classList.remove('dashboard')
  },
  methods: {},
  meta: {
    title: 'Dashboard',
    name: 'Dashboard'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap">
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic h-full">
          <div class="flex col-grow no-wrap dashboard-container h-full" style="min-height: 100%">
            <transition name="slide" appear>
              <div class="transition-generic dash-main w-full h-full" style="min-height: 100%">
                <div class="dash-menu">
                  <ul class="root">
                    <li>
                      <a>Dashboard / Insights</a>
                      <ul class="level1">
                        <li>
                          <router-link active-class="active" :to="{name: 'dashboard.main'}" exact>
                            <u-icon name="dashboard"/>
                            Principal
                          </router-link>
                        </li>
                        <li>
                          <router-link active-class="active" :to="{name: 'dashboard.cadastros'}" exact>
                            <u-icon name="person"/>
                            Cadastros
                          </router-link>
                        </li>
                        <li class="disabled hide">
                          <a>
                            <u-icon name="event_avaliable"/>
                            Tarefas e Trabalho</a>
                        </li>
                        <li class="disabled hide">
                          <a>
                            <u-icon name="gavel" type="fa"/>
                            Marketplace</a>
                        </li>
                        <li class="disabled hide">
                          <a>
                            <u-icon name="monetization_on"/>
                            Financeiro</a>
                        </li>
                        <li>
                          <router-link active-class="active" :to="{name: 'dashboard.bens'}" exact>
                            <u-icon name="list" type="fa"/>
                            Bens
                          </router-link>
                        </li>
                        <li class="disabled hide">
                          <a>
                            <u-icon name="truck" type="fa" icon-style="light"/>
                            Remoção</a>
                        </li>
                        <li class="disabled hide">
                          <a>
                            <u-icon name="envelope" type="fa" icon-style="light"/>
                            Atendimento</a>
                        </li>
                        <li class="disabled hide">
                          <a>
                            <u-icon name="megaphone" type="fa" icon-style="light"/>
                            Marketing</a>
                        </li>
                        <li class="disabled hide">
                          <a>
                            <u-icon name="handshake" type="fa" icon-style="light"/>
                            CRM</a>
                        </li>
                        <li class="disabled hide">
                          <a>
                            <u-icon name="book" type="fa" icon-style="light"/>
                            Processos</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <router-view/>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style src="./assets/default.styl" lang="stylus"></style>

