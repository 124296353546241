<template>
  <list-select emit-only-value ref="select" v-on:input="(v) => $emit('input', v)" :search-data="search" :search-only-select="searchOnlySelect" v-model="model" :parse-callback="parse" :expand="expand" :disabled="disable" />
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
import {listCategoria} from "@/domain/financeiro/services/categoria"
export default {
  name: "CategoriaHirerarquiaSelect",
  props: {
    value: {
      required: true
    },
    tipo: {
      required: false
    },
    searchOnlySelect: {
      type: Boolean,
      default: true
    },
    expand: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.select.autoSelectValue(this.model)
    })
  },
  watch: {
    value (v) {
      this.$nextTick(() => {
        if (v !== this.model) {
          this.$refs.select.autoSelectValue(v)
        }
      })
    }
  },
  components: {ListSelect},
  methods: {
    parse (v) {
      if (v && v.parent && v.parent.parent) {
        return `${v.parent.parent.descricao} // ${v.parent.descricao} // ${v.descricao}`
      }
      if (v && v.parent) {
        return `${v.parent.descricao} // ${v.descricao}`
      }
      return v && v.descricao ? v.descricao : v
    },
    search (terms) {
      console.log(terms)
      return new Promise((resolve, reject) => {
        listCategoria()
        .then(response => {
          console.log(response.data)
          let list
          if (this.tipo) {
            list = this.tipo == 1 ? response.data.tree.receitas : response.data.tree.despesas
          } else {
            list = [...response.data.tree.receitas, ...response.data.tree.despesas]
          }
          resolve(list)
        })
        .catch(error => {
          this.alertApiError(error)
          reject(error)
        })
        /*setTimeout(() => {
          let list = UFs.map((obj) => {
            return {
              ...obj,
              label: obj.sigla,
              value: obj.sigla
            }
          }).sort((a, b) => {
            if (a.nome > b.nome) {
              return 1;
            }
            if (a.nome < b.nome) {
              return -1;
            }
            // a must be equal to b
            return 0;
          })
          resolve(list)
        })*/
      })
    }
  }
}
</script>
