<template>
  <div class="sl-layout-page">
    <div v-if="loading">
      <u-fake-loader width="90%"/>
      <br>
      <u-fake-loader width="30%"/>
      <u-fake-loader width="50%"/>
      <u-fake-loader width="40%"/>
      <br><br>
      <u-fake-loader width="20%"/>
      <u-fake-loader width="40%"/>
      <br>
      <br>
      <br>
      <br>
      <br>
      <u-fake-loader width="20%"/>
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutPaper",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
