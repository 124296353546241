<template>
  <div class="lote-tempo-progresso">
    <div class="progresso" :style="style" :class="classes" />
  </div>
</template>

<script>
export default {
  name: "TempoProgresso",
  props: ['timer', 'defaultTimer'],
  computed: {
    porcentagem () {
      const defaultTimer = this.defaultTimer
      const timer = this.timer
      return ((defaultTimer - timer) * 100) / defaultTimer
    },
    classes () {
      const css = []
      if (this.porcentagem > 30) {
        css.push('meioChegando')
      }
      if (this.porcentagem > 70) {
        css.push('finalChegando')
      }
      return css
    },
    style () {
      const css = []
      css.push({width: this.porcentagem + '%'})
      return css
    }
  }
}
</script>