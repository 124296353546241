<template>
  <div class="flex items-center">
    <status-bem-icon class="fa-fw" :status="status" />
    <span :class="'bem-status-' + status">{{getStatus(status).title}}</span>
  </div>
</template>

<script>
import {Status, getStatus} from '../../../../domain/bem/helpers/Status'
import StatusBemIcon from './StatusBemIcon';

export default {
  name: 'StatusBemSelector',
  components: {StatusBemIcon},
  props: ['status'],
  computed: {
  },
  methods: {
    getStatus (s) {
      return getStatus(s, Status)
    }
  }
}
</script>

<style scoped>

</style>
