<template>
  <e-btn-circle-status v-if="s === helper.STATUS_SOLICITACAO_AGUARDANDO_ACEITE" status="blue" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_SOLICITACAO_ACEITO" status="blue" class="sm m-r-xs" />
  <u-icon v-else-if="s === helper.STATUS_SOLICITACAO_AGUARDANDO_RECOLHIMENTO" name="truck" type="fa" icon-style="solid" class="fa-fw  m-r-xs" />
  <u-icon v-else-if="s === helper.STATUS_SOLICITACAO_AGUARDANDO_VISTORIA" name="tasks" type="fa" icon-style="solid" class="fa-fw  m-r-xs" />
  <u-icon name="exchange" type="fa" icon-style="light" v-else-if="s === helper.STATUS_SOLICITACAO_EM_TRANSPORTE" class="fa-fw m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_SOLICITACAO_ENTREGUE" status="positive" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_SOLICITACAO_REJEITADO" status="negative" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_SOLICITACAO_CANCELADO" status="negative" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_SOLICITACAO_BEM_NAO_ENCONTRADO" status="negative" class="sm m-r-xs" />
</template>

<script>
import {EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../domain/remocao/helpers/Status'
export default {
  name: 'StatusSolicitacaoIcon',
  props: ['status'],
  components: {EBtnCircleStatus},
  computed: {
    helper () {
      return Status
    },
    s () {
      return parseFloat(this.status)
    }
  }
}
</script>

<style scoped>

</style>
