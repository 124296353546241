import http from '../../../utils/services/http'

export const reciboPagamentoLotes = (lotes, pagamentos) => {
    return http.post('/api/financeiro/recibos/pagamentoLote', {
        lotes,
        pagamentos
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const reciboComissaoComitenteLotes = (lotes, pagamentos) => {
    return http.post('/api/financeiro/recibos/comissaoComitente', {
        lotes,
        pagamentos
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const notaArrematacao = (lotes, tipo = 'separado', salvar = false, enviarArrematante = false) => {
    return http.post('/api/financeiro/notaArrematacao', {
        lotes,
        tipo: tipo,
        salvar,
        enviarArrematante
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const registrarNotaArrematacao = (id, data) => {
    return http.post(`/api/financeiro/notaArrematacao/${id}/registrar`, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const enviarNotaArrematacaoParaArrematante = (id, data) => {
    return http.post(`/api/financeiro/notaArrematacao/${id}/enviarParaCliente`, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const extratoArrematacao = (loteId) => {
    return http.post(`/api/financeiro/lote/${loteId}/extratoArrematacao`)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const termoRetirada = (lotes) => { // separado/unificado
    return http.post('/api/financeiro/termos/retirada', {
        lotes
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const download = (endpoint) => {
    let config = {}
    config.responseType = 'blob'
    return http.get(endpoint, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => { // @TODO: Mover para ser reutilizado
            if (response.data instanceof Blob) {
                return new Promise((resolve, reject) => {
                    const fr = new FileReader();
                    fr.onload = function () {
                        const r = JSON.parse(this.result) // @?
                        reject({data: r})
                    }
                    fr.readAsText(response.data)
                })
            } else {
                return Promise.reject(response)
            }
        })
}

export const faturaArrematacao = (leilaoId, arrematanteId, _download = true, loteId = null) => {
    let url = `/api/leiloes/${leilaoId}/faturaPdf/download?arrematante=${arrematanteId}`
    if (loteId) {
        url = url + '&lote=' + loteId
    }
    return download(url)
}

export const recibo = (leilaoId, arrematanteId, _download = true) => { // @TODO
    return download(`/api/leiloes/${leilaoId}/faturaPdf/download?arrematante=${arrematanteId}`)
}

export const enviarCobrancaLote = (data) => {
    return http.post('/api/leilao/financeiro/enviarCobranca', data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const enviarCobrancaEmMassa = (leilaoId) => {
    return http.post(`/api/leilao/${leilaoId}/enviarCobranca`)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const enviarReciboPagamentoLote = (pagamento) => {
    return http.post(`/api/financeiro/pagamentoLote/${pagamento}/enviarRecibo`)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const enviarFormalizacaoLote = (data) => {
    return http.post('/api/leilao/financeiro/enviarFormalizacao', data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
