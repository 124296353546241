<script>
import Layout from '../../layout/Layout'
import Menu from '../../remocao/menu'
import {list} from '../../../domain/reboque/services'
import {UTable, UTh, UTr, UTd, UCheckbox} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import ContextMenuReboqueItem from './context/list-context'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu'
import SearchFilter from '../../layout/helpers/SearchFilter'
import {ErpInput, ErpSelect, ErpSField} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import reboqueWindow from '../windows/reboque'
import ECol from '../../layout/components/Col'

let filters = {
  id: '',
  search: '',
  placa: '',
  marcaModelo: '',
  reboquista: '',
  active: [true, false]
}

export default {
  name: 'Reboques',
  mixins: [DefaultMixin],
  components: {
    ECol,
    ContextMenuReboqueItem,
    SearchFilter,
    DefaultListMenu,
    MenuOptions,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSField,
    ErpSelect
  },
  data () {
    return {
      filtersData: {
        uf: {
          loading: true,
          serverData: []
        }
      },
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: [
          {name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true},
          {
            name: 'descricao',
            required: true,
            label: 'Descrição',
            align: 'left',
            field: 'descricao',
            sortable: true
          },
          {
            name: 'placa',
            required: false,
            label: 'Placa',
            align: 'left',
            field: 'placa',
            sortable: false
          },
          {
            name: 'marcaModelo',
            required: false,
            label: 'Marca/Modelo',
            align: 'left',
            field: 'marcaModelo',
            sortable: false
          },
          {
            name: 'custo',
            required: false,
            label: 'Custo por km (estimado)',
            align: 'left',
            field: 'custo',
            sortable: false
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: false
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ],
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    visibledColumns () {
      return ['id', 'descricao', 'placa', 'marcaModelo', 'custo', 'status', 'options']
    },
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    request ({pagination, filter}) {

      let extraFilters = []
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.placa && extraFilters.push(`&placa=${this.table.filters.placa}`)
      this.table.filters.marcaModelo && extraFilters.push(`&marcaModelo=${this.table.filters.marcaModelo}`)
      this.table.filters.reboquista && extraFilters.push(`&reboquista=${this.table.filters.reboquista}`)

      let active = this.table.filters.active.join(',')

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${this.table.filters.search}&active=${active}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
        .then(({data}) => {
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = data.total
          this.table.serverData = data.result

          this.table.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    load () {
      this.table.loading = true
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      this.reboqueWindow()
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.search
      })
    },
    abrir (id) {
      console.log('Abrir window reboque')
      this.reboqueWindow(id)
    },
    reboqueWindow: reboqueWindow
  },
  meta: {
    title: 'Reboques',
    name: 'Reboques'
  }
}
</script>

<template>
  <layout class="erp-list" :menu="menu" menu-style="compact" menu-active="reboques">
    <div>
      <search-filter>
        <form id="reboqueFilter" class="m-t-sm">
          <div class="row erp-row def-m-r">

            <div class="col" style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Código"
              >
                <erp-input shortkey="F3" v-model="table.filters.id" />
              </erp-s-field>
            </div>

            <div class="col">
              <erp-s-field
                  view="tl"
                  label="Descrição"
              >
                <erp-input shortkey="F2" v-model="table.filters.search" />
              </erp-s-field>
            </div>

            <div class="col">
              <erp-s-field
                  view="tl"
                  label="Placa"
              >
                <erp-input shortkey="F4" v-model="table.filters.placa" />
              </erp-s-field>
            </div>

            <!-- TODO: REBOQUISTA -->

            <e-col style="max-width: 110px; min-width: 110px">
              <erp-s-field
                  view="tl"
                  label="Status"
              >
                <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]" multiple
                            v-model="table.filters.active" />
              </erp-s-field>
            </e-col>
          </div>

          <div class="row erp-row">
            <div class="col flex justify-end m-t-sm def-list-buttons" style="min-width: 300px">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                       @click="pesquisar" />
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                       @shortkey.native="clearFilters" />
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
              <div class="flex flex-center column">
                <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                       @shortkey.native="novo" />
                <span class="shortcut">Shift + N</span>
              </div>
            </div>
          </div>
        </form>
      </search-filter>
      <div class="erp-list">
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th :style="col.name === 'id' ? ' max-width: 25px' : ''" v-for="col in props.cols" :key="col.name"
                  :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" @dblclick.native="abrir(props.row.id)">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td style="max-width: 25px" key="id" :props="props">
              {{props.row.id}}
            </u-td>
            <u-td key="descricao"
                  :props="props">
              {{props.row.descricao}}
            </u-td>
            <u-td key="placa"
                  :props="props">
              {{props.row.placa}}
            </u-td>
            <u-td key="marcaModelo" :props="props">
              {{props.row.marcaModelo}}
            </u-td>
            <u-td key="custo" :props="props">
              R$ {{props.row.custo|moeda}}
            </u-td>
            <u-td class="text-uppercase" key="status" :props="props">
              <span v-if="props.row.active" class="text-positive">Ativo</span>
              <span v-else class="text-negative">Inativo</span>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="abrir(props.row.id)" :leilao="props.row" />
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-reboque-item @abrir="abrir(props.row.id)" :leilao="props.row" />
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
