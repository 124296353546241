<template>
  <list-select :emit-only-value="false" ref="select" async :disabled="!uf" v-on:input="(v) => $emit('input', v)" :search-data="search" v-model="model" />
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
import UFs from "@/domain/remocao/helpers/UFs"
import http from "@/utils/services/http"
export default {
  name: "UfSelect",
  props: {
    value: {
      required: true
    },
    uf: {
      required: true
    }
  },
  data () {
    return {
      model: this.value,
      result: []
    }
  },
  watch: {
    uf () {
      this.$refs.select.reset()
    },
    value (v) {
      this.$nextTick(() => {
        if (v !== this.model && this.uf) {
          this.$refs.select.autoSelectValue(v)
        }
      })
    }
  },
  components: {ListSelect},
  methods: {
    search (terms) {
      console.log('Find', this.model)
      return new Promise((resolve, reject) => {
        http.get(`//servicodados.ibge.gov.br/api/v1/localidades/estados/${this.uf}/municipios`, {
          transformRequest (data, headers) {
            delete headers.common['Authorization']
            return data
          }
        })
            .then(response => {
              this.result = response.data
              if (!response.data || !response.data.length) {
                resolve([])
              }
              let list = response.data.map((obj) => {
                return {
                  ...obj,
                  value: obj.id,
                  label: obj.nome
                }
              }).sort((a, b) => {
                if (a.nome > b.nome) {
                  return 1;
                }
                if (a.nome < b.nome) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              })
              resolve(list)
            })
            .catch(({response}) => {
              reject(response)
            })
      })
    }
  }
}
</script>
