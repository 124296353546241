import {TIPO_IMOVEL, TIPO_VEICULO, tipoMessageToCode} from '../../../../domain/bem/helpers/Status'

export default {
  computed: {
    isVeiculo () {
      if (this.bem && this.bem.tipoInterno) {
        return this.bem.tipoInterno === TIPO_VEICULO
      }
      return this.isType('veiculos')
    },
    isImovel () {
      if (this.bem && this.bem.tipoInterno) {
        return this.bem.tipoInterno === TIPO_IMOVEL
      }
      return this.isType('imoveis')
    }
  },
  methods: {
    isType (type) {
      return this.tipoBemSelecionado
        &&
        (
          this.tipoBemSelecionado.codigo === type
          ||
          (
            this.tipoBemSelecionado.parent
            && this.tipoBemSelecionado.parent.codigo === type
          )
        )
    }
  }
}
