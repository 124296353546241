export default {
  title: 'Remoção',
  menu: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      icon: 'home',
      style: 'light',
      url: '/#/remocao'
    },
    {
      id: 'remocoes',
      title: 'Remoções',
      icon: 'clipboard-list-check',
      style: 'light',
      url: '/#/remocao/lista'
    },
    /*{
      id: 'solicitacoes',
      title: 'Solicitações',
      icon: 'plus-circle',
      style: 'regular',
      url: '/#/remocao/solicitacoes'
    },*/
    {
      id: 'reboquistas',
      title: 'Reboquistas',
      icon: 'user-hard-hat',
      style: 'light',
      url: '/#/reboquistas/lista'
    },
    {
      id: 'reboques',
      title: 'Frota/Reboques',
      icon: 'truck',
      style: 'light',
      url: '/#/reboques/lista'
    },
    /*{
      id: 'cotacoes',
      title: 'Cotações',
      icon: 'hand-holding-usd',
      style: 'light',
      url: '/#/remocao/cotacoes'
    },*/
    {
      id: 'vistoriadores',
      title: 'Vistoriadores',
      icon: 'users',
      style: 'regular',
      url: '/#/vistoriadores/lista'
    },
    {
      id: 'checklist-acessorios',
      title: 'Gerenciamento de Checklist',
      icon: 'tasks',
      style: 'regular',
      url: '/#/remocao/checklist-acessorios'
    },
    {
      id: 'monitoramento',
      title: 'Monitoramento',
      icon: 'desktop-alt',
      style: 'regular',
      url: '/#/remocao/monitor'
    },
    {
      id: 'origens-destinos',
      title: 'Origens e Destinos',
      icon: 'map-marker-check',
      style: 'regular',
      url: '/#/remocao/locais'
    },
    {
      id: 'patios',
      title: 'Pátios',
      icon: 'map-marker',
      style: 'regular',
      url: '/#/patios/lista'
    },
    {
      id: 'relatorios',
      title: 'Relatórios',
      icon: 'file-chart-line',
      style: 'regular',
      url: '/#/relatorios'
    }/*,
    {
      id: 'config',
      title: 'Configurações',
      icon: 'cog',
      style: 'light',
      url: '#'
    }*/
  ]
}
