<template>
  <div>
    <div v-if="bem.id" class="m-t bem-tasks">
      <u-btn @click="gerarTarefas" class="sl-btn text-black" no-caps color="grey-3"
             label="Gerar tarefas automaticamente"/>
      <lista-tarefas
          ref="tasks"
          relacao-entidade="bem"
          :relacao-entidade-id="bem.id"
          layout="empty"
          hide-calendar
          hide-kanban
      >
        <template v-slot:bottom>
          <div class="noRowsTemplate">
            <div class="image"><img src="@/components/sltarefas/assets/images/task-icon.png"></div>
            <h2>Atividades relacionadas</h2>
            <p>Aqui você cadastra todas as tarefas relacionadas com prazo e detalhes das atividades.</p>
            <div class="norows-btn m-t">
              <u-btn @click="$refs.tasks.novo()" label="Adicionar uma nova tarefa" color="positive" no-caps icon="plus" icon-type="fa" />
            </div>
          </div>
        </template>
      </lista-tarefas>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import ListaTarefas from "@/components/sltarefas/components/ListaTarefas"
import {gerar} from "@/domain/bem/services/configTarefas"

export default {
  name: "GestaoTarefas",
  components: {
    ListaTarefas
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  watch: {
  },
  computed: {},
  methods: {
    gerarTarefas() {
      this.$uloc.dialog({
        title: 'Gerar tarefas',
        message: 'Você tem certeza que deseja gerar as tarefas baseado no tipo de bem?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Gerando...'})
        gerar(this.bem.id)
            .then(() => {
              this.$uloc.loading.hide()
              this.$refs.tasks.load()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
  }
}
</script>
