import http from '../../../utils/services/http'
import {donwloadFile} from "@/utils/downloadFile";

export const list = (bem, limit = 100, page = 1, filtros = '') => {
    let url
    if (typeof bem !== 'undefined' && bem) {
        url = `/api/bem/${bem}/propostas?page=` + page + '&limit=' + limit + filtros
    } else {
        url = `/api/bem/propostas?page=` + page + '&limit=' + limit + filtros
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (bem, id) => {
    let url
    if (typeof bem !== 'undefined' && bem) {
        url = `/api/bem/${bem}/propostas/${id}`
    } else {
        url = `/api/bem/propostas/${id}`
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newProposta = (bem, data) => {
    let url = `/api/bem/${bem}/propostas`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateProposta = (bem, proposta, method = 'PUT') => {
    let url = `/api/bem/${bem}/propostas/${proposta.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, proposta)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteProposta = (bem, id) => {
    let url
    if (typeof bem !== 'undefined' && bem) {
        url = `/api/bem/${bem}/propostas/${id}`
    } else {
        url = `/api/bem/propostas/${id}`
    }
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const formalizar = (id, formato = 'html', forceDownload = true) => {
    let url = `/api/bem/propostas/${id}/docFormalizacao?format=` + formato
    let config = {}
    if (formato !== 'html' && formato !== 'json') {
        config.responseType = 'blob'
    }
    config.timeout = 90000
    return http.post(url, {}, config)
        .then(response => {
            if (formato !== 'html' && formato !== 'json' && forceDownload) {
                donwloadFile(response)
            }
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            if (response.data instanceof Blob) {
                return new Promise((resolve, reject) => {
                    const fr = new FileReader();
                    fr.onload = function () {
                        const r = JSON.parse(this.result)
                        reject({data: r})
                    }
                    fr.readAsText(response.data)
                })
            } else {
                return Promise.reject(response)
            }
        })
}
