<template>
  <div class="flex no-wrap items-center" style="margin-left: -3px !important;">
    <div class="flex-zero m-r-xs">
      <label data-test="stage-1-rotting-flag" class="check-switch" :class="bindClasses">
        <input :disabled="disabled" type="checkbox" v-model="model" v-on:input="$emit('input', $event.target.checked)">
        <div class="check-switch-toggle"></div>
      </label>
    </div>
    <span v-show="!noYesNoVal" class="col-grow-1 font-12 sl-checkbox-confim" v-if="value">{{ yesVal }}</span>
    <span v-show="!noYesNoVal" class="col-grow-1 font-12 sl-checkbox-confim" v-else>{{ noVal }}</span>
  </div>
</template>

<script>
export default {
  name: "SlCheckbox",
  props: {
    value: {
      required: true
    },
    yesVal: {
      default: 'Sim'
    },
    noVal: {
      default: 'Não'
    },
    color: {
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noYesNoVal: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (v) {
      this.$nextTick(() => {
        if (this.model !== v) {
          this.model = v
        }
      })
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {
    bindClasses () {
      const css = []
      if (this.color) {
        css.push('color-' + this.color)
      }
      return css
    }
  }
}
</script>
