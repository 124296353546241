<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import createPrint from "components/relatorios/components/customPrint/createPrint"
import {listDocumentos} from "@/domain/documento/services"
import {docBem} from "@/domain/relatorios/services"

export default {
  name: 'BtnEmissoesBem',
  props: ['bem'],
  data () {
    return {
      docs: null,
      loading: true
    }
  },
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  methods: {
    activated () {
      console.log('Activated popover')
      this.loading = true
      listDocumentos(20, 1, '&tags=bem')
          .then(response => {
            this.docs = response.data.result
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    gerarDoc (doc) {
      this.gerar(this.bem.id, doc.id)
    },
    gerar(bemId, docId, formato = 'html', orientation = 'portrait') { // Para relatórios gerenciais
      this.$uloc.loading.show()
      docBem(bemId, docId, {}, formato)
          .then(response => {
            this.$uloc.loading.hide()
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'leilao', 'Relatórios de Leilão', orientation)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    },
    createPrint: createPrint
  }
}
</script>

<template>
  <u-btn icon="print" icon-type="fa" icon-style="light" align="left" color="blue-grey-4" size="sm" flat dense rounded class="m-r">
    <u-popover @show="activated" class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div v-if="loading" class="menu_ menu-btn-leilao" style="min-width: 100px">
        <div style="padding: 10px; min-height: 40px">
          <i class="fa fa-spin fa-spinner" />
        </div>
      </div>
      <div v-else class="menu_ menu-btn-leilao">
        <template v-if="!docs || !docs.length">
          <ul>
            <li>Nenhum documento disponível para emissão</li>
          </ul>
        </template>
        <ul v-for="doc in docs" :key="'doc-' + doc.id">
          <menu-options-item icon="file-alt" icon-type="fa" icon-style="light" :label="doc.name" @click="gerarDoc(doc)" close  />
        </ul>
        <!--        <ul>
                  <menu-options-item icon="file-alt" icon-type="fa" icon-style="light" label="Certidão de publicação" close />
                </ul>-->
        <slot />
      </div>
    </u-popover>
  </u-btn>
</template>
