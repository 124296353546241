<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Tipo'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
        :size="size || '1'"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {listTiposComitente as list} from '../../../../domain/cadastro/services'

export default {
  name: 'TipoComitenteSelect',
  mixins: [SelectMixin],
  props: {
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {},
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      this.loading = true
      list(500, 1, [])
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.result)) {
            return
          }
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: obj.nome,
              value: obj.id
            }
          })

          !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
          this.asyncdata = list
        })
        .catch((error) => {
          this.loading = false

        })
    }
  }
}
</script>
