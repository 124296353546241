<template>
  <div>
    <div class="pda-app-overflow flex column items-center justify-center">
      <div class="flex flex-zero__ column text-center h-full w-full items-center pda-busca-container">
        <div class="flex flex-zero column text-center h-full w-full" style="margin: auto; min-height: 200px">
          <transition name="slide" appear>
            <div class="transition-generic">
              <div><h2><strong>Digite</strong> o algum dado que relacione ao arrematante</h2></div>
              <div class="m-b m-t-xs">Exemplo: Nome, CPF/CNPJ<!--, E-mail -->, Apelido<!--, Telefone --></div>
              <div style="width: 356px; margin: 0 auto">
                <erp-input @keydown.enter="buscar" autofocus v-model="busca"
                           class="btn-pda btn-pda-rounded big text-center"
                           shortkey="F2" />
              </div>
              <div v-if="!loading" class="text-center">
                <pda-menu-item label="Pesquisar" shortcut="ENTER" class="btn-pda-def" @click.native="buscar" />
              </div>
              <pda-busca-arrematante ref="buscador" :busca="busca" />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ErpInput} from 'uloc-vue-plugin-erp'
import appList from './app'
import PdaBuscaArrematante from './arrematante/BuscaArrematante'
import PdaMenuItem from '../MenuItem'

export default {
  name: 'PesquisaArrematante',
  props: ['parseProps'],
  components: {PdaMenuItem, PdaBuscaArrematante, ErpInput},
  mounted () {
    this.$parent.labelTarget = null
    this.$parent.return = () => {
      this.$parent.app = appList.home
    }
    this.$parent.menuView = ['ata', 'pqa', 'pql', 'atl', 'rtn']
  },
  data () {
    return {
      busca: null,
      ativarBusca: false,
      loading: false,
      finded: false,
      loadingProgress: 0,
      notFound: false,
      arrematante: null
    }
  },
  methods: {
    buscar () {
      if (!this.busca || this.busca.length === 0) {
        return
      }
      this.ativarBusca = true
      this.$refs.buscador.search()
    },
    startLoading () {
      clearInterval(this.$loading)
      this.loadingProgress = 0
      this.$loading = setInterval(() => {
        if (this.loadingProgress >= 100) {
          clearInterval(this.$loading)
          return
        }
        this.loadingProgress = this.loadingProgress + Math.round(Math.random() * 2)
      }, 10)
    },
    stopLoading () {
      clearInterval(this.$loading)
      this.loadingProgress = 100
      window.setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  }
}
</script>

<style lang="stylus">

</style>
