// import Main from './Main'
import ListaBens from './components/ListaBens'
import ListaBensPage from './components/ListaBensPage'
import OpenBem from "@/components/bem/components/OpenBem"

export default [
  // { path: '/remocao', component: Main, name: 'remocao.main' },
  {
    path: '/bens/lista', component: ListaBensPage, name: 'bens.lista-page', children: [
      {
        path: '', component: ListaBens, name: 'bens.lista',
      },
      { path: '/bens/:id', component: OpenBem, name: 'bens.show' },
    ],
  },
  {
    path: '/patio/estoque', component: ListaBensPage, name: 'bens.estoque.main', children: [
      {
        path: '', component: ListaBens, name: 'bens.estoque',
      }
      ]
  }
]
