<template>
  <div class="m-t font-11">
    <div class="text-grey-8">Comitentes participantes:</div>
    <div class="flex row">
      <comitente-leilao-stats v-for="comitente in leilao.comitentes" :key="comitente.id" :comitente="comitente" />
    </div>
  </div>
</template>

<script>
import ComitenteLeilaoStats from '../ComitenteLeilaoStats'
export default {
  name: 'ComitentesParticipantes',
  components: {ComitenteLeilaoStats},
  props: ['leilao'],
  computed: {}
}
</script>
