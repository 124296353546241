<script>
  import {AuthThemeUloc} from 'uloc-vue-auth'
  // import {UCheckbox} from 'uloc-vue'

  export default {
    // mixins: [LoginMixin],
    name: 'AuthMain',
    components: {AuthThemeUloc},
    data() {
      return {
        loader: true,
        showPrompt: false,
        lembrar: false,
        config: {
          'erp.login.logo': null
        }
      }
    },
    computed: {
      logo () {
        const c = 'erp.login.logo'
        return this.config && this.config[c] && this.config[c].value !== null && this.config[c].value !== 'null' ? this.config[c].value : null
      },
      logoTmp () {
        // TMP
        if (location.hostname.indexOf('5bitech') > -1){ // TODO: Remover
          return 'https://clientes.suporteleiloes.com/fivebi/Five-blue.png'
        }
        return false
      }
    },
    mounted() {
      this.config = this.$globalConfig
      document.body.classList.add('login-body')
    },
    beforeDestroy() {
      document.body.classList.remove('login-body')
    },
    destroyed() {
    },
    methods: {
      connect() {
        /*this.$uloc.notify(
          type: 'info',
          message: 'Conectando'
        })*/
        this.doLogin()
      },
      testConfig(c) {
        return this.config[c] && this.config[c].value !== null && this.config[c].value !== 'null'
      },
      getConfig(c) {
        return this.config[c] ? this.config[c].value : null
      }
    }
  }
</script>

<template>
  <auth-theme-uloc v-if="loader" />
</template>
