import http from "@/utils/services/http"

export const listDocumentos = (limit, page, filtros) => {
    let url = '/api/documentos?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findDocumento = (id) => {
    let url = `/api/documentos/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const previewDocumento = (id, data) => {
    let url = `/api/documentos/preview`
    return http.post(url, {
        document: data,
        id: id
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newDocumento = (data) => {
    let url = `/api/documentos`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateDocumento = (documento, method = 'PUT') => {
    let url = `/api/documentos/${documento.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, documento)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteDocumento = (id) => {
    let url = `/api/documentos/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}


export const uploadArquivoDocumento = (id, data) => {
    let url = `/api/documentos/${id}/arquivos`
    return http.post(url, data, {
        'timeout': 300000
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
