<template>
  <component v-bind:is="currentTabComponent" class="pessoa-marcacao-popover" ref="popover" :anchor-click="false">
    <!--    <div @click="$emit('selected', {id: 1, name: 'Tiago Felipe'})" style="padding: 10px">Buscando por: {{input}}</div>-->
    <!--    <a @click="(e) => itemMouseClick({}, 0, e)">Teste</a>-->
    <u-list ref="list" :no-border="true">
      <u-item v-if="loading" class="text-center flex flex-center">
        <sl-loading class="size-18" content=""/>
      </u-item>
      <!--      <u-item-wrapper
                v-else-if="computedResults.length"
                v-for="(result, index) in computedResults" :key="index"
                :class="{
                'u-select-highlight': keyboardIndex === index,
                'cursor-pointer': !result.disable,
                'text-faded': result.disable
              }"
                :cfg="result"
                @mouseenter.native="itemMouseEnter(result, index)"
                @mousedown.native="(e) => itemMouseClick(result, index, e)"
            />-->
      <u-item
          v-else-if="computedResults.length"
          v-for="(result, index) in computedResults" :key="index"
          :class="{
          'u-select-highlight': keyboardIndex === index,
          'cursor-pointer': !result.disable,
          'text-faded': result.disable
        }"
          :cfg="result"
          @mouseenter.native="itemMouseEnter(result, index)"
          @mousedown.native="(e) => itemMouseClick(result, index, e)"
      >
        <div class="flex items-center">
          <person-avatar :pessoa="result.person" class="m-r-sm"/>
          {{ result.person.name }}
        </div>
      </u-item>
      <u-item v-else class="text-center flex flex-center">
        Nenhuma pessoa encontrada.
      </u-item>
    </u-list>
  </component>
</template>

<script>
/**
 * @TODO: Revusar código inutilizado
 */
import {UPopover, UList, UItem} from "uloc-vue"
import KeyboardSelectionMixin from "uloc-vue/src/mixins/keyboard-selection"
import SlLoading from "@/components/layout/components/Loading"
import {getEventKey, stopAndPrevent} from "uloc-vue/src/utils/event"
import {normalizeToInterval} from "uloc-vue/src/utils/format"
import {simpleList} from "@/domain/usuario/services"
import Inline from "@/components/layout/components/EditorComponents/helpers/Inline"
import Vue from "vue"
import PersonAvatar from "@/components/pessoa/components/include/PersonAvatar";

Vue.component('popover', UPopover)
Vue.component('inline', Inline)

const searchPerson = function (terms) {
  if (!terms || terms === 'null') {
    terms = ''
  }
  let filtros = encodeURI(`&roles=ROLE_ERP&sortBy=name&descending=false&search=${terms}`)
  return simpleList(50, 1, filtros)
}

export default {
  name: "Mark",
  mixins: [KeyboardSelectionMixin],
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    autoSearch: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      input: null,
      result: [],
      enterKey: false,
      timer: null
    }
  },
  computed: {
    currentTabComponent() {
      return this.inline ? 'inline' : 'popover'
    },
    computedResults() {
      let input = String(this.input).replace('@', '')
      if (this.autoSearch && !this.input) {
        return this.result
      }
      return this.result.filter(d => {
        return !input || String(d.label).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(input.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1  //d.label.toLowerCase().includes(input.toLowerCase())
      })
    },
    keyboardMaxIndex() {
      return this.computedResults.length - 1
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.searchPerson(this.input, (result) => {
        this.result = result
      })
      document.addEventListener('keydown', this.__keyboardHandleKey)
      // document.addEventListener('blur', this.blurHide)
      //document.addEventListener('focus', this.__focusShowTrigger)
    })
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.__keyboardHandleKey)
    //document.removeEventListener('focus', this.__focusShowTrigger)
  },
  components: {PersonAvatar, SlLoading, UPopover, UList, UItem},
  watch: {
    computedResults() {
      this.$nextTick(() => {
        // this.__focusShowTrigger()
      })
    },
    keyboardIndex(val) {
      if (this.scroll) {
        setTimeout(() => {
          const selected = this.$el.querySelector('.u-select-highlight')
          if (selected && selected.scrollIntoView) {
            if (selected.scrollIntoViewIfNeeded) {
              return selected.scrollIntoViewIfNeeded(false)
            }
            selected.scrollIntoView(this.keyboardMoveDirection < 0)
          }
        }, 100)
      }
    },
  },
  methods: {
    show(e) {
      this.$refs.popover.show(e)
    },
    setVal(val) {
      this.input = val
    },
    searchPerson(terms, done) {
      this.loading = true
      searchPerson(terms)
          .then(response => {
            this.loading = false
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.person.name,
                value: p.person.name,
                active: null
              }
            })
            done(result)
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    itemMouseEnter(result, index) {
      !result.disable && (this.keyboardIndex = index)
    },
    itemMouseClick(result, index, e) {
      console.log('Click', e)
      stopAndPrevent(e)
      !result.disable && this.selectValue(result)
    },
    selectValue(val) {
      this.$emit('selected', val)
    },
    __keyboardCustomKeyHandle(key) {
      switch (key) {
        case 27: // ESCAPE
          // this.__clearSearch()
          break
        case 38: // UP key
        case 40: // DOWN key
        case 9: // TAB key
          this.__keyboardSetCurrentSelection(true)
          break
      }
    },
    __keyboardHandleKey(e) {
      const key = getEventKey(e)

      switch (key) {
        case 38: // UP key
          this.__keyboardMoveCursor(-1, e)
          break
        case 40: // DOWN key
          console.log('Down key')
          this.__keyboardMoveCursor(1, e)
          break
        case 13: // ENTER key
          stopAndPrevent(e)
          this.__keyboardSetCurrentSelectionFromEnter()
          return
          break
        case 9: // TAB key
          this.hide()
          break
      }

      this.__keyboardCustomKeyHandle(key, e)
    },
    __keyboardShowTrigger() {
      this.trigger()
    },
    __focusShowTrigger() {
      clearTimeout(this.timer)
      setTimeout(() => this.trigger(true), 100)
    },
    __keyboardIsSelectableIndex(index) {
      return index > -1 && index < this.computedResults.length && !this.computedResults[index].disable
    },
    __keyboardSetSelection(index, navigation) {
      // this.setValue(this.results[index], navigation)
    },
    __delayTrigger() {
      this.__clearSearch()
      if (!this.__input.hasFocus()) {
        return
      }
      if (this.staticData) {
        this.trigger()
        return
      }
      this.timer = setTimeout(this.trigger, this.debounce)
    },
    blurHide() {
      this.timer = setTimeout(() => this.$refs.popover.hide(), 300)
    },
    trigger(focus) {
      //this.__keyboardShow(-1)
      if (this.computedResults.length) {
        this.__keyboardShow(-1)
      }
    },
    __keyboardSetCurrentSelectionFromEnter(navigation) {
      if (this.keyboardIndex >= 0 && this.keyboardIndex <= this.keyboardMaxIndex) {
        this.selectValue(this.computedResults[this.keyboardIndex], navigation)
      }
    },
    __keyboardMoveCursor(offset, e) {
      stopAndPrevent(e)

      if (!this.computedResults || !this.computedResults.length) {
        return
      }

      clearTimeout(this.keyboardMoveTimer)
      let
          index = this.keyboardIndex,
          valid = this.__keyboardIsSelectableIndex || (() => true)

      do {
        index = normalizeToInterval(
            index + offset,
            -1,
            this.keyboardMaxIndex
        )
      }
      while (index !== this.keyboardIndex && !valid(index))

      this.keyboardMoveDirection = index > this.keyboardIndex ? 1 : -1
      this.keyboardMoveTimer = setTimeout(() => {
        this.keyboardMoveDirection = false
      }, 500)
      this.keyboardIndex = index
      if (this.scroll) {
        setTimeout(() => {
          this.$refs.list.$el.scrollIntoView()
        }, 50)
      }
      return
    }
  }
}
</script>
