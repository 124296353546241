<template>
  <u-chips-input :autofocus="autofocus" @remove="remove" @focus="focusInput" :placeholder="terms && Array.isArray(terms) && terms.length ? '' : placeholder" ref="definput" class="erp-input erp-autocomplete-multiple float-icon input-hide-loading" color="amber" v-model="terms" hide-underline
  :class="{hasIcon: icon}">
    <template v-if="icon" v-slot:before>
      <u-icon :color="iconColor" :name="icon" :type="iconType" class="m-l-sm" />
    </template>
    <autocomplete-wrapper ref="autocomplete" @search="search" @selected="selected" :max-results="50" :debounce="200" :min-characters="1" />
    <sl-loading v-if="isLoading || ($refs.definput && $refs.definput.isLoading)" content="" class="size-18" />
    <div v-if="isNew" class="new"><span class="lbl">Novo</span></div>
  </u-chips-input>
</template>

<script>
import {UChipsInput} from "uloc-vue"
import AutocompleteWrapper from "@/components/layout/components/AutocompleteWrapper"
import SlLoading from "@/components/layout/components/Loading"

export default {
  name: "AutocompleteMultiple",
  components: {SlLoading, AutocompleteWrapper, UChipsInput},
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    loadOnFocus: {
      type: Boolean,
      default: false
    },
    placeholder: null,
    value: {
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    iconType: {
      type: String,
      default: 'fa'
    },
    iconColor: {
      type: String,
      default: 'grey-7'
    },
    search: {
      type: Function,
      required: true
    },
    parseValue: {
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      terms: this.parseValue ? this.parseValue(this.value) : this.value,
      selectedValue: null
    }
  },
  computed: {
    isNew () {
      return this.selectedValue && this.selectedValue.isNew && this.terms === this.selectedValue.value
    }
  },
  methods: {
    focusInput () {
      if (!this.loadOnFocus) return
    },
    selected (item, kbdNav) {
      this.selectedValue = item
      console.log('Selected', kbdNav)
      !kbdNav && this.add(item)
    },
    add (item) {
      console.log('Added item', item)
      let value = this.value
      if (!value.find(v => v.id ? (v.id === item.id) : v.value === item.value)) {
        value.push(item)
      }
      this.$emit('input', value)
    },
    remove (item) {
      console.log('Removed item', item)
      let value = this.value
      value.splice(item.index, 1)
      this.$emit('input', value)
    }
  }
}
</script>