import window from '../../../../../reuse/window/windowDefault'

export default window(
  'acessorio',
  'Acessório',
  () => import('./Acessorio'),
  function (props) {
    return props
  },
  {
    resolveCallback: function (wid) {
      console.log('Callback resolve', wid, this)
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          console.log('Atualizado: ', val)
          this.updateList(val)
        }
      })
    },
    width: '500',
    height: '500',
  }
)
