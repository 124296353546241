import {ErpInput, ErpSField,} from 'uloc-vue-plugin-erp'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
export default {
  inject: ['bemContainer'],
  components: {
    ErpSField,
    ErpInput,
    ERow,
    ECol
  },
  props: {
  },
  computed: {
    bem () {
      return this.bemContainer.bem
    },
    router () {
      return this.bemContainer.router
    }
  }
}
