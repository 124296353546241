export const ESTADO_CIVIL_SOLTEIRO = 1;
export const ESTADO_CIVIL_CASADO = 2;
export const ESTADO_CIVIL_VIUVO = 3;
export const ESTADO_CIVIL_SEPARADO = 4;
export const ESTADO_CIVIL_DIVORCIADO = 5;
export const ESTADO_CIVIL_UNIAO_ESTAVEL = 6;
export const ESTADO_CIVIL_OUTRO = 7;


export const estadosCivis = [
    {label: 'Solteiro', value: ESTADO_CIVIL_SOLTEIRO},
    {label: 'Casado', value: ESTADO_CIVIL_CASADO},
    {label: 'Viúvo', value: ESTADO_CIVIL_VIUVO},
    {label: 'Separado', value: ESTADO_CIVIL_SEPARADO},
    {label: 'Divorciado', value: ESTADO_CIVIL_DIVORCIADO},
    {label: 'União estável', value: ESTADO_CIVIL_UNIAO_ESTAVEL},
    {label: 'Outro', value: ESTADO_CIVIL_OUTRO},
]
