<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import importacaoWindow from '../../../importador/windows/importacao'
import importacaoWebservice from '../../windows/importacaoWebservice'

export default {
  name: 'BtnImportacaoLeilao',
  props: ['leilao'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  methods: {
    importacaoWindow: importacaoWindow,
    importacaoWebservice: importacaoWebservice,
    importacaoAvancada (l) {
      this.$router.push({name: 'leiloes.importacao-avancada'})
    }
  }
}
</script>

<template>
  <e-btn align="left" label="Importação">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item @click="importacaoWindow('lote', leilao.id)" icon="upload" icon-type="fa" label="De um arquivo" close  />
          <menu-options-item @click="importacaoWebservice(leilao)" label="De um webservice" close  />
          <menu-options-item @click="importacaoAvancada(leilao)" label="Importação avançada" icon="folder-plus" icon-type="fa" close  />
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
