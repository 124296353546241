import * as Status from '../../../../domain/leiloes/helpers/LoteStatus'
import {atualizaLoteamento, atualizaStatusLotes} from '../../../../domain/leiloes/services';

export default {
  computed: {
    Status () {
      return Status
    }
  },
  methods: {
    alterarStatus () {
      let lotes = this.table.selected
      console.log('Alterando status de ' + lotes.length + ' lotes')
      let actions = []
      Object.keys(Status.Status).map((k) => {
        actions.push({
          label: Status.Status[k].title,
          handler: () => this.confirmaAlterarStatus(k)
        })
      })
      this.$uloc.actionSheet({
        title: 'Alterar status de lote',
        grid: false,
        actions: [
          ...actions,
          {}, // separator
          {
            label: 'Cancelar',
            icon: 'times',
            iconType: 'fa',
            color: 'negative',
            handler: () => {
              // this.$uloc.notify('Operação cancelada')
            }
          }
        ]
      })
        .catch(() => {
          // dimiss...
        })

    },
    confirmaAlterarStatus (s, lotes) {
      console.log('Mudando status para ' + s)
      if (!lotes || !Array.isArray(lotes)) {
        lotes = this.table.selected.slice().map(lt => {
          return {id: lt.id, status: s}
        })
      }
      this.$uloc.loading.show()
      atualizaStatusLotes(this.leilao.id, lotes)
        .then((response) => {
          this.load()
          this.$uloc.loading.hide()
        })
        .catch(error => {
          this.$uloc.loading.hide()
          this.alertApiError(error)
          console.log(error, error.data)
        })
    }
  }
}
