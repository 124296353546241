export default function (bens, arrematante) {
  let window = arrematante ? `pda.transf-comp.${arrematante.id}` : 'pda.transf-comp'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Transferência de titularidade de venda',
    width: '900',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window erp-window-macos',
    contentClass: '__overflow-hidden',
    props: {
      lotes: bens,
      arrematante: arrematante
    }
  }, () => import('./TransferenciaComprador.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          this.$emit('update')
          this.load && this.load()
        }
      })
      console.log(wid)
    }) // return wid
}
