var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{staticClass:"remocao",attrs:{"title":"Dashboard","menu":_vm.menu,"menu-active":"dashboard","pad":""},on:{"resize":function($event){return _vm.$refs.widgets.adjustPosition()}}},[_c('div',[_c('widgets',{ref:"widgets",attrs:{"maxSlots":10,"adjusts":[{'screen': 0, 'slots': 12}, {'screen': 0, 'slots': 4}]}},[_c('widget-item',{attrs:{"wslot":2,"hslot":2}},[_c('widget-remocoes-stats',{attrs:{"title":"Em andamento","subtitle":"Remoções em aberto","count":[{title: null, value: _vm.remocao.data.abertas}],"loading":_vm.remocao.loading}},[_vm._t("default",function(){return [_c('router-link',{attrs:{"to":{name: 'remocao.lista'}}},[_vm._v("Gerenciar remoções")])]},{"slot":"link"})],2)],1),_c('widget-item',{attrs:{"wslot":2,"hslot":2}},[_c('widget-remocoes-stats',{attrs:{"title":"Recebidas hoje","count":[
              {title: 'Entregue', value: _vm.remocao.data.recebidasHoje.entregue},
              {title: 'P/ Vistoria', value: _vm.remocao.data.recebidasHoje.paraVistoria, class: 'indicator-color-blue'}
              ],"loading":_vm.remocao.loading}},[_vm._t("default",function(){return [_c('a',[_vm._v("Monitorar")])]},{"slot":"link"})],2)],1),_c('widget-item',{attrs:{"wslot":2,"hslot":2}},[_c('widget-remocoes-stats',{attrs:{"title":"Solicitadas","count":[
              {title: 'Hoje', value: _vm.remocao.data.solicitadas.hoje},
              {title: 'No mês', value: _vm.remocao.data.solicitadas.mes}
              ],"loading":_vm.remocao.loading}},[_vm._t("default",function(){return [_c('a',[_vm._v("Incluir nova remoção")])]},{"slot":"link"})],2)],1),_c('widget-item',{attrs:{"wslot":4,"hslot":2}},[_c('widget-remocoes-stats',{attrs:{"title":"Estatística retirada","count":[
              {title: 'Retirados hoje', value: _vm.remocao.data.retirada.hoje},
              {title: 'Previsto', value: _vm.remocao.data.retirada.previsto}
              ],"loading":_vm.remocao.loading}},[_vm._t("default",function(){return [_c('a',[_vm._v("Monitorar retiradas")])]},{"slot":"link"})],2)],1),_c('widget-item',{attrs:{"wslot":2,"hslot":2}},[_c('widget-remocoes-stats',{attrs:{"title":"Solicitações de remoção","count":[{title: null, value: _vm.remocao.data.solicitacoes}],"loading":_vm.remocao.loading}},[_vm._t("default",function(){return [_c('a',[_vm._v("Gerenciar solicitações")])]},{"slot":"link"})],2)],1),_c('widget-item',{attrs:{"wslot":4,"hslot":2}},[_c('widget-remocoes-stats',{attrs:{"title":"Estatística de Reboquistas","count":[
              {title: 'Ativos', value: _vm.remocao.data.reboquistas.ativos},
              {title: 'Pendentes', value: _vm.remocao.data.reboquistas.pendentes},
              {title: 'Bloqueados', value: _vm.remocao.data.reboquistas.bloqueados}
              ],"loading":_vm.remocao.loading}},[_vm._t("default",function(){return [_c('router-link',{attrs:{"to":{name: 'reboquistas.lista'}}},[_vm._v("Gerenciar reboquistas")])]},{"slot":"link"})],2)],1),_c('widget-item',{attrs:{"wslot":4,"hslot":2}},[_c('widget-remocoes-stats',{attrs:{"title":"Estatística de vistoria","count":[
              {title: 'Vistoriados hoje', value: _vm.remocao.data.vistoria.hoje},
              {title: 'Inconsistências', value: _vm.remocao.data.vistoria.inconsistencias, class: 'indicator-color-red'}
              ],"loading":_vm.remocao.loading}},[_vm._t("default",function(){return [_c('a',[_vm._v("Monitorar vistorias")])]},{"slot":"link"})],2)],1)],1)],1),(_vm.showDashOptions)?_c('dash-options',{staticClass:"dash-remocao",attrs:{"menu":_vm.menuDashOptions}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }