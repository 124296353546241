<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Selecione as colunas que deseja no relatório'"
      :helper="helper"
      :helper-position="helperPosition || 'lb'"
      :error="error"
      :label-width="labelWidth"
      :style="fieldStyle"
  >
    <erp-select
        @input="__set"
        :multiple="multiple"
        :placeholder="placeholder || 'Selecione'"
        :options="colunasRelatorio"
        v-model="model"
        propoverClass="select-colunasRelatorio-def1"
        :disable="disable"
        :style="inputStyle"
    >
    </erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../../mixins/SelectMixin'
import {getColunasSelect} from "components/arrematante/components/include/export-base/colunas-relatorio-arrematantes";

export default {
  name: 'ColunasExportArrematantesSelect',
  mixins: [SelectMixin],
  props: {},
  data () {
    return {}
  },
  computed: {
    colunasRelatorio () {
      return getColunasSelect()
    }
  },
  components: {},
  methods: {
    __updateInternal () {
      this.$nextTick(() => {
        if (this.value !== 'undefined' && this.value !== null && this.model === null) {
          let _cp = JSON.stringify(this.value) // force to update datepicker with actual value
          this.model = ''
          setTimeout(() => {
            this.model = JSON.parse(_cp)
          }, 1)
        }
      })
    }
  }
}
</script>

<style lang="stylus">
.select-colunasRelatorio-def1 {
  td {
    display flex
    justify-items center
  }
}
</style>
