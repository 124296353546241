export default {
  name: 'IntegraGrupoPortoMixin',
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  mounted () {
    this.erplayout.erpheader.menu.setActiveMenu('comitentes')
  }
}
