<template>
  <div class="menu_submenu">
    <div class="submenu-column submenu-column_1">
      <ul class="submenu_inner submenu_inner_level-1">
        <li class="submenu_item submenu_item_level-2">
          <span class="submenu_link submenu_link_nolink"><span class="submenu_link-text">Automação</span></span>
          <ul class="submenu_inner_level-2">
            <nav-sub-link @click="atualizarLanceInicial2leilao" icon="check" icon-type="fa" icon-style="regular" label="Atualizar automaticamente valores 2o leilão" />
            <nav-sub-link @click="enviarAvisoInicioLeilao" icon="envelope" icon-type="fa" icon-style="regular" label="Enviar aviso de início de leilão" />
            <nav-sub-link @click="alterarStatusLoteEmMassa" icon="edit" icon-type="fa" icon-style="regular" label="Alterar Status de Lotes em Massa" />
            <nav-sub-link @click="gerarNotas" icon="file" icon-type="fa" icon-style="regular" label="Gerar Todas as Notas do Leilão" />
            <nav-sub-link icon="handshake" icon-type="fa" icon-style="envelope" label="Formalizar todas as vendas" />
            <nav-sub-link icon="handshake" icon-type="fa" icon-style="envelope" label="Formalizar todas as vendas com carimbo do tempo" />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavSubLink from './NavSubLink'
import LeilaoMixin from "@/components/leiloes/components/LeilaoMixin"
import {atualizarValoresLeilao, avisaInicioLeilao, geraNotasEmMassa} from "@/domain/leiloes/services"
export default {
  name: 'AutomacaoSubmenu',
  mixins: [LeilaoMixin],
  components: {NavSubLink},
  inject: ['nav'],
  methods: {
    click () {
      this.nav.clickEvent()
      return true
    },
    atualizarLanceInicial2leilao () {
      this.click()
      this.$uloc.dialog({
        title: 'Atualizar Lance Inicial do 2o Leilão',
        message: 'Tem certeza? Todos os valores iniciais do segundo leilão serão atualizados baseando-se no desconto definido no leilão',
        cancel: true,
        preventClose: true,
        color: 'secondary'
      }).then(data => {
        this.$uloc.loading.show({message: 'Atualizando valores'})
        atualizarValoresLeilao(this.leilao.id, {instancia: 2})
            .then(response => {
              this.$uloc.loading.hide()
              this.$uloc.dialog({
                title: 'Atualização de valores',
                message: response.data.status
              })
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
    },
    enviarAvisoInicioLeilao () {
      this.click()
      this.$uloc.dialog({
        title: 'Aviso de Início do Leilão',
        message: 'Selecione os canais que deseja enviar o aviso para os habilitados',
        options: {
          type: 'checkbox',
          model: [],
          items: [
            {label: 'E-mail', value: 'email', color: 'secondary'},
            {label: 'Whatsapp', value: 'whatsapp', disable: true},
            {label: 'SMS', value: 'sms', disable: true},
            {label: 'Push notification', value: 'push', disable: true}
          ]
        },
        cancel: true,
        preventClose: true,
        color: 'secondary'
      }).then(data => {
        this.$uloc.loading.show({message: 'Enviando aviso'})
        avisaInicioLeilao(this.leilao.id, data)
            .then(response => {
              this.$uloc.loading.hide()
              this.$uloc.dialog({
                title: 'Envio de Avisos',
                message: response.data.status
              })
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
    },
    alterarStatusLoteEmMassa () {
      this.click()
      this.$emit('alterarStatusLotesDialog')
    },
    gerarNotas () {
      this.click()
      this.$uloc.dialog({
        title: 'Gerar notas em massa',
        message: 'Informe o e-mail que deseja enviar as notas para download',
        prompt: {
          model: ''
        },
        cancel: true,
        preventClose: true,
        color: 'secondary'
      }).then(data => {
        console.log(data)
        this.$uloc.loading.show({message: 'Solicitando emissão das notas...'})
        geraNotasEmMassa(this.leilao.id, data)
            .then(response => {
              this.$uloc.loading.hide()
              this.$uloc.dialog({
                title: 'Emissão agendada',
                message: `Em alguns minutos um e-mail será enviado para o e-mail ${data} com o link para download das notas`
              })
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
    }
  }
}
</script>
