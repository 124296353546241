<script>
    import DashboardWidgetContextMenu from "../context-menu/dashboard-widget-context";
    export default {
      name: 'widget-header',
      props: {
      },
      data () {
        return {}
      },
      computed: {},
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {DashboardWidgetContextMenu}
    }
</script>

<template>
    <div class="widget-header">
        <slot/>
        <u-icon class="pull-right" name="ellipsis-v" type="fa" icon-style="light"> <!-- TODO: Criar um menu padrão sem necessidade do @click -->
            <dashboard-widget-context-menu ref="context"></dashboard-widget-context-menu>
        </u-icon>
    </div>
</template>
