<template>
  <div>
    <div class="m-t" v-if="loading">
      <div class="flex items-center"><sl-loading content="" class="size-18 m-r-sm" /> Verificando marketplaces integradas<dot-loading /></div>
    </div>
    <div v-if="!loading && !semIntegracao">
      <div v-if="hasImovelWeb">
        <h3 class="dtitle">Imóvel Web</h3>
        <div class="m-t">
          <sl-checkbox yes-val="Publicado" no-val="Não publicado" v-model="isImovelWebActive" />
          <div v-if="loadingImovelWeb" class="m-t">
            <div class="flex items-center"><sl-loading content="" class="size-18 m-r-sm" /> Conectando com a Imóvel Web, aguarde, isto pode levar alguns segundos<dot-loading :limit="10" /></div>
          </div>
          <div v-if="isImovelWebActive && bem.extra.imovelweb" class="m-t">
            <div class="m-b">
              <u-btn @click="sincronizarAnuncioImovelWeb" no-caps color="green" label="Sincronizar dados"></u-btn>
            </div>
            <div class="m-b">Retorno API:</div>
            <div style="width: 100%; height: 140px; overflow: auto">
              <pre>{{bem.extra.imovelweb}}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading && semIntegracao" class="m-t">
      Nenhuma integração configurada.
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {imovelWebCriaAnuncio, imovelWebDeletarAnuncio, listEnableds} from "@/domain/bem/services/marketplace"
import SlLoading from "components/layout/components/Loading"
import DotLoading from "components/layout/components/DotLoading"
import SlCheckbox from "@/reuse/input/Checkbox"

export default {
  name: "MarketplaceApi",
  components: {SlCheckbox, DotLoading, SlLoading},
  mounted() {
    this.loadEnableds()
  },
  mixins: [CadastroBemMixin],
  data() {
    const bem = this.bemContainer.bem
    return {
      loading: true,
      enableds: [],
      possiveis: [
          'imovelweb',
          'resale'
      ],
      loadingImovelWeb: false,
      isImovelWebActive: bem && bem.extra && bem.extra.imovelweb,
      isImovelWebActivePrevent: false
    }
  },
  watch: {
    isImovelWebActive (v) {
      if (this.isImovelWebActivePrevent) return
      this.isImovelWebActivePrevent = true
      if (v) {
        this.sincronizarAnuncioImovelWeb()
      } else {
        this.loadingImovelWeb = true
        imovelWebDeletarAnuncio(this.bem.id)
            .then(response => {
              if (!this.bem.extra) {
                this.bem.extra = {}
              }
              if (!response.data.error) {
                this.bem.extra.imovelweb = response.data
              }
              this.loadingImovelWeb = false
              setTimeout(() => {
                this.isImovelWebActivePrevent = false
              }, 1000)
            })
            .catch(error => {
              this.isImovelWebActive = true
              this.loadingImovelWeb = false
              setTimeout(() => {
                this.isImovelWebActivePrevent = false
              }, 1000)
              this.alertApiError(error)
            })
      }
    }
  },
  computed: {
    hasImovelWeb () {
      return this.enableds['imovelweb'] == '1'
    },
    semIntegracao () {
      let r = true
      this.possiveis.map(p => {
        if (typeof this.enableds[p] !== 'undefined' && this.enableds[p] == '1') {
          r = false
        }
      })
      return r
    }
  },
  methods: {
    loadEnableds() {
      this.loading = true
      listEnableds()
          .then(response => {
            this.enableds = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    sincronizarAnuncioImovelWeb () {
      this.isImovelWebActivePrevent = true
      this.loadingImovelWeb = true
      imovelWebCriaAnuncio(this.bem.id)
          .then(response => {
            if (!this.bem.extra) {
              this.bem.extra = {}
            }
            if (!response.data.error) {
              this.bem.extra.imovelweb = response.data
            }
            this.loadingImovelWeb = false
            setTimeout(() => {
              this.isImovelWebActivePrevent = false
            }, 1000)
          })
          .catch(error => {
            this.isImovelWebActive = false
            this.loadingImovelWeb = false
            setTimeout(() => {
              this.isImovelWebActivePrevent = false
            }, 1000)
            this.alertApiError(error)
          })
    }
  }
}
</script>
