// import Main from './Main'
import Page from "@/components/integra-resale/layout/Layout"

export default [
  {
    path: '/integra/resale', component: Page, name: 'integra.resale', children: [
      {
        path: '',
        component: () => import('./components/Principal.vue'),
        name: 'integra.resale.main'
      },
      {
        path: 'consultaImoveis',
        component: () => import('./components/ConsultaImoveis.vue'),
        name: 'integra.resale.consultaImoveis'
      }
    ]
  }
]
