<template>
  <div class="leilao-tipo-label">
    {{tipoTitle}}
  </div>
</template>

<script>

export default {
  name: 'ClassificacaoLeilaoLabel',
  props: {
    classificacao: {
      required: true
    }
  },
  computed: {
    tipoTitle () {
      return this.classificacao
    }
  }
}
</script>
