<template>
  <div class="flex items-center">
    <status-lote-icon class="fa-fw" :status="status" />
    <span :class="'scolor lote-status lote-status-' + status">{{getStatus(status).title}}</span>
  </div>
</template>

<script>
import {Status, getStatus} from '../../../../domain/leiloes/helpers/LoteStatus'
import StatusLoteIcon from './StatusLoteIcon'

export default {
  name: 'StatusLoteSelector',
  components: {StatusLoteIcon},
  props: ['status'],
  computed: {
  },
  methods: {
    getStatus (s) {
      return getStatus(s, Status)
    }
  }
}
</script>

<style scoped>

</style>
