<script>
/* eslint-disable */
import DefaultMixin from '../mixin'
import MenuLeilao from './menu/MenuLeilao'
import {UFakeLoader} from 'uloc-vue'
import {find} from '@/domain/leiloes/services'
import MenuSuperior from '@/components/leiloes/components/menu/MenuSuperior'
import LeilaoMixin from '@/mixins/LeilaoMixin'

export default {
  name: 'LeilaoPage',
  provide: function () {
    return {
      page: this
    }
  },
  mixins: [DefaultMixin, LeilaoMixin],
  components: {
    MenuSuperior,
    MenuLeilao,
    UFakeLoader
  },
  data () {
    return {
      leilao: null,
      active: 'hom',
      seguirLeilao: false
    }
  },
  computed: {},
  mounted () {
    document.body.classList.add('app-leilao-body')
    this.load()
  },
  beforeDestroy() {
    document.body.classList.remove('app-leilao-body')
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load () {
      console.log('Load leilão data')
      this.leilao = null
      find(this.$route.params.id)
        .then((response) => {
          this.leilao = response.data
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar o leilão!',
            color: 'negative',
            message: `Não conseguimos carregar o leilão, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    setActive (v) {
      this.active = v
    }
  },
  meta: {
    title: 'Leilão',
    name: 'Leilão'
  }
}
</script>

<template>
  <div v-if="leilao" class="flex col-grow no-wrap leilao-page">
<!--    <menu-superior />-->
    <menu-leilao :active="active" :leilao="leilao" />
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic">
          <router-view />
        </div>
      </transition>
    </div>
  </div>
  <div v-else class="flex col-grow no-wrap items-center justify-center default-loading leilao-loading">
    <div class="full-width wrapper-xl full-height self-start">
      <u-fake-loader width="100%" height="40px" />
      <u-fake-loader width="20%" />
      <u-fake-loader width="32%" />
      <u-fake-loader width="56%" />
      <br>
      <br>
      <br>
      <br>
      <br>
      <u-fake-loader width="32%" />
      <u-fake-loader width="10%" />
      <u-fake-loader width="78%" />
    </div>
  </div>
</template>

<style lang="stylus" src="./leilao.styl" />
