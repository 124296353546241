import {ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'

export default {
  props: {
    label: [String, Number],
    labelWidth: String,
    noLabel: Boolean,
    fieldView: String,
    value: {required: true},
    options: Array,
    multiple: Boolean,
    placeholder: String,
    helper: String,
    helperPosition: String,
    error: Boolean,
    disable: Boolean,
    readonly: Boolean,
    selectFirst: Boolean,
    fieldStyle: null,
    inputStyle: null,
    simpleBorder: false,
    required: Boolean,
    size: String,
    findedDataObj: {required: false},
    inputClass: String,
    isValueObject: Boolean
  },
  data () {
    console.log(this.value, this.isValueObject)
    return {
      model: this.isValueObject && this.value && this.value.id ? this.value.id : this.value,
      loading: false
    }
  },
  watch: {
    /*model (v) {
      this.$nextTick(() => {
        if (this.model !== this.value) {
          this.__emit(v)
        }
      })
    },*/
    value (val) {
      this.model = this.multiple && Array.isArray(val)
        ? val.slice()
        : val
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model){
        let clone = JSON.stringify(this.model)
        if(Array.isArray(this.model)){
          this.model = []
        } else{
          this.model = null
        }
        this.$nextTick(() => {
          this.model = JSON.parse(clone) // force to selected event
        })
      }
    })
  },
  computed: {
  },
  components: {ErpSField, ErpSelect},
  methods: {
    mapperProps (opt) {
      return {
        ...opt,
        status: opt.value
      }
    },
    __set (e) {
      let val = e && e.target ? e.target.value : e
      this.__emit(val)
    },
    __emit (value) {
      this.$emit('input', value)
      //this.$nextTick(() => {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('change', value)
        }
      //})
    }
  }
}
