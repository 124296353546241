<template>
  <li @click="click" class="submenu_item submenu_item_level-3">
    <a class="submenu_link" :href="href" data-menu-level="3">
      <span v-if="iconUrl" class="submenu_link-icon" :style="'background-image: url(' + iconUrl + ')'"></span>
      <div v-else-if="iconType === 'erp'" class="erp-icon-size"><i class="erp-icon" :class="icon"/></div>
      <u-icon :name="icon" :type="iconType" :style="iconStyle" v-else-if="icon" />
      <span class="submenu_link-link-text">{{ label }}</span>
    </a>
  </li>
</template>

<script>
export default {
  name: 'NavSubLink',
  inject: ['nav'],
  data () {
    return {
      href: null
    }
  },
  props: {
    iconUrl: {required: false},
    icon: {required: false},
    iconType: {required: false},
    iconStyle: {required: false},
    label: {required: true},
    link: {required: false},
    route: {required: false},
    hasSubmenu: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (this.route) {
      this.href = this.$router.resolve(this.route).href
    } else {
      this.href = this.link
    }
  },
  methods: {
    click ($event) {
      this.$emit('click', $event)
      this.nav.clickEvent($event)
    }
  }
}
</script>
