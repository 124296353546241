<template>
  <div class="sl-menu-helper">{{title}}</div>
</template>

<script>
export default {
  name: "Helper",
  props: ['title'],
  mounted() {

  }
}
</script>
