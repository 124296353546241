export default function (lotes) {
  let window = 'autoArrematacao'
  !this.$uloc.printView.get(window) && this.$uloc.printView.new({
    wid: window,
    title: 'Auto Positivo de Arrematação',
    windowClass: 'erp-print',
    contentClass: '',
    props: {
      lotes: lotes.slice().map(l => l && l.id ? l.id : l)
    }
  }, () => import('@/components/pda/print/views/AutoPositivo'))
    .then((wid) => {
    }) // return wid
}
