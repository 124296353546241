export default [
  {
    path: '/gestao/controle-rgi', component: () => import('./Main'), name: 'rgi.main',
    children: [
      {
        path: '', component: () => import('./Documentos'), name: 'rgi.lista', children: [
        ]
      }
    ]
  }
]
