<template>
  <div class="no-select text-nowrap" v-if="Array.isArray(solicitacao.status)">
    <div class="flex items-center m-b-xs text-nowrap" v-for="(s, i) in solicitacao.status">
      <span class="m-r-xs font-bold">#{{i+1}}</span> <status-solicitacao-selector :status="s.status" />
    </div>
  </div>
  <status-solicitacao-selector class="no-select" v-else :status="solicitacao.status" />
</template>

<script>
import StatusSolicitacaoSelector from './StatusSolicitacaoSelector'
export default {
  name: 'StatusSolicitacao',
  components: {StatusSolicitacaoSelector},
  props: ['solicitacao']
}
</script>

<style scoped>

</style>
