import { render, staticRenderFns } from "./StatusBem.vue?vue&type=template&id=6cfafbdb&scoped=true&"
import script from "./StatusBem.vue?vue&type=script&lang=js&"
export * from "./StatusBem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cfafbdb",
  null
  
)

export default component.exports