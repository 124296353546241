<script>
import TemplateMixin from '../mixin'
import CategoryList from './include/CategoryList'
import {list} from '../../../domain/templates/services'
import {UFakeLoader} from 'uloc-vue'
import windowTemplate from './window/windowTemplate'

export default {
  name: 'Templates',
  mixins: [TemplateMixin],
  components: {CategoryList, UFakeLoader},
  data () {
    return {
      loading: false,
      templates: null,
      categories: null,
      category: null,
      buscaRight: null
    }
  },
  computed: {},
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    load () {
      this.loading = true
      this.category = null
      list(1000, 1, '') // TODO Implementar acima de 1.000 templates
        .then(response => {
          console.log(response.data)
          // this.templates = response.data.result
          this.categories = response.data.categories
          if (response.data.internals && response.data.internals.result) {
            response.data.internals.result.map(template => {
              if (!template.category && !template.category.id) {
                return
              }
              let find = this.categories.find(c => c.id === template.category.id)
              if (find) {
                if (!find.templates) {
                  find.templates = []
                }
                find.templates.push(template)
              }
            })
          }
          let customs = {
            id: 0,
            type: 'docs',
            code: 'custom',
            name: 'Personalizados',
            description: 'Templates criados de forma personalizada'
          }
          if (response.data.customs && response.data.customs.result) {
            customs.templates = response.data.customs.result
          }
          this.categories.push(customs)
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    },
    selectCategory (cat) {
      this.category = cat
    },
    windowTemplate: windowTemplate,
    criar () {
      this.windowTemplate()
    },
    openTemplate (t) {
      this.windowTemplate(t.id, t)
    }
  },
  meta: {
    title: 'Templates',
    name: 'Templates'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap templates-container">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <header class="templates-header">
          <h1 class="templates-header-title">Automação de Templates</h1>
          <h2 class="templates-header-subtitle">
            Configure todos os documentos/templates do software
            <a href="https://help.suporteleiloes.com/templates" target="_blank" title="Ajuda" tabindex="0">Ajuda</a>
          </h2>
        </header>

        <div class="templates-body">
          <div class="tbox box-left">
            <div class="tbox-content">
              <div class="tbox-avatar">
                <u-icon name="file-alt" type="fa" icon-style="light" class="text-primary" />
              </div>
              <!--<a class="tbox-close" tabindex="0">
                <u-icon name="times" type="fa" />
              </a>-->
              <div v-if="loading">
                <div class="col-grow m-t-lg">
                  <div class="inline-block w-full">
                    <u-fake-loader width="60%" class="w-full" />
                    <u-fake-loader width="90%" />
                  </div>
                </div>
                <div class="user-actions">
                  <u-fake-loader width="30px" />
                </div>
              </div>
              <div v-else class="m-t">
                <h1 class="content-title text-center">Selecione uma categoria</h1>
                <category-list :list="categories" @select="selectCategory" />
              </div>
            </div>
          </div>
          <div class="box-connect"></div>
          <div class="tbox box-right">
            <div class="tbox-content" v-if="loading">
              <div class="col-grow m-t-lg">
                <div class="inline-block w-full">
                  <u-fake-loader width="60%" class="w-full" />
                  <u-fake-loader width="90%" />
                </div>
              </div>
              <div class="user-actions">
                <u-fake-loader width="30px" />
              </div>
            </div>
            <div v-else class="tbox-content">
              <a v-if="category" class="tbox-close" tabindex="0" @click="category = null">
                <u-icon name="times" type="fa" />
              </a>
              <h1 class="content-title text-center disabled">Selecione uma ação</h1>
              <p v-if="!category" class="disabled text-center m-t">Para selecionar uma ação, primeiro selecione uma
                category ao lado</p>
              <div v-if="category && category.templates">
                <category-list :list="category.templates" @select="openTemplate" />
              </div>

              <div class="text-center m-t">
                <e-btn @click="criar" label="Criar novo template" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
