<script>
import {UContextMenu} from 'uloc-vue'
import ListMenu from './list-menu-lote'

export default {
  name: 'ContextMenuLoteItem',
  props: ['lote'],
  data () {
    return {
      atualizando: false
    }
  },
  computed: {},
  methods: {
  },
  components: {ListMenu, UContextMenu}
}
</script>

<template>
  <u-context-menu ref="context" class="erp-context-menu">
    <list-menu @abrir="$emit('abrir')" @abrirBem="$emit('abrirBem')" @removerLote="$emit('removerLote')" />
  </u-context-menu>
</template>
