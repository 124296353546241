<template>
  <helper-input-btn class="m-l-n-xs">
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="searchPatio"
        readonly
    >
      <u-popover v-if="this.findedData" hover :hover-delay="500" class="popover-user-profile" anchor="bottom middle"
                 self="top middle" :offset="[10, 10]">
        <div class="wrapper bg-white font-12">
          ...
          <!--<div class="row">
            <div class="col m-r">ID do patio: <strong>{{findedData.id}}</strong></div>
            <div class="col bold">Código: <strong>{{findedData.codigo}}</strong></div>
          </div>
          <div class="row m-t">
            <div class="col m-r">Comitente: <strong>{{findedData.comitente}}</strong></div>
          </div>
          <div class="m-t">
            <div class="m-b">Descrição</div>
            <div><strong>{{findedData.descricao}}</strong></div>
          </div>
          <div class="m-t">
            <e-btn label="Acessar patio" />
          </div>-->
        </div>
      </u-popover>
    </erp-input>
    <div class="erp-icon-float"><a @click="searchPatio"><i class="erp-icon search min"></i> </a></div>
<!--    <u-icon @click.native="patioWindow(null)" name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" />-->
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import searchPatio from '../../../patio/windows/search/SearchPatio'
import patioWindow from '../../../patio/windows/patio'

export default {
  name: 'PatioInput',
  mixins: [InputMixin],
  data () {
    return {
      findedData: this.findedDataObj,
      description: null
    }
  },
  watch: {},
  mounted () {
    console.log(this.findedDataObj)
    if (this.findedDataObj) {
      this.description = this.findedDataObj.id + ' - ' + this.findedDataObj.nome
    }
  },
  components: {HelperInputBtn, UPopover},
  methods: {
    __updateInternal () {
      this.updateInternal((v) => v.id + ' - ' + v.nome)
    },
    searchPatio: searchPatio,
    patioWindow: patioWindow,
    emit (id) {
      this.$emit('data', this.findedData)
      this.__emit(id)
    }
  }
}
</script>
