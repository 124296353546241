export default function (id) {
  let window = id ? `bem.${id}` : 'bem.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Bem' + (id ? ` #${id}` : ''),
    width: '1000',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    maximize: false,
    move: false,
    fullscreen: true,
    disableFixedSize: true,
    windowClass: 'bem-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id,
      processo: this.processo || null,
      router: this.$router
    }
  }, () => import('../components/window/Bem.vue'))
    .then((wid) => {
      console.log(wid)
      setTimeout(() => {
        document.querySelector('.bem-window').classList.add('opened')
      }, 20)
    }) // return wid
}
