<template>
  <div>
    <div class="pda-app-overflow flex column items-center">
      <div class="flex flex-zero__ column text-center h-full w-full">
        <div class="flex flex-zero column text-center h-full w-full">
          <transition name="slide" appear>
            <div class="transition-generic">
              <div class="m-b-sm"><h2><strong>Informe o leilão e digite o número do(s) lote(s) abaixo</strong>, você poderá separá-los
                por vírgula</h2></div>
              <e-row>
                <e-col>
                  <erp-select
                      :loading="loadingLeiloes"
                      placeholder="Leilões ativos"
                      :options="asyncdata"
                      v-model="leilao"
                      class="btn-pda btn-pda-rounded md"
                      multiple
                  ></erp-select>
                </e-col>
                <e-col class="m-l"><erp-input v-model="busca" @keydown.enter="buscar" class="btn-pda btn-pda-rounded md" shortkey="F2" /></e-col>
                <e-col class="m-l" style="max-width: 160px"><pda-menu-item label="Pesquisar" shortcut="ENTER" class="btn-pda-def auto-width" @click.native="buscar" /></e-col>
              </e-row>
              <pda-busca-lote ref="buscador" :busca="busca" :leiloes="leilao" disable-busca-watch busca-multipla />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import AtendimentoLeilao from '@/components/pda/components/apps/AtendimentoLeilao'

export default {
  name: 'PesquisaLote',
  mixins: [AtendimentoLeilao],
  components: {},
  data () {
    return {
      leilao: [],
      disableWatchLeilao: true
    }
  },
  methods: {
    buscar () {
      if (!this.busca || this.busca.length === 0) {
        return
      }
      this.$refs.buscador.search()
    },
  }
}
</script>
