<template>
  <div class="m-t-lg">
    <e-row>
      <e-col><p class="font-bold m-b-xs font-13">Histórico de pagamentos</p></e-col>
    </e-row>
    <div class="erp-list pda-list pda-list-tela-pagamento__">
      <u-table
          ref="table"
          color="primary"
          :data="historico"
          :columns="table.columns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
          hide-bottom
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols"
                :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr @dblclick.native="abrir(props.row)" v-if="!props.row.type || props.row.type !== 'resume'"
              class="cursor-pointer text-uppercase" slot="body"
              slot-scope="props"
              :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td key="data" :props="props">
            {{ props.row.data.date|formatDate }}
          </u-td>
          <u-td :title="props.row.descricao" class="td-limit l-130 uppercase" key="descricao"
                :props="props">
            {{ props.row.observacoes }}
          </u-td>
          <u-td key="pagante" class="uppercase" :props="props">
            {{ props.row.nomePagamento.split(' ').shift() }}/{{ props.row.banco ? props.row.banco.nome : 'Mãos' }} <a
              @click="abrir(props.row)" class=""><i
              class="erp-icon search zoom-40"></i></a>
          </u-td>
          <u-td key="forma" :props="props">
            {{ props.row.metodo ? props.row.metodo.nome : '-' }}
          </u-td>
          <u-td key="status" :props="props">
            <pagamento-status :pagamento="props.row"/>
          </u-td>
          <u-td key="valor" :props="props">
            <span style="color: #3A6D00">R$ {{ props.row.valor|moeda }}</span>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Visualizar" @click="abrir(props.row)" icon="folder-open"/>
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <!--<context-menu-pendencias-item @abrir="abrir(props.row)" :leilao="props.row" />-->
        </u-tr>
        <u-tr v-else class="erp-table-resume-footer" slot="body" slot-scope="props"
              :props="props">
          <u-td></u-td>
          <u-td></u-td>
          <u-td></u-td>
          <u-td></u-td>
          <u-td></u-td>
          <u-td></u-td>
          <u-td key="valor" :props="props">
            <strong style="color: #3A6D00">R$ {{ props.row.valor|moeda }}</strong>
          </u-td>
          <u-td></u-td>
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import MenuOptions from '../../../../layout/context-menu/context-window-options'
import {UCheckbox, UTable, UTd, UTh, UTr} from 'uloc-vue'
import {EBtnTableOptions} from 'uloc-vue-plugin-erp'
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
import {decimal} from "@/domain/utils/money"
import windowLogPagamento from '@/components/leiloes/windows/pagamentoLote'
import PagamentoStatus from "@/components/leiloes/components/include/PagamentoStatus"

export default {
  name: 'TpHistoricoPagamento',
  props: {
    serverData: {required: true}
  },
  data() {
    return {
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10,
          rowsPerPage: 50
        },
        columns: [
          {name: 'data', label: 'Data', field: 'data', align: 'left', sortable: true},
          {
            name: 'descricao',
            required: true,
            label: 'Descrição',
            align: 'left',
            field: 'descricao',
            sortable: true
          },
          {
            name: 'pagante',
            required: true,
            label: 'Pagante',
            align: 'left',
            field: 'pagante',
            sortable: true
          },
          {
            name: 'forma',
            required: true,
            label: 'Forma',
            align: 'left',
            field: 'forma',
            sortable: true
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: true
          },
          {
            name: 'valor',
            required: true,
            label: 'Valor',
            align: 'left',
            field: 'valor',
            sortable: true
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ],
        filter: '',
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.request({
      pagination: this.table.serverPagination,
      filter: this.table.busca
    })
  },
  computed: {
    historico() {
      let pagamentos = []
      let totalPago = 0
      this.serverData.serverData.map((obj) => {
        if (obj.arremate && obj.arremate.pagamentos) {
          obj.arremate.pagamentos.map((pag) => {
            if (pag.pagamentoPrincipal) {
              if (this.pushPagamento(pagamentos, pag.pagamentoPrincipal)) {
                if (pag.pagamentoPrincipal.status === 1) { // PAGO @TODO: Usar Constante de Status de Pagamento
                  totalPago += decimal(pag.pagamentoPrincipal.valor)
                }
              }
            } else {
              this.pushPagamento(pagamentos, pag)
              if (pag.status === 1) { // PAGO @TODO: Usar Constante de Status de Pagamento
                totalPago += decimal(pag.valor)
              }
            }
          })
        }
      })
      pagamentos.push({
        type: 'resume',
        valor: totalPago
      })
      return pagamentos
    }
  },
  components: {
    PagamentoStatus,
    MenuOptionsItem,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions
  },
  methods: {
    request({pagination, filter}) {
      this.table.loading = true

      setTimeout(() => {
        this.table.loading = false
      }, 1000)

      /* pendenciasVenda(this.arrematante.id)
         .then(({data}) => {
           this.table.serverPagination = pagination
           this.table.serverPagination.rowsNumber = data.total
           this.table.serverData = this.filteredrData(data.result)

           this.table.loading = false
         })
         .catch(error => {
           this.loading = false
         }) */
    },
    pushPagamento(arr, p) {
      if (!arr.find(pag => pag.id === p.id)) {
        arr.push(p)
        return true
      }
      return false
    },
    abrir(pagamento) {
      this.windowLogPagamento(pagamento.id || pagamento)
    },
    windowLogPagamento: windowLogPagamento
  }
}
</script>
