<template>
  <div>
    <div class="pda-app-overflow">
      <div>
        <div class="pda-a-header flex row justify-between">
          <arrematante-card :arrematante="arrematante"/>
          <div class="flex items-center">
            <score class="m-r" :arrematante="arrematante"/>
            <pda-arrematante-status :arrematante="arrematante"/>
          </div>
        </div>
        <pendencias :arrematante="arrematante"/>
        <representantes-arrematante :arrematante="arrematante"/>
        <div class="stats m-t-md">
          <stats :arrematante="arrematante" :stats="stats" @showArremates="showArremates = true"/>
        </div>
        <pendencias v-if="showArremates" label="Histórico de arremates" :arrematante="arrematante" class="w-full m-t" mostrar-tudo />
      </div>
    </div>
  </div>
</template>

<script>
import appList from './app'
import ArrematanteCard from './arrematante/Card'
import Score from './arrematante/Score'
import PdaArrematanteStatus from './arrematante/Status'
import Pendencias from './arrematante/Pendencias'
import RepresentantesArrematante from './arrematante/Representantes'
import Stats from './arrematante/Stats'
import arrematanteWindow from '@/components/arrematante/windows/arrematante'
import {resumoFinanceiro} from '@/domain/arrematante/services'

export default {
  name: 'AppArrematante',
  components: {Stats, RepresentantesArrematante, Pendencias, PdaArrematanteStatus, Score, ArrematanteCard},
  props: {
    parseProps: {required: false}
  },
  data() {
    return {
      stats: null,
      showArremates: false
    }
  },
  computed: {
    arrematante() {
      return this.parseProps.arrematante
    }
  },
  created() {
    this.$parent.$on('visualizarCadastroArrematante', this.abrir)
    this.$parent.$on('incluirRepresentante', this.incluirRepresentante)
  },
  beforeDestroy() {
    this.$parent.$off('visualizarCadastroArrematante', this.abrir)
    this.$parent.$off('incluirRepresentante', this.incluirRepresentante)
  },
  mounted() {
    resumoFinanceiro(this.arrematante.id)
        .then(({data}) => {
          console.log(data)
          this.stats = data
        })
        .catch(error => {
          this.alertApiError(error)
        })
    this.$parent.labelTarget = [
      {label: 'Atendimento'},
      {label: 'Arrematante'},
      {label: this.parseProps.arrematante.pessoa.name.split(' ').shift(), bold: true}
    ]
    this.$parent.return = () => {
      this.$parent.app = appList.atendimentoArrematante
    }
    this.$parent.menuView = ['ipl', 'vca', 'ira', /*'fco',*/ 'rtn']
  },
  methods: {
    arrematanteWindow: arrematanteWindow,
    abrir() {
      console.log('Abrindo cadastro do arrematante...')
      this.arrematanteWindow(this.arrematante.id)
    },
    incluirRepresentante() {
      console.log('Abrindo cadastro de representante...')
    }
  }
}
</script>
