import http from '../../../utils/services/http'

export const list = (bem, limit = 100, page = 1, filtros = '') => {
    let url = `/api/bem/${bem}/historicoProcessos?page=` + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (bem, id) => {
    let url = `/api/bem/${bem}/historicoProcessos/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newProcesso = (bem, data) => {
    let url = `/api/bem/${bem}/historicoProcessos`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateProcesso = (bem, processo, method = 'PUT') => {
    let url = `/api/bem/${bem}/historicoProcessos/${processo.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, processo)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteProcesso = (bem, id) => {
    let url = `/api/bem/${bem}/historicoProcessos/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
