import http from '../../../utils/services/http';

export const findTiposEmail = (id) => {
  let url = `/api/tiposEmail/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposEmail = (limit, page, filtros) => {
  let url = '/api/tiposEmail?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoEmail = (data) => {
  let url = `/api/tiposEmail`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoEmail = (id, data) => {
  let url = `/api/tiposEmail/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoEmail = (id) => {
  let url = `/api/tiposEmail/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
