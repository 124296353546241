<template>
  <div>
    <h2 class="diviser two-lines">
      Extra
      <span>Informações complementares para o cadastro</span>
    </h2>

    <div class="m-t-lg">
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="URL Google Maps:"
              label-width="110px"
          >
            <erp-input v-model="bem.localizacaoUrlGoogleMaps"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <bem-campo-secao :full-width="false" css="erp-row-view simple col-limit-4 v3 view-wrap" secao="extra"/>
      </e-row>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import SlCheckbox from "@/reuse/input/Checkbox"
import BemCampoSecao from "@/components/bem/components/include/CamposSecao"

export default {
  name: "BemExtra",
  components: {
    BemCampoSecao
    // SlCheckbox
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  computed: {},
  methods: {
    extrairUrlDoMapa(codigo) {
      const regex = /src="([^"]+)"/;
      const resultado = regex.exec(codigo);
      if (resultado && resultado.length > 1) {
        return resultado[1];
      } else {
        return null;
      }
    },
    tratarLocalizacaoMapEmbed() {
      const urlTratada = this.extrairUrlDoMapa(this.bem.localizacaoMapEmbed)
      if (urlTratada) {
        this.bem.localizacaoMapEmbed = urlTratada
      }
    }
  }
}
</script>
