<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Tipo de bem'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select v-if="tree" @input="__set" v-model="model" :placeholder="placeholder || 'Selecione'" :options="tiposFiltered"
                :loading="loading" size="2">
      <template v-slot:itemLabel="props">
                <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}"
                      :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{props.opt.value}} - {{props.opt.label}}</span>
      </template>
    </erp-select>
    <erp-select
        v-else
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdataFiltered"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {listTiposBem} from '@/domain/bem/services'
import {listTiposBem as listTiposBem2} from '@/domain/cadastro/services'
import {treeCheck} from "components/cadastro/components/tiposbem/helpers/treeCheck"

export default {
  name: 'TipoBemSelect',
  mixins: [SelectMixin],
  props: {
    listAll: {
      type: Boolean,
      default: false
    },
    tree: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {
    asyncdataFiltered () {
      return this.listAll ? this.asyncdata : this.asyncdata.filter(item => !item.parent)
    },
    tiposFiltered () {
      return this.treeCheck(this.asyncdata)
    }
  },
  watch: {
    model (v) {
      this.$nextTick(() => {
        if (!this.model) {
          return
        }
        console.log(v, this.model)
        let selected = this.asyncdata.filter(item => item.id === this.model)
        if (!selected) {
          console.log('No selected')
          return
        }
        if (this.tree) {
          this.$emit('input', this.model)
        }
        this.$emit('selected', selected[0])
      })
    }
  },
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      this.loading = true
      let method = listTiposBem
      if (this.tree) {
        method = () => listTiposBem2(1000, 1, null, true)
      }
      method()
        .then((response) => {
          this.loading = false
          if (this.tree) {
            this.asyncdata = response.data
            return
            // @TODO: multiple?
          }
          if (!Array.isArray(response.data.result)) {
            return
          }
          this.$emit('load', response.data.result)
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: this.tree && obj.parent ? '- ' + obj.id + ' - ' + obj.nome : obj.nome,
              value: obj.id,
              disable: this.tree && !obj.parent
            }
          })

          !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
          this.asyncdata = list
        })
        .catch((error) => {
          this.loading = false

        })
    },
    treeCheck: treeCheck
  }
}
</script>
