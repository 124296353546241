<template>
  <li v-if="!loading" class="user-list-item" :class="{'faded': !user.active}">
    <div class="user-info col-grow">
      <img class="user-img" v-if="user.image" :src="user.image" />
      <div v-else class="user-img">{{initials(personName)}}</div>
      <div class="inline-block">
        <a class="user-name font-bold">{{personName}}</a> <span v-if="me" class="text-orange-6 font-bold">Este é você!</span>
        <div class="font-12 m-t-xxs">{{user.email}}</div>
      </div>
    </div>
    <div class="user-actions">
      <div class="inline-block m-r">
        <span class="text-black font-bold" v-if="isAdmin && user.active">Admin</span>
        <span class="text-negative font-bold" v-if="!user.active">Inativo</span>
      </div>
      <a class="action-link">
        <u-icon name="eye" type="fa" />
      </a>
    </div>
  </li>
  <li v-else class="user-list-item">
    <div class="user-info col-grow">
      <div class="inline-block w-full">
        <u-fake-loader width="40%" class="w-full" />
        <u-fake-loader />
      </div>
    </div>
    <div class="user-actions">
      <u-fake-loader width="30px" />
    </div>
  </li>
</template>

<script>
import {UFakeLoader} from 'uloc-vue'

export default {
  name: 'ListUser',
  props: {
    user: Object,
    me: Boolean,
    loading: Boolean
  },
  components: {UFakeLoader},
  computed: {
    personName () {
      if(this.user.person) {
        return this.user.person.name
      }
      return this.user.username
    },
    isAdmin () {
      if(this.user.roles) {
        return this.user.roles.includes('ROLE_ADMIN')
      }
      return false
    }
  },
  methods: {
    initials (name) {
      name = name.split(' ')
      name = name.map(n => {
        return n.charAt(0).toUpperCase()
      })
      return name.slice(0, 2).join('')
    }
  }
}
</script>
