<script>
import bemWindow from '../windows/bem'
import BemWindow from "@/components/bem/components/window/Bem"

export default {
  name: "OpenBem",
  components: {BemWindow},
  created() {
    this.bemWindow(this.$route.params.id)
  },
  methods: {
    // bemWindow: bemWindow
  }
}
</script>

<template>
  <bem-window style="max-width: 1400px; margin: auto" :id="$route.params.id" />
</template>