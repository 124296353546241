import http from '../../../utils/services/http'

export const list = (limit, page, filtros) => {
  let url = '/api/processos?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
    let url = `/api/processos/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteProcesso = (id) => {
    let url = `/api/processos/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findByNumero = (numero) => {
    let url = `/api/processos/findByNumero?numero=${numero}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const getInformacoes = (numero) => {
  let url = `/api/processos/get-informacoes?numero=${numero}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newProcesso = data => {
  let url = '/api/processos'
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateProcesso = (id, data, type = 'PUT') => {
  let url = `/api/processos/${id}`
    if (type === 'PUT') {
        type = http.put
    } else {
        type = http.patch
    }
  return type(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const adicionaJuizAoProcesso = (idProcesso, idJuiz, remover = false) => {
  let url = `/api/processos/${idProcesso}/adicionar-juiz`
  return http.post(url, {juiz: idJuiz, remover})
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const adicionaEscrivaoAoProcesso = (idProcesso, idEscrivao, remover = false) => {
  let url = `/api/processos/${idProcesso}/adicionar-escrivao`
  return http.post(url, {escrivao: idEscrivao, remover})
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const adicionaBemAoProcesso = (idProcesso, idBem, remover = false) => {
  let url = `/api/processos/${idProcesso}/adicionar-bem`
  return http.post(url, {bem: idBem, remover})
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const adicionaReuAoProcesso = (idProcesso, idReu, remover = false) => {
  let url = `/api/processos/${idProcesso}/adicionar-reu`
  return http.post(url, {reu: idReu, remover})
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const adicionaAutorAoProcesso = (idProcesso, idAutor, remover = false) => {
  let url = `/api/processos/${idProcesso}/adicionar-autor`
  return http.post(url, {autor: idAutor, remover})
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const adicionaAdvogadoAoProcesso = (idProcesso, idAdvogado, tipo, remover = false) => {
  let url = `/api/processos/${idProcesso}/adicionar-advogado`
  return http.post(url, {advogado: idAdvogado, tipo, remover})
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updatePessoaProcesso = (processo, person) => {
    let url = `/api/processos/${processo.id}/pessoaRelacionada`
    return http.post(url, person)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updatePartesProcesso = (processo) => {
    let url = `/api/processos/${processo.id}/partes`
    return http.post(url, processo)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateContatoProcesso = (processo) => {
    let url = `/api/processos/${processo.id}/contatos`
    return http.post(url, processo)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const uploadArquivoProcesso = (id, data) => {
    let url = `/api/processos/${id}/arquivos`
    return http.post(url, data, {
        'timeout': 300000
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadArquivoProcesso = (processo, arquivo, inline = false) => {
    let url = `/api/processos/${processo.id}/arquivos/${arquivo}/download`
    if (inline) {
        url = url + '?inline=true'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteArquivo = (processo, arquivoId) => {
    let url = `/api/processos/${processo.id}/arquivos/${arquivoId}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const criarNota = (processo, data) => {
    let url = `/api/processos/${processo.id}/nota`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const showNota = (processo, notaId) => {
    let url = `/api/processos/${processo.id}/notas/${notaId}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const historico = (processo, tipo = null) => {
    let url = `/api/processos/${processo.id}/historico`
    if (tipo) {
        url = url + '?tipo=' + tipo
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const showNotaLogByReferId = (processo, referId) => {
    let url = `/api/processos/${processo.id}/notas/show-by-refer/${referId}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listMovimentacoes = (limit, page, filtros) => {
    let url = `/api/processos/movimentacoes?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const showMovimentacao = (id) => {
    let url = `/api/processos/movimentacoes/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteNota = (processo, notaId) => {
    let url = `/api/processos/${processo.id}/notas/${notaId}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listTiposParte = (limit, page, filtros) => {
    let url = `/api/processos/tipos-parte?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
