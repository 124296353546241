import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'bem.laudos.list'
export const listStorage = tableColumnsCache(listName, [
    {label: 'Origem', name: 'origem', active: true, sortable: false, ordering: 1},
    {label: 'Data do Laudo', name: 'data', active: true, sortable: false, ordering: 2},
    {label: 'Formato', name: 'formato', active: true, sortable: false, ordering: 3},
    {label: 'Observações', name: 'observacoes', active: true, sortable: false, ordering: 4},
], listVersion)
