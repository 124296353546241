export default [
  {
    path: '/marketing',
    component: () => import('./Main'), name: 'marketing',
    children: [
      {
        path: 'campanhas',
        name: 'marketing.main',
        component: () => import('./Campanhas')
      },
      {
        path: 'campanhas/criar',
        name: 'marketing.criar-campanha',
        component: () => import('./CriarCampanha')
      },
      {
        path: 'campanhas/:id/criar/passoapasso/:canal/:action',
        name: 'marketing.criar-campanha.canal',
        component: () => import('./CriarCampanha2')
      },
      {
        path: 'campanhas/:id',
        name: 'marketing.campanha',
        component: () => import('./Campanha')
      },
      {
        path: 'gerador',
        name: 'marketing.gerador',
        component: () => import('./GeradorConteudo')
      },
    ]
  }
]
