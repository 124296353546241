<script>
/* eslint-disable */
import DefaultMixin from '../mixin'
import BtnStatusLeilao from './context/BtnStatusLeilao'
import LeilaoInfo from './include/pages/LeilaoInfo'
import BtnImportacaoLeilao from './context/BtnImportacaoLeilao'
import BtnAcoesLeilao from './context/BtnAcoesLeilao'
import BtnLoteamentoLeilao from './context/BtnLoteamentoLeilao'
import ListaLotes from './include/ListaLotes'
import ComitentesParticipantes from './include/pages/ComitentesParticipantes'
import {atualizaStatusLeilao, atualizaStatusInternoLeilao} from '@/domain/leiloes/services'
import * as StatusLeilao from '../../../domain/leiloes/helpers/LeilaoStatus'
import DocumentosLeilao from './include/DocumentosLeilao'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import MenuSuperior from "@/components/leiloes/components/menu/MenuSuperior"

export default {
  name: 'Leilao',
  inject: ['page'],
  provide: function () {
    return {
      leilaoPage: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    MenuSuperior,
    DocumentosLeilao,
    ComitentesParticipantes,
    ListaLotes,
    BtnLoteamentoLeilao,
    BtnAcoesLeilao,
    BtnImportacaoLeilao,
    LeilaoInfo,
    BtnStatusLeilao,
    ERow,
    ECol
  },
  data () {
    return {
      leilao: this.page.leilao,
      loadingUpdateStatus: false,
      loadingUpdateStatusInterno: false
    }
  },
  computed: {
    StatusLeilao () {
      return StatusLeilao
    }
  },
  mounted () {
    // console.log('--------')
    // console.log('--------')
    // console.log('Leilao: ', this,leilao)
    this.erplayout && this.erplayout.erpheader.setMenuModuleToggle(true)
    this.page.setActive('hom')
    if (this.page && this.page.leilao) {
      this.$options.meta.title = `Leilão ${this.page.leilao.codigo}`
    }
  },
  destroyed () {
  },
  methods: {
    updateStatus (s) {
      console.log('Update status fom ', this.leilao.status, ' to ', s)
      this.loadingUpdateStatus = true
      atualizaStatusLeilao(this.leilao.id, s)
        .then((response) => {
          this.loadingUpdateStatus = false
          this.leilao.status = s
        })
        .catch(error => {
          this.loadingUpdateStatus = false
          this.alertApiError(error)
          console.log(error, error.data)
        })
    },
    updateStatusInterno (s) {
      console.log('Update status fom ', this.leilao.statusInterno, ' to ', s)
      this.loadingUpdateStatusInterno = true
      atualizaStatusInternoLeilao(this.leilao.id, s)
        .then((response) => {
          this.loadingUpdateStatusInterno = false
          this.leilao.statusInterno = s
        })
        .catch(error => {
          this.loadingUpdateStatusInterno = false
          this.alertApiError(error)
          console.log(error, error.data)
        })
    },
    loteamentoAutomatico (ordering) {
      this.$refs.lotes.loteamentoAutomatico(ordering)
    },
    updateLeilaoEvent () {
      this.page.load()
    },
    adicionarSublotes (lote) {
      this.$refs.btnLoteamento.criacaoRapidaBens(this.leilao, null, lote)
    }/*,
    teste ($event) {
      console.log(this.stats)
      this.stats.SLEventClient(this.stats.SL_EVENT_TYPES.CLICK, $event, 'leilao', 1, 'erp.localhost')
    }*/
  },
  meta: {
    title: 'Leilão',
    name: 'Leilão'
  }
}
</script>

<template>
  <div>
    <menu-superior />
    <div class="wrapper">
      <div class="row">
        <div class="col flex">
          <btn-status-leilao style="max-width: 160px" @updateStatus="updateStatus" :loading="loadingUpdateStatus"
                             :leilao="leilao" />
          <e-btn class="text-left leilao-status bg-purple text-white m-l-sm no-border no-select" style="cursor: default" align="left" label="Leilão como Venda Direta" v-if="leilao.vendaDireta" />
        </div>
        <div class="col text-right">
          <div>
            <btn-acoes-leilao
                              :leilao="leilao"
                              @alteraStatusInterno="updateStatusInterno"
                              @prepararLeilao="updateStatusInterno(StatusLeilao.STATUS_INTERNO_PREPARADO)"
                              @updateLeilao="updateLeilaoEvent"
            /> <!-- v-if="cracl(null, ['sl/leilao/update', 'sl/leilao/alterar-status-interno'])" -->
            <btn-loteamento-leilao
                ref="btnLoteamento"
                @loteamentoManual="() => $refs.lotes.loteamentoManual()"
                @lotearAutomatico="loteamentoAutomatico"
                @loteAdicionado="$refs.lotes.load()"
                @updateLotes="$refs.lotes.load()"
                class="m-l-xs m-r-xs"
                :leilao="leilao" /> <!-- v-if="cracl(null, ['sl/leilao/add-lotes'])" -->
            <btn-importacao-leilao
                :leilao="leilao" /> <!-- v-if="cracl(null, ['sl/leilao/update', 'sl/leilao/importacao'])" -->
          </div>

  <!--        <div class="m-t-lg text-right font-11">
            <div>ID: <strong>#{{leilao.id}}</strong></div>
            <div class="m-t-xs">Código: <strong>{{leilao.numero}}/{{leilao.ano}}</strong></div>
            <div class="m-t-xs">
              Cadastrado no sistema
              <strong>{{leilao.createdAt.date|formatDate('dd/MM/yyyyHH:mm')}}</strong>
            </div>
            <div class="m-t-xs" v-if="leilao.leiloeiro">Leiloeiro: <strong>{{leilao.leiloeiro.nome}}</strong></div>
            <div class="m-t-xs" v-if="leilao.patio">Pátio: <strong>{{leilao.patio.nome}}</strong> <a>
              <u-icon name="map-marker-alt" type="fa" class="text-blue m-t-n-xxs"></u-icon>
            </a></div>
          </div>-->
        </div>
      </div>
      <div class="row">
        <documentos-leilao ref="docs" :leilao="leilao" />
      </div>
      <div class="leilao-box leilao-page-info m-t">
        <leilao-info />
      </div>
      <lista-lotes ref="lotes" @loaded="(lotes) => leilao.lotes = lotes" class="m-t" :leilao="leilao" @adicionarSublotes="adicionarSublotes" />

      <comitentes-participantes :leilao="leilao" />
    </div>
  </div>
</template>
