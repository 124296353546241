import Mark from "./EditorComponents/Mark"
import EditorImage from "./EditorComponents/Image"
import Vue from 'vue'

export function getSelectionCoords(win) {
    win = win || window;
    var doc = win.document;
    var sel = doc.selection, range, rects, rect;
    var x = 0, y = 0;
    if (sel) {
        if (sel.type != "Control") {
            range = sel.createRange();
            range.collapse(true);
            // console.log('RANGE: ', range)
            x = range.boundingLeft;
            y = range.boundingTop;
        }
    } else if (win.getSelection) {
        sel = win.getSelection();
        if (sel.rangeCount) {
            range = sel.getRangeAt(0).cloneRange();
            if (range.getClientRects) {
                range.collapse(true);
                rects = range.getClientRects();
                if (rects.length > 0) {
                    rect = rects[0];
                }
                x = rect.left; // @TODO: <URGENT> Causa erro porém se corrigir faz o keydown listen trabalhar de forma errada
                y = rect.top;
            }
            // Fall back to inserting a temporary element
            if (x == 0 && y == 0) {
                var span = doc.createElement("span");
                if (span.getClientRects) {
                    // Ensure span has dimensions and position by
                    // adding a zero-width space character
                    span.appendChild(doc.createTextNode("\u200b"));
                    range.insertNode(span);
                    rect = span.getClientRects()[0];
                    x = rect.left;
                    y = rect.top;
                    var spanParent = span.parentNode;
                    spanParent.removeChild(span);

                    // Glue any broken text nodes back together
                    spanParent.normalize();
                }
            }
        }
    }
    return {x: x, y: y};
}

function placeCaretAfterNode(node) {
    if (typeof window.getSelection != "undefined") {
        var range = document.createRange();
        range.setStartAfter(node);
        range.collapse(true);
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }
}

function getBlockElement(el, parent) {
    if (parent && el === parent) {
        return null
    }

    const
        style = window.getComputedStyle
            ? window.getComputedStyle(el)
            : el.currentStyle,
        display = style.display

    if (display === 'block' || display === 'table') {
        return el
    }

    return getBlockElement(el.parentNode)
}

function isChildOf(el, parent) {
    if (!el) {
        return false
    }
    while ((el = el.parentNode)) {
        if (el === document.body) {
            return false
        }
        if (el === parent) {
            return true
        }
    }
    return false
}

const urlRegex = /^https?:\/\//

export class EditorCommands {
    constructor(el, vm) {
        this.el = el
        this.vm = vm
        this.keydownListeners = []
    }

    get selection() {
        if (!this.el) {
            return
        }
        const sel = document.getSelection()
        // only when the selection in element
        if (isChildOf(sel.anchorNode, this.el) && isChildOf(sel.focusNode, this.el)) {
            return sel
        }
    }

    get hasSelection() {
        return this.selection
            ? this.selection.toString().length > 0
            : null
    }

    get range() {
        const sel = this.selection

        if (!sel) {
            return
        }

        return sel.rangeCount
            ? sel.getRangeAt(0)
            : null
    }

    get parent() {
        const range = this.range
        if (!range) {
            return
        }

        const node = range.startContainer
        return node.nodeType === document.ELEMENT_NODE
            ? node
            : node.parentNode
    }

    get blockParent() {
        const parent = this.parent
        if (!parent) {
            return
        }
        return getBlockElement(parent, this.el)
    }

    save(range = this.range) {
        this._range = range
    }

    restore(range = this._range) {
        const
            r = document.createRange(),
            sel = document.getSelection()

        if (range) {
            r.setStart(range.startContainer, range.startOffset)
            r.setEnd(range.endContainer, range.endOffset)
            sel.removeAllRanges()
            sel.addRange(r)
        } else {
            sel.selectAllChildren(this.el)
            sel.collapseToEnd()
        }
    }

    hasParent(name, spanLevel) {
        const el = spanLevel
            ? this.parent
            : this.blockParent

        return el
            ? el.nodeName.toLowerCase() === name.toLowerCase()
            : false
    }

    hasParents(list) {
        const el = this.parent
        return el
            ? list.includes(el.nodeName.toLowerCase())
            : false
    }

    is(cmd, param) {
        switch (cmd) {
            case 'formatBlock':
                if (param === 'DIV' && this.parent === this.el) {
                    return true
                }
                return this.hasParent(param, param === 'PRE')
            case 'link':
                return this.hasParent('A', true)
            case 'fontSize':
                return document.queryCommandValue(cmd) === param
            case 'fontName':
                const res = document.queryCommandValue(cmd)
                return res === `"${param}"` || res === param
            case 'fullscreen':
                return this.vm.inFullscreen
            case void 0:
                return false
            default:
                const state = document.queryCommandState(cmd)
                return param ? state === param : state
        }
    }

    getParentAttribute(attrib) {
        if (this.parent) {
            return this.parent.getAttribute(attrib)
        }
    }

    can(name) {
        if (name === 'outdent') {
            return this.hasParents(['blockquote', 'li'])
        }
        if (name === 'indent') {
            const parentName = this.parent ? this.parent.nodeName.toLowerCase() : false
            if (parentName === 'blockquote') {
                return false
            }
            if (parentName === 'li') {
                const previousEl = this.parent.previousSibling
                return previousEl && previousEl.nodeName.toLowerCase() === 'li'
            }
            return false
        }
    }

    apply(cmd, param, done = () => {
    }, evt, keymapping) {
        if (cmd === 'source') {
            if (this.vm.source && !this.vm.onlySource) {
                this.vm.source = false
                this.vm.$nextTick(() => {
                    // console.log(this.vm.value)
                    this.vm.$refs.content.innerHTML = this.vm.value
                    setTimeout(() => {
                        //this.vm.$forceUpdate()
                    }, 10)
                })
            } else {
                if (this.vm.onlySource) {
                    if (!this.vm.source) {
                        this.vm.source = true
                    }
                }
                this.vm.$emit('input', this.vm.$refs.content.innerHTML)
                setTimeout(() => {
                    this.vm.source = true
                }, 10)
            }
            return
        } else if (cmd === 'formatBlock') {
            if (['BLOCKQUOTE', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'PRE'].includes(param) && this.is(cmd, param)) {
                cmd = 'outdent'
                param = null
            }
        } else if (cmd === 'print') {
            done()
            const win = window.open()
            win.document.write(`
        <!doctype html>
        <html>
          <head>
            <title>Print - ${document.title}</title>
          </head>
          <body>
            <div>${this.el.innerHTML}</div>
          </body>
        </html>
      `)
            win.print()
            win.close()
            return
        } else if (cmd === 'link') {
            const link = this.getParentAttribute('href')
            if (!link) {
                const selection = this.selectWord(this.selection)
                const url = selection ? selection.toString() : ''
                if (!url.length) {
                    return
                }
                this.vm.editLinkUrl = urlRegex.test(url) ? url : `https://${url}`
                document.execCommand('createLink', false, this.vm.editLinkUrl)
            } else {
                this.vm.editLinkUrl = link
            }
            this.range.selectNodeContents(this.parent)
            this.save()
            return
        } else if (cmd === 'ul') {
            document.execCommand('insertUnorderedList', false)
            done()
            return
        } else if (cmd === 'ol') {
            document.execCommand('insertOrderedList', false)
            done()
            return
        } else if (cmd === 'fullscreen') {
            this.vm.toggleFullscreen()
            done()
            return
        } else if (cmd === 'mark') {
            if (this.vm.disableMark) return
            // @TODO: <URGENTE> Adicionar link à pessoa na marcação. Cuidado para evitar js injection
            if (keymapping === false) {
                this.vm.focus()
                let sel = window.getSelection();
                let range = sel.getRangeAt(0)
                range.collapse(true)
                let cursorRange = document.createRange()
                let emptyElement = document.createTextNode(' @')
                range.insertNode(emptyElement)
                // cursorRange.setStartAfter(emptyElement)
                // sel.removeAllRanges()
                sel.modify('move', 'forward', 'character')
                sel.addRange(cursorRange)
                this.vm.focus()
                return
            }
            /**
             * @TODO: Atualmente quando digitado somente @ o popover abre na posição correta, mas após digitar @tiago,
             * apertar espaço e depois retornar o cursor, o popover abre fora da posição desejada
             */
            let lastWord = this.getLastWord()
            if (this.markListener || lastWord.charAt(0) !== '@') { // @TODO: Usar o keyCode da vm
                done()
                return
            }
            let disableMark = () => {
                this.markListener = false
                // console.log('INDEX', this.keydownListeners.indexOf(this.markListenerEvent))
                this.keydownListeners.splice(this.keydownListeners.indexOf(this.markListenerEvent), 1)
                this.mark.$destroy()
                this.mark = null
            }

            this.mark = new Vue(Mark)
            this.mark.$on('selected', (user) => {
                // console.log('Selected user: ', user)
                this.vm.focus()
                const sel = this.selectWord(this.selection)
                document.execCommand('delete', false)
                let range = sel.getRangeAt(0)
                let data = String(range.startContainer.data)
                if (data.charAt(data.length - 1) === '@') {
                    sel.modify('extend', 'left', 'character')
                    document.execCommand('delete', false)
                }
                range = sel.getRangeAt(0)
                range.collapse(true)
                let node = document.createElement("span")
                node.classList.add('sl-editor-mark')
                node.contentEditable = false
                node.dataset.personMarkId = user.person.id
                node.appendChild(document.createTextNode('@' + user.label))
                range.insertNode(node)

                sel.modify('move', 'forward', 'character')

                range = sel.getRangeAt(0)

                let cursorRange = document.createRange()
                let emptyElement = document.createTextNode('  ')
                range.insertNode(emptyElement)
                cursorRange.setStartAfter(emptyElement)
                sel.removeAllRanges()
                sel.addRange(cursorRange)
                this.vm.focus()
                setTimeout(() => {
                    this.vm.$forceUpdate()
                    // this.vm.$emit('input', this.el.innerHTML)
                }, 50)
                disableMark()
            })
            // this.mark.$mount()
            document.body.appendChild(this.mark.$mount().$el)
            this.markListener = true
            this.markListenerEvent = (e, val) => {
                setTimeout(() => {
                    if (e.key === " " || e.keyCode === 32 || ((e.keyCode === 8 || e.key === 'backspace') && this.getLastLetter().trim().length === 0)) {
                        disableMark()
                    } else {
                        const lastWord = this.getLastWord()
                        this.mark && this.mark.setVal(lastWord)
                    }
                }, 20)
            }
            this.keydownListeners.push(this.markListenerEvent)
            setTimeout(() => {
                this.mark.show(evt)
                setTimeout(() => {
                    const lastWord = this.getLastWord()
                    this.mark && this.mark.setVal(lastWord)
                    done()
                }, 10)
            }, 10)
            return
        } else if (cmd === 'image') {
            let destroy = () => {
                if (this.imageUpload) {
                    this.imageUpload.$destroy()
                    this.imageUpload = null
                }
            }
            // console.log('IMAGE Command')
            this.imageUpload = new Vue(EditorImage)
            this.imageUpload.$on('uploaded', (file) => {
                let url = file.url
                this.vm.focus()
                // document.execCommand('insertImage', false, url)
                let sel = window.getSelection();
                let range = sel.getRangeAt(0)
                range.collapse(true)
                let node = document.createElement("div")
                node.contentEditable = false
                let img = document.createElement("img")
                img.src = url
                node.appendChild(img)
                node.classList.add('sl-editor-image-attach')
                range.insertNode(node)

                sel.modify('move', 'forward', 'character')
                range = sel.getRangeAt(0)
                let cursorRange = document.createRange()
                let emptyElement = document.createTextNode('  ')
                range.insertNode(emptyElement)
                cursorRange.setStartAfter(emptyElement)
                sel.removeAllRanges()
                sel.addRange(cursorRange)
                this.vm.focus()
                destroy()
            })
            this.imageUpload.$on('error', () => {
                destroy()
            })
            document.body.appendChild(this.imageUpload.$mount().$el)
            return
        }

        document.execCommand(cmd, false, param)
        done()
    }

    selectWord(sel) {
        if (!sel.isCollapsed) {
            return sel
        }

        // Detect if selection is backwards
        const range = document.createRange()
        range.setStart(sel.anchorNode, sel.anchorOffset)
        range.setEnd(sel.focusNode, sel.focusOffset)
        const direction = range.collapsed ? ['backward', 'forward'] : ['forward', 'backward']
        range.detach()

        // modify() works on the focus of the selection
        const
            endNode = sel.focusNode,
            endOffset = sel.focusOffset
        sel.collapse(sel.anchorNode, sel.anchorOffset)
        sel.modify('move', direction[0], 'character')
        sel.modify('move', direction[1], 'word')
        sel.extend(endNode, endOffset)
        sel.modify('extend', direction[1], 'character')
        sel.modify('extend', direction[0], 'word')

        return sel
    }

    getLastLetter() {
        const selection = window.getSelection()
        if (selection.rangeCount !== 0) {
            let val = selection.anchorNode.textContent.substr(selection.anchorOffset - 1, 1)
            val = String(val)
                .replace(/&nbsp;/g, " ")
            return val
        }
        return null
    }

    /**
     * Retorna a última palavra à esquerda do cursor (usa o espaço como limitador)
     * @returns {string|null}
     */
    getLastWord() {
        const selection = window.getSelection()
        if (selection.rangeCount !== 0) {
            // const range = document.createRange()
            let text = selection.anchorNode.textContent
            text = String(text).trim()
                .replace(/&nbsp;/g, " ")
            text = text.substring(0, selection.anchorOffset)
            if (text.indexOf(" ") > 0) {
                var words = text.split(" ");
                return words[words.length - 1]; //return last word
            } else {
                return text;
            }
        }
        return null
    }

    keydownEvent(e, val, keys, cb) {
        val = val
            .replace(/&nbsp;/g, " ")
        // // console.log('Keydown event listeners: ', this.keydownListeners, val)
        this.keydownListeners && this.keydownListeners.map(listener => {
            listener(e, val)
        })

        setTimeout(() => {
            const text = this.getLastLetter()
            if (text !== null && text !== '' && text !== ' ') {
                const lastWord = this.getLastWord()
                if (lastWord.charAt(lastWord.length - 1).trim().length === 0) {
                    return
                }
                const target = Object.values(keys).find(k => {
                    return k.keyCode && k.keyCode === lastWord.charAt(0)
                })
                if (target !== void 0) {
                    // console.log('Target key code finded', target)
                    const {x, y} = getSelectionCoords()
                    e.clientX = x - 12
                    e.clientY = y + 20
                    cb(target.cmd, target.param, true, e, true)
                }
            }
        }, 12)
    }

    addTextToCursor (text) {
        this.vm.focus()
        let sel = window.getSelection();
        let range = sel.getRangeAt(0)
        range.collapse(true)
        let cursorRange = document.createRange()
        let emptyElement = document.createTextNode(text)
        range.insertNode(emptyElement)
        sel.modify('move', 'forward', 'character')
        sel.addRange(cursorRange)
        this.vm.focus()
        setTimeout(() => {
            this.vm.$forceUpdate()
            // this.vm.$emit('input', this.el.innerHTML)
        }, 50)
    }
}
