<template>
  <div class="leilao-tempo-stats" :class="{'full-component': full}">
    <div class="title" v-if="!full">PROGRESSO DO LEILÃO</div>
    <div class="dados">
      <div class="coluna">
        <div class="conclusao">
          <div class="titulo">Conclusão</div>
          <div class="value">
            <div class="bar">
              <u-knob v-model="porcentagemConclusaoLeilao" readonly
                      color="green"
                      line-width="6px"
                      track-color="black">{{ Math.floor(porcentagemConclusaoLeilao) }}%
              </u-knob>
            </div>
            <div class="legend">Lote <strong>{{ controlador.lote.numero }}</strong>/{{ leilao.totalLotes }}</div>
          </div>
        </div>
      </div>
      <div class="coluna">
        <div class="previsao">
          <div class="titulo">Previsão</div>
          <div class="value">
            <div class="hora" v-if="controlador.estatisticas && controlador.estatisticas.tempoPorLote && controlador.estatisticas && controlador.estatisticas.previsao && controlador.estatisticas.previsao.date">
              {{ $options.filters.formatDate(controlador.estatisticas.previsao.date, 'HH:mm') }}</div>
            <div class="hora font-16" v-else>Calculando...</div>
            <div class="legend" v-if="controlador.estatisticas && controlador.estatisticas.tempoPorLote">{{ controlador.estatisticas.tempoPorLote }}m por lote</div>
          </div>
        </div>
        <div class="online" v-if="!full">
          <div class="titulo">Pessoas online</div>
          <div class="value">
            {{ controlador.estatisticas ? controlador.estatisticas.pessoasOnline : '...' }}
          </div>
          <div class="legend">&nbsp;</div>
        </div>

        <div class="previsao" v-if="full">
          <div class="titulo">Tempo de resposta</div>
          <div class="value">
            27ms
          </div>
          <div class="legend">Tempo médio</div>
        </div>
      </div>

      <div class="coluna" v-if="full">
        <div class="tempo-lote">
          <div class="titulo">Tempo do lote</div>
          <div class="value">
            <tempo-progresso style="max-width: 90%; margin: auto" :timer="controlador.timerPregao" :default-timer="controlador.getTimer()" /> <!-- @TODO: R-V2 -->
            <div class="legend m-t-xs">{{ controlador.timerPregaoFormatado }}</div>
          </div>
        </div>

        <div class="previsao" v-if="full">
          <div class="titulo">Lances no lote</div>
          <div class="value">
            {{ lote.lances.length }}
          </div>
          <div class="legend">&nbsp;</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {UKnob} from 'uloc-vue'
import TempoProgresso from '@/components/leiloes/components/monitoramento/components/TempoProgresso'

export default {
  name: 'LeilaoTempoStats',
  inject: ['controlador'],
  props: {
    lote: {required: false},
    leilao: {required: false},
    full: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {}
  },
  computed: {
    porcentagemConclusaoLeilao() {
      if (!this.leilao.pregaoAtivo || typeof this.leilao.pregaoAtivo.loteRestante === 'undefined') {
        return 0
      }
      const faltam = this.leilao.pregaoAtivo.loteRestante
      return Math.abs(((faltam / this.leilao.totalLotes) - 1) * 100)
    }
  },
  components: {
    TempoProgresso,
    UKnob
  }
}
</script>
