// import Main from './Main'
import Page from "@/components/integra-grupo-porto/layout/Layout"

export default [
  {
    path: '/integra/grupo-porto', component: Page, name: 'integra.grupo-porto', children: [
      {
        path: '',
        component: () => import('./components/Principal.vue'),
        name: 'integra.grupo-porto.main'
      },
      {
        path: 'consultaLeilao',
        component: () => import('./components/ConsultaLeilao.vue'),
        name: 'integra.grupo-porto.consultaLeilao'
      }
    ]
  }
]
