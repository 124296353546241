export default [
    {
        path: '/negocios', component: () => import('./components/Page'), name: 'negocio.page', children: [
            {path: '', component: () => import('./components/Kanban'), name: 'negocio.kanban'},
            {
                path: ':id',
                name: 'negocio',
                component: () => import('@/components/negocio/components/window/Negocio')
            },
            {path: 'funil/criar', component: () => import('./components/Funil'), name: 'funil.novo'},
            {path: 'funil/:id', component: () => import('./components/Funil'), name: 'funil.editar'}
        ]
    }
]
