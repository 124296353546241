<script>
import DefaultMixin from '../mixin'
import MenuCadastroBasico from './menu/Menu.vue'
// import {USpinnerUloc} from 'uloc-vue'

export default {
  name: 'CadastroBasicoPage',
  provide: function () {
    return {
      page: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    MenuCadastroBasico
    // USpinnerUloc
  },
  data () {
    return {
      active: this.$route.name.split('.').shift()
    }
  },
  computed: {},
  mounted () {
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    setActive (v) {
      this.active = v
    }
  },
  watch: {
    '$route' (r) {
      this.active = r.name.split('.').shift()
    }
  },
  meta: {
    title: 'Cadastro Básico',
    name: 'Cadastro Básico'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap">
    <menu-cadastro-basico :active="active" />
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic">
          <router-view />
        </div>
      </transition>
    </div>
  </div>
</template>
