import {LocalStorage} from 'uloc-vue'

const listCache = function (id, list, version = '0') {
  let listStorage = LocalStorage.get.item(id)
  let listStorageVersion = LocalStorage.get.item(`${id}.version`)
  if (!listStorage || listStorageVersion !== version) {
    listStorage = list;
    LocalStorage.set(id, listStorage)
  }
  if (!listStorageVersion || listStorageVersion !== version) {
    LocalStorage.set(`${id}.version`, version)
  }
  return listStorage
}

export default listCache
