<script>
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
import {listMovimentacoes} from "@/domain/processos/services";
import tableColumnsCache from "@/utils/tableColumnsCache"
import {UTable, UTh, UTr, UTd, UCheckbox, date, UTooltip} from 'uloc-vue'
import MenuOptions from "../../../../../layout/context-menu/context-window-options";
import {EBtnTableOptions} from "uloc-vue-plugin-erp";
import Menu from "../../../../../pessoa/menu";
import windowMovimentacaoProcesso from "../../window/windowMovimentacaoProcesso";
import MenuOptionsItem from "../../../../../layout/context-menu/context-window-options-item";
import ECol from "../../../../../layout/components/Col";
import ERow from "../../../../../layout/components/Row";
import {datePtToEn} from "@/utils/date";
import SearchFilter from "../../../../../layout/helpers/SearchFilter";
import DateInput from "../../../../../../reuse/input/Date";
import {ErpInput, ErpSField, ErpCheckbox, ErpSelect} from 'uloc-vue-plugin-erp'
import {TIPOS_PROCESSO} from "@/domain/processos/helpers/tipo-processo";
import TipoProcessoSelect from "../TipoProcessoSelect";
import highlightKeywords from "../../../../../../utils/highlight-keywords";
import GlobalPessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput";

const listName = 'processo.movimentacoes.lista'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
  {label: 'Movimentação', name: 'movimentacao', active: true, sortable: true, ordering: 1},
  {label: 'Data da publicação', name: 'dataMovimentacao', active: true, sortable: true, ordering: 1},
  {label: 'Registro no sistema', name: 'createdAt', active: true, sortable: true, ordering: 1},
  {label: 'Push', name: 'push', active: true, sortable: true, ordering: 1},
], 4)

let filters = {
  id: null,
  busca: '',
  data1: '',
  data2: '',
  tipoProcesso: '',
  tipoData: 'publicacao',
  pessoaNotificada: null
}

export default {
  name: "ProcessoMovimentacao",
  components: {
    GlobalPessoaInput,
    TipoProcessoSelect,
    DateInput,
    SearchFilter,
    ERow,
    ECol,
    MenuOptionsItem,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    UTooltip,
    ErpSField,
    ErpInput,
    ErpSelect
    // ErpCheckbox
  },
  mixins: [AppMixin],
  props: {
    filtrarPorProcesso: {required: false}
  },
  data() {
    return {
      listStorage: listStorage,
      listType: 'ativos',
      busca: '',
      // isBuscaPeriodo: false,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20,
          sortBy: 'createdAt',
          descending: true
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    menu() {
      return Menu
    },
    menuDashOptions() {
      return MenuOptions
    },
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    isPaginaAndamentos () {
      return this.filtrarPorProcesso ||  (this.$route && this.$route.name === 'andamentos.main')
    },
    tiposProcesso () {
      return TIPOS_PROCESSO
    },
    isBuscaPeriodo () {
      return this.table.filters.data1 && this.table.filters.data1.length && this.table.filters.data2 && this.table.filters.data2.length
    }
  },
  created () {
    if (this.isPaginaAndamentos) {
      const tmp = this.table.columns.pop()
      this.table.columns.push({
        name: 'numeroProcesso',
        required: true,
        label: 'Processo',
        field: 'numeroProcesso',
        sortable: false,
        align: 'left'
      })
      this.table.columns.push(tmp)
      filters.numeroProcesso = ''
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    request({pagination, filter}) {
      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10) {
          alert('Digite a data corretamente para o filtro.')
          return
        }
        data1 = datePtToEn(this.table.filters.data1)
        if (this.isBuscaPeriodo) {
          data2 = datePtToEn(this.table.filters.data2)
          if (!date.isValid(data1) || !date.isValid(data2)) {
            alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
            return
          }
        } else {
          if (!date.isValid(data1)) {
            alert('Digite a data corretamente. Formato: dd/mm/yyyy')
            return
          }
          data2 = null
        }
        extraFilters.push(`&data1=${data1}`)
        data2 && extraFilters.push(`&data2=${data2}`)
      }

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.numeroProcesso && extraFilters.push(`&numeroProcesso=${this.table.filters.numeroProcesso}`)
      this.table.filters.tipoProcesso && extraFilters.push(`&tipoProcesso=${this.table.filters.tipoProcesso}`)
      this.table.filters.tipoData && extraFilters.push(`&tipoData=${this.table.filters.tipoData}`)
      this.table.filters.pessoaNotificada && extraFilters.push(`&pessoaNotificada=${this.table.filters.pessoaNotificada.id}`)

      if (!this.isPaginaAndamentos) {
        const {id} = this.$route.params
        id && extraFilters.push(`&processo=${id}`)
      }
      if (this.filtrarPorProcesso) {
        extraFilters.push(`&processo=${this.filtrarPorProcesso}`)
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'name'}&descending=${pagination.descending ? 'true' : 'false'}&search=${filter}${extraFilters.join('')}`)

      listMovimentacoes(pagination.rowsPerPage, pagination.page, filtros)
          .then((response) => {
            let data = response.data
            this.loading = false
            this.table.loading = false
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.table.loading = false
            this.loading = false
          })
    },
    load(format = null) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    listOptions () {
      console.log('Allons-y!!')
    },
    abrir (id) {
      this.windowMovimentacaoProcesso(id)
    },
    edit () {
      this.windowMovimentacaoProcesso(id)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    windowMovimentacaoProcesso,
    highlightKeywords
  }
}
</script>

<template>
  <div class="pessoa-list-options" :class="{'wrapper': isPaginaAndamentos}">
    <div class="erp-list full-width">
      <div class="" style="padding-bottom: 0" v-if="isPaginaAndamentos">
        <p class="font-16 font-bold">Andamentos</p>
      </div>
      <search-filter style="margin: 10px 0">
        <form class="m-t-sm">
          <e-row class="e-input-modern size1 def-m-r">
            <e-col>
              <erp-s-field view="tl" label="Pesquisa inteligente">
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F2" v-model="busca" />
              </erp-s-field>
            </e-col>

            <e-col v-if="isPaginaAndamentos">
              <erp-s-field view="tl" label="Número">
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F4" v-model="table.filters.numeroProcesso" />
              </erp-s-field>
            </e-col>

            <e-col style="min-width: 106px; max-width: 106px" v-if="isPaginaAndamentos">
              <tipo-processo-select select-null v-model="table.filters.tipoProcesso" @change="pesquisar"/>
            </e-col>

            <e-col style="min-width: 116px; max-width: 116px">
              <date-input label="Data (De)" v-model="table.filters.data1" @keyup.native.enter="pesquisar" />
            </e-col>

            <e-col class="col" style="min-width: 116px; max-width: 116px">
              <date-input label="Data (Até)" v-model="table.filters.data2" @keyup.native.enter="pesquisar" />
            </e-col>

            <e-col class="col" style="min-width: 116px; max-width: 116px">
              <erp-s-field
                  view="tl"
                  label="Tipo Data"
              >
                <erp-select
                    placeholder="Qualquer"
                    :options="[
                        {label: 'Publicação', value: 'publicacao'},
                        {label: 'Registro', value: 'registro'}
                    ]"
                    v-model="table.filters.tipoData" />
              </erp-s-field>
            </e-col>

            <e-col class="col" style="min-width: 146px">
              <erp-s-field
                  view="tl"
                  label="Responsável pelo Push"
              >
                <global-pessoa-input link :limit="10" class="erp-input" v-model="table.filters.pessoaNotificada" type="user" />
              </erp-s-field>
            </e-col>

            <div class="col flex justify-end m-t-sm def-list-buttons" style="min-width: 220px">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                       @click="pesquisar" />
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                       @shortkey.native="clearFilters" />
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
            </div>
          </e-row>
        </form>
      </search-filter>

      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table erp-table-odd"
          :rows-per-page-options="[20, 50, 100]"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th style="text-transform: none" v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
            <div v-if="col.name === 'options'" class="text-center">
              <u-btn disabled style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
            </div>
            <u-tooltip v-if="col.alt">{{col.alt}}</u-tooltip>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer_" slot="body" slot-scope="props"
              :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td key="movimentacao" :props="props" style="word-break: break-word; white-space: break-spaces">
            <span class="m-t-sm m-b-sm block text-justify m-r" v-html="highlightKeywords(props.row.texto, props.row.palavrasChave)"></span>
          </u-td>
          <u-td key="dataMovimentacao" :props="props">
            <span v-if="props.row.dataPublicacao">{{ props.row.dataPublicacao.date|formatDate('dd/MM/yyyy') }}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="createdAt" :props="props">
            <span v-if="props.row.createdAt">{{ props.row.createdAt.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</span>
            <span v-else>-</span>
          </u-td>
          <u-td key="push" :props="props">
            <div v-if="props.row.notifications && props.row.notifications.length">
              <div class="push-notification" :class="{opened: n.visualizadoEm}" v-for="n in props.row.notifications" :key="n.id">
                {{n.pessoa.name}}
                <u-tooltip :offset="[5,5]" v-if="n.visualizadoEm && n.visualizadoEm.date">
                  Visualizado em {{n.visualizadoEm.date|formatDate('dd/MM/yyyy HH:mm:ss')}}
                </u-tooltip>
              </div>
            </div>
          </u-td>
          <u-td key="numeroProcesso" :props="props" v-if="isPaginaAndamentos">
            {{ props.row.processo.numero | formataNumeroProcesso }}
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Abrir" @click="abrir(props.row.id)" icon="folder-open" />
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
    </div>
  </div>
</template>
