export default [
  {
    path: '/processo', component: () => import('./components/Page'), name: 'processo.page', children: [
      {
        path: '',
        name: 'processo.main',
        component: () => import('./components/processos/List')
      },
      {
        path: 'processos',
        name: 'processos.main',
        component: () => import('./components/processos/List')
      },
      {
        path: 'tribunais',
        name: 'tribunais.main',
        component: () => import('./components/tribunais/List')
      },
      {
        path: 'comarcas',
        name: 'comarcas.main',
        component: () => import('./components/comarcas/List')
      },
      {
        path: 'varas',
        name: 'varas.main',
        component: () => import('./components/varas/List')
      },
      {
        path: 'juizes',
        name: 'juizes.main',
        component: () => import('./components/juiz/List')
      },
      {
        path: 'advogados',
        name: 'advogados.main',
        component: () => import('./components/advogado/List')
      },
      {
        path: 'escrivaes',
        name: 'escrivaes.main',
        component: () => import('./components/escrivao/List')
      },
      {
        path: 'reus',
        name: 'reus.main',
        component: () => import('./components/reu/List')
      },
      {
        path: 'produtividade',
        name: 'produtividade.main',
        component: () => import('./components/produtividade/Produtividade')
      },
      {
        path: ':id',
        name: 'processo',
        component: () => import('@/components/processos/components/processos/Processo')
      }
    ]
  },
  {
    path: '/andamentos',
    component: () => import('./components/Page'),
    name: 'andamentos.page',
    children: [
      {
        path: '',
        name: 'andamentos.main',
        component: () => import('./components/processos/include/apps/Movimentacao')
      }
    ]
  }
]
