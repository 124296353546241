var render = function render(){var _vm=this,_c=_vm._self._c;return _c('u-popover',{ref:"popover",staticClass:"autocomplete-popover",attrs:{"fit":true,"anchor-click":false,"max-height":_vm.maxHeight,"no-focus":true,"no-refocus":true},on:{"show":() => {
        this.__input.selectionOpen = true
        this.$emit('show')
      },"hide":() => {
        this.__input.selectionOpen = false
        this.$emit('hide')
      }}},[_c('u-list',{style:(_vm.computedWidth),attrs:{"no-border":true,"separator":_vm.separator}},_vm._l((_vm.computedResults),function(result,index){return _c('u-item-wrapper',{key:index,class:{
        'u-select-highlight': _vm.keyboardIndex === index,
        'cursor-pointer': !result.disable,
        'text-faded': result.disable
      },attrs:{"cfg":result},nativeOn:{"mouseenter":function($event){return _vm.itemMouseEnter(result, index)},"click":function($event){return _vm.itemMouseClick(result, index)}}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }