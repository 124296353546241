<template>
  <div class="fixalerts">
    <div class="u-alert-center" :class="{'show': center}">{{centerMessage}}</div>
  </div>
</template>

<script>
export default {
  name: 'FixAlert',
  data () {
    return {
      center: false,
      centerMessage: null
    }
  },
  methods: {
    showCenter (v, message) {
      this.center = v
      if (!v) {
        this.centerMessage = null
      } else {
        this.centerMessage = message
      }
    }
  }
}
</script>

<style scoped>

</style>
