<template>
  <!--<e-btn-circle-status v-if="s === helper.STATUS_CADASTRADO" status="blue" class="sm m-r-xs" />-->
  <u-icon v-if="s === helper.STATUS_EM_REMOCAO" name="exchange" type="fa" icon-style="solid" class="fa-fw  m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_EM_LEILAO" status="purple" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_DEVOLVIDO" status="negative" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_DOADO" status="negative" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_LEILOADO" status="negative" class="sm m-r-xs" />
</template>

<script>
import {EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../domain/bem/helpers/Status'
export default {
  name: 'StatusBemIcon',
  props: ['status'],
  components: {EBtnCircleStatus},
  computed: {
    helper () {
      return Status
    },
    s () {
      return parseFloat(this.status)
    }
  }
}
</script>

<style scoped>

</style>
