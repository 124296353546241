<template>
  <div class="pda-arrematante-card font-12 m-t-lg">
    <div class="card-img">
      <img v-if="arrematante.image" width="79" :src="arrematante.image.thumb.url">
      <span v-else>Sem foto</span>
    </div>
    <div class="card-info m-t-sm">
      <div class="font-11">
        <span class="m-r-sm">{{String(arrematante.id).padStart(6, '0')}}</span>
        <span>{{Number(arrematante.pessoa.type) === 1 ? 'Pessoa Física' : 'Pessoa Jurídica'}}</span>
      </div>
      <div class="font-bold font-13 m-t-xs">{{arrematante.pessoa.name}}</div>
      <div class="m-t-xs" v-if="Number(arrematante.pessoa.type) === 1">
        CPF: {{arrematante.pessoa.document|formataCpf}}
      </div>
      <div class="m-t-xs" v-else>
        CNPJ: {{arrematante.pessoa.document|formataCnpj}}
      </div>
      <div class="m-t">
        <div>Apelido: <strong>{{arrematante.apelido}}</strong></div>
        <div class="m-t-xxs">Contatos: {{telefones}} / {{emails}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArrematanteCard',
  props: ['arrematante'],
  computed: {
    telefones () {
      if (!this.arrematante.pessoa.phoneNumbers) {
        return 'Sem telefone'
      }
      let value = []
      this.arrematante.pessoa.phoneNumbers.map((item) => {
        value.push(this.$options.filters.formataTelefone(item.phoneNumber))
      })
      return value.join(' / ')
    },
    emails () {
      if (!this.arrematante.pessoa.emails) {
        return 'Sem email'
      }
      let value = []
      this.arrematante.pessoa.emails.map((item) => {
        value.push(item.email)
      })
      return value.join(' / ')
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus">
  .pda-arrematante-card {
    display flex
    transition all 0.3s ease

    &.card-pos-abs{
      position absolute
      left 0
      width 500px
    }

    &.float-card{
      transform scale(0.68)
      position absolute
      margin-top -50px
      left 100%
      margin-left -400px
    }
  }

  .card-img {
    margin-right 16px

    img, span {
      width 79px
      box-shadow 0px 3px 6px rgba(0, 0, 0, 0.16)
    }
    span{
      height 100%
      display flex
      align-items center
      justify-content center
      user-select none
    }
  }
</style>
