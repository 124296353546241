<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Conservação'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
        :disable="disable"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'

export default {
  name: 'SituacaoChaveSelect',
  mixins: [SelectMixin],
  props: {
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {},
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      this.loading = false
      this.asyncdata = [
        {
          label: 'Chave normal',
          value: 1
        },
        {
          label: 'Possui somente a lâmina',
          value: 2
        },
        {
          label: 'Chave danificada',
          value: 3
        }
      ]
    }
  }
}
</script>
