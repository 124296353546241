import http from '../../../utils/services/http'

export const list = (limit, page, filtros) => {
  let url = `/api/tribunais?page=${page}&limit=${limit}${filtros}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/tribunais/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTribunal = (data) => {
  let url = `/api/tribunais`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateTribunal = (id, data) => {
    let url = `/api/tribunais/${id}`
    return http.put(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
