<template>
  <div class="no-select" v-if="Array.isArray(leilao.status)">
    <div class="flex items-center m-b-xs" v-for="(s, i) in leilao.status">
      <span class="m-r-xs font-bold">#{{i+1}}</span> <tipo-leilao-selector :status="s.status" />
    </div>
  </div>
  <tipo-leilao-selector class="no-select" v-else :status="leilao.status" />
</template>

<script>
import TipoLeilaoSelector from './TipoLeilaoSelector';
export default {
  name: 'TipoLeilao',
  components: {TipoLeilaoSelector},
  props: ['leilao']
}
</script>

<style scoped>

</style>
