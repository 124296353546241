import {ErpSField, ErpInput} from 'uloc-vue-plugin-erp'

export default {
  props: {
    label: [String, Number],
    fieldView: String,
    value: {required: true},
    placeholder: String,
    multiple: Boolean,
    autofocus: Boolean,
    simpleBorder: Boolean,
    disable: Boolean,
    findedDataObj: {required: false}
  },
  data () {
    return {
      model: this.value,
      description: null
    }
  },
  mounted () {
    this.__updateInternal && this.__updateInternal()
  },
  watch: {
    /*model (v) {
      window.setTimeout(() => {
        if (this.model === null) {
          this.reset()
        }
      }, 2000)
      console.log('Change model', v, this.value)
      if (v === null && JSON.stringify(v) !== JSON.stringify(this.value) && this.findedData) {
        this.reset()
      }
    },*/
    value (val) {
      this.$nextTick(() => {
        console.log(this.value, this.findedData)
        if (this.value === null && this.findedData && !this.findedDataObj) {
          this.reset()
        }
      })
      this.__updateInternal && this.__updateInternal()
      this.model = this.multiple && Array.isArray(val)
        ? val.slice()
        : val
    }
  },
  computed: {},
  components: {ErpSField, ErpInput},
  methods: {
    __set (e) {
      let val = e && e.target ? e.target.value : e
      this.__emit(val)
    },
    __emit (value) {
      this.$emit('input', value)
      this.$nextTick(() => {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('change', value)
        }
      })
    },
    updateInternal (objComposition) {
      this.$nextTick(() => {
        if (this.description === null && typeof this.value !== 'undefined' && this.value !== null) {
          if (typeof this.value === 'object') {
            this.description = objComposition(this.value)
          } else {
            this.description = this.value
          }
        }
      })
    },
    reset () {
      this.description = null
      if (this.findedData) {
        this.findedData = null
      }
      this.__emit(null)
    }
  }
}
