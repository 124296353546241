export default function (leilao) {
  let window = `leiloes.${leilao.id}.importacaoWebservice`
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Importação via Webservice',
    width: '70%',
    height: '80%',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window-modern',
    contentClass: '__overflow-hidden',
    props: {
      leilao: leilao
    }
  }, () => import('../components/importacao/ImportacaoWebservice'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        /*loteAdicionado: (wid, val) => {
          this.$emit('loteAdicionado')
        }*/
      })
    }) // return wid
}
