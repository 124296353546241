<template>
  <div class="m-t-lg">
    <e-row v-if="!noLabel">
      <e-col><p class="font-bold m-b-xs font-13">{{label}}</p></e-col>
      <e-col class="text-right font-10 text-grey-7 m-t-sm">
        Use <strong>F6</strong> para incluir nova procuração
      </e-col>
    </e-row>
    <div class="erp-list pda-list">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
          hide-bottom
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr class="cursor-pointer" slot="body" slot-scope="props"
              :props="props">
          <u-td key="documento" :props="props">
            <span v-if="Number(props.row.pessoa.tipo) === 1">
             {{props.row.pessoa.documento|formataCpf}}
            </span>
            <span v-else>
              {{props.row.pessoa.documento|formataCnpj}}
            </span>
          </u-td>
          <u-td :title="props.row.descricao" key="nome"
                :props="props">
            {{props.row.pessoa.nome}}
          </u-td>
          <u-td key="poderes" :props="props">
            <span v-if="props.row.poderes === poderes.PODER_TODOS" style="color: #0055FF">{{poderes.Poderes[poderes.PODER_TODOS].title}}</span>
            <span v-else style="color: #A16623">{{poderes.Poderes[props.row.poderes].title}}</span>
          </u-td>
          <u-td key="status" :props="props">
            <div class="flex items-center">
              <e-btn-circle-status :status="props.row.active ? 'positive' : 'negative'" class="m-r-xs" />
              <span v-if="props.row.active" class="text-positive">Ativa</span>
              <span v-else class="text-negative font-bold">Suspensa</span>
            </div>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Detalhes da autorização" @click="$emit('abrir')" icon="folder-open" />
                  <menu-options-item close label="Revogar" label-class="text-negative" @click="$emit('abrir')"
                                     icon="times" />
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import MenuOptions from '../../../../layout/context-menu/context-window-options'
import {UTable, UTd, UTh, UTr} from 'uloc-vue'
import {EBtnTableOptions, EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import * as Poderes from '../../../../../domain/arrematante/helpers/RepresentanteStatus'
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'

export default {
  name: 'RepresentantesArrematante',
  props: {
    arrematante: {required: true},
    representados: {
      type: Boolean
    },
    label: {
      default: 'Quem pode representar esta pessoa'
    },
    noLabel: {
      type: Boolean
    }
  },
  data () {
    return {
      table: {
        busca: '',
        serverData: Array.isArray(this.arrematante.representantes) ? this.arrematante.representantes : [],
        serverPagination: {
          page: 1,
          rowsNumber: Array.isArray(this.arrematante.representantes) ? this.arrematante.representantes.length : 0,
          rowsPerPage: 100
        },
        columns: [
          {
            name: 'documento',
            required: true,
            label: 'Documento',
            align: 'left',
            field: 'documento',
            sortable: true
          },
          {
            name: 'nome',
            required: true,
            label: 'Nome',
            align: 'left',
            field: 'nome',
            sortable: true
          },
          {
            name: 'poderes',
            required: true,
            label: 'Poderes',
            align: 'left',
            field: 'poderes',
            sortable: true
          },
          {
            name: 'status',
            required: true,
            label: 'Status da procuração',
            align: 'left',
            field: 'status',
            sortable: false
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ],
        filter: '',
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    poderes () {
      return Poderes
    }
  },
  mounted () {
    if (this.representados) {
      this.table.serverData = Array.isArray(this.arrematante.representados) ? this.arrematante.representados : []
      this.table.rowsNumber = Array.isArray(this.arrematante.representados) ? this.arrematante.representados.length : 0
    }
    this.request({
      pagination: this.table.serverPagination,
      filter: this.table.busca
    })
  },
  components: {
    MenuOptionsItem,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    EBtnTableOptions,
    EBtnCircleStatus
  },
  methods: {
    request ({pagination, filter}) {
      /* this.table.loading = true

      representantes(this.arrematante.id)
        .then(({data}) => {
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = data.total
          this.table.serverData = data.result

          this.table.loading = false
        })
        .catch(error => {
          this.loading = false
        }) */
    },
    abrir (usuario) {
    }
  }
}
</script>
