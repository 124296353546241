export default [
  {
    id: 'basic',
    name: 'Principal',
    icon: 'home',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'integra.grupo-porto.main'
  },
  {
    id: 'consultaLeilao',
    name: 'Consulta Leilão',
    icon: 'search',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'integra.grupo-porto.consultaLeilao'
  },
  {
    id: 'atualizaLotes',
    name: 'Transmitir Lotes',
    icon: 'list',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'integra.grupo-porto.atualizaLotes'
  },
  {
    id: 'atualizaLotes',
    name: 'Transmitir Vendas',
    icon: 'cash-register',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    route: 'integra.grupo-porto.atualizaLotes'
  }
]
