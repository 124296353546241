<template>
  <autocomplete ref="input" :placeholder="placeholder" :hide-underline="hideUnderline" :disabled="disabled" :parse="parse" :search="search" v-model="model" :min-characters="type && type === 'user' ? 0 : 3" :is-loading="loading" caret :layout="layout" />
</template>

<script>
import Autocomplete from "@/components/layout/components/Autocomplete"
import {list} from "@/domain/pessoa/services"
import {simpleList} from "@/domain/usuario/services"
const search = function (terms, tipo, limit = 50, tags = []) {
  if (tags && tags.length) {
    tags = '&tags=' + tags.join(',')
  } else {
    tags = ''
  }
  let filtros = encodeURI(`&sortBy=name&descending=false&search=${terms}${tags}`)
  return list(limit, 1, filtros)
}

const searchUser = function (terms, tipo = null, limit = 50, tags = []) {
  if (!terms || terms === 'null') {
    terms = ''
  }
  if (tags && tags.length) {
    tags = '&tags=' + tags.join(',')
  } else {
    tags = ''
  }
  let filtros = encodeURI(`&roles=ROLE_ERP&sortBy=name&descending=false&search=${terms}${tags}`)
  return simpleList(limit, 1, filtros)
}
export default {
  name: "GlobalPessoaInput",
  components: {Autocomplete},
  props: {
    value: {
      required: true
    },
    placeholder: {
      required: false
    },
    link: { // @TODO
      type: Boolean,
      required: false
    },
    disabled: { // @TODO
      type: Boolean,
      required: false
    },
    limit: {
      type: Number,
      default: 50
    },
    type: {
      type: String,
      default: 'all'
    },
    tags: {
      type: Array
    },
    hideUnderline: {
      type: Boolean,
      default: true
    },
    layout: {
      type: String,
      default: 'default'
    },
  },
  data () {
    return {
      model: this.value,
      loading: false
    }
  },
  watch: {
    model(v) {
      this.$emit('input', this.model)
    },
    value(v) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.model !== v) {
            this.model = v
            // this.$refs.input.set(v)
          }
        }, 100)
      })
    }
  },
  methods: {
    parse (v) {
      return v && v.name
    },
    search(terms, done) {
      let s = search
      if (this.type === 'user') {
        s = searchUser
      }
      this.loading = true
      s(terms, null, this.limit, this.tags)
          .then(response => {
            this.loading = false
           let  extra = null
            if (terms) {
              extra = {
                label: `Adicionar nova pessoa`,
                value: terms,
                isNew: true,
                link: true,
                click: () => {
                  window.open('/#/pessoas', '_blank')
                }
              }
            }
            let result = response.data.result.map(p => {
              let _p = p
              if (this.type === 'user') {
                _p = p.person
              }
              return {
                ..._p,
                label: _p.name,
                value: _p.name,
                active: null
              }
            })
            extra && result.push(extra)
            done(result)
          }).catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },
  }
}
</script>
