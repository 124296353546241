<template>
  <div class="row erp-row" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ERow',
  props: {
    mr: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      let css = []
      if (this.mr) {
        css.push('def-m-r')
      }
      return css
    }
  }
}
</script>
