export {getStatus} from '../../../utils/getStatus'

export const STATUS_COTACAO = 999
export const STATUS_SOLICITADO = 0
export const STATUS_REMOVIDO = 1
export const STATUS_EM_TRANSITO = 2
export const STATUS_AGUARDANDO_VISTORIA = 3
export const STATUS_ENTREGUE = 100
export const STATUS_NAO_ENTREGUE = 101
export const STATUS_CANCELADO = 99

export const Status = {
  [STATUS_COTACAO]: {title: 'Em cotação', class: 'text-info'},
  [STATUS_SOLICITADO]: {title: 'Solicitado', class: 'text-warning'},
  [STATUS_REMOVIDO]: {title: 'Removido', class: 'text-primary'},
  [STATUS_EM_TRANSITO]: {title: 'Em trânsito', class: 'text-black'},
  [STATUS_AGUARDANDO_VISTORIA]: {title: 'Aguardando vistoria', class: 'text-orange'},
  [STATUS_ENTREGUE]: {title: 'Entregue', class: 'text-positive'},
  [STATUS_NAO_ENTREGUE]: {title: 'Não entregue', class: 'text-negative'},
  [STATUS_CANCELADO]: {title: 'Cancelado', class: 'text-danger'}
}

export const STATUS_SOLICITACAO_AGUARDANDO_ACEITE = 0;
export const STATUS_SOLICITACAO_ACEITO = 1;
export const STATUS_EM_TRANSITO_ATE_RECOLHIMENTO = 33;
export const STATUS_SOLICITACAO_AGUARDANDO_RECOLHIMENTO = 2;
export const STATUS_SOLICITACAO_EM_TRANSPORTE = 3;
export const STATUS_SOLICITACAO_AGUARDANDO_VISTORIA = 4;
export const STATUS_SOLICITACAO_ENTREGUE = 100;
export const STATUS_SOLICITACAO_REJEITADO = 101;
export const STATUS_SOLICITACAO_CANCELADO = 102;
export const STATUS_SOLICITACAO_BEM_NAO_ENCONTRADO = 199;

export const StatusSolicitacao = {
  [STATUS_SOLICITACAO_AGUARDANDO_ACEITE]: {title: 'Aguardando aceite', class: 'text-info'},
  [STATUS_SOLICITACAO_ACEITO]: {title: 'Aceito', class: 'text-info'},
  [STATUS_EM_TRANSITO_ATE_RECOLHIMENTO]: {title: 'Em trânsito', class: 'text-warning'},
  [STATUS_SOLICITACAO_AGUARDANDO_RECOLHIMENTO]: {title: 'Aguardando recolhimento', class: 'text-warning'},
  [STATUS_SOLICITACAO_EM_TRANSPORTE]: {title: 'Em trânsito', class: 'text-primary'},
  [STATUS_SOLICITACAO_AGUARDANDO_VISTORIA]: {title: 'Aguardando vistoria', class: 'text-black'},
  [STATUS_SOLICITACAO_ENTREGUE]: {title: 'Entregue', class: 'text-positive'},
  [STATUS_SOLICITACAO_REJEITADO]: {title: 'Não entregue', class: 'text-negative'},
  [STATUS_SOLICITACAO_CANCELADO]: {title: 'Cancelado', class: 'text-danger'},
  [STATUS_SOLICITACAO_BEM_NAO_ENCONTRADO]: {title: 'Bem não encontrado', class: 'text-danger'},
}
