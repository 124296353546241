export default {
  home: 'app-home',
  atendimentoArrematante: 'app-iniciar-atendimento',
  arrematante: 'app-arrematante',
  pesquisaArrematante: 'app-pesquisa-arrematante',
  pesquisaLote: 'app-pesquisa-lote',
  atendimentoLeilao: 'app-iniciar-atendimento-leilao',
  filaEspera: 'app-fila-espera',
  pagamentoLote: 'app-pagamento-lote'
}
