<template>
  <div class="layout-monitor-map">
    <div class="google-map"
         style="z-index:14000;width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0"
         ref="googleMap"></div>
  </div>
</template>

<script>
import GoogleMap from '../../../../reuse/map/google-maps'

export default {
  name: 'Monitor',
  mixins: [GoogleMap],
}
</script>

<style lang="stylus">
  .layout-monitor-map {
    display flex
    flex 1
    height 100%
    align-content center
    justify-content center
    justify-items center
    align-items center
    position relative
  }
</style>
