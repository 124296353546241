<template>
  <div class="m-t-lg">
    <e-row>
      <e-col><p class="font-bold m-b-xs font-13">Arrematantes encontrados</p></e-col>
    </e-row>
    <div class="erp-list pda-list">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
          hide-bottom
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr class="cursor-pointer" slot="body" slot-scope="props" @dblclick.native="abrirPda(props.row)"
              :props="props">
          <u-td :title="props.row.pessoa.name" class="td-limit l-130 uppercase" key="nome"
                :props="props">
            {{props.row.pessoa.name}}
          </u-td>
          <u-td key="apelido" :props="props" class="td-limit l-130 uppercase">
            {{props.row.apelido}}
          </u-td>
          <u-td key="id" :props="props">
            {{props.row.id}}
          </u-td>
          <u-td key="documento" :props="props">
            <div class="m-t-xs" v-if="Number(props.row.pessoa.type) === 1">
              {{props.row.pessoa.document|formataCpf}}
            </div>
            <div class="m-t-xs" v-else>
              {{props.row.pessoa.document|formataCnpj}}
            </div>
          </u-td>
          <u-td key="pendencias" :props="props">
            <span v-if="props.row.pendencias" class="text-negative">SIM</span>
            <span v-else>NÃO</span>
          </u-td>
          <u-td class="text-uppercase" key="status" :props="props">
            <status-arrematante :arrematante="props.row" />
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="PDA" @click="abrirPda(props.row)" icon="terminal" />
                  <menu-options-item close label="Visualizar Cadastro" @click="abrir(props.row)" icon="folder-open" />
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <!--<context-menu-pendencias-item @abrir="abrir(props.row)" :leilao="props.row" />-->
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import MenuOptions from '../../../../layout/context-menu/context-window-options'
import {UTable, UTd, UTh, UTr} from 'uloc-vue'
import {EBtnTableOptions} from 'uloc-vue-plugin-erp'
import {find, list} from '../../../../../domain/arrematante/services'
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
import StatusArrematante from '../../../../arrematante/components/include/StatusArrematante'
import appList from '../app'
import arrematanteWindow from '@/components/arrematante/windows/arrematante'

export default {
  name: 'PdaBuscaArrematante',
  inject: {
    pda: {
      default () {
        console.error('BuscaArrematante for PDA requires PDA parent instance')
      }
    }
  },
  props: {
    busca: {required: true}
  },
  data () {
    return {
      table: {
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10,
          rowsPerPage: 50
        },
        columns: [
          {
            name: 'nome',
            required: true,
            label: 'Nome',
            align: 'left',
            field: 'nome',
            sortable: true
          },
          {
            name: 'apelido',
            required: true,
            label: 'Apelido',
            align: 'left',
            field: 'apelido',
            sortable: true
          },
          {name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true},
          {
            name: 'documento',
            required: true,
            label: 'Documento',
            align: 'left',
            field: 'documento',
            sortable: true
          },
          {
            name: 'pendencias',
            required: true,
            label: 'Pendências',
            align: 'left',
            field: 'pendencias',
            sortable: true
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: true
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ],
        filter: '',
        selected: [],
        loading: false
      }
    }
  },
  mounted () {
  },
  components: {
    StatusArrematante,
    MenuOptionsItem,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    EBtnTableOptions
  },
  methods: {
    search () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.busca
      })
    },
    request ({pagination, filter}) {
      this.table.loading = true

      list(100, 1, '&search=' + this.busca)
        .then(({data}) => {
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = data.total
          if (Array.isArray(data.result) && data.result.length === 1) {
            this.abrirPda(data.result[0])
          }
          this.table.serverData = data.result

          this.table.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    abrir (arrematante) {
      this.arrematanteWindow(arrematante.id)
    },
    abrirPda (arrematante) {
      find(arrematante.id)
        .then(response => {
          this.pda.changeApp(appList.arrematante, {arrematante: response.data})
        })
        .catch((error) => {
          console.error(error, error.data)
        })
    },
    arrematanteWindow: arrematanteWindow
  }
}
</script>
