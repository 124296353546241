<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Montadora/Marca'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <!--<erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
    ></erp-select>-->
    <marca-input
        @input="__set"
        :tipo="tipo"
        :size="1"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :disable-create="disableCreate || false"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
        :finded-data-obj="findedDataObj"
        @updateSelected="(v) => $emit('updateSelected', v)"
    />
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {listMarcas as list} from '../../../../domain/bem/services'
import MarcaInput from '../../../cadastro/components/marcas/window/MarcaInput'

export default {
  name: 'MarcaSelect',
  mixins: [SelectMixin],
  props: {
    tipo: {
      required: false
    },
    disableCreate: {
      required: false,
      type: Boolean
    }
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {},
  mounted () {
    // this.load(this.tipo)
  },
  components: {MarcaInput},
  methods: {
    load (tipo) {
      this.loading = true
      list(tipo)
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.result)) {
            return
          }
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: obj.nome,
              value: obj.id
            }
          })

          !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
          this.asyncdata = list
        })
        .catch((error) => {
          this.loading = false

        })
    }
  }
}
</script>
