import http from '../../../utils/services/http'

export const dashboard = () => {
  return http.get('/api/bem/resumeDashboard')
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const list = (limit, page, filtros, exportar = false) => {
  let url = '/api/bens?page=' + page + '&limit=' + limit + filtros
  let config = {}
  if (exportar){
    config.responseType = 'blob'
    config.timeout = 30000
  }
  return http.get(url, config)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/bens/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newBem = (data) => {
  let url = `/api/bens`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateBem = (id, data) => {
  let url = `/api/bens/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteBem = (id) => {
  let url = `/api/bens/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateFotoBem = (id, data) => {
  let url = `/api/bens/${id}/photo`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteFotoBem = (id) => {
  let url = `/api/bens/${id}/photo`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const uploadArquivoBem = (id, data) => {
  let url = `/api/bens/${id}/arquivos`
  return http.post(url, data, {
    'timeout': 300000
    // headers: {'Content-Type': 'multipart/form-data'}
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const uploadArquivoBemSync = async (id, data, onUploadProgress = null) => {
  let url = `/api/bens/${id}/arquivos`
  const config = {
    'timeout': 300000
    // headers: {'Content-Type': 'multipart/form-data'}
  }
  if (onUploadProgress) {
    config.onUploadProgress = onUploadProgress
  }
  return http.post(url, data, config)
}

export const deleteArquivoBem = (bem, arquivo) => {
  let url = `/api/bens/${bem}/arquivos/${arquivo}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateArquivoBem = (bem, arquivo, data) => {
  let url = `/api/bens/${bem}/arquivos/${arquivo}`
  return http.patch(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateArquivosBem = (bem, arquivos) => {
  let url = `/api/bens/${bem}/arquivos/update`
  return http.patch(url, arquivos)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const definirFotoPrincipal = (bem, arquivo) => {
  let url = `/api/bens/${bem}/arquivos/${arquivo}/definirFotoPrincipal`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const downloadArquivoBem = (bem, arquivo) => {
  let url = `/api/bens/${bem}/arquivos/${arquivo}/download`
  return http.get(url/*, {
    responseType: 'blob',
    timeout: 30000
  }*/)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposBem = () => {
  // let url = `/api/bem/tipos`
  let url = `/api/tiposBem`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listSubTiposBem = (id) => {
  // let url = `/api/bem/tipos`
  let url = `/api/subTiposBem/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listMarcas = (tipo) => {
  let url = `/api/bem/marcas?tipo=${tipo}`
  // let url = `/api/marcas`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listModelos = (marca) => {
  // let url = `/api/bem/marcas/${marca}/modelos`
  let url = `/api/modelos/${marca}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listCores = () => {
  // let url = `/api/bem/cores`
  let url = `/api/tiposCor`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposApreensao = () => {
  // let url = `/api/bem/tiposApreensao`
  let url = `/api/tiposApreensao`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposArquivo = () => {
  // let url = `/api/bem/tiposArquivo`
  let url = `/api/tiposArquivo`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listCombustiveis = () => {
  // let url = `/api/bem/combustiveis`
  let url = `/api/tiposCombustivel`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listFinalidadeImovel = () => {
  let url = `/api/finalidadeImovel`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listConservacao = () => {
  let url = `/api/tiposConservacaoBem`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listAcessorios = (tipo) => {
  if (typeof tipo === 'undefined' || tipo === null || tipo === 'null') {
    tipo = ''
  }
  let url = `/api/acessorios?tiposBem=${tipo}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listPericias = (tipo) => {
  if (typeof tipo === 'undefined' || tipo === null || tipo === 'null') {
    tipo = ''
  }
  let url = `/api/tiposPericia?tiposBem=${tipo}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getCamposExtrasByTipo = (id) => {
  let url = `/api/bens/camposExtras/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getCaracteristicasBem = (data) => {
  let url = `/api/bens/list-caracteristicas`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getCamposBem = (data) => {
  let url = `/api/bens/list-campos`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listAnosFipe = (modelo) => {
  let url = `/api/fipe/${modelo}/anos`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTags = (limit, page, filtros, format = null) => {
  let url = '/api/bem/tags?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listArquivosBem = (bem, limit, page, filtros = '') => {
  let url = `/api/bens/${bem}/arquivos?${filtros}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const stats = (comitente = null, filters = '') => {
  let url = `/api/bens/stats?comitente=${comitente || ''}&filters=${filters}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listSolicitacoesRemocaoBem = (idBem) => {
  let url = `/api/bem/${idBem}/solicitacoesRemocao`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const findModeloFipe = (codigoFipe) => {
  let url = `/api/modelos/findFipe/${codigoFipe}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const criacaoRapida = async (data) => {
  let url = `/api/bens/criacaoRapida`
  return await http.post(url, data)
}

export default {
  dashboard,
  list,
  find,
  listTiposBem,
  listSubTiposBem,
  listMarcas,
  listModelos,
  listCores,
  listCombustiveis,
  listConservacao,
  listTiposArquivo,
  listAcessorios,
  listTiposApreensao,
  listPericias
}
