import http from '../../../utils/services/http'

export const dashboard = () => {
  return http.get('/api/remocoes/resume-dashboard')
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const list = (limit, page, filtros) => {
  let url = '/api/remocoes?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/remocoes/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newRemocao = (data) => {
  let url = `/api/remocoes`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateRemocao = (id, data) => {
  let url = `/api/remocoes/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteRemocao = (id) => {
  let url = `/api/remocoes/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findSolicitacao = (remocao, id) => {
  let url = `/api/remocoes/${remocao}/solicitacoes/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findFotosChecklist = (id) => {
  // let url = `/api/remocao/${id}/solicitacao/${id}/fotos`
  let url = `/api/fotosRemocaoExemplo/`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const locais = (cidade) => {
  // let url = `/api/remocoes/locais?cidade={id}`
  let url = `/api/remocoes/locais?cidade=${cidade}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listaLocais = (limit, page, filtros) => {
    let url = '/api/remocoes/locais?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newLocalRemocao = (data) => {
    let url = '/api/remocoes/locais'
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateLocalRemocao = (id, data) => {
    let url = `/api/remocoes/locais/${id}`
    return http.patch(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findLocalRemocao = (id) => {
    let url = `/api/remocoes/locais/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteLocalRemocao = (id) => {
    let url = `/api/remocoes/locais/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const getValores = (id) => {
    let url = `/api/remocoes/locais/${id}/valores`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadArquivoChecklist = (arquivo) => {
  let url = `/api/remocoes/checklist/arquivos/${arquivo}/download`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const downloadGrv = (solicitacao, emissor = 1) => {
  let url = `/api/remocoes/remocao/solicitacoes/${solicitacao}/download?emissor=${emissor}`
  let config = {}
  config.responseType = 'blob'
  return http.get(url, config)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const salvarGrv = (id, data) => {
  const url = `/api/vistoriadores/remocao/solicitacoes/${id}/salvar-grv` // @TODO
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const importarTabelaPrecos = (data, exportar = false) => {
    let url = `/api/remocoes/importarTabelaPrecos`
    let config = {
        'timeout': 300000
    }
    if (exportar) {
        url = url + '?export=1'
        config.responseType = 'blob'
    }
    return http.post(url, data, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch((response) => {
            return Promise.reject(response)
        })
}

export const executarImportacaoTabelaPrecos = (importacao) => {
    let url = `/api/remocoes/importarTabelaPrecos/${importacao}/executar`
    return http.post(url, {
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch((response) => {
            return Promise.reject(response)
        })
}

export const listaClassificacaoRemocao = (limit, page, filtros) => {
    let url = '/api/classificacao-remocao?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export default {
  dashboard,
  list,
  find,
  findSolicitacao,
  locais
}
