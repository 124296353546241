<template>
  <div class="col" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ECol',
  props: ['size'],
  computed: {
    classes () {
      let css = []
      if (this.size) {
        css.push('col-size-' + this.size)
      }
      return css
    }
  }
}
</script>
