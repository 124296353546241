<template>
  <div class="wrapper-lg">
    Selecione um tipo de cadastro no menu lateral para gerenciá-lo.
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>

<style scoped>

</style>
