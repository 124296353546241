<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Comitente'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <comitente-input
        @input="__set"
        :tipo="tipo"
        :size="1"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :disable-create="disableCreate || false"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
        :finded-data-obj="findedDataObj"
        @updateSelected="(v) => $emit('updateSelected', v)"
    />
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {list} from '@/domain/comitentes/services'
import ComitenteInput from '../../../cadastro/components/comitentes/window/ComitenteInput'

export default {
  name: 'ComitenteSelect',
  mixins: [SelectMixin],
  props: {
    leilao: null,
    tipo: {
      required: false
    },
    disableCreate: {
      required: false,
      type: Boolean
    }
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {
    filtros () {
      let filtros = []
      /*if (this.leilaoId){
        filtros.push('leilao=' + this.leilaoId)
      }*/
      return filtros.join('&')
    }
  },
  mounted () {
  },
  components: {ComitenteInput},
  methods: {}
}
</script>
