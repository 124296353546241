<script>
import VariavelMixin from '../mixin'
import CategoryList from './include/CategoryList'
import {list} from '../../../domain/variaveis/services'
import {UFakeLoader} from 'uloc-vue'
import windowVariavel from './window/windowVariavel'

export default {
  name: 'Variaveis',
  mixins: [VariavelMixin],
  components: {CategoryList, UFakeLoader},
  data () {
    return {
      loading: false,
      variables: [],
      category: null
    }
  },
  computed: {
    varInternals () {
      return this.variables.filter(v => v.internal)
    },
    variablesCustom () {
      return this.variables.filter(v => !v.internal)
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    load () {
      this.loading = true
      list(1000, 1, '') // TODO Implementar acima de 1.000 variáveis
        .then(response => {
          console.log(response.data)
          this.variables = response.data.result
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    },
    selectCategory (cat) {
      this.category = cat
    },
    windowVariavel: windowVariavel,
    editVariavel (v) {
      this.windowVariavel(v.id, v)
    }
  },
  meta: {
    title: 'Variáveis do Sistema',
    name: 'Variaveis do Sistema'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap templates-container variaveis">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <header class="templates-header">
          <h1 class="templates-header-title">Variáveis do Sistema</h1>
          <h2 class="templates-header-subtitle">
            Configure todas as variáveis do software
            <a href="https://help.suporteleiloes.com/templates/variaveis" target="_blank" title="Ajuda" tabindex="0">Ajuda</a>
          </h2>
        </header>

        <div class="templates-body">
          <div class="tbox box-left">
            <div class="tbox-content">
              <div class="tbox-avatar">
                <u-icon name="speaker_notes" class="text-primary" />
              </div>
              <!--<a class="tbox-close" tabindex="0">
                <u-icon name="times" type="fa" />
              </a>-->
              <div class="m-t">
                <h1 class="content-title text-center">Variáveis Globais Internas</h1>
                <div v-if="loading">
                  <div class="col-grow m-t-lg">
                    <div class="inline-block w-full">
                      <u-fake-loader width="60%" class="w-full" />
                      <u-fake-loader width="90%" />
                    </div>
                  </div>
                  <div class="user-actions">
                    <u-fake-loader width="30px" />
                  </div>
                </div>
                <category-list v-if="varInternals" :list="varInternals" />
              </div>
            </div>
          </div>
          <div class="box-connect"></div>
          <div class="tbox box-right">
            <div class="tbox-content">
              <div class="tbox-avatar">
                <u-icon name="create" class="text-primary" />
              </div>
              <a v-if="category" class="tbox-close" tabindex="0" @click="category = null">
                <u-icon name="times" type="fa" />
              </a>
              <h1 class="content-title text-center m-t">Variáveis Configuradas</h1>
              <p class="disabled text-center m-b-none">Configure variáveis estáticas ou dinâmicas baseado em sua
                necessidade</p>
              <p class="disabled text-center small text-blue-grey-7">Estas variáveis podem substituir variáveis globais
                internas</p>
              <div v-if="loading">
                <div class="col-grow m-t-lg">
                  <div class="inline-block w-full">
                    <u-fake-loader width="60%" class="w-full" />
                    <u-fake-loader width="90%" />
                  </div>
                </div>
                <div class="user-actions">
                  <u-fake-loader width="30px" />
                </div>
              </div>
              <category-list v-if="variablesCustom && variablesCustom.length" :list="variablesCustom" @select="editVariavel" />
              <div class="m-t-lg" v-if="!loading && (!variablesCustom || !variablesCustom.length)">
                Nenhuma variável pré-definida foi cadastrada.
              </div>

              <e-btn @click="windowVariavel" class="m-t" label="Cadastrar nova variável" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../../templates/assets/default.styl"></style>
