import Main from './Main'

export default [
    {
        path: '/', component: Main, children: [
            {path: '', component: () => import('./components/Dash1'), name: 'dashboard.main'},
            {path: 'insights/cadastros', component: () => import('./components/Cadastros'), name: 'dashboard.cadastros'},
            {
                path: 'insights/bens',
                component: () => import('./components/DashboardCadastro'),
                name: 'dashboard.bens'
            }
        ]
    }
]
