<template>
  <div>
    <h2 class="diviser">
      Partes
    </h2>

    <div class="m-t">
      <table class="sl-table sl-table-config">
        <thead>
        <tr>
          <th>Tipo Parte</th>
          <th>Nome</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(parte, i) in fake.partes" :key="'parte-' + i">
          <template v-if="editing">
          <td><tipo-parte-select label="Tipo" v-model="parte.tipoId" /></td>
          <td>
            <erp-s-field
                :view="'tl'"
                :label="'Pessoa'"
                :helper-position="'lb'"
            >
              <autocomplete :search="searchPerson" v-model="parte.pessoa" icon="user"/>
            </erp-s-field>
          </td>
          <td>

          </td>
          </template>
          <template v-else>
            <td>
              {{ parte.tipo ? parte.tipo.nome : 'Parte' }}
            </td>
            <td>
              <a class="pessoa-link" @click="gotoPerson(parte.pessoa)" v-if="parte.pessoa && parte.pessoa.id">{{ parte.pessoa.name }}</a>
              <span v-else>{{parte.pessoa ? parte.pessoa.name : '-'}}</span>
            </td>
            <td>
              <a class="text-negative font-12" @click="removerParte(parte)">Remover</a>
            </td>
          </template>
        </tr>
        </tbody>
      </table>
      <e-btn class="m-t" label="Adicionar parte" @click="adicionarParte" />
      <e-btn v-if="editing" class="m-l m-t" color="positive" label="Salvar" @click="salvar" />
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {list, updatePerson} from "@/domain/pessoa/services"
import mockParte from "@/domain/processos/helpers/mockParte"
import TipoParteSelect from "@/components/processos/components/processos/include/TipoParteSelect"
import {ErpSField} from "uloc-vue-plugin-erp"
import Autocomplete from "@/components/layout/components/Autocomplete"
import {updatePartesProcesso} from "@/domain/processos/services"
import globalPersonMixin from "@/components/pessoa/globalPersonMixin"
const searchPerson = function (terms) {
  let filtros = encodeURI(`&sortBy=name&descending=false&startWith=${terms}`)
  return list(50, 1, filtros)
}


export default {
  name: "BemProcessoPartes",
  props: {
    processo: {
      required: true
    }
  },
  components: {
    TipoParteSelect,
    ErpSField,
    Autocomplete
  },
  mixins: [CadastroBemMixin, globalPersonMixin],
  data() {
    return {
      expand: false,
      editing: false,
      loading: false,
      fake: {
        partes: null
      },
      updatingEmail: false
    }
  },
  watch: {
    processo (v) {
      this.$nextTick(() => {
        this.mountFakePartes()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.mountFakePartes()
    })
  },
  computed: {},
  methods: {
    mountFakePartes () {
      if (!this.processo || !this.processo.partes) return
      this.fake.partes = this.processo.partes.map(p => {
        return {
          ...p,
          tipoId: p.tipo && p.tipo.id,
          pessoa: {
            ...p.pessoa,
            value: p.pessoa.name,
            label: p.pessoa.name
          }
        }
      })
    },
    salvar() {
      this.loading = true
      let processo = JSON.parse(JSON.stringify(this.processo))
      let partes = JSON.parse(JSON.stringify(this.fake.partes))
      partes = partes.map(p => {
        return {
          ...p,
          tipo: p.tipoId
        }
      })
      processo.partes = partes
      updatePartesProcesso(processo)
          .then(response => {
            this.loading = false
            this.editing = false
            this.$emit('update', response.data)
            this.$uloc.notify({
              color: 'positive',
              message: `Processo atualizado com sucesso.`,
              position: 'bottom-left',
            })
            this.$nextTick(() => {
              this.mountFakePartes()
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    adicionarParte() {
      this.editing = true
      this.fake.partes.push(JSON.parse(JSON.stringify(mockParte)))
    },
    searchPerson(terms, done) {
      searchPerson(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova pessoa`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    removerParte (parte) {
      this.editing = true
      this.fake.partes.splice(this.fake.partes.indexOf(parte), 1)
    }
  }
}
</script>
