export default [
  {
    name: 'novo-reboquista',
    title: 'Novo Reboquista',
    description: 'Registrar um novo  prestador de serviço de reboque',
    icon: 'truck',
    iconType: 'fa',
    iconStyle: 'light',
    url: '/reboquistas/lista'
  },
  {
    name: 'nova-cotacao',
    title: 'Nova Cotação',
    description: 'Inicie um processo de cotação de custo de remoção',
    icon: 'hand-holding-usd',
    iconType: 'fa',
    iconStyle: 'light',
    url: '#'
  },
  {
    name: 'relatorios',
    title: 'Relatórios',
    description: 'Gere relatórios gerenciais com informações sobre remoções',
    icon: 'clipboard-list-check',
    iconType: 'fa',
    iconStyle: 'light',
    url: '/relatorios'
  },
  {
    name: 'comunicado',
    title: 'Enviar Comunicado',
    description: 'Envie uma mensagem para todos os reboquistas',
    icon: 'envelope-open',
    iconType: 'fa',
    iconStyle: 'light',
    url: '#'
  }
]
