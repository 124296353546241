<template>
  <div>
    <h2 class="diviser">
      Interessados que definiram este bem como favorito (0)
    </h2>

    <div class="m-t">
      Nenhum registro encontrado
    </div>

    <h2 class="diviser m-t-lg">
      Interessados que participaram com lance (0)
    </h2>

    <div class="m-t">
      Nenhum registro encontrado
    </div>

    <h2 class="diviser m-t-lg">
      Interessados que se habilitaram para bem (0)
    </h2>

    <div class="m-t">
      Nenhum registro encontrado
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"

export default {
  name: "BemLeilaoCrm",
  components: {
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  watch: {
  },
  computed: {},
  methods: {
  }
}
</script>
