<template>
  <div class="task-group-persons">
    <div @click="click(person)" v-for="person in persons" :key="person.id"
         class="person-avatar avatar inline hover-scale min">
      <u-tooltip :offset="[5,5]">{{ person.name }}</u-tooltip>
      <img v-if="person.photo" width="30" class="" :src="person.photo"/>
      <div v-else class="img-fake">{{ firstLettersName(person.name) }}</div>
    </div>
  </div>
</template>

<script>
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import {UTooltip} from "uloc-vue"

export default {
  name: "TaskGroupPersons",
  props: {
    persons: Array,
    noClick: {
      type: Boolean,
      default: false
    },
    emitClick: {
      type: Boolean,
      default: false
    }
  },
  mixins: [GlobalPersonMixin],
  components: {
    UTooltip
  },
  methods: {
    click (person) {
      if (!this.noClick && !this.emitClick) {
        this.gotoPerson(person)
        return
      }
      if (this.emitClick) {
        this.$emit('click', person)
      }
    }
  }
}
</script>

