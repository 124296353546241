export const STATUS_SOLICITADO = 0;
export const STATUS_INICIADO = 1;
export const STATUS_CONCLUIDO = 100;
export const STATUS_CANCELADO = 99;

export const STATUS = {
    [STATUS_SOLICITADO]: {title: 'Solicitado', class: 'vistoria-status-solicitado'},
    [STATUS_INICIADO]: {title: 'Iniciada', class: 'vistoria-status-iniciado'},
    [STATUS_CONCLUIDO]: {title: 'Concluído', class: 'vistoria-status-concluido'},
    [STATUS_CANCELADO]: {title: 'Cancelado', class: 'vistoria-status-cancelado'},
}

import {getStatus} from '@/utils/getStatus'

export function _getStatus (s) {
    return getStatus(s, STATUS)
}
