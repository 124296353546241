<template>
  <div class="arrematante-score no-select">
    SCORE: <span class="font-bold" :class="scoreColor">{{arrematante.score || 'Indefinido'}}</span>
  </div>
</template>

<script>
export default {
  name: 'Score',
  props: ['arrematante'],
  computed: {
    scoreColor () {
      let score = Number(this.arrematante.score)
      let css = []
      if (score < 3) {
        css.push('text-negative')
      }
      if (score > 3 && score < 6) {
        css.push('text-warning')
      }
      if (score > 6 && score < 8) {
        css.push('text-blue')
      }
      if (score > 8) {
        css.push('text-positive')
      }
      return css
    }
  }
}
</script>
