import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'bem.historicoProprietarios.list'
export const listStorage = tableColumnsCache(listName, [
    {label: 'Nome', name: 'nome', active: true, sortable: false, ordering: 1},
    {label: 'Início', name: 'inicio', active: true, sortable: false, ordering: 2},
    {label: 'Fim', name: 'fim', active: true, sortable: false, ordering: 3},
    {label: 'Motivo', name: 'motivo', active: true, sortable: false, ordering: 4},
], listVersion)
