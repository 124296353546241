export default function (id) {
  let window = id ? `site.${id}` : 'site.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Site' + (id ? ` #${id}` : ''),
    width: '500',
    height: '500',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id
    }
  }, () => import('../components/window/Site.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        update: (wid, storage) => {
          // console.log('Created event')
          this.load && this.load()
        }
      })
    }) // return wid
}
