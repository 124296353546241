// NOTE: PJE === processo eletrônico (digitalizado)

export const TIPO_PROCESSO_PJE = 1
export const TIPO_PROCESSO_FISICO = 2

export const TIPOS_PROCESSO = {
    [TIPO_PROCESSO_PJE]: {label: 'Eletrônico'},
    [TIPO_PROCESSO_FISICO]: {label: 'Físico'}
}

export function getStatusString (status) {
    const result = TIPOS_PROCESSO[status]
    if (!result) return 'Status indefinido'
    return result.label
}
