<template>
  <div v-if="criterioGroup.length">
    <criterio v-for="(criterio, index) in criterioGroup" :key="index" :criterio="criterio" />
    <div class="tc-ou">
      <u-btn @click="addCriterioE" color="blue-grey-2" icon="plus" icon-type="fa" class="text-black" size="sm"><strong class="m-l-xs">E</strong></u-btn>
    </div>
    <u-btn v-if="cindex < reference.length-1" color="blue-2" class="text-black m-t" disable size="sm"><strong>OU</strong></u-btn>
  </div>
</template>

<script>
import {mockCriterio} from './Taxas'
import Criterio from './Criterio'

export default {
  name: 'CriterioGroup',
  props: {
    cindex: Number,
    criterioGroup: {
      required: true,
      type: Array
    },
    reference: {
      required: true,
      type: Array
    },
  },
  computed: {
  },
  methods: {
    addCriterioE () {
      this.criterioGroup.push(JSON.parse(JSON.stringify(mockCriterio)))
    }
  },
  watch: {
    reference (v) {
      console.log(v)
      /*if (!v.length()){
        this.$
      }*/
    }
  },
  components: {
    Criterio
  }
}
</script>
