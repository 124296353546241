export default function (lote, action = null) {
  let window, title
  if (typeof lote !== 'undefined' && lote) {
    window = lote.id ? `lote.${lote.id}` : 'lote.new'
    title = 'Visualizar Lote'
  } else {
    window = 'lote.new'
    title = 'Novo Lote'
  }

  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: title,
    width: '90%',
    height: '90%',
    maxWidth: '1400',
    minWidth: '1000px',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window erp-window-with-panel',
    contentClass: 'overflow-hidden',
    props: {
      __lote: lote,
      router: this.$router,
      action: action
    }
  }, () => import('../components/window/Lote.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        loaded: (wid, lote) => {
          console.log('Loaded lote event')
        }
      })
      this.windowLoteEvents && this.windowLoteEvents(wid)
    }) // return wid
}
