export default function (id, bem) {
  let window = typeof id !== 'undefined' ? `bem.proposta.${id}.show` : 'bem.proposta.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: typeof id !== 'undefined' ? 'Proposta ID ' + id : 'Nova Proposta',
    move: false,
    backdrop: true,
    clickOutside: false,
    closeOnEsc: false,
    windowClass: 'erp-window-modern v2 no-border-radius',
    width: '900',
    height: '800',
    props: {
      id: id,
      router: this.router,
      bem: bem
    }
  }, () => import('./Proposta'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        updated: (wid, val) => {
          console.log('Update event')
          this.load && this.load()
          this.entityUpdate && this.entityUpdate(val)
        },
        created: (wid, val) => {
          console.log('Create event')
          this.load && this.load()
          this.entityUpdate && this.entityUpdate(val)
        }
      })
    }) // return wid
}
