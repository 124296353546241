<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'UF'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :placeholder="placeholder || 'Qualquer'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import UFs from '../../../../domain/remocao/helpers/UFs'

export default {
  name: 'UfSelect',
  mixins: [SelectMixin],
  props: {
    ignoreForceModel: {type: Boolean, default: false}
  },
  data () {
    return {
    }
  },
  computed: {
    asyncdata () {
      let list = UFs.map((obj) => {
        return {
          ...obj,
          label: obj.sigla,
          value: obj.sigla
        }
      }).sort((a, b) => {
        if (a.nome > b.nome) {
          return 1;
        }
        if (a.nome < b.nome) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
      !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Qualquer', value: null})
      return list
    }
  },
  watch: {
    model (v) {
      if (this.ignoreForceModel) return
      // if (!v || !this.result || !this.result.length) return
      // let test = this.result.find(item => item.id === v)
      let test = true
      if (test) {
        this.$emit('localSelecionado', v)
      }
    }
  },
  components: {},
  methods: {}
}
</script>
