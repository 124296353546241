<template>
  <div class="flex justify-between content-between no-wrap input-with-btn cep-input">
    <erp-input simple-border v-mask="'#######-##.####.#.##.####'" v-on:input.native="$emit('input', $event.target.value)" :required="required" :autofocus="autofocus" ref="definput" class="float-icon input-hide-loading" v-model="model"
             placeholder="" hide-underline
             @keyup="pesquisarProcesso">
      <sl-loading v-if="loading" content="" class="absolute-center-right size-18 m-r-sm" />
    </erp-input>
    <u-btn @click="pesquisarManual" no-caps flat class="btn-modern b-radius-right no-border-left btn-group text-nowrap" label="Buscar processo" />
  </div>
</template>

<script>
import {debounce} from "uloc-vue"
import SlLoading from "@/components/layout/components/Loading"
import {findByNumero} from "@/domain/processos/services"
import {ErpInput} from "uloc-vue-plugin-erp"

export default {
  name: "ProcessoInput",
  components: {SlLoading, ErpInput},
  props: {
    person: {
      required: false
    },
    address: {
      required: false
    },
    value: {
      required: true
    },
    debounce: {
      type: Number,
      default: 500
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      model: this.value,
      numeroOk: null
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    pesquisarProcesso: debounce(function () {
      const numero = String(this.model).replace(/\D/g, '')
      if (numero.length < 19) {
        return
      }
      this.pesquisar(numero)
    }, 500),
    pesquisarManual () {
      this.pesquisar(this.model)
    },
    pesquisar (numero) {
      this.numeroOk = null
      this.loading = true
      findByNumero(numero)
          .then(({data}) => {
            this.loading = false
            console.log(data)
            if (data.erro) {
              this.numeroOk = false
              this.$uloc.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Processo não encontrado'
              })
              return
            }
            console.log(data)
            this.$emit('processoEncontrado', data)
            this.numeroOk = true
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
            if (error.status == 404) {
              this.$emit('naoEncontrado', numero)
            } else {
              this.alertApiError(error)
            }
          })
    }
  }
}
</script>
