<template>
  <span>{{number}} {{label}}{{number > 1 ? plural : ''}}</span>
</template>

<script>
export default {
  name: 'LabelCount',
  props: {
    number: {
      type: Number,
      default: 0
    },
    plural: {
      type: String,
      default: 's'
    },
    label: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
