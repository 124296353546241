export default {
  title: 'Pessoa',
  menu: [
    {
      id: 'pessoas',
      title: 'Gestão de Pessoas',
      icon: 'user',
      style: 'light',
      url: '/#/pessoas'
    },
    {
      id: 'caller',
      title: 'Caller',
      icon: 'headset',
      style: 'light',
      url: '/#/configuracoes/globais/caller'
    }
  ]
}
