<script>
import {USpinnerFacebook} from 'uloc-vue'
import SlLoading from "components/layout/components/Loading"
export default {
  name: 'WindowLoading',
  props: {
    errors: {required: false},
    status: {required: false},
    v2: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  destroyed () {
  },
  methods: {},
  components: {SlLoading, USpinnerFacebook}
}
</script>

<template>
  <div v-if="v2" class="flex flex-center items-center justify-center h-full">
    <sl-loading />
  </div>
  <div v-else class="flex col-grow-1 content-center items-center h-full justify-center">
    <div class="flex col-grow-1 content-center items-center h-full justify-center" v-if="!errors">
      <slot>
        <div class="text-center">
          <u-spinner-facebook />
          <div>{{status}}</div>
        </div>
      </slot>
    </div>
    <span class="text-negative" v-else>{{errors}}</span>
  </div>
</template>
