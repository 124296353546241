import CallerComponent from "./CallerComponent"

const createVm = function (Component, Vue) {
    const node = document.createElement('div')
    document.body.appendChild(node)
    const vm = new Vue({
        el: node,
        data() {
            return {}
        },
        render: h => h(Component, {
            ref: 'caller',
        }),
        mounted() {
            console.log('Mount caller')
        }
    })
    return vm.$refs.caller
}

export default {
    install({$uloc, Vue}) {
        this.create = $uloc.caller = createVm(CallerComponent, Vue)
    }
}
