export default [
    {
        path: '/pessoas', component: () => import('./Main'), name: 'pessoas.page', children: [
            {
                path: '',
                name: 'pessoas',
                component: () => import('@/components/pessoa/components/Pessoas')
            },
            {
                path: ':id',
                name: 'pessoa.show',
                component: () => import('@/components/pessoa/components/Pessoa')
            },
        ]
    },
]
