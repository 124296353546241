export default {
  title: 'Tarefas',
  menu: [
    {
      id: 'tasks',
      title: 'Listar tarefas',
      icon: 'clipboard-list-check',
      style: 'light',
      url: '/#/sl/tarefas'
    },
    {
      id: 'relatorios',
      title: 'Relatórios',
      icon: 'user-chart',
      style: 'regular',
      url: '#'
    }
  ]
}
