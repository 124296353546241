import {listTiposApreensao, deleteTipoApreensao} from '@/domain/cadastro/services'
import window from './window/window.js'

export default {
  pageTitle: 'Tipos de Apreensão',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'situacao',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'situacao',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listTiposApreensao,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    },
    excluir: deleteTipoApreensao
  }
}
