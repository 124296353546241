/**
 *  Mixin for Google Maps
 *  (c) uloc.dev
 */
import GoogleMapsApiLoader from 'google-maps-api-loader'

export default {
  data () {
    return {
      apiKey: 'AIzaSyAyNTueAQuFQzYRFnOuk3icHWoMAXyBZbI',
      mapOptions: {
        zoom: 4.66,
        center: {lat: -13.9524043, lng: -56.5849919}
      },
      google: null,
      map: null,
      markers: null,
      mapContainer: 'googleMap',
      apis: {
        geocoder: true,
        directions: true

      },
      geocodeAddressTitle: 'Local'
    }
  },
  async mounted () {
    this.geolocate();

    let apiKey = this.apiKey
    const configName = 'maps.apiKey'
    if (this.$appConfig.isConfigNotEmpty(configName)) {
      apiKey = this.$appConfig.getConfig(configName)
    }

    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    this.initializeMap(this.mapContainer, this.apis.geocoder, this.apis.directions)
  },
  destroyed () {
    delete this.google
  },
  watch: {},
  methods: {
    geolocate: function () {
      navigator.geolocation.getCurrentPosition(position => {
        this.$mePosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },
    initializeMap (mapContainer, geocoder = true, directions = true) {
      // const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(
        this.$refs[mapContainer], this.mapOptions
      )
      /*this.google.maps.event.addListener(this.map, 'rightclick', (event) => {
        this.showContextMenu(event.latLng);
      })*/

      // Geocoder
      geocoder && (this.geocoder = new this.google.maps.Geocoder())

      // MaxZoom
      this.maxZoomService = new this.google.maps.MaxZoomService()

      // Direction
      directions && (this.directionsService = new this.google.maps.DirectionsService())
    },

    /**
     * Geocode
     */
    geocodeLatLng (latlng) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode({'location': latlng}, function (results, status) {
          if (status === 'OK') {
            if (results[0]) {
              resolve(results[0].formatted_address)
            } else {
              reject('Endereço não encontrado')
            }
          } else {
            reject('Falha no geocoder: ' + status)
          }
        })
      })
    },

    getEmbedUrl(lat, lng) {
      return `https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=` + lat + "," + lng;
    },

    getStreetViewEmbedUrl(latitude, longitude) {
      console.log('Buscando streetview')
      return new Promise((resolve, reject) => {
        const streetViewService = new this.google.maps.StreetViewService();
        const latLng = new this.google.maps.LatLng(latitude, longitude);
        let apiKey = this.apiKey

        streetViewService.getPanorama({ location: latLng }, function(data, status) {
          if (status === google.maps.StreetViewStatus.OK) {
            const panoId = data.location.pano;
            const urlEmbed = `https://www.google.com/maps/embed/v1/streetview?key=${apiKey}&pano=${panoId}`;
            resolve(urlEmbed)
            console.log("URL de incorporação do Street View: " + urlEmbed);
          } else {
            console.log("Não foi possível obter o panorama do Street View devido a: " + status);
            reject("Não foi possível obter o panorama do Street View devido a: " + status)
          }
        });
      })
    },

    geocodeAddress (address) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode({'address': address}, function (results, status) {
          if (status === 'OK') {
            resolve(results[0].geometry.location)
          } else {
            reject('Endereço não encontrado: ' + status)
          }
        })
      })

    },
    searchAddressInMap (address) {
      console.log(address)
      let latLng = this.geocodeAddress(address)
        .then(location => {
          let mapComponent = this
          mapComponent.map.setCenter(location);

          this.__emitAddressChange(location)

          this.$geocodeAddressMarker && this.$geocodeAddressMarker.setMap(null)
          this.$geocodeAddressMarker && delete this.$geocodeAddressMarker
          this.$geocodeAddressMarker = new mapComponent.google.maps.Marker({
            map: mapComponent.map,
            position: location,
            draggable: true,
            title: this.geocodeAddressTitle
          });

          this.google.maps.event.addListener(this.$geocodeAddressMarker, 'dragend', (e) => {
            console.log('Marker Move: ', e)
            this.__emitAddressChange(e.latLng)
          })

          mapComponent.maxZoomService.getMaxZoomAtLatLng(location, (response) => {
            if (response.status !== 'OK') {
              mapComponent.map.setZoom(11)
            } else {
              mapComponent.map.setZoom(response.zoom)
            }
          });
        })
        .catch(err => {
          this.$uloc.dialog({
            title: 'Falha ao localizar o endereço!',
            color: 'negative',
            message: `Não conseguimos carregar o endereço informado no mapa, verifique se o endereço está correto e tente novamente.`
          })
          console.error('ERR GEOCODER ', err)
          this.$geocodeAddressMarker && delete this.$geocodeAddressMarker
          this.$uloc.notify({color: 'negative', message: `Endereço não localizado no mapa`})
        })
    },
    __emitAddressChange (location) {
      if (typeof this.changeLatLng === 'function') {
        this.changeLatLng(location)
      }
      if (typeof this.changeAddress === 'function') {
        this.geocodeLatLng(location)
          .then((address) => {
            this.changeAddress(address)
          })
      }
    },
    getRounting (origin, destiny, extras = null) {
      return new Promise(resolve => {
        this.directionsService.route(
          {
            origin: origin,
            destination: destiny,
            travelMode: 'DRIVING'
          },
          (response, status) => {
            console.log(response, status)
            if (status === 'OK') {
              let route = {id: null, rota: response}
              if (!this.direction){
                this.direction = new this.google.maps.DirectionsRenderer()
              }
              this.direction.setMap(this.map)
              this.direction.setDirections(route.rota)
              resolve(response)
            } else {
              window.alert('Directions request failed due to ' + status)
            }
          })
      })
    }
  }
}
