import http from '../../../utils/services/http'
import {donwloadFile} from "@/utils/downloadFile";

export const listRelatoriosDinamicos = (entidade, limit = 1000, page = 1, filtros = '') => {
  if (entidade) {
    filtros = filtros + '&entidade=' + entidade
  }
  let url = '/api/relatorio/dinamico?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/relatorio/dinamico/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const download = (id, parameters, formato = 'excel') => {
  let url = `/api/relatorio/dinamico/${id}/download?formato=` + formato
  let config = {}
  config.responseType = 'blob'
  config.timeout = 30000
  return http.post(url, {
    parameters: parameters
  }, config)
    .then(response => {
      return Promise.resolve(response)
    })
      .catch(({response}) => { // @TODO: Mover para ser reutilizado
        if (response.data instanceof Blob) {
          return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = function () {
              const r = JSON.parse(this.result)
              reject({data: r})
            }
            fr.readAsText(response.data)
          })
        } else {
          return Promise.reject(response)
        }
      })
}
export const gerarDiarioLeilaoV2 = (leilaoId, formato = 'pdf') => {
  let url = `/api/leiloes/${leilaoId}/relatorios/diarioLeilaoV2`
  if (formato === 'pdf') {
    url = url + '?pdf=1'
  }
  let config = {}
  config.responseType = 'blob'
  config.timeout = 30000
  return http.get(url, config)
    .then(response => {
      return Promise.resolve(response)
    })
      .catch(({response}) => { // @TODO: Mover para ser reutilizado
        if (response.data instanceof Blob) {
          return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = function () {
              const r = JSON.parse(this.result)
              reject({data: r})
            }
            fr.readAsText(response.data)
          })
        } else {
          return Promise.reject(response)
        }
      })
}
export const catalogoTxt = (leilaoId, formato = 'txt') => {
  let url = `/api/leiloes/${leilaoId}/relatorios/catalogoTxt`
  if (formato === 'txt') {
    url = url + '?txt=1'
  }
  let config = {}
  config.responseType = 'blob'
  config.timeout = 30000
  return http.get(url, config)
    .then(response => {
      return Promise.resolve(response)
    })
      .catch(({response}) => {
        if (response.data instanceof Blob) {
          return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = function () {
              const r = JSON.parse(this.result)
              reject({data: r})
            }
            fr.readAsText(response.data)
          })
        } else {
          return Promise.reject(response)
        }
      })
}

export const downloadEndpoint = (endpoint) => {
  let config = {}
  config.responseType = 'blob'
  return http.get(endpoint, config)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => { // @TODO: Mover para ser reutilizado
        if (response.data instanceof Blob) {
          return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = function () {
              const r = JSON.parse(this.result)
              reject({data: r})
            }
            fr.readAsText(response.data)
          })
        } else {
          return Promise.reject(response)
        }
      })
}

export const remocaoEntradaSaida = (parameters, formato = 'html') => {
  let url = `/api/relatorio/remocao/entradaSaidaPatio?formato=` + formato
  let config = {}
  if (formato !== 'html') {
    config.responseType = 'blob'
  }
  config.timeout = 30000
  return http.post(url, parameters, config)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const relatorios = {
  leiloes: '/api/leiloes',
  lotes: '/api/relatorio/leiloes/lotes',
  comprasPorArrematante: '/api/relatorio/leiloes/comprasPorArrematante',
  lances: '/api/relatorio/leiloes/lances',
  ata: '/api/relatorio/leiloes/ata',
  prestacaoContasLeilao: '/api/relatorios/leiloes/prestacaoContas',
  veiculosConfirmacaoDadosLeilao: '/api/relatorio/leiloes/veiculosConfirmacaoDados',
  relacaoPatioLeilao: '/api/relatorio/leiloes/relacaoPatioLeilao',
  analisePagamentoMaiorLeilao: '/api/relatorio/leiloes/analisePagamentoMaiorLeilao',
  analisePagamentoLeilao: '/api/relatorio/leiloes/analisePagamentoLeilao',
  analiseAusenciaArquivoBem: '/api/relatorio/bens/analiseAusenciaArquivoBem',
  aniversariantesMes: '/api/relatorio/arrematantes/aniversariantesMes',
  tarefas: '/api/relatorio/tarefas/indicador',
  'processos.clientes': '/api/relatorio/processos/clientes',
  'processos.secretarias': '/api/relatorio/processos/secretarias',
  processos: '/api/relatorio/processos',
  'relatorios.bens': '/api/relatorio/bens',
  produtividade: '/api/relatorios/produtividade',
  tempoTrabalho: '/api/relatorios/tempoTrabalho',
  custom: '/api/relatorios/leilao/custom',
  tempoEstoque: '/api/relatorios/bens/tempoEstoque',
  exportarArrematantes: '/api/relatorios/arrematantes/exportar',
}

export const relatorio = (id, data, formato = 'excel', forceDownload = true) => {
  let _url = relatorios[id]
  let url = `${_url}?format=` + formato
  let config = {}
  if (formato !== 'html' && formato !== 'json') {
    config.responseType = 'blob'
  }
  config.timeout = 90000
  return http.post(url, data, config)
    .then(response => {
      if (formato !== 'html' && formato !== 'json' && forceDownload) {
        donwloadFile(response)
      }
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      if (response.data instanceof Blob) {
        return new Promise((resolve, reject) => {
          const fr = new FileReader();
          fr.onload = function () {
            const r = JSON.parse(this.result)
            reject({data: r})
          }
          fr.readAsText(response.data)
        })
      } else {
        return Promise.reject(response)
      }
    })
}

export const docBem = (bem, doc, data = {}, formato = 'excel', forceDownload = true) => {
  let url = `/api/bens/${bem}/emissaoDoc?doc=${doc}&format=` + formato
  let config = {}
  if (formato !== 'html' && formato !== 'json') {
    config.responseType = 'blob'
  }
  config.timeout = 90000
  return http.post(url, data, config)
      .then(response => {
        if (formato !== 'html' && formato !== 'json' && forceDownload) {
          donwloadFile(response)
        }
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        if (response.data instanceof Blob) {
          return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = function () {
              const r = JSON.parse(this.result)
              reject({data: r})
            }
            fr.readAsText(response.data)
          })
        } else {
          return Promise.reject(response)
        }
      })
}
