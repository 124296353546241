import {UIcon, UTooltip} from "uloc-vue"
import {getEventKey, stopAndPrevent} from "uloc-vue/src/utils/event"

/**
 * @TODO: Evoluir para emoticon
 */

export default {
    name: "SLEditorToolbar",
    inject: ['editorContainer'],
    props: {
        toolbarMenu: Object
    },
    data() {
        return {}
    },
    created() {
    },
    mounted() {
    },
    computed: {
        buttonDef() {
            const e = {
                bold: 'Negrito',
                italic: 'Itálico',
                underline: 'Underline',
                ul: 'Lista UL',
                ol: 'Lista OL',
                hyperlink: 'Link',
                toggleFullscreen: 'Fullscreen',
                print: 'Imprimir',
                mark: 'Marcar alguém',
                image: 'Imagem',
                removeFormat: 'Remover formatação',
                source: 'Código Fonte / Previsualizar',
                left: 'Justificar Esquerda',
                right: 'Justificar Direita',
                center: 'Justificar Meio',
                justify: 'Justificar',
            }

            const defaultDisable = function () {
                return this.source
            }

            let items = {
                bold: {cmd: 'bold', icon: 'bold', iconType: 'fa', iconStyle: 'light', tip: e.bold, key: 66},
                italic: {cmd: 'italic', icon: 'italic', iconType: 'fa', iconStyle: 'light', tip: e.italic, key: 73},
                underline: {
                    cmd: 'underline',
                    icon: 'underline',
                    iconType: 'fa',
                    iconStyle: 'light',
                    tip: e.underline,
                    key: 85
                },
                ul: {cmd: 'ul', icon: 'list-ul', iconType: 'fa', iconStyle: 'light', tip: e.ul},
                ol: {cmd: 'ol', icon: 'list-ol', iconType: 'fa', iconStyle: 'light', tip: e.ol},
                quote: {
                    cmd: 'formatBlock',
                    param: 'BLOCKQUOTE',
                    icon: 'quote-left',
                    iconType: 'fa',
                    iconStyle: 'light',
                    tip: e.ul
                },
                left: {cmd: 'justifyLeft', icon: 'align-left', iconType: 'fa', iconStyle: 'light', tip: e.left},
                center: {cmd: 'justifyCenter', icon: 'align-center', iconType: 'fa', iconStyle: 'light', tip: e.center},
                right: {cmd: 'justifyRight', icon: 'align-right', iconType: 'fa', iconStyle: 'light', tip: e.right},
                justify: {
                    cmd: 'justifyFull',
                    icon: 'align-justify',
                    iconType: 'fa',
                    iconStyle: 'light',
                    tip: e.justify
                },
                fullscreen: {
                    cmd: 'fullscreen',
                    icon: 'expand-alt',
                    iconType: 'fa',
                    iconStyle: 'light',
                    tip: e.toggleFullscreen,
                    key: 70
                },
                print: {
                    type: 'no-state',
                    cmd: 'print',
                    icon: 'print',
                    iconType: 'fa',
                    iconStyle: 'light',
                    tip: e.print,
                    key: 80
                },
                link: {cmd: 'link', icon: 'link', iconType: 'fa', iconStyle: 'light', tip: e.hyperlink, key: 76},
                removeFormat: {
                    type: 'no-state',
                    cmd: 'removeFormat',
                    icon: 'unlink',
                    iconType: 'fa',
                    iconStyle: 'light',
                    tip: e.removeFormat
                },
                mark: {
                    cmd: 'mark',
                    param: 'fromBtn',
                    icon: 'at',
                    iconType: 'fa',
                    iconStyle: 'light',
                    tip: e.mark,
                    key: 50,
                    keyCode: '@',
                    forceKeyMapping: true
                },
                image: {cmd: 'image', icon: 'image', iconType: 'fa', iconStyle: 'light', tip: e.image},
                source: {cmd: 'source', icon: 'code', iconType: 'fa', iconStyle: 'light', tip: e.source, disableTest: () => {}},
                /*



                outdent: {type: 'no-state', disable: vm => vm.caret && !vm.caret.can('outdent'), cmd: 'outdent', icon: i.outdent, iconType: 'fa', iconStyle: 'light', tip: e.outdent},
                indent: {type: 'no-state', disable: vm => vm.caret && !vm.caret.can('indent'), cmd: 'indent', icon: i.indent, iconType: 'fa', iconStyle: 'light', tip: e.indent},
                removeFormat: {type: 'no-state', cmd: 'removeFormat', icon: i.removeFormat, iconType: 'fa', iconStyle: 'light', tip: e.removeFormat},
                code: {cmd: 'formatBlock', param: 'PRE', icon: i.code, iconType: 'fa', iconStyle: 'light', tip: `<code>${e.code}</code>`},*/
            }


            Object.keys(items).map(key => {
                if (this.toolbarMenu) {
                    if (typeof this.toolbarMenu[key] !== 'undefined' && (!this.toolbarMenu[key] || !this.toolbarMenu[key].enable)) {
                        delete items[key]
                    }
                }
                if (items[key] && typeof items[key]['disableTest'] === 'undefined') {
                    items[key].disableTest = defaultDisable
                }
            })
            return items
        },
        buttons() {
            // @TODO: Permitir que o componente pai possa informar quais botões deseja no toolbar
            return Object.values(this.buttonDef)
        },
        keys() {
            const
                k = {},
                add = btn => {
                    if (btn.key) {
                        k[btn.key] = {
                            cmd: btn.cmd,
                            param: btn.param,
                            forceKeyMapping: btn.forceKeyMapping,
                            keyCode: btn.keyCode,
                            disableTest: btn.disableTest
                        }
                    }
                }

            this.buttons.forEach(btn => {
                add(btn)
            })
            return k
        }
    },
    watch: {},
    methods: {
        click(b, e) {
            this.$emit('runCmd', b.cmd, b.param, true, e)
            stopAndPrevent(e)
        }
    },
    render(h) {
        return h('div',
            {
                staticClass: 'sl-editor-toolbar'
            },
            [
                h('div', this.buttons.map(b => {
                    return h('button', {
                        staticClass: 'sl-editor-toolbar-btn',
                        class: {disabled: !!b.disableTest.call(this.editorContainer)},
                        on: {
                            click: (e) => !b.disableTest.call(this.editorContainer) && this.click(b, e)
                        }
                    }, [
                        h(UIcon, {
                            props: {
                                name: b.icon,
                                type: b.iconType,
                                iconStyle: b.iconStyle
                            }
                        }),
                        h(UTooltip, {
                            props: {
                                offset: [2, 2],
                                delay: 300
                            }
                        }, b.tip)
                    ])
                }))
            ])
    }
}
