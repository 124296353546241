<template>
  <div v-if="pagamento.deleted">
    <e-btn-circle-status status="negative" class="sm m-r-xs fa-fw" /><span class="text-negative">Deletado</span>
  </div>
  <div v-else-if="pagamento.status === Status.STATUS_PAGAMENTO_ARREMATE_PENDENTE_CONFIRMACAO">
    <e-btn-circle-status status="grey" class="sm m-r-xs fa-fw" /><span class="text-grey">Pendente</span>
  </div>
  <div v-else-if="pagamento.status === Status.STATUS_PAGAMENTO_ARREMATE_CONFIRMADO">
    <e-btn-circle-status status="positive" class="sm m-r-xs fa-fw" /><span class="text-positive">Processado</span>
  </div>
  <div v-else-if="pagamento.status === Status.STATUS_PAGAMENTO_ARREMATE_CANCELADO">
    <e-btn-circle-status status="negative" class="sm m-r-xs fa-fw" /><span class="text-negative">Cancelado</span>
  </div>
  <div v-else-if="pagamento.status === Status.STATUS_PAGAMENTO_ARREMATE_ESTORNADO">
    <e-btn-circle-status status="negative" class="sm m-r-xs fa-fw" /><span class="text-negative">Estornado</span>
  </div>
  <div v-else>Status indefinido</div>
</template>

<script>
import {EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../domain/leiloes/helpers/PagamentoStatus'

export default {
  name: 'PagamentoStatus',
  props: ['pagamento'],
  computed: {
    Status () {
      return Status
    }
  },
  components: {EBtnCircleStatus}
}
</script>
