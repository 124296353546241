import * as StatusBemConst from "@/domain/bem/helpers/Status"

export default {
  comitente: null,
  codigo: null,
  tipoBem: null,
  marca: null,
  modelo: null,
  ano: null,
  placa: null,
  chassi: null,

  situacao: [StatusBemConst.STATUS_RASCUNHO,StatusBemConst.STATUS_EM_REMOCAO, StatusBemConst.STATUS_CADASTRADO, StatusBemConst.STATUS_NO_PATIO, StatusBemConst.STATUS_EM_LEILAO],
  codigoFipe: null,
  patio: null,
  financiado: null,
  processo: null,
  processoInteligente: null,
  remessa: null,
  numeroSinistro: null,
  data1: null,
  data2: null,
  tipoData: 'cadastro',
  codigoRemocao: null,
  numeroAtendimento: null,
  bloqueadoLeilao: null,
  alagamento: null,
  search: null,
  //
  vendaDireta: null,
  // Imóvel
  matricula: null,
  uf: null,
  cidade: null,
  endereco: null,
  bairro: null,
  cep: null,
  analiseCadastral: null,
  origem: null,
  evento: null,
  emDeposito: null,
}

export const situacaoAll = [StatusBemConst.STATUS_RASCUNHO,StatusBemConst.STATUS_EM_REMOCAO, StatusBemConst.STATUS_CADASTRADO, StatusBemConst.STATUS_NO_PATIO, StatusBemConst.STATUS_EM_LEILAO, StatusBemConst.STATUS_LEILOADO, StatusBemConst.STATUS_DOADO, StatusBemConst.STATUS_DEVOLVIDO]
