import {listTiposBem, excluirTipoBem} from '@/domain/cadastro/services'
import window from './window/window.js'

export default {
  pageTitle: 'Tipos de Bem',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'parent',
      required: true,
      label: 'Parente',
      align: 'left',
      field: 'parent',
      class: '',
      sortable: true,
      parser: function (props) {
        if (props.parent) {
          return props.parent.nome
        }
        return '-'
      }
    },
    {
      name: 'id',
      required: true,
      label: 'ID',
      align: 'left',
      field: 'id',
      sortable: true,
      parser: function (props) {
        return props.id
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listTiposBem,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    },
    excluir: excluirTipoBem
  }
}
