<script>
    // import { dom } from 'uloc-vue'
    // const { height, width } = dom
    export default {
      name: 'widget-indicator',
      props: ['value'],
      data () {
        return {}
      },
      computed: {},
      mounted () {
        // this.adjustPosition()
      },
      created () {
        // window.addEventListener('resize', this.adjustPosition)
      },
      updated () {
        // this.adjustPosition()
      },
      beforeDestroy () {
        // window.removeEventListener('resize', this.adjustPosition)
      },
      destroyed () {
      },
      methods: {
        adjustPosition () {
          // this.slots = []
          // this.$width = width(document.body)
          // this.$el.style.fontSize = '26px'
        }
      },
      components: {}
    }
</script>

<template>
    <a class="indicator adjust-font" v-html="value"></a>
</template>
