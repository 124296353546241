<template>
  <u-popover ref="popover" class="autocomplete-popover" :fit="true" :anchor-click="false" :max-height="maxHeight" :no-focus="true" :no-refocus="true"
             @show="() => {
          this.__input.selectionOpen = true
          this.$emit('show')
        }"
             @hide="() => {
          this.__input.selectionOpen = false
          this.$emit('hide')
        }"
  >
    <u-list :no-border="true" :separator="separator" :style="computedWidth">
      <u-item-wrapper
          v-for="(result, index) in computedResults" :key="index"
          :class="{
          'u-select-highlight': keyboardIndex === index,
          'cursor-pointer': !result.disable,
          'text-faded': result.disable
        }"
          :cfg="result"
          @mouseenter.native="itemMouseEnter(result, index)"
          @click.native="itemMouseClick(result, index)"
      />
    </u-list>
  </u-popover>
</template>

<script>
import {UAutocomplete, UPopover, UList, UItemWrapper} from "uloc-vue"

export default {
  name: "AutocompleteWrapper",
  mixins: [UAutocomplete],
  components: {UPopover, UList, UItemWrapper},
  methods: {
    itemMouseEnter (result, index) {
      !result.disable && (this.keyboardIndex = index)
    },
    itemMouseClick (result, index) {!result.disable && this.setValue(result) },
    createNew () {
      console.log('Create new item with ', this.__input.val)
    }
  }
}
</script>
