<template>
  <div class="leilao-tipo-label" :class="'l-tipo-' + tipoCode">
    {{tipoTitle}}
  </div>
</template>

<script>
import {Tipo as Status} from '../../../../domain/leiloes/helpers/LeilaoStatus'

export default {
  name: 'TipoLeilaoLabel',
  props: {
    tipo: {
      required: true
    }
  },
  computed: {
    tipoTitle () {
      return Status[this.tipo].title
    },
    tipoCode () {
      return this.tipo
    }
  }
}
</script>
