<template>
  <div class="dinput">
    <textarea v-if="type === 'textarea'" v-on:input="$emit('input', $event.target.value)" class="sl-textarea" v-model="model"/>
    <d-select v-else-if="type === 'select'" :field="field" :field-value="fieldValue" v-on:input="$emit('input', value)" v-model="model" />
    <d-radio v-else-if="type === 'option'" :field="field" :field-value="fieldValue" v-on:input="$emit('input', value)" v-model="model" />
    <d-checkbox v-else-if="type === 'checkbox'" :field="field" :field-value="fieldValue" v-on:input="$emit('input', value)" v-model="model" />
    <input v-else v-on:input="$emit('input', $event.target.value)" class="sl-input" v-model="model"/>
  </div>
</template>

<script>
import {inputTypes} from "@/reuse/input/dynamic/Helper"
import DRadio from "@/reuse/input/dynamic/inputs/DRadio"
import DSelect from "@/reuse/input/dynamic/inputs/DSelect"
import DCheckbox from "@/reuse/input/dynamic/inputs/DCheckbox"

export default {
  name: "DetectInput",
  components: {
    DRadio,
    DSelect,
    DCheckbox
  },
  props: {
    value: {required: true},
    field: {required: false},
    fieldValue: {required: false},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: this.value
    }
  },
  computed: {
    type() {
      if (typeof inputTypes[this.field.tipo] !== 'undefined') {
        return inputTypes[this.field.tipo].type
      }
      return inputTypes[1].type
    }
  }
}
</script>
