<template>
  <div class="flex items-center no-wrap">
    <status-pagamento-lote-icon class="fa-fw" :status="status" />
    <span :class="'scolor lote-pag-status lote-pag-status-' + status">{{getStatus(status).title}}</span>
  </div>
</template>

<script>
import {Status, getStatus} from '../../../../domain/leiloes/helpers/PagamentoStatus'
import StatusPagamentoLoteIcon from './StatusPagamentoLoteIcon'

export default {
  name: 'StatusPagamentoLoteSelector',
  components: {StatusPagamentoLoteIcon},
  props: ['status'],
  computed: {
  },
  methods: {
    getStatus (s) {
      return getStatus(s, Status)
    }
  }
}
</script>
