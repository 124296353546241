<template>
  <div class="menu-content">
    <!--            <slot name="compontentTitle">
                  <h3 class="hide-expanded">{{ menu && menu.shortName || menuName }}</h3>
                  <h3 class="hide-folded">{{ menu && menu.shortName || menuName }}</h3>
                </slot>-->
    <!--        <div v-if="null !== title && !isFolded" class="menu-title">{{ title }}</div>-->
    <ul class="menu-items">
      <slot name="list">
        <menu-item :key="m.id" v-for="m in menu" :m="m" />
      </slot>
    </ul>
<!--    <div v-if="menuContainer.submenuOpen" class="submenu-item">
      <ul class="menu-items">
        <li class="mitem in-submenu subitem-title">
          <div class="mitem-wrapper">
            <a  class="mitem-action flex">
              <div class="micon"><u-icon name="chevron-left" type="fa" icon-style="solid" class="fa-fw" /></div>
              <span class="menu-lbl menu-item-col">Contas</span>
            </a>
          </div>
        </li>
        <li class="mitem in-submenu" :class="{'active': m.id === active}" :key="m.id" v-for="m in menu">
          <div class="mitem-wrapper">
            <span v-if="m.desc">{{ m.title }}</span>
            <a class="mitem-action flex" :data-title="m.title" :href="m.url">
              &lt;!&ndash;                        <div class="micon"><u-icon :name="m.icon" :type="m.type || 'fa'" :icon-style="m.style || 'solid'" class="fa-fw" /></div>&ndash;&gt;
              <span class="menu-lbl menu-item-col">{{ m.title }}</span>
              <div class="menu-nav menu-item-col">
                <span><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline&#45;&#45;fa fa-chevron-down fa-w-14 fa-fw fa-sm"><path data-v-d57394a6="" fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" class=""></path></svg></span>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>-->
  </div>
</template>

<script>
import MenuItem from "@/components/layout/menu-components/MenuItem"
export default {
  name: "MenuContent",
  provide: function () {
    return {
      menuContent: this
    }
  },
  components: {MenuItem},
  inject: ['menuContainer'],
  props: {
    menu: {
      required: true
    }
  },
  data () {
    return {
      whenSubmenuOpened: false,
      hasSubmenuEffect: false
    }
  }
}
</script>
