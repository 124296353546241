import Main from './Main'
import Monitor from './components/rastreamento/Monitor'

export default [
  { path: '/remocao', component: Main, name: 'remocao.main' },
  { path: '/remocao/lista', component: () => import('./components/ListaRemocoes'), name: 'remocao.lista' },
  { path: '/remocao/cotacoes', component: () => import('./components/Cotacoes'), name: 'remocao.cotacoes' },
  // { path: '/patios', component: Patios, name: 'bem.patios' },
  { path: '/remocao/solicitacoes', component: () => import('./components/SolicitacoesRemocao'), name: 'remocao.solicitacao' },
  { path: '/remocao/locais', component: () => import('./components/ListaLocais'), name: 'remocao.locais' },
  { path: '/remocao/locais/importarTabelaPrecos', component: () => import('./components/origensDestinos/ImportacaoTabelaPrecos'), name: 'remocao.locais.importarTabelaPrecos' },
  { path: '/remocao/monitor', component: Monitor, name: 'remocao.monitor' },
  { path: '/remocao/checklist-acessorios', component: () => import('./components/gerenciamento-checklist/ListaChecklistAcessorios'), name: 'remocao.checklist.acessorios' },
  { path: '/remocao/checklist-campos', component: () => import('./components/gerenciamento-checklist/ListaChecklistCampos'), name: 'remocao.checklist.campos' }
]
