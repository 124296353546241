<template>
  <menu-lateral default-class="menu-lateral-leilao" posmenu-full>
      <div class="">
        <p class="font-bold font-16 hide-folded">Cadastro Básico</p>

        <div class="m-t">
          <ul class="menu-leilao-items">
            <li v-for="m in menu" :class="[m.class || null, m.desc && 'desc', {'active': m.id === active}]">
              <span v-if="m.desc">{{m.name}}</span>
              <a :href="m.href" class="flex" v-else>
                <div class="erp-icon-size" v-if="m.iconType === 'erp'"><i class="erp-icon" :class="m.icon"></i></div>
                <u-icon :name="m.icon" :type="m.iconType" :style="m.iconStyle" v-else />
                <span class="menu-lbl">{{m.name}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
  </menu-lateral>
</template>

<script>
import MenuJS from './menu.js'
import MenuLateral from '../../../layout/MenuLateral'

export default {
  name: 'MenuCadastroBasico',
  components: {MenuLateral},
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  props: ['active'],
  mounted () {
  },
  beforeDestroy () {
  },
  computed: {
    menu () {
      return MenuJS
    }
  }
}
</script>
