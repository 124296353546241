<template>
  <div>
    <h2 class="diviser">
      Laudos de Avaliação
    </h2>

    <div class="m-t">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-w-table simple-bottom no-scroll m-t-md"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <!--          <u-th auto-width>
                      <u-checkbox
                          v-model="props.selected"
                          :indeterminate="props.partialSelected"
                          size="sm"
                      />
                    </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :style="col.name === 'motivo' ? 'text-align: right !important' : ''" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-right">
              <span class="cursor-pointer" @click="listOptions">Opções</span>
            </div>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" slot="body" slot-scope="props"
              :props="props" :class="{'u-table-item-destac': props.row.isToday}">
          <!--          <u-td auto-width>
                      <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                    </u-td>-->
          <u-td key="origem" :props="props">
            {{ props.row.referNome || '-' }}
          </u-td>
          <u-td key="data" :props="props">
            {{ props.row.createdAt|formatDate('dd/MM/yyyy') }}
          </u-td>
          <u-td key="formato" :props="props">
            <u-icon class="text-negative" type="fa" :name="getFileIco(props.row.filename)" />
          </u-td>
          <u-td key="observacoes" class="td-limit" :props="props">
            {{ props.row.observacoes }}
          </u-td>
          <u-td key="options" class="text-right" style="max-width: 100px" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Baixar" @click="download(props.row)" />
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
<!--      <div v-if="bem.id && !loading" class="m-t">
        <u-btn @click="novo" class="sl-btn" color="positive" no-caps label="Registrar processo ao histórico"/>
      </div>-->
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {listArquivosBem as list, deleteArquivoBem} from "@/domain/bem/services/index"
import {listStorage} from "@/components/bem/components/include/laudos/config/list"
import processoWindow from "@/components/bem/components/include/historico-processos/window/processoWindow"
import ListMixin from "@/reuse/list/ListMixin"
import fileType from "@/mixins/fileType"
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import bemArquivoMixin from "@/components/bem/components/include/arquivos/bemArquivoMixin"

export default {
  name: "BemLaudosAvaliacao",
  data() {
    return {
      listStorage: this.listStorage,
      loading: false,
      listData: null
    }
  },
  components: {
    MenuOptionsItem
  },
  mounted() {
    this.bem.id && this.load()
  },
  mixins: [CadastroBemMixin, ListMixin, fileType, bemArquivoMixin],
  watch: {},
  beforeCreate() {
    this.listStorage = listStorage
  },
  computed: {},
  methods: {
    request({pagination, filter}) {
      console.log('Loading')

      this.table.loading = true
      const extraFilters = [
          'nome=laudo%'
      ]
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}&${extraFilters.join('')}`)

      list(this.bem.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    processoWindow,
    novo() {
      this.processoWindow(null, this.bem)
    },
    abrir(id) {
      this.processoWindow(id, this.bem)
    },
    excluir(id) {
      this.$uloc.dialog({
        title: 'Excluir arquivo',
        message: 'Você tem certeza que deseja excluir este arquivo?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Deletando...'})
        deleteArquivoBem(this.bem.id, id)
            .then(() => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    }
  }
}
</script>
