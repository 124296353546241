<script>
/**
 * TODO: Move do ERP Plugin
 */
// import {UIcon} from 'uloc-vue'
import MenuContent from "@/components/layout/menu-components/MenuContent"

export default {
  name: 'MenuLateral',
  provide: function () {
    return {
      menuContainer: this
    }
  },
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  props: {
    menu: [Array, Object],
    menuStyle: String,
    active: String,
    folded: {
      type: Boolean,
      default: false
    },
    autoFolded: {
      type: Boolean,
      default: true
    },
    defaultClass: {
      default: 'menu-sl'
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    posmenuFull: {
      type: Boolean,
      default: false
    },
    noFolded: {
      type: Boolean,
      default: false
    },
    menuName: {
      default: 'ERP'
    }
  },
  data() {
    return {
      isFolded: this.folded,
      submenuOpen: false,
      hasSubmenuEffect: false,
      mouseOver: false,
    }
  },
  watch: {
    folded(v) {
      this.toggle(v)
    }
  },
  mounted() {
    // document.querySelector('.u-erp-layout-container').append(this.$el)
    this.adjustPosition()
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 400)
  },
  created() {
    window.addEventListener('resize', this.adjustPosition)
    this.$on('open', (id) => {
      this.submenuOpen = true
    })
    this.$on('close', (id) => {
      this.submenuOpen = false
    })
  },
  updated() {
    this.adjustPosition()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustPosition)
    document.body.classList.remove('with-menu-lateral')
    this.erplayout.erpheader.setMenuModuleToggle(true)
  },
  methods: {
    toggle(v) {
      v = typeof v === 'boolean' ? v : !this.isFolded
      this.isFolded = v
      this.$disableAdjustPosition = true
      this.$nextTick(() => {
        window.setTimeout(() => {
          this.$disableAdjustPosition = false
        }, 500)
        this.$emit('resize')
        if (v) {
        } else {
        }
      })
    },
    adjustPosition() {
      if (this.fullHeight && !this.posmenuFull) {
        document.body.classList.contains('with-menu-lateral') && document.body.classList.add('with-menu-lateral')
      }
      setTimeout(() => {
        !this.posmenuFull && !document.body.classList.contains('with-menu-lateral') && document.body.classList.add('with-menu-lateral')
      }, 100)
      if (this.$disableAdjustPosition) {
        return
      }
      let w = this.$width = window.innerWidth
      if (w < 1200) {
        !this.isFolded && this.autoFolded && this.toggle(true)
      } else {
        this.isFolded && this.autoFolded && this.toggle(false)
      }
    },
    toggleSubmenu (show = true) {
      if (!show) {
        this.submenuOpen = false
        this.whenSubmenuOpened = false
        setTimeout(() => {
          this.hasSubmenuEffect = false
        }, 50)
      } else {
        this.hasSubmenuEffect = true
        setTimeout(() => {
          this.whenSubmenuOpened = true
          this.submenuOpen = true
        }, 200)
      }
    },
    mouseOverAction () {
      this.$overtimer = setTimeout(() => {
        // this.mouseOver = true
      }, 200)
    },
    mouseLeaveAction () {
      this.$overtimer && clearTimeout(this.$overtimer)
      //this.mouseOver = false
    }
  },
  components: {MenuContent},
  computed: {
    computedClass() {
      let classes = []
      classes.push(this.defaultClass)
      if (this.isFolded) {
        classes.push('folded')
      }
      if (this.menuStyle) {
        classes.push(this.menuStyle)
      }
      if (this.submenuOpen) {
        classes.push('submenu-open')
      }
      if (this.mouseOver) {
        classes.push('mouse-over')
      }
      return classes
    },
    menuList() {
      if (!this.menu) {
        return null
      }
      return Array.isArray(this.menu) ? this.menu : this.menu.menu
    },
    title() {
      if (Array.isArray(this.menu) || !this.menu || typeof this.menu.title === 'undefined') {
        return null
      }
      return this.menu.title
    }
  }
}
</script>

<template>
  <div @mouseover="mouseOverAction" @mouseleave="mouseLeaveAction" :class="computedClass">
    <aside class="menu-container">
      <div class="menu-wrapper">
        <slot>
          <menu-content :menu="menuList" />
        </slot>
<!--        <slot name="icon-toggle">
          <a @click="toggle" class="btn-toggle">
            <u-icon :name="isFolded ? 'chevron-right' : 'chevron-left'" type="fa"></u-icon>
          </a>
        </slot>-->
      </div>
      <a v-if="!noFolded" class="toggleBtn" @click="toggle">
        <i class="fa" :class="{'fa-chevron-right': isFolded, 'fa-chevron-left': !isFolded}" />
      </a>
    </aside>
  </div>
</template>

<style src="./menu.styl" lang="stylus"></style>

