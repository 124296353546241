<template>
  <!--<status-pagamento-lote :pagamento="lote.arremate ? lote.arremate.pagamento : null" v-if="lote.status === statusVendido && lote.arremate && lote.arremate.pagamento && !lote.arremate.pagamento.status" />-->
  <status-pagamento-lote v-if="lote.status === statusVendido && getArremate" :pagamento="{status: Number(getArremate.status)}"  />
  <status-lote-selector v-else class="no-select no-wrap text-nowrap" :status="lote.status" />
</template>

<script>
import StatusLoteSelector from './StatusLoteSelector';
import StatusPagamentoLote from './StatusPagamentoLote';
import {STATUS_VENDIDO} from '@/domain/leiloes/helpers/LoteStatus'

export default {
  name: 'StatusLote',
  components: {StatusPagamentoLote, StatusLoteSelector},
  props: ['lote', 'arremate'],
  mounted () {
    if (this.arremate) {
      this.lote.arremate = this.arremate
    }
  },
  computed: {
    statusVendido () {
      return STATUS_VENDIDO
    },
    getArremate () {
      if (this.arremate){
        return this.arremate
      }
      if (this.lote.arremate) {
        return this.lote.arremate
      }
      return null
    }
  }
}
</script>
