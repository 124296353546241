<template>
  <div class="person-avatar avatar">
    <img v-if="pessoa.photo" :src="pessoa.photo">
    <div v-else class="img-fake">{{ firstLettersName(pessoa.name) }}</div>
  </div>
</template>

<script>
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"

export default {
  name: "PersonAvatar",
  mixins: [GlobalPersonMixin],
  props: {
    pessoa: {
      required: true
    }
  }
}
</script>
