<script>
import {UContextMenu} from 'uloc-vue'
import ListMenu from './list-menu-arrematante'

export default {
  name: 'ContextMenuArrematanteItem',
  props: ['arrematante'],
  data () {
    return {
      atualizando: false
    }
  },
  computed: {},
  methods: {
  },
  components: {ListMenu, UContextMenu}
}
</script>

<template>
  <u-context-menu ref="context" class="erp-context-menu">
    <list-menu @abrir="$emit('abrir')"  @excluir="$emit('excluir')" />
  </u-context-menu>
</template>
