<template>
  <input @change="inputListener($event)" type="file" accept="image/*" id="input" ref="input"
         v-show="false">
</template>

<script>
import {fileToBase64} from "@/utils/fileToBase64"
import {uploadArquivoAvulso} from "@/domain/usuario/services";

export default {
  name: "EditorImage",
  mixins: [],
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.click()
    })
  },
  beforeDestroy() {
  },
  components: {},
  watch: {},
  methods: {
    getUploadedFileContent() {
      const file = this.$file
      return fileToBase64(file)
    },
    inputListener(e) {
      let frame = document.getElementById('frame')
      let _file = this.$file = e.target.files[0];
      // Do something with the image file.
      this.tmp = URL.createObjectURL(this.$file)
      this.changed = true
      this.delete = false
      this.type = 'upload'
      window.setTimeout(() => {
        this.getUploadedFileContent()
            .then(file => {
              uploadArquivoAvulso({
                data: file,
                file: _file,
                filename: _file.name,
              }).then(response => {
                this.$emit('uploaded', response.data)
                console.log('Uploaded file content', response.data)
              }).catch(error => {
                this.$emit('error', file)
                this.alertApiError(error)
              })
            })
            .catch(error => {
              console.error(error)
            })
        e.target.value = ''
      }, 50)
      // frame.src = URL.createObjectURL(file);
    },
  }
}
</script>
