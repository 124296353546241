<template>
  <div class="taxa-criterio">
    <div style="max-width: 200px" class="tc-col">
      <erp-select placeholder="Selecione o campo" :options="camposCriterio" v-on:input="changeTipoCampo" :value="criterio.campo" />
    </div>
    <div class="tc-col">
      <component v-bind:is="criteriaComponent" :criterio="criterio" />
    </div>
    <div style="max-width: 30px" class="flex items-center justify-center">
      <u-btn @click="removeMe" size="xs" color="grey" icon="times" icon-type="fa" flat rounded round />
    </div>
  </div>
</template>

<script>
import {ErpSelect} from 'uloc-vue-plugin-erp'
import {camposCriterio} from '@/domain/taxas/helpers/Criterios'

export default {
  name: 'Criterio',
  props: {
    criterio: {
      required: true,
      type: Object
    }
  },
  watch: {
    /*'criterio.campo' (v) {
      this.criterio.value = null
    }*/
  },
  computed: {
    camposCriterio () {
      return camposCriterio
    },
    criteriaComponent () {
      if (!this.criterio.campo) {
        return
      }
      let criterio = camposCriterio.find(c => c.value === this.criterio.campo)
      if (!criterio) {
        return
      }
      console.log(criterio)
      return criterio && criterio.component ? criterio.component : null
    }
  },
  methods: {
    changeTipoCampo (v) {
      if (this.criterio.campo === v) return
      this.criterio.campo = v
      this.criterio.value = null
    },
    removeMe () {
      this.$uloc.dialog({
        title: 'Remover critério?',
        ok: 'Sim',
        cancel: 'Cancelar'
      }).then(() => {
        let length = this.$parent.criterioGroup.length
        let index = this.$parent.criterioGroup.indexOf(this.criterio)
        console.log(length, index)
        if (index !== -1) {
          if (length === 1) {
            this.$parent.reference.splice(this.$parent.reference.indexOf(this.$parent.criterioGroup), 1) // remove array
          } else {
            this.$parent.criterioGroup.splice(this.$parent.criterioGroup.indexOf(this.criterio), 1)
          }
        }
      }).catch(() => {
      })
    }
  },
  components: {
    ErpSelect
  }
}
</script>

<style lang="stylus" scoped>
  .taxa-criterio {
    display flex
    flex-direction row
    position relative

    &::before {
      height: 40px;
      top: 19px;
      left: 16px;
      width 2px;
      background: #c0d0e6;
      content: '';
      position: absolute;
    }

    .tc-col {
      flex 1
      padding 10px 0 10px 30px
      position relative
    }
  }

  .tc-ou {
    padding-left 30px
    position relative
  }

  .taxa-criterio::after, .tc-ou::after {
    height: 2px;
    left: 17px;
    margin-top: -1px;
    top: 50%;
    width: 14px;
    background: #c0d0e6;
    content: '';
    position: absolute;
  }
</style>
