<template>
  <div :class="statusClass">{{getStatus(arrematante.status).title}}</div>
</template>

<script>
// import StatusLoteSelector from './StatusLoteSelector';
// import StatusPagamentoLote from './StatusPagamentoLote';
import * as Status from '../../../../domain/arrematante/helpers/Status'

export default {
  name: 'StatusArrematante',
  // components: {StatusPagamentoLote, StatusLoteSelector},
  props: ['arrematante'],
  computed: {
    status () {
      return Status
    },
    statusClass () {
      let css = ['arrematante-status']
      if (this.arrematante.status === Status.STATUS_PENDENTE) {
         css.push('text-warning')
      } else if (this.arrematante.status === Status.STATUS_APROVADO) {
        css.push('text-positive')
      } if (this.arrematante.status === Status.STATUS_REPROVADO) {
        css.push('text-negative')
      } if (this.arrematante.status === Status.STATUS_BLOQUEADO) {
        css.push('text-negative a-status-block font-bold')
      } if (this.arrematante.status === Status.STATUS_EM_ANALISE) {
        css.push('text-info a-status-block font-bold')
      }
      return css
    }
  },
  methods: {
    getStatus (s) {
      return Status.getStatus(s, Status.Status)
    }
  }
}
</script>

<style lang="stylus">
  .arrematante-status{
    &.text-positive{
      color #009505 !important
    }
    &.text-warning{
      color #956300 !important
    }
    &.text-negative{
      color #EF0505 !important
    }
    &.text-info{
      color #7266ba !important
    }
  }
</style>
