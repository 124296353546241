<template>
  <div class="dinput-options">
    <div v-for="(v, key) in field.valores" :key="'val-' + key" class="option-item">
      <label v-if="v != '{informar}'" class="option-item-content">
        <div class="item-input"><input type="radio" v-model="fieldValue.valor" :value="v" /></div> {{v}}
      </label>
      <label v-else class="option-item-content">
        <div class="item-input"><input type="radio" v-model="fieldValue.valor" :value="v" /></div> <input :disabled="fieldValue.valor !== v" v-model="fieldValue.valorExtra" type="text" placeholder="Outro" class="erp-input radio-extra" /></label>
    </div>
  </div>
</template>

<script>
import Mixin from "@/reuse/input/dynamic/inputs/Mixin"

export default {
  name: "DRadio",
  mixins: [Mixin]
}
</script>
