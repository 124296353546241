export default function (lotes, pagamentos) {
  let window = 'termoRetiradaLote'
  !this.$uloc.printView.get(window) && this.$uloc.printView.new({
    wid: window,
    title: 'Termo de Retirada de Lote',
    windowClass: 'erp-print',
    contentClass: '',
    props: {
      lotes: lotes
    }
  }, () => import('@/components/pda/print/views/TermoRetirada'))
    .then((wid) => {
    }) // return wid
}
