<template>
  <div class="data" :class="classes"><span><slot /></span></div>
</template>

<script>
export default {
  name: "LeilaoDataItem",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      const css = []
      this.active && css.push('active')
      return css
    }
  }
}
</script>
