import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'bem.financeiro.list'
export const listStorage = tableColumnsCache(listName, [
    {label: 'Tipo do débito', name: 'categoria', active: true, sortable: false, ordering: 1},
    {label: 'Data Vencimento', name: 'vencimento', active: true, sortable: false, ordering: 2},
    {label: 'Lançamento', name: 'lancamento', active: true, sortable: false, ordering: 3},
    {label: 'Descrição', name: 'descricao', active: true, sortable: false, ordering: 4},
    {label: 'Situação', name: 'status', active: true, sortable: false, ordering: 5},
    {label: 'Valor', name: 'valor', active: true, sortable: false, ordering: 6},
], listVersion)
