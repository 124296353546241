<template>
  <div class="erp-profile-menu">
    <div class="profile-icon">
      <img v-if="$uloc.auth.session.user && $uloc.auth.session.user.photo" :src="$uloc.auth.session.user.photo" />
      <img v-else src="https://static.suporteleiloes.com/static/default-user-1.png" />
    </div>
    <u-popover class="window-context-menu menu-profile" anchor="bottom left" self="top left" :offset="[0, 5]">
      <div>
        <h2 class="uppercase">{{ $uloc.auth.session.user.name }}</h2>
        <ul>
          <menu-options-item @click="$router.push('/perfil')" icon="address-card" icon-type="fa" label="Preferências pessoais" close  />
        </ul>
        <div class="diviser"></div>
        <h2 class="pos">CORPORATIVO</h2>
        <ul>
          <menu-options-item @click="$router.push('/sl/tarefas#paraMim')" icon="tasks" icon-type="fa" label="Minhas tarefas" close  />
          <menu-options-item @click="$router.push('/perfil/mensagens')" icon="envelope" icon-type="fa" label="Mensagens e notificações" close  />
<!--          <menu-options-item icon="calendar-alt" icon-type="fa" label="Compromissos" close  />-->
        </ul>
        <div class="diviser"></div>
        <ul>
          <menu-options-item @click="$router.push('/logout')" icon="sign-out" icon-type="fa" label="Sair" close  />
        </ul>
      </div>
    </u-popover>
  </div>
</template>

<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
export default {
  name: 'ProfileMenu',
  components: {MenuOptionsItem, UPopover},
  provide: function () {
    return {
      profileMenu: this
    }
  },
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  props: {
    user: {required: true}
  },
  data () {
    return {
    }
  },
  mounted() {
  },
  methods: {
    changeProfilePhoto (photo) {
      console.log('Change photo')
    }
  }
}
</script>
