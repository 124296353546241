<template>
  <div class="no-select" v-if="Array.isArray(leilao.statusInterno)">
    <div class="flex items-center m-b-xs" v-for="(s, i) in leilao.statusInterno">
      <span class="m-r-xs font-bold">#{{i+1}}</span> <status-interno-leilao-selector :status="s.status" />
    </div>
  </div>
  <status-interno-leilao-selector class="no-select" v-else :status="leilao.statusInterno" />
</template>

<script>
import StatusInternoLeilaoSelector from './StatusInternoLeilaoSelector'
export default {
  name: 'StatusInternoLeilao',
  components: {StatusInternoLeilaoSelector},
  props: ['leilao']
}
</script>

<style scoped>

</style>
