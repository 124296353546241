export default function (id) {
  let window = 'pagamento.lote.' + id
  let title = 'Registro de Pagamento de Lote'

  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: title,
    width: '800',
    height: '400',
    minWidth: '600px',
    minHeight: '400px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id
    }
  }, () => import('../components/window/PagamentoLote.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
