import {downloadArquivoBem} from "@/domain/bem/services"

export default {
    methods: {
        download (arquivo) {
            arquivo.downloading = true
            downloadArquivoBem(this.bem.id, arquivo.id)
                .then(response => {
                    /*const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    // link.setAttribute('download', 'file.pdf'); //or any other extension
                    document.body.appendChild(link)
                    link.click();
                    document.body.removeChild(link)*/
                    console.log(response.data)
                    const link = document.createElement('a')
                    link.href = response.data.url
                    document.body.appendChild(link)
                    link.click();
                    window.setTimeout(() => {
                        document.body.removeChild(link)
                        arquivo.downloading = false
                    }, 100)
                    // window.open(response.data.url)
                })
                .catch(error => {
                    arquivo.downloading = false
                    this.alertApiError(error)
                })
        },
    }
}
