import {listLeiloeiros, deleteLeiloeiro} from '@/domain/cadastro/services'
import window from './window/window.js'

export default {
  pageTitle: 'Leiloeiros',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.pessoa ? props.pessoa.name : props.nome
      }
    },
    {
      name: 'uf',
      required: true,
      label: 'UF',
      align: 'left',
      field: 'uf',
      sortable: true,
      parser: function (props) {
        return props.uf
      }
    },
    {
      name: 'saudacao',
      required: true,
      label: 'Saudação / Título',
      align: 'left',
      field: 'saudacao',
      sortable: true,
      parser: function (props) {
        return props.saudacao
      }
    },
    {
      name: 'matricula',
      required: true,
      label: 'Matrícula',
      align: 'left',
      field: 'matricula',
      sortable: true,
      parser: function (props) {
        return props.matricula
      }
    },
    {
      name: 'urlLogomarca',
      required: true,
      label: 'URL Logomarca',
      align: 'left',
      field: 'urlLogomarca',
      sortable: true,
      parser: function (props) {
        return props.urlLogomarca
      }
    },
    {
      name: 'urlSite',
      required: true,
      label: 'URL do Site',
      align: 'left',
      field: 'urlSite',
      sortable: true,
      parser: function (props) {
        return props.urlSite
      }
    },
    {
      name: 'endereco',
      required: true,
      label: 'Endereço',
      align: 'left',
      field: 'endereco',
      sortable: true,
      parser: function (props) {
        return props.endereco
      }
    },
    {
      name: 'telefones',
      required: true,
      label: 'Telefone(s)',
      align: 'left',
      field: 'telefones',
      sortable: true,
      parser: function (props) {
        return props.telefones
      }
    },
    {
      name: 'emails',
      required: true,
      label: 'E-mail(s)',
      align: 'left',
      field: 'emails',
      sortable: true,
      parser: function (props) {
        return props.emails
      }
    },
    {
      name: 'numeracaoNota',
      required: true,
      label: 'Numeração Nota Arrematação',
      align: 'left',
      field: 'numeracaoNota',
      sortable: true,
      parser: function (props) {
        return props.numeracaoNota
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listLeiloeiros,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    },
    excluir: deleteLeiloeiro
  }
}
