import {number_format as numberFormat} from '../utils/phpjs'
import {errorToString, systemError} from '@/utils/printError'
import {format, parseISO, formatDistance} from 'date-fns'
import {ptBR} from 'date-fns/locale'
import {checkRoleAcl} from '@/utils/security'
import {formataNumeroProcesso} from "@/utils/processo-utils"
import {callRobo} from "@/domain/processos/services/robo"

/* eslint-disable */
export default {
  install(Vue, options) {
    Vue.mixin({
      filters: {
        moeda: function (valor) {
          return numberFormat(valor, 2, ',', '.')
        },
        moedaToNumber: function (valor) {
          return Number((String(valor).replace(/[^0-9,]/g, '')).replace(',', '.'))
        },
        number: function (valor) {
          return numberFormat(valor, null, ',', '.')
        },
        formataCpf: function (cpf) {
          if (!cpf?.trim()) return '-'
          return String(cpf).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4')
        },
        formataCnpj(cnpj) {
          if (!cnpj?.trim()) return '-'
          return String(cnpj).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
        },
        formataCpfCnpj(doc) {
          return String(doc).length <= 11 ? this.formataCpf(doc) : this.formataCnpj(doc)
        },
        formataCpfCnpj2(doc) {
          return String(doc).length <= 11 ? String(doc).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4') : String(doc).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
        },
        formataCep(cep) {
          cep = '' + cep
          return cep.trim().replace(/(\d{2})(\d{3})(\d{3})/g, '$1.$2-$3')
        },
        formataTelefone(telefone) {
          if (typeof telefone === 'undefined') {
            return
          }
          if (String(telefone).match(/\D/)) {
            return telefone
          }
          return String(telefone).length === 11 ? String(telefone).replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3') : String(telefone).length === 10 ? String(telefone).replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3') : null
        },
        formatDate(d, f = 'dd/MM/yyyy HH:mm') {
          if (!d) {
            return '-'
          }
          if (d && d.date) {
            d = d.date
          }
          let result
          try {
            result = format(parseISO(d), f, {
              locale: ptBR
            })
            return result
          } catch (e) {
            console.error(e)
            return '-'
          }
        },
        formatDateNoIso(d, f = 'dd/MM/yyyy HH:mm') {
          if (d && d.date) {
            d = d.date
          }
          let result
          try {
            result = format(d, f, {
              locale: ptBR
            })
            return result
          } catch (e) {
            console.error(e)
            return '-'
          }
        },
        formatDateDistance(d) {
          return formatDistance(parseISO(d), new Date(), {addSuffix: true, locale: ptBR})
        },
        formatDateDistanceNoIso(d) {
          return formatDistance(d, new Date(), {addSuffix: true, locale: ptBR})
        },
        formataNumeroProcesso (n) {
          return formataNumeroProcesso(n)
        }
      },
      methods: {
        systemError: systemError,
        errorsToString: errorToString,
        alertApiError: function (response, title = null, color = null, message = null) {
          this.$uloc.dialog({
            className: 'erp-confirm c-error',
            title: title || 'Falha ao tentar processar!',
            color: color || 'negative',
            message: message || this.errorsToString(response)
          })
        },
        cracl: function (role, acl) {
          return checkRoleAcl(role, acl, this.$uloc.auth.session.user.roles, this.$uloc.auth.session.user.acl)
        },
        confirmarExclusao(title, confirmacao, message = null) {
          return new Promise((resolve, reject) => {
            this.$uloc.dialog({
              title: title,
              message: message || `Digite abaixo "${confirmacao}" e clique para confirmar para continuar com a exclusão:`,
              prompt: {
                model: '',
                type: 'text' // optional
              },
              cancel: true,
              color: 'negative'
            }).then(data => {
              if (data !== confirmacao) {
                this.$uloc.notify({
                  color: 'negative',
                  message: `Você não confirmou a operação. Exclusão não efetuada.`
                })
                reject()
                return
              }
              resolve()
            }).catch(() => {
              reject()
            })
          })
        },
        dg(titulo = 'Salvo com sucesso!', mensagem = 'Clique em Ok para continuar', btn = 'Ok', type = 'c-success', color = 'black') {
          return this.$uloc.dialog({
            className: 'erp-confirm ' + type,
            title: titulo,
            message: mensagem,
            ok: btn,
            noBackdropDismiss: true,
            color: color
          })
        },
        testConfig (c, trueVal = '1', _default = true) {
          if (!_default) {
            if (!this.$uloc.auth.session.user.config.globalUserConfig || !this.$uloc.auth.session.user.config.globalUserConfig[c]) return false
          }
          return !this.$uloc.auth.session.user.config.globalUserConfig || !this.$uloc.auth.session.user.config.globalUserConfig[c] || this.$uloc.auth.session.user.config.globalUserConfig[c] == trueVal
        },
        callRoboProcesso(processo) {
          this.$uloc.notify({
            color: 'black',
            message: 'Atualizando o Processo "' + processo.numero + '" o tempo de execução é de em média 45 seg.'
          })
          console.log(processo.id)
          return callRobo(processo.id)
          .then(() => {
            this.$uloc.notify({
              title: 'Processo atualizado',
              color: 'positive',
              message: 'O Processo "' + processo.numero + '" foi atualizado com sucesso'
            })
            return Promise.resolve();
          })
          .catch((err) => {
            this.$uloc.notify({
              title: 'Atualização do Processo falhou',
              color: 'negative',
              message: 'O Processo "' + processo.numero + '" foi atualizado com sucesso'
            })
            return Promise.reject(err);

          })
        },
        firstLettersName(name) {
          name = name.split(' ')
          if (Array.isArray(name) && name.length > 1) {
            return name[0].charAt(0) + name[1].charAt(0)
          }
          return String(name).substr(0, 2)
        }
      }
    })
  }
}
