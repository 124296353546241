<template>
  <div>
    <div class="pda-app-overflow flex column items-center">
      <div class="flex flex-zero__ column text-center h-full w-full">
        <div class="flex flex-zero column text-center h-full w-full">
          <transition name="slide" appear>
            <div class="transition-generic">
              <e-row class="text-left">
                <e-col>
                  <div class="m-b-sm"><h2><strong>Informe o leilão</strong> para iniciar o atendimento:</h2></div>
                </e-col>
                <e-col class="text-right" style="max-width: 320px">
                  <div class="m-b-xs font-12"><strong>Pesquise de forma avançada:</strong></div>
                  <div class="font-12 text-grey-7">Número do Lote, CPF/CNPJ ou Nome do Arrematante</div>
                </e-col>
              </e-row>
              <e-row>
                <e-col class="text-left">
                  <e-row>
                    <e-col>
                      <erp-select
                          :loading="loadingLeiloes"
                          placeholder="Leilões ativos"
                          :options="asyncdata"
                          v-model="leilao"
                          class="btn-pda btn-pda-rounded md"
                      ></erp-select>
                    </e-col>
                  </e-row>
                </e-col>
                <e-col class="text-left m-l" style="max-width: 320px">
                  <erp-input v-model="busca" @keydown.enter="buscar" class="btn-pda btn-pda-rounded md" shortkey="F2" />
                </e-col>
              </e-row>

              <e-row>
                <e-col class="text-left">
                  <pda-busca-lote ref="buscador" :busca="busca" :leiloes="leilao" ativar-filtros />
                </e-col>
              </e-row>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {ErpInput, ErpSelect} from 'uloc-vue-plugin-erp'
import appList from './app'
import PdaMenuItem from '../MenuItem'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import {find, list} from '@/domain/leiloes/services'
import PdaBuscaLote from './leiloes/BuscaLote'

export default {
  name: 'AtendimentoLeilao',
  props: ['parseProps'],
  components: {PdaBuscaLote, ECol, ERow, PdaMenuItem, ErpInput, ErpSelect},
  mounted () {
    this.loadLeiloesAtivos()
    this.$parent.labelTarget = null
    this.$parent.return = () => {
      this.$parent.app = appList.home
    }
    this.$parent.menuView = ['ata', 'pqa', 'pql', 'atl', 'rtn']
    if (this.$route.query.leilao) {
      find(this.$route.query.leilao)
          .then(response => {
            this.leilao = response.data.id
            this.$nextTick(() => {
              this.$refs.buscador.search()
            })
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  },
  data () {
    return {
      loadingLeiloes: false,
      leilao: null,
      busca: null,
      asyncdata: []
    }
  },
  watch: {
    leilao (v) {
      if (!this.leilao || this.disableWatchLeilao) {
        return
      }
      this.$nextTick(() => {
        this.$refs.buscador.search()
      })
    }
  },
  methods: {
    buscar () {
      console.log('Buscar')
      if (!this.busca || this.busca.length === 0) {
        return
      }
      // this.$refs.buscador.search()
    },
    loadLeiloesAtivos () {
      this.loading = true
      console.log('Loading leiloes')
      list(100, 1, '&pagamentoAtivo=1&sortBy=dataProximoLeilao&descending=true') // TODO: Criar filtro de ativo na requisição
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.result)) {
            return
          }
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: obj.codigo + ' ' + this.$options.filters.formatDate(obj.dataProximoLeilao.date),
              value: obj.id
            }
          })


          // !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
          this.asyncdata = list
        })
        .catch((error) => {
          this.loading = false

        })
    }
  }
}
</script>
