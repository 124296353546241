<template>
    <div class="docs-inline" v-if="leilao.documentos && leilao.documentos.length">
        <div class="m-r flex items-center">Anexos:</div>
        <div v-for="documento in leilao.documentos" :key="documento.id"
             class="font-11 wrapper-xs hoverable flex row justify-between doc">
          <a :href="documento.url" @click="download($event, documento)" target="_blank" class="text-black">
            {{documento.nome}}
            <!--<span class="font-thin text-grey-7 l-130">[{{String(documento.originalFilename).substring(0,30)}}...]</span>-->
            <span v-if="documento.permissao === 0" class="text-positive">(público)</span>
            <span v-else-if="documento.permissao === 1" class="text-gray">(arrematantes)</span>
            <span v-else-if="documento.permissao === 2" class="text-purple">(comitentes)</span>
            <span v-else-if="documento.permissao === 100" class="text-negative">(privado)</span>
          </a>

          <a @click="excluir(documento)" class="text-negative hidden-nohover delete-action">Excluir</a>
        </div>
    </div>
</template>

<script>
import uploadDocumento from '../../windows/uploadDocumento'
import {deleteDocumentoLeilao, downloadDocumentoLeilao} from '@/domain/leiloes/services'

export default {
  name: 'DocumentosLeilao',
  props: ['leilao'],
  components: {},
  mounted () {
  },
  methods: {
    uploadDocumento: uploadDocumento,
    excluir (arquivo) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um arquivo',
        message: 'Você tem certeza que deseja remover este arquivo do cadastro do leilão? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        console.log('Removendo...', arquivo)
        deleteDocumentoLeilao(this.leilao.id, arquivo.id)
          .then(response => {
            console.log(response.data)
            this.leilao.documentos.splice(this.leilao.documentos.indexOf(arquivo), 1)
            this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Arquivo excluído com sucesso.'})
          })
          .catch((error) => {
            console.log(error)
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    download (event, arquivo) {
      if (arquivo.permissao > 1) {
        event.preventDefault()
        event.stopPropagation()
        arquivo.downloading = true
        downloadDocumentoLeilao(this.leilao.id, arquivo.id)
          .then(response => {
            console.log(response.data)
            const link = document.createElement('a')
            link.href = response.data.url
            document.body.appendChild(link)
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link)
              arquivo.downloading = false
            }, 100)
          })
          .catch(error => {
            arquivo.downloading = false
            this.alertApiError(error)
          })
      }
    }
  }
}
</script>
