export {getStatus} from '../../../utils/getStatus'

export const STATUS_RASCUNHO = 0
export const STATUS_CADASTRADO = 1
export const STATUS_EM_REMOCAO = 2
export const STATUS_NO_PATIO = 3
export const STATUS_EM_LEILAO = 4
export const STATUS_DEVOLVIDO = 5
export const STATUS_DOADO = 6
export const STATUS_LEILOADO = 100

export const Status = {
  [STATUS_RASCUNHO]: {title: 'Rascunho', class: 'bem-status-0'},
  [STATUS_CADASTRADO]: {title: 'Cadastrado', class: 'bem-status-1'},
  [STATUS_EM_REMOCAO]: {title: 'Em remoção', class: 'bem-status-2'},
  [STATUS_NO_PATIO]: {title: 'No pátio', class: 'bem-status-3'},
  [STATUS_EM_LEILAO]: {title: 'Em leilão', class: 'bem-status-4'},
  [STATUS_DEVOLVIDO]: {title: 'Devolvido', class: 'bem-status-5'},
  [STATUS_DOADO]: {title: 'Doado', class: 'text-danger'},
  [STATUS_LEILOADO]: {title: 'Leiloado', class: 'text-positive'}
}

export const TIPO_VEICULO = 1
export const TIPO_IMOVEL = 2
export const TIPO_ANIMAL = 3

export const tipoMessageToCode = {
  'veiculos': TIPO_VEICULO,
  'imoveis': TIPO_IMOVEL,
  'animal': TIPO_ANIMAL,
}
