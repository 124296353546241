import http from '../../../utils/services/http'

export const list = (bemId = null, limit = 1000, page = 1, filtros = '') => {
    let url
    if (bemId) {
        url = '/api/bem/checklist?bem=' + bemId + '&page=' + page + '&limit=' + limit + filtros
    } else {
        url = '/api/bem/checklist?page=' + page + '&limit=' + limit + filtros
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id) => {
    let url = `/api/bem/checklist/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newChecklist = (data) => {
    let url = `/api/bem/checklist`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateChecklist = (checklist, method = 'PUT') => {
    let url = `/api/bem/checklist/${checklist.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, checklist)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteChecklist = (id) => {
    let url = `/api/bem/checklist/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const gerar = (bemId, extra = '') => {
    let url = `/api/bem/${bemId}/generate-checklist` + extra
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
