import Main from './Main'

export default [
  {
    path: '/website', component: Main, name: 'website.main', children: [
      {
        path: 'banners',
        name: 'website.banners',
        component: () => import('./components/banners/Banners')
      },
      {
        path: 'paginas',
        name: 'website.paginas',
        component: () => import('./components/paginas/components/Paginas')
      },
      {
        path: 'menus',
        name: 'website.menus.page',
        component: () => import('./components/menus/components/Menus')
      }
    ]
  }
]
