<template>
  <div class="m-t-lg">
    <e-row v-if="!simple">
      <e-col>
        <p class="font-bold m-b-xs font-13">{{ label }}
          <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="table.loading"/>
        </p>
      </e-col>
      <e-col class="text-right">
        <pda-menu-item :class="this.table.selected.length === 0 && 'disabled'" label="Transferir comprador"
                       shortcut="SHIFT + F3" @click.native="transferirCompradorAction"
                       class="btn-pda-def btn-pad-small"/>
      </e-col>
    </e-row>
    <div class="erp-list pda-list">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
          hide-bottom
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th v-if="!simple" auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th :style="col.name === 'lote' ? 'max-width: 25px !important' : ''" v-for="col in props.cols"
                :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr class="cursor-pointer" :class="{'cancelado': props.row.lanceCancelado && props.row.lanceCancelado.lote}"
              slot="body" slot-scope="props"
              :props="props">
          <u-td v-if="!simple" auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td key="lote" style="max-width: 25px !important" :props="props">
            <div v-if="props.row.lote">
              {{ props.row.lote.numero }}
            </div>
            <div v-else>
              {{ props.row.lanceCancelado.lote.numero }}
            </div>
          </u-td>
          <u-td :title="props.row.lote ? props.row.lote.descricao : '-'" class="td-limit l-130 uppercase"
                key="descricao"
                :props="props">
            <div v-if="props.row.lote">
              {{ props.row.lote.descricao }}
            </div>
            <div v-else>
              {{ props.row.lanceCancelado.lote.descricao }}
            </div>
          </u-td>
          <u-td key="placa" :props="props">
            {{ props.row.lote ? props.row.lote.bem.placa : (props.row.lanceCancelado ? props.row.lanceCancelado.lote.bem.placa : '') }}
          </u-td>
          <u-td key="leilao" :props="props">
            <div v-if="props.row.lote">
              {{ props.row.lote.leilao.codigo }}
            </div>
            <div v-else>
              {{ props.row.lanceCancelado.lote.leilao.codigo }}
            </div>
          </u-td>
          <u-td key="valor" :props="props">
            <div v-if="props.row.lote">
              R$ {{ props.row.lance.valor|moeda }}
            </div>
            <div v-else>
              R$ {{ props.row.lanceCancelado.valor|moeda }}
            </div>
          </u-td>
          <u-td class="font-bold" key="compra" :props="props">
            <span>{{props.row.comprador ? props.row.comprador.apelido : props.row.arrematante.apelido}}</span>
            <span class="badge badge-sm">(<span v-if="props.row.arrematante.id === arrematante.id">A</span><span v-if="props.row.comprador && props.row.comprador.id === arrematante.id">C</span>)</span>
          </u-td>
          <u-td class="text-uppercase" key="status" :props="props">
            <status-lote :lote="props.row.lote || props.row.lanceCancelado.lote"
                         :arremate="props.row"/>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Ver lote" @click="abrir(props.row.lote)" icon="folder-open"/>
                  <menu-options-item v-if="!simple" close label="Transferir comprador" label-class="text-blue"
                                     @click="transferirComprador([props.row], arrematante)"
                                     icon="exchange"/>
                  <menu-options-item v-if="!simple" close label="Cancelar venda" label-class="text-negative"
                                     @click="cancelarVenda(props.row.lote)" icon="times"/>
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <!--<context-menu-pendencias-item @abrir="abrir(props.row)" :leilao="props.row" />-->
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import MenuOptions from '../../../../layout/context-menu/context-window-options'
import {UCheckbox, UTable, UTd, UTh, UTr} from 'uloc-vue'
import {EBtnTableOptions} from 'uloc-vue-plugin-erp'
import {pendenciasVenda} from '@/domain/arrematante/services'
import loteWindow from '../../../../leiloes/windows/lote'
import StatusLote from '../../../../leiloes/components/include/StatusLote'
import PdaMenuItem from '../../MenuItem'
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
import transferirComprador from './transferirComprador'
import cancelarVenda from './cancelarVenda'

export default {
  name: 'Pendencias',
  props: {
    arrematante: {required: true},
    simple: {
      type: Boolean,
      default: false
    },
    simpleData: Array,
    label: {
      default: 'Pendências'
    },
    mostrarTudo: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10,
          rowsPerPage: 50
        },
        columns: [
          {name: 'lote', label: 'LT', field: 'lote', align: 'left', sortable: true, active: true},
          {
            name: 'descricao',
            required: true,
            label: 'Descrição',
            align: 'left',
            field: 'descricao',
            sortable: true,
            active: true
          },
          {
            name: 'placa',
            required: false,
            label: 'Placa',
            align: 'left',
            field: 'placa',
            sortable: true,
            active: false
          },
          {
            name: 'leilao',
            required: true,
            label: 'Leilão',
            align: 'left',
            field: 'leilao',
            sortable: true,
            active: true
          },
          {
            name: 'valor',
            required: true,
            label: 'Valor',
            align: 'left',
            field: 'valor',
            sortable: true,
            active: true
          },
          {
            name: 'compra',
            required: true,
            label: 'Comprador',
            align: 'left',
            field: 'compra',
            sortable: true,
            active: true
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: false,
            active: true
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false,
            active: true
          }
        ],
        filter: '',
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.table.columns.filter(item => item.active).map(item => item.name)
    },
  },
  components: {
    MenuOptionsItem,
    PdaMenuItem,
    StatusLote,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions
  },
  methods: {
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca

      })
    },
    request({pagination, filter}) {
      this.table.loading = true

      if (this.simple) {
        this.table.serverPagination = {
          page: 1,
          rowsNumber: this.simpleData.length,
          rowsPerPage: 500
        }
        // this.table.serverPagination.rowsNumber = simpleData.length
        this.table.serverData = this.simpleData
        this.table.loading = false
        return
      }

      pendenciasVenda(this.arrematante.id, !this.mostrarTudo ? '0,1' : null) // TODO: Possibilitar passar resolver via prop, pois este component é utilizado em áreas do arrematante, como todos os arremates, e não pendências.
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = this.proccessColumns(data.result)

            this.table.loading = false
          })
          .catch(error => {
            this.table.loading = false
          })
    },
    proccessColumns(data) {
      if (Array.isArray(data) && data.length > 0) {
        data.slice().map(venda => {
          let lote = venda.lote || venda.lanceCancelado.lote
          if (lote && lote.bem && lote.bem.placa) {
            let c = this.table.columns.find(c => c.name === 'placa')
            if (c && !c.active) {
              console.log('Existe placa')
              c.active = true
            }
          }
          console.log(lote)
        })
      }
      return data
    },
    abrir(lote) {
      this.loteWindow(lote)
    },
    loteWindow: loteWindow,
    transferirCompradorAction() {
      this.table.selected.length > 0 && this.transferirComprador(this.table.selected, this.arrematante)
    },
    transferirComprador: transferirComprador,
    cancelarVenda: cancelarVenda
  }
}
</script>
