import http from '../../../utils/services/http';

export const findTiposTelefone = (id) => {
  let url = `/api/tiposTelefone/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposTelefone = (limit, page, filtros) => {
  let url = '/api/tiposTelefone?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoTelefone = (data) => {
  let url = `/api/tiposTelefone`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoTelefone = (id, data) => {
  let url = `/api/tiposTelefone/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoTelefone = (id) => {
  let url = `/api/tiposTelefone/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
