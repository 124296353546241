export default function (id) {
  let window = typeof id !== 'undefined' ? `tasks.${id}.show` : 'tasks.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Tarefa ' + (id ? `#${id}` : ''),
    clickOutside: false,
    maximize: false,
    move: false,
    fullscreen: true,
    closeOnEsc: false,
    windowClass: 'sl-modal-window window-task',
    contentClass: 'overflow-hidden',
    minWidth: '1000',
    maxWidth: '1000',
    width: '95%',
    height: '98%',
    props: {
      id: id,
      $router: this.$router,
      cliente: this.cliente,
      processo: this.processo,
      autoSelect: this.autoSelect,
      entidade: this.relacaoEntidade,
      entidadeId: this.relacaoEntidadeId,
      tags: this.tags
    }
  }, () => import('../components/Tarefa'))
    .then((wid) => {
      setTimeout(() => {
        document.querySelector('.sl-modal-window').classList.add('opened')
      }, 20)
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          this.load && this.load()
        },
        created: (wid, val) => {
          this.load && this.load()
        }
      })
    }) // return wid
}
