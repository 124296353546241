export default [
  {
    path: '/bens2/lista', component: () => import('./components/ListaBensPage'), name: 'bens2.lista-page', children: [
      {
        path: '', component: () => import('./components/ListaBens'), name: 'bens2.lista',
      },
      { path: '/bens2/:id', component: () => import("@/components/bem2/components/OpenBem"), name: 'bens2.show' },
    ],
  }
]
