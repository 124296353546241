export default {
  title: 'Comitentes',
  menu: [
    {
      id: 'comitentes',
      title: 'Comitentes',
      icon: 'clipboard-list-check',
      style: 'light',
      url: '/#/comitentes/lista'
    },
    {
      id: 'relatorios',
      title: 'Relatórios',
      icon: 'user-chart',
      style: 'regular',
      url: '#'
    },
    {
      id: 'cadastros',
      title: 'Cadastros básicos',
      icon: 'cog',
      style: 'light',
      url: '/#/cadastro'
    }
  ]
}
