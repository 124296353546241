<template>
  <div class="velocimetro-container">
    <div class="velocimetro">
      <div class="pointer" :style="posicao"></div>
    </div>
    <div class="vlegend">5%</div>
  </div>
</template>

<script>
export default {
  name: "Velocimetro",
  data() {
    return {
      deg: '-90deg'
    }
  },
  mounted() {
    setTimeout(() => {
      this.deg = '-70deg'
    }, 3000)
  },
  computed: {
    posicao() {
      const css = []
      css.push({transform: `rotate(${this.deg})`})
      return css
    }
  }
}
</script>
