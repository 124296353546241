<template>
  <div class="m-t-lg">
    <e-row>
      <e-col><p class="font-bold m-b-xs font-13">Pendências / Vendas Recentes <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="table.loading"/></p></e-col>
    </e-row>
    <div class="erp-list pda-list pda-list-tela-pagamento m-b">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
          hide-bottom
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th :style="col.name === 'lote' ? 'max-width: 25px !important' : ''" v-for="col in props.cols"
                :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr v-if="!props.row.type || props.row.type !== 'resume'" class="cursor-pointer" slot="body"
              slot-scope="props"
              :props="props" :class="{cancelado: props.row.status !== statusVendido}">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs" v-if="!(props.row.status !== statusVendido)"/>
          </u-td>
          <u-td key="lote" style="max-width: 25px !important" :props="props">
            {{ props.row.numero }}
          </u-td>
          <u-td key="leilao" style="max-width: 25px !important" :props="props">
            {{ props.row.leilao.codigo }}
          </u-td>
          <u-td :title="props.row.descricao" class="td-limit l-130 uppercase" key="descricao"
                :props="props">
            {{ props.row.descricao }}
          </u-td>
          <!--<u-td key="leilao" :props="props">
            {{props.row.leilao.data.date|formatDate}}
          </u-td>-->
          <u-td key="valor" :props="props">
            R$ {{ props.row.arremate.lance.valor|moeda }}
          </u-td>
          <u-td key="taxas" :props="props">
            R$ {{ impostos(props.row)|moeda }}
          </u-td>
          <u-td key="pago" :props="props">
            R$ {{ totalPago(props.row)|moeda }}
          </u-td>
          <u-td key="saldo" :props="props">
            {{ saldo((props.row.arremate.lance.valor + impostos(props.row)), totalPago(props.row), true) }}
          </u-td>
          <u-td class="text-uppercase" key="status" :props="props">
            <status-lote :lote="props.row"/>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Ver lote" @click="abrir(props.row)" icon="folder-open"/>
                  <menu-options-item close label="Transferir comprador" label-class="text-blue" @click="$emit('abrir')"
                                     icon="exchange"/>
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <!--<context-menu-pendencias-item @abrir="abrir(props.row)" :leilao="props.row" />-->
        </u-tr>
        <u-tr v-else class="erp-table-resume-footer" slot="body" slot-scope="props"
              :props="props">
          <u-td></u-td>
          <u-td></u-td>
          <u-td></u-td>
          <u-td></u-td>
          <u-td key="valor" :props="props">
            <strong>R$ {{ props.row.totalLotes|moeda }}</strong>
          </u-td>
          <u-td style="color: #6B6102" key="taxas" :props="props">
            <strong>R$ {{ props.row.taxas|moeda }}</strong>
          </u-td>
          <u-td class="text-positive" key="pago" :props="props">
            <strong>R$ {{ props.row.totalPago|moeda }}</strong>
          </u-td>
          <u-td key="saldo" :props="props">
            <strong :class="classSaldo(props.row.total, props.row.totalPago)">{{
                saldo(props.row.total,
                    props.row.totalPago, true)
              }}</strong>
          </u-td>
          <u-td></u-td>
          <u-td></u-td>
        </u-tr>
      </u-table>

      <div v-if="semPendencias" class="m-t m-b">
        Nenhuma pendência encontrada.
      </div>
    </div>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import MenuOptions from '../../../../layout/context-menu/context-window-options'
import {UCheckbox, UTable, UTd, UTh, UTr} from 'uloc-vue'
import {EBtnTableOptions} from 'uloc-vue-plugin-erp'
import {pendenciasVendaDetalhes as pendenciasVenda} from '@/domain/arrematante/services'
import loteWindow from '../../../../leiloes/windows/lote'
import StatusLote from '../../../../leiloes/components/include/StatusLote'
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
import {STATUS_VENDIDO} from '@/domain/leiloes/helpers/LoteStatus'
import * as StatusPagamento from '@/domain/leiloes/helpers/PagamentoStatus'
import {decimal} from "@/domain/utils/money"

export default {
  name: 'PendenciasTelaPagamento',
  props: {
    arrematante: {required: true}
  },
  data() {
    return {
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10,
          rowsPerPage: 50
        },
        columns: [
          {name: 'lote', label: 'LT', field: 'lote', align: 'left', sortable: true},
          {name: 'leilao', label: 'Leilão', field: 'leilao', align: 'left', sortable: true},
          {
            name: 'descricao',
            required: true,
            label: 'Descrição',
            align: 'left',
            field: 'descricao',
            sortable: true
          },
          {
            name: 'valor',
            required: true,
            label: 'Valor',
            align: 'left',
            field: 'valor',
            sortable: true
          },
          {
            name: 'taxas',
            required: true,
            label: 'Taxas',
            align: 'left',
            field: 'taxas',
            sortable: true
          },
          {
            name: 'pago',
            required: true,
            label: 'Pago',
            align: 'left',
            field: 'pago',
            sortable: true
          },
          {
            name: 'saldo',
            required: true,
            label: 'Saldo',
            align: 'left',
            field: 'saldo',
            sortable: true
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: false
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ],
        filter: '',
        selected: [],
        loading: false
      },
      semPendencias: false
    }
  },
  watch: {
    'table.selected'() {
      this.$nextTick(() => {
        if (Array.isArray(this.table.selected) && this.table.selected.find(v => v.type && v.type === 'resume')) {
          this.table.selected = this.table.selected.filter(v => v && v.arremate)
        }
      })
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    statusVendido () {
      return STATUS_VENDIDO
    }
  },
  components: {
    MenuOptionsItem,
    StatusLote,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions
  },
  methods: {
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    filteredrData(serverData) {
      if (!Array.isArray(serverData)) {
        serverData = []
      }
      let data
      let total = 0
      let totalLotes = 0
      let taxas = 0
      let totalPago = 0
      data = serverData.filter(({lote}) => {
        if (lote.arremate && [StatusPagamento.STATUS_PENDENTE_APROVACAO, StatusPagamento.STATUS_PENDENTE, StatusPagamento.STATUS_PAGO].includes(lote.arremate.status) && lote.arremate.pagamentos) {
          let taxasLote = 0
          totalLotes = totalLotes + decimal(lote.arremate.lance.valor)
          lote.impostos && lote.impostos.map(v => {
            taxas += decimal(v.valor)
            taxasLote += decimal(v.valor)
          })
          total = total + (decimal(lote.arremate.lance.valor) + taxasLote)
          totalPago += this.totalPago(lote)
        }
        return true
      })
      data = data.map(({lote}) => lote)
      let resumo = {
        type: 'resume',
        totalLotes,
        total,
        taxas,
        totalPago
      }
      data.push(resumo)
      this.$emit('data', {
        serverData: data,
        resumo
      })
      return data
    },
    request({pagination, filter}) {
      this.table.loading = true

      pendenciasVenda(this.arrematante.id)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = this.filteredrData(data.result)

            this.table.loading = false
          })
          .catch(error => {
            this.table.loading = false
            this.$emit('data', null)
            this.semPendencias = true
          })
    },
    abrir(lote) {
      this.loteWindow(lote)
    },
    loteWindow: loteWindow,
    saldo(v1, v2, formated = false) {
      let saldo = Number(v2) - Number(v1)
      if (!formated) {
        return saldo
      }
      let prefix = 'R$'
      if (saldo > 0) {
        prefix = '+R$'
      } else if (saldo < 0) {
        prefix = '-R$'
      }
      return prefix + ' ' + this.$options.filters.moeda(Math.abs(saldo))
    },
    classSaldo(total, totalPago) {
      let saldo = this.saldo(total, totalPago)
      if (saldo > 0) return 'text-positive'
      if (saldo < 0) return 'text-negative'
      return 'text-blue-10'
    },
    impostos(lote) {
      if (!lote.impostos) {
        return 0
      }
      let impostos = 0
      lote.impostos.map(v => {
        impostos += v.valor
      })
      return impostos
    },
    totalPago(lote) {
      if (!lote.arremate.pagamentos) {
        return 0
      }
      let totalPago = 0
      lote.arremate.pagamentos.map(p => {
        if (p.status === StatusPagamento.STATUS_PAGAMENTO_ARREMATE_CONFIRMADO) {
          totalPago += p.valor
        }
      })
      return totalPago
    }
  }
}
</script>
