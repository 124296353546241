import http from '../../../utils/services/http';

export const findTiposEndereco = (id) => {
  let url = `/api/tiposEndereco/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposEndereco = (limit, page, filtros) => {
  let url = '/api/tiposEndereco?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoEndereco = (data) => {
  let url = `/api/tiposEndereco`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoEndereco = (id, data) => {
  let url = `/api/tiposEndereco/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoEndereco = (id) => {
  let url = `/api/tiposEndereco/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
