<script>
    export default {
      name: 'widget-footer',
      props: {
        'no-padding': {
          type: Boolean,
          default: false
        }
      },
      data () {
        return {}
      },
      computed: {},
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {}
    }
</script>

<template>
    <div class="widget-footer">
        <slot/>
    </div>
</template>
