<template>
    <div v-if="campos" class="m-t full-width">
      <e-row :class="css" mr>
        <e-col v-for="c in campos" :key="'campo-' + c.campo.id" :style="colStyle">
          <label class="text-black open-sans" style="font-size: 14px; font-weight: 500">{{ c.campo.nome }}</label>
          <p>
            <detect-input class="full-width" v-model="c.valor" :field="c.campo" :field-value="c" />
          </p>
        </e-col>

      </e-row>
    </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import DetectInput from "@/reuse/input/dynamic/DetectInput"

export default {
  name: "BemCampoSecao",
  props: {
    secao: {required: true},
    fullWidth: {
      type: Boolean,
      default: true
    },
    css: {
      default: 'erp-row-view col-limit-4 v2 view-wrap'
    }
  },
  components: {
    DetectInput
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  watch: {
  },
  computed: {
    campos () {
      const codigo = this.secao
      console.log(codigo)
      return this.bem.campos && this.bem.campos.filter(c => c.campo.secao && c.campo.secao.codigo === codigo)
    },
    colStyle () {
      return this.fullWidth ? 'flex: 1; min-width: 100%; width: 100%; max-width: 100%' : ''
    }
  },
  methods: {
  }
}
</script>
