export default function (id, tipo = null) {
  let window = id ? `leilao.${id}` : 'leilao.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Leilão' + (id ? ` #${id}` : ''),
    width: '1000',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    maximize: false,
    move: false,
    fullscreen: true,
    disableFixedSize: true,
    windowClass: 'bem-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id,
      tipo: tipo
    }
  }, () => import('../components/window/Leilao.vue'))
    .then((wid) => {
      setTimeout(() => {
        document.querySelector('.bem-window').classList.add('opened')
      }, 20)
      this.windowLeilaoEvents && this.windowLeilaoEvents(wid)
    }) // return wid
}
