<template>
  <e-btn-circle-status v-if="s === helper.STATUS_ABERTO_PARA_LANCES" status="grey" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_EM_PREGAO" status="purple" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_HOMOLOGANDO" status="blue" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_VENDIDO" status="positive" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_CONDICIONAL" status="purple" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_SEM_LICITANTES" status="warning" class="sm m-r-xs" />
  <u-icon v-else-if="s === helper.STATUS_BAIXA_OFERTA" name="frown" type="fa" icon-style="solid" class="fa-fw  m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_RETIRADO" status="negative" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_CANCELADO" status="negative" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_PREJUDICADO" status="warning" class="sm m-r-xs" />
  <e-btn-circle-status v-else-if="s === helper.STATUS_REPASSE" status="grey" class="sm m-r-xs" />
  <i v-else></i>
</template>

<script>
import {EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import * as Status from '../../../../domain/leiloes/helpers/LoteStatus'
export default {
  name: 'StatusLoteIcon',
  props: ['status'],
  components: {EBtnCircleStatus},
  computed: {
    helper () {
      return Status
    },
    s () {
      return parseFloat(this.status)
    }
  }
}
</script>

<style scoped>

</style>
