<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "EmptyTemplate"
}
</script>
