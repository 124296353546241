import {excluirAcessorio, listAcessorios} from '@/domain/cadastro/services'
import window from './window/windowAcessorio'

export default {
  pageTitle: 'Acessórios de Bem',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'tipo',
      required: true,
      label: 'Tipo(s) de Bem',
      align: 'left',
      field: 'tipo',
      class: 'td-limit l-130',
      sortable: true,
      parser: function (props) {
        let tipos = []
        Array.isArray(props.tiposBem) && props.tiposBem.map(v => {
          tipos.push(v.nome)
        })
        tipos = tipos.join(', ')
        if (props.tiposExcecao && Array.isArray(props.tiposExcecao) && props.tiposExcecao.length > 0) {
          let excecao = []
          props.tiposExcecao.map(v => {
            excecao.push(v.nome)
          })
          tipos += ' exceto ' + excecao.join(', ')
        }
        return tipos
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listAcessorios,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    },
    excluir: excluirAcessorio
  }
}
