<template>
  <div class="task-person-input">
    <div class="p-header">
      <u-input ref="input" @keyup="setVal" clearable inverted-light :before="[{icon: 'search', handler () {}}]" v-model="search" class="full-width bg-grey-1 b-radius-3px" />
    </div>
    <div class="p-body">
      <div class="body-content">
        <u-mark v-if="opened" @selected="selected" ref="mark" inline auto-search scroll />
      </div>
      <div class="p-footer">
        <u-btn label="Cancelar" @click="$emit('close')" no-caps  />
      </div>
    </div>
  </div>
</template>

<script>
import {UInput, UBtn} from "uloc-vue"
import {list} from '@/domain/pessoa/services'
import UMark from "@/components/layout/components/EditorComponents/Mark"
import {EscapeKey} from "uloc-vue"
const searchPerson = function (terms) {
  let filtros = encodeURI(`&tipo=1&sortBy=name&descending=false&search=${terms}`)
  return list(50, 1, filtros)
}

export default {
  name: "PessoaInput",
  data () {
    return {
      opened: false,
      search: null,
      selectedValue: null
    }
  },
  mounted() {
  },
  methods: {
    open () {
      EscapeKey.findAndPop(this.closeOnEscapeKey)
      EscapeKey.register(this.closeOnEscapeKey)
      // EscapeKey.disable()
      this.opened = true
      this.focus()
    },
    close () {
      EscapeKey.findAndPop(this.closeOnEscapeKey)
      EscapeKey.enable()
      this.opened = false
    },
    closeOnEscapeKey () {
      this.$emit('close')
    },
    focus () {
      this.$refs.input.focus()
    },
    selected (item) {
      this.selectedValue = item
      this.$emit('input', item)
      this.search = null
      this.setVal()
    },
    searchPerson(terms, done) {
      searchPerson(terms)
          .then(response => {
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    setVal () {
      this.$nextTick(() => {
        this.$refs.mark.setVal(this.search)
      })
    }
  },
  components: {
    UMark,
    UInput,
    UBtn
  }
}
</script>
