import {adicionaLotes} from '../../../../domain/leiloes/services'

export default {
  data () {
    return {
      loadingAdicionar: {
        isLoading: false,
        fechar: false,
        continuar: false
      },
      cacheBensAdicionados: []
    }
  },
  methods: {
    adicionar (fechar) {
      let bens = this.table.selected.slice().map(lt => lt.id)
      console.log('Adicionando bens selecionados', bens)
      this.loadingAdicionar.isLoading = true
      if (fechar) {
        this.loadingAdicionar.fechar = true
      } else {
        this.loadingAdicionar.continuar = true
      }
      adicionaLotes(this.leilao.id, bens)
        .then(response => {
          this.loadingAdicionar.isLoading = false
          this.loadingAdicionar.fechar = false
          this.loadingAdicionar.continuar = false
          this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Lote(s) adicionado(s) com sucesso!'})
          this.$uloc.window.emit(this.$root.wid, 'loteAdicionado')
          this.cacheBensAdicionados = this.cacheBensAdicionados.concat(bens)
          this.table.selected = []
          if (fechar) {
            this.$uloc.window.close(this.$root.wid)
          }
          console.log(response.data)
        })
        .catch(error => {
          this.loadingAdicionar.isLoading = false
          this.loadingAdicionar.fechar = false
          this.loadingAdicionar.continuar = false
          this.alertApiError(error)
          console.log(error, error.data)
        })
    }
  }
}
