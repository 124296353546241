<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Classificação'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdata"
        v-model="model"
        :multiple="multiple"
        :disable="disable"
        :simpleBorder="simpleBorder"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
// import {listSubTiposBem as list} from '../../../../domain/bem/services'

export default {
  name: 'SubtipoBemSelect',
  mixins: [SelectMixin],
  props: {
    tipo: {
      required: true
    },
    tipoData: {
      required: true
    }
  },
  data () {
    return {
      asyncdata: [],
    }
  },
  computed: {},
  mounted () {
    if (this.tipo !== null) {
      this.load(this.tipo)
    }
    // this.load()
  },
  watch: {
    tipo (v) {
      this.asyncdata = []
      // this.model = null
      this.__emit(null)
      if (!v) {
        return
      }
      let id
      if (typeof v === 'object') {
        id = v.id || null
      } else {
        id = parseFloat(v)
      }
      this.load(id)
    },
    model (v) {
      this.$nextTick(() => {
        if (!this.model) {
          return
        }
        let selected = this.asyncdata.filter(item => item.id === this.model)
        if (!selected) {
          return
        }
        this.$emit('selected', selected[0])
      })
    }
  },
  components: {},
  methods: {
    load (id) {
      let originalValue
      if (this.value) {
        originalValue = this.value
      }
      /* this.loading = true
      list(id)
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.tipos)) {
            return
          }
          let list = response.data.tipos.map((obj) => {
            return {
              ...obj,
              label: obj.nome,
              value: obj.id
            }
          })

          !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
          this.asyncdata = list
        })
        .catch((error) => {
          this.loading = false

        }) */
      let list = this.tipoData.filter(item => {
        return item.parent && item.parent.id === this.tipo
      })
      list = list.map((obj) => {
        return {
          ...obj,
          label: obj.nome,
          value: obj.id
        }
      })
      if(!list || list.length === 0) {
        list = this.tipoData.filter(item => {
          return item.id === this.tipo
        })
        this.asyncdata = list.map((obj) => {
          return {
            ...obj,
            label: obj.nome,
            value: obj.id
          }
        })
        // this.model = this.tipo
        this.__emit(this.tipo)
        return
      }
      this.asyncdata = list
      if (originalValue) { // to prevent setted model
        setTimeout(() => {
          this.model = originalValue
          this.__emit(originalValue)
        }, 100)
      }
    }
  }
}
</script>
