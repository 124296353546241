<template>
  <div v-if="getStats" class="pda-a-stats">
    <widgets :maxSlots="12" :adjusts="[{'screen': 0, 'slots': 12}, {'screen': 0, 'slots': 4}]" ref="widgets">
      <widget-item :wslot="3" :hslot="2">
        <widget-default @click.native="$emit('showArremates')" label="Total de compras" :count="getStats.compras">
          <!--<template slot="footer">
            <a>xxx</a>
            <u-spinner-uloc class="text-primary" v-else />
          </template>-->
        </widget-default>
      </widget-item>
      <widget-item :wslot="3" :hslot="2">
        <widget-default label="Compras para terceiros" :count="getStats.comprasTerceiros">
          <!--<template slot="footer">
            <a>xxx</a>
            <u-spinner-uloc class="text-primary" v-else />
          </template>-->
        </widget-default>
      </widget-item>
      <widget-item :wslot="3" :hslot="2">
        <widget-default label="Total de desistências" :count="getStats.desistencias">
          <!--<template slot="footer">
            <a>xxx</a>
            <u-spinner-uloc class="text-primary" v-else />
          </template>-->
        </widget-default>
      </widget-item>
      <widget-item :wslot="3" :hslot="2">
        <widget-default label="Saldo em conta">
          <span class="m-r-xs">R$</span> <span class="text-3x">{{ arrematante.saldo|moeda }}</span>
          <!--<template slot="footer">
            <a>xxx</a>
            <u-spinner-uloc class="text-primary" v-else />
          </template>-->
        </widget-default>
      </widget-item>
    </widgets>
  </div>
  <div v-else>
    Estatísticas de compras do arrematante não disponível
  </div>
</template>

<script>
import Widgets from '../../../../layout/widget/Widgets'
import WidgetItem from '../../../../layout/widget/WidgetItem'
import WidgetDefault from '../../../../layout/widget/widgets/WidgetDefault'

export default {
  name: 'Stats',
  props: ['arrematante', 'stats'],
  computed: {
    getStats() {
      if (this.stats) {
        return this.stats
      }
      if (this.arrematante && this.arrematante.stats) {
        return this.arrematante.stats
      }
      return null
    }
  },
  components: {WidgetDefault, WidgetItem, Widgets}
}
</script>

<style lang="stylus">
.pda-a-stats {
  .widget-header {
    font-size 13px
  }

  .widget-body {
    overflow hidden
  }
}
</style>
