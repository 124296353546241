import Vue from 'vue'
import App from './Root.vue'
import Uloc, {
  UBtn,
  UIcon,
  // UInput,
  // UInputFrame,
  // UField,
  UTooltip,
  ActionSheet,
  AddressbarColor,
  AppFullscreen,
  AppVisibility,
  Cookies,
  Dialog,
  LoadingBar,
  Loading,
  Meta,
  Notify,
  Platform,
  Screen,
  LocalStorage,
  SessionStorage,
  Window,
  // UPage,
  ShortKey
} from 'uloc-vue'
import {ErpSField, EBtn} from 'uloc-vue-plugin-erp'
import Auth from 'uloc-vue-auth'
import {JWTProvider} from 'uloc-vue-auth'
import {createIcons} from './icons'
import {createRouter} from './router'
import Utils from './plugins/utils'
import PrintView from '@/plugins/print'
import Caller from '@/components/pessoa/plugin/caller'
import VueMask from 'v-mask'
Vue.use(VueMask)

import VueTypedJs from 'vue-typed-js'
Vue.use(VueTypedJs)

Vue.config.productionTip = false

Vue.use(Uloc, {
  components: {UBtn, UIcon, UTooltip, EBtn}, // UPage, UInput, UInputFrame, UField,
  directives: {},
  plugins: {
    ActionSheet,
    AddressbarColor,
    AppFullscreen,
    AppVisibility,
    Cookies,
    Dialog,
    LoadingBar,
    Loading,
    Meta,
    Notify,
    Platform,
    Screen,
    LocalStorage,
    SessionStorage,
    Window,
    ShortKey,
    PrintView,
    Caller
  },
  config: {}
})

const router = createRouter()

Vue.use(Auth, {
  router: router,
  config: {
    loginUrl: '/login'
  }
})

// Auth.Plugin.http.defaults.baseURL = 'http://127.0.0.1:8000'
let provider = new JWTProvider({
  urlApi: process.env.VUE_APP_API,
  urlAuth: '/api/auth'
})
Auth.Provider.defineProvider(provider)
Auth.firewall().enable()

//Vue.prototype.$uloc.menu.set(Menu)

createIcons()

Vue.use(Utils)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
