import {listTiposArquivo as list} from '../../../domain/bem/services'
export default function (bem) {
  let window = `bens.${bem.id}.uploadArquivos`
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Arquivos do Bem',
    width: '70%',
    height: '80%',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window-modern',
    contentClass: '__overflow-hidden',
    props: {
      // bem: bem
      enableTitleFile: true,
      enableValidade: true,
      typeFileList: list,
      uploadService: this.uploadService,
      tipoArquivo: this.tipoArquivo || null,
      successCallback: this.uploadServiceSuccess,
      permissions: [
        {
          label: 'Público',
          value: 0
        },
        {
          label: 'Arrematante',
          value: 1
        },
        {
          label: 'Comitentes',
          value: 2
        },
        {
          label: 'Interno',
          value: 100
        }
      ]
    }
  }, () => import('../../../reuse/upload/WindowUploader.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        /*loteAdicionado: (wid, val) => {
          this.$emit('loteAdicionado')
        }*/
      })
    }) // return wid
}
