import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'bem.vistorias.list'
export const listStorage = tableColumnsCache(listName, [
    {label: 'Origem', name: 'origem', active: true, sortable: false, ordering: 1},
    {label: 'Data', name: 'data', active: true, sortable: false, ordering: 2},
    {label: 'Vistoriador', name: 'vistoriador', active: true, sortable: false, ordering: 3},
    {label: 'Conclusão', name: 'conclusao', active: true, sortable: false, ordering: 3},
    {label: 'Situação', name: 'status', active: true, sortable: false, ordering: 4},
], listVersion)
