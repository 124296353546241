<script>
    export default {
      name: 'widget-item',
      props: {
        wslot: {
          type: Number,
          default: 1
        },
        hslot: {
          type: Number,
          default: 1
        }
      },
      data () {
        return {}
      },
      computed: {},
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {}
    }
</script>

<template>
    <li>
        <div class="widget">
            <slot/>
        </div>
    </li>
</template>
