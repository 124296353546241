<template>
  <div>
    <h2 class="diviser">
      Características
    </h2>

    <div class="m-t">
      <tags class="bem-tags"/>
    </div>

    <bem-campo-secao secao="caracteristica" />
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import Tags from "@/components/bem/components/include/Tags"
import BemCampoSecao from "@/components/bem/components/include/CamposSecao"

export default {
  name: "Caracteristicas",
  components: {
    BemCampoSecao,
    Tags
    // SlCheckbox
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  watch: {
  },
  methods: {
  }
}
</script>
