import { render, staticRenderFns } from "./LocalBem.vue?vue&type=template&id=35e68da9&scoped=true&"
import script from "./LocalBem.vue?vue&type=script&lang=js&"
export * from "./LocalBem.vue?vue&type=script&lang=js&"
import style0 from "./LocalBem.vue?vue&type=style&index=0&id=35e68da9&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e68da9",
  null
  
)

export default component.exports