<template>
  <div class="menu_submenu">
    <div class="submenu-column submenu-column_1">
      <ul class="submenu_inner submenu_inner_level-1">
        <li class="submenu_item submenu_item_level-2">
          <span class="submenu_link submenu_link_nolink"><span class="submenu_link-text">Relatórios</span></span>
          <ul class="submenu_inner_level-2">
            <nav-sub-link @click="$router.push({name: 'leiloes.relatorios.resumo'})" icon="chart-pie" icon-type="fa" icon-style="solid" label="Resumo" />
            <nav-sub-link @click="$router.push({name: 'leiloes.relatorios.saida'})" icon="chart-line" icon-type="fa" icon-style="solid" label="Saída/Vendas" />
            <nav-sub-link @click="$router.push({name: 'leiloes.relatorios.lances'})" icon="chart-bar" icon-type="fa" icon-style="solid" label="Lances" />
            <nav-sub-link @click="$router.push({name: 'leiloes.relatorios'})" icon="layer-plus" icon-type="fa" icon-style="solid" label="Todos os Relatórios" />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavSubLink from './NavSubLink'
import LeilaoMixin from "@/components/leiloes/components/LeilaoMixin"
export default {
  name: 'RelatoriosSubmenu',
  mixins: [LeilaoMixin],
  components: {NavSubLink},
  inject: ['nav'],
  methods: {
    click () {
      this.nav.clickEvent()
      return true
    }
  }
}
</script>
