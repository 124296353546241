<script>
import {UContextMenu} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'

export default {
  name: 'sistema-taxacao-context',
  props: ['leilao'],
  data () {
    return {
      atualizando: false
    }
  },
  computed: {},
  methods: {
  },
  components: {MenuOptionsItem, UContextMenu}
}
</script>

<template>
  <u-context-menu ref="context" class="erp-context-menu">
    <ul>
      <!--<menu-options-item close label="Desativar" @click="$emit('desativar')" icon="folder-open" />-->
      <menu-options-item close label="Excluir" @click="$emit('excluir')" icon="times" />
    </ul>
  </u-context-menu>
</template>
