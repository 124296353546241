<template>
  <ul>
    <menu-options-item close label="Abrir lote" @click="$emit('abrir')" icon="folder-open" />
    <menu-options-item close label="Abrir bem" @click="$emit('abrirBem')" icon="folder-open" />
    <menu-options-item v-if="!disableRemoverLote" close label="Remover lote da relação" label-class="text-negative" @click="$emit('removerLote')" icon="times" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
export default {
  name: 'lote-list-menu',
  props: {
    lote: {required: false},
    disableRemoverLote: {
      type: Boolean,
      default: false
    }
  },
  components: {MenuOptionsItem}
}
</script>
