import removerAcentos from "./remover-acentos";

export default function highlightKeywords (text = '', keywords = []) {
    if (!text || !keywords?.length) return text
    if (typeof keywords === 'string') {
        keywords = JSON.parse(keywords)
    }
    const openingTag = '<strong class="text-danger">'
    const closingTag = '</strong>'

    keywords.forEach(word => {
        let tmp = removerAcentos(text)
        const regex = new RegExp(word, 'g')
        const matchesIterator = tmp.matchAll(regex)
        let beginIndex = null
        let count = 0
        for (const match of matchesIterator) {
            if (beginIndex !== null) {
                beginIndex = match.index + (openingTag.length + closingTag.length) * count
            } else {
                beginIndex = match.index
            }
            const endIndex = beginIndex + match[0].length + openingTag.length
            let start = text.substring(0, beginIndex)
            let end = text.substring(beginIndex)
            text = start + openingTag + end
            start = text.substring(0, endIndex)
            end = text.substring(endIndex)
            text = start + closingTag + end
            ++count
        }
    })
    return text
}