<template>
  <div class="stats estatistica-acesso">
    <div class="mtab">
      <div class="title">Estatística de Acesso</div>
      <div class="list1">
        <div>
          <p>Online</p>
          <div class="mavatar"><img height="57" src="../assets/online.svg"/></div>
          <div class="value">57</div>
        </div>
        <div>
          <p>Participou</p>
          <div class="mavatar"><img height="57" src="../assets/participou.svg"/></div>
          <div class="value">3</div>
          <div class="subvalue">12%</div>
        </div>
        <div>
          <p>Pico</p>
          <div class="mavatar"><img height="57" src="../assets/pico.svg"/></div>
          <div class="value">87</div>
        </div>
        <div class="comitentes">
          <p>Comitentes</p>
          <div class="mavatar"><img height="57" src="../assets/comitentes.svg"/></div>
          <div class="value">0</div>
          <div class="subvalue">Nenhum</div>
        </div>
      </div>

      <div class="log-operacional">
        <p class="title2">Log operacional</p>
        <monitor :leilao="controlador.leilao" :lote="controlador.lote"/>
      </div>
    </div>
  </div>
</template>

<script>
import Monitor from '@/components/leiloes/components/controlador/include-v2/Monitor'

export default {
  name: 'EstatisticaAcesso',
  components: {Monitor},
  inject: ['controlador'],
  computed: {}
}
</script>
