export default function (lote) {
  let window = lote ? `pda.cancelar-venda.${lote.id}` : 'pda.cancelar-venda'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Cancelar Venda',
    width: '80%',
    height: '80%',
    minWidth: '800px',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window window-negative',
    contentClass: 'overflow-hidden',
    props: {
      lote: lote
    }
  }, () => import('./CancelarVenda.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        cancel: (wid, val) => {
          console.log('Cancelado alteracao de status')
          this.load && this.load()
        },
        update: (wid, val) => {
          console.log('Atualização do status com sucesso')
          this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Status modificado com sucesso'})
          this.load && this.load()
        }
      })
    }) // return wid
}
