<template>
  <div>
    <h2 class="diviser">
      Histórico de processos
    </h2>

    <div class="m-t">
      <!--      <sl-table :columns="listStorage" :loading="loading">
              <template v-if="listData && listData.length" v-slot:body>
                <sl-table-tr v-for="data in listData" :key="data.id">
                  <sl-table-td>{{data.nome}}</sl-table-td>
                  <sl-table-td>{{data.inicio|formatDate('dd/MM/yyyy')}}</sl-table-td>
                  <sl-table-td>{{data.fim|formatDate('dd/MM/yyyy')}}</sl-table-td>
                  <sl-table-td>{{data.motivo}}</sl-table-td>
                </sl-table-tr>
              </template>
              <template v-else v-slot:body>
                <tr>
                  <td colspan="100%">Nenhum dado cadastrado.</td>
                </tr>
              </template>
            </sl-table>-->
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-w-table simple-bottom no-scroll m-t-md"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <!--          <u-th auto-width>
                      <u-checkbox
                          v-model="props.selected"
                          :indeterminate="props.partialSelected"
                          size="sm"
                      />
                    </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :style="col.name === 'motivo' ? 'text-align: right !important' : ''" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-right">
              <span class="cursor-pointer" @click="listOptions">Opções</span>
            </div>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" slot="body" slot-scope="props"
              :props="props" :class="{'u-table-item-destac': props.row.isToday}">
          <!--          <u-td auto-width>
                      <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                    </u-td>-->
          <u-td key="numero" :props="props">
            {{ props.row.numero }}
          </u-td>
          <u-td key="inicio" :props="props">
            {{ props.row.inicio|formatDate('dd/MM/yyyy') }}
          </u-td>
          <u-td key="fim" :props="props">
            {{ props.row.fim|formatDate('dd/MM/yyyy') }}
          </u-td>
          <u-td class="text-right" key="motivo" :props="props" style="width: auto">
            <div class="text-positive" v-if="props.row.active">Ativo</div>
            <div class="" v-else>{{ props.row.motivo }}</div>
          </u-td>
          <u-td key="options" class="text-right" style="max-width: 100px" :props="props">
            <e-btn-table-options>
              <menu-options>
                <list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                           @excluir="excluir(props.row.id)" :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
      <div v-if="bem.id && !loading" class="m-t">
        <u-btn @click="novo" class="sl-btn" color="positive" no-caps label="Registrar processo ao histórico"/>
      </div>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {list, deleteProcesso} from "@/domain/bem/services/historicoProcessos"
import {listStorage} from "@/components/bem/components/include/historico-processos/config/list"
import processoWindow from "@/components/bem/components/include/historico-processos/window/processoWindow"
import ListMixin from "@/reuse/list/ListMixin"

export default {
  name: "HistoricoProcessos",
  data() {
    return {
      listStorage: this.listStorage,
      loading: false,
      listData: null
    }
  },
  components: {
  },
  mounted() {
    this.bem.id && this.load()
  },
  mixins: [CadastroBemMixin, ListMixin],
  watch: {},
  beforeCreate() {
    this.listStorage = listStorage
  },
  computed: {},
  methods: {
    request({pagination, filter}) {
      console.log('LOading')

      this.table.loading = true
      const extraFilters = []
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}&${extraFilters.join('')}`)

      list(this.bem.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    processoWindow,
    novo() {
      this.processoWindow(null, this.bem)
    },
    abrir(id) {
      this.processoWindow(id, this.bem)
    },
    excluir(id) {
      this.$uloc.dialog({
        title: 'Excluir registro',
        message: 'Você tem certeza que deseja excluir este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Deletando...'})
        deleteProcesso(this.bem.id, id)
            .then(() => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    }
  }
}
</script>
