<template>
  <span>{{ dots }}</span>
</template>

<script>
export default {
  name: "DotLoading",
  props: {
    limit: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      dots: ''
    }
  },
  created() {
    this.start()
  },
  beforeDestroy() {
    this.stop()
  },
  methods: {
    start() {
      this.$interval = setInterval(() => {
        if (this.dots.length === this.limit) {
          this.dots = ''
        } else {
          this.dots = this.dots + '.'
        }
      }, 200)
    },
    stop() {
      this.$interval && clearInterval(this.$interval)
    }
  }
}
</script>
