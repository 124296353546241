<script>
export default {
  name: 'menu-options-item',
  props: {
    label: String,
    shortcut: String,
    close: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconType: {
      type: String,
      default: 'fa'
    },
    iconStyle: {
      type: String,
      default: 'regular'
    },
    labelClass: String,
    checked: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {
    closeMenu(ev) {
      let vm = this
      while ((vm = vm.$parent)) {
        const name = vm.$options.name
        if (name === 'UPopover' || name === 'UModal') {
          vm.hide(ev)
          break
        }
      }
    },
    click(evt) {
      if (this.close && !this.checked) {
        this.closeMenu(evt)
      }
      this.$emit('click', evt)
    }
  },
  components: {}
}
</script>

<template>
  <li>
    <a @click="click" class="menuItem">
      <slot>
            <span class="menuLabel">
                <div class="flex col-grow no-wrap justify-between">
                  <slot name="content">
                    <div :class="labelClass" class="flex items-center">
                      <u-icon class="m-r-xs fa-fw" v-if="icon" :name="icon" :type="iconType" :icon-style="iconStyle"/>
                      <span class="menu-label-content no-select">{{ label }}</span>
                    </div>
                    <span class="shortcut">{{ shortcut }}</span>
                    <u-icon v-if="checked" name="check" type="fa" color="grey-7" class="absolute-right"
                            style="font-size: 10px !important;"/>
                  </slot>
                </div>
            </span>
      </slot>
    </a>
  </li>
</template>
