/**
 *
 * @param name
 * @param title
 * @param component
 * @param props
 * @param config
 * @returns {function(...[*]=)}
 */
export default function (name, title, component, props, config) {
  return function (_props, _config) {
    // console.log('Eu', _props)
    let window, _title
    if (typeof _props.id !== 'undefined' && _props.id) {
      window = _props.id ? `${name}.${_props.id}` : `${name}.new`
      _title = title + ' #' + _props.id
    } else {
      window = `${name}.new`
      _title = `Novo ${title}`
    }

    if (typeof _config === 'undefined') {
      _config = {}
    }
    config = Object.assign(config, _config)

    !this.$uloc.window.get(window) && this.$uloc.window.new({
      wid: config.window || window,
      title: config.title || _title,
      width: config.width || '1000',
      height: config.height || '800',
      minWidth: config.minWidth || '1000px',
      minHeight: config.minHeight || '500px',
      backdrop: config.backdrop || false,
      clickOutside: config.clickOutside || false,
      windowClass: config.windowClass || 'erp-window',
      contentClass: config.contentClass || 'overflow-hidden',
      props: {props: props(_props)}
    }, component)
      .then((wid) => {
        console.log(wid)
        config.resolveCallback && typeof config.resolveCallback === 'function' && config.resolveCallback.call(this, wid)
      }) // return wid
  }
}
