export default function (lotes, pagamentos) {
  let window = 'recibo'
  !this.$uloc.printView.get(window) && this.$uloc.printView.new({
    wid: window,
    title: 'Recibo de Pagamento de Lote',
    windowClass: 'erp-print',
    contentClass: '',
    props: {
      lotes: lotes,
      pagamentos: pagamentos
    }
  }, () => import('@/components/pda/print/views/Recibo'))
    .then((wid) => {
    }) // return wid
}
