export {getStatus} from '../../../utils/getStatus'

export const STATUS_PENDENTE_APROVACAO = 0;
export const STATUS_APROVADO = 1;
export const STATUS_NAO_APROVADO = 2;
export const STATUS_BLOQUEADO = 3;
export const STATUS_EM_ANALISE = 4;

export const statusStrings = {
  [STATUS_PENDENTE_APROVACAO]: {label: 'Pendente', keyStats: 'pendentes'},
  [STATUS_APROVADO]: {label: 'Aprovado', keyStats: 'ativos'},
  [STATUS_NAO_APROVADO]: {label: 'Não Aprovado', keyStats: 'naoaprovados'},
  [STATUS_BLOQUEADO]: {label: 'Bloqueado', keyStats: 'bloqueados'},
  [STATUS_EM_ANALISE]: {label: 'Em análise', keyStats: 'analise'}
}
