export default function (id, bem = null) {
  let window = typeof id !== 'undefined' ? `vistoria.${id}.show` : 'vistoria.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Nova Vistoria',
    move: false,
    backdrop: true,
    clickOutside: false,
    closeOnEsc: false,
    windowClass: 'erp-window-modern v2 no-border-radius',
    width: '600',
    height: '500',
    props: {
      id: id,
      router: this.$router || this.router,
      bem: bem
    }
  }, () => import('../components/VistoriaNewEdit'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        updated: (wid, val) => {
          console.log('Update payment event')
          this.load && this.load()
          this.updateVistoria && this.updateVistoria(val)
        },
        created: (wid, val) => {
          console.log('Create payment event')
          this.load && this.load()
          this.updateVistoria && this.updateVistoria(val)
        }
      })
    }) // return wid
}
